import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function deleteStripeAccountAPI(email: string | null, id: string) {
	if (!email) return null;
	console.log("API CALL: fetchStudentAPI");

	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		const url = `https://auris-db-backend.vercel.app/deleteStripeUserAccount`;

		console.log("API CALL: deleteStripeAccount");
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: idToken,
			},
			body: JSON.stringify({ email: email.toLowerCase().trim(), studentID: id }),
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	} catch (error: any) {
		Sentry.captureException(error);
		throw error;
	}
}
