import * as t from "../../data/types";
import React, { useEffect, useState } from "react";
import { useTeacherLoggedIn } from "../../context/UserContext";
import removeStudentFromClassAPI from "../../services/api/removeStudentFromClassAPI";
import getClassesByTeacherAPI from "../../services/api/getClassesByTeacherAPI";
import deleteClassAPI from "../../services/api/deleteClassAPI";
import { useAlert } from "../../context/AlertContext";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { ADMIN_EMAIL } from "../../data/constants/constants";

enum DeletionCheckType {
	Student = "Student",
	Class = "Class",
}

export default function DeleteStudents({ classToEdit }: { classToEdit: t.Class }) {
	const [showModal, setShowModal] = useState<DeletionCheckType | null>(null);
	const [objectToDelete, setObjectToDelete] = useState<t.User | t.Class | null>(null);
	const { t } = useTranslation("ManageClasses");

	function handleDelete(object: t.User | t.Class, type: DeletionCheckType) {
		setObjectToDelete(object);
		setShowModal(type);
	}

	return (
		<div className="overviewTable">
			<table>
				<thead className="theadCSS">
					<tr>
						<th>{t("Name")}</th>
						<th>{t("Student ID")}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{classToEdit.Students?.map((student, id) => (
						<tr key={id}>
							<td>{student.FirstName + " " + student.LastName}</td>
							<td>{student.StudentNumber}</td>
							<td className="error link" onClick={() => handleDelete(student, DeletionCheckType.Student)}>
								{t("delete student")}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<br />
			<br />
			<button className="redButton" onClick={() => handleDelete(classToEdit, DeletionCheckType.Class)}>
				{t("Delete class and all its students!")}
			</button>
			<DeletionCheck
				objectToDelete={objectToDelete}
				showModal={showModal}
				closeModal={() => setShowModal(null)}
			/>
		</div>
	);
}

function DeletionCheck({
	objectToDelete,
	showModal,
	closeModal,
}: {
	objectToDelete: t.User | t.Class | null;
	showModal: DeletionCheckType | null;
	closeModal: () => void;
}): JSX.Element {
	const { scrollContentRef } = useTeacherLoggedIn();
	const { teacher, setClassData, setLoading } = useTeacherLoggedIn();
	const { triggerAlert } = useAlert();
	const [deletionCheck, setDeletionCheck] = useState<string>("");
	const [errorMessage, setErrorMessage] = useState<string>("");

	const studentToDelete = showModal === DeletionCheckType.Student ? (objectToDelete as t.User) : null;
	const classToDelete = showModal === DeletionCheckType.Class ? (objectToDelete as t.Class) : null;
	const { t } = useTranslation("ManageClasses");

	const handleCloseModal = () => {
		setErrorMessage("");
		setDeletionCheck("");
		closeModal();
	};

	const handleContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	async function handleDelete() {
		if (studentToDelete && deletionCheck !== studentToDelete.StudentNumber)
			return setErrorMessage(t("Student ID does not match"));
		if (classToDelete && deletionCheck !== classToDelete.ClassName)
			return setErrorMessage(t("Name of class does not match"));

		try {
			setLoading(true);
			if (studentToDelete?.id) await removeStudentFromClassAPI(studentToDelete.id);
			if (classToDelete) await deleteClassAPI(classToDelete.id);
			await getClassesByTeacherAPI(teacher.id, setClassData);
			if (studentToDelete) triggerAlert(t("Student successfully removed from class"), "success");
			if (classToDelete) triggerAlert(t("Class successfully deleted"), "success");
		} catch (error) {
			if (teacher?.Email !== ADMIN_EMAIL) Sentry.captureException(error);
			triggerAlert(t("Something went wrong, please try again later"), "error");
		} finally {
			setErrorMessage("");
			setDeletionCheck("");
			setLoading(false);
		}
	}
	return (
		<div className={`modal ${showModal ? "show" : ""}`} onClick={handleCloseModal}>
			<div ref={scrollContentRef} className="modal-content" onClick={handleContentClick}>
				<div>
					<div className="flexRow">
						<h2>{t("Deletion check")}</h2>
						<h2 className="close" onClick={handleCloseModal}>
							&times;
						</h2>
					</div>
					{studentToDelete && (
						<>
							<p>
								{t("Are you sure you want to delete this student?", {
									name: studentToDelete.FirstName + " " + studentToDelete.LastName,
								})}
							</p>
							<p>
								{t("Type the student's ID to confirm:")} {studentToDelete.StudentNumber}
							</p>
						</>
					)}
					{classToDelete && (
						<>
							<p>
								{t("Are you sure you want to delete this class and all its students?", {
									className: classToDelete.ClassName,
								})}
							</p>
							<p>
								{t("Type the name of the class to confirm:")} {classToDelete.ClassName}
							</p>
						</>
					)}
				</div>
				<div>
					{errorMessage && <p className="error">{errorMessage}</p>}
					<input type="text" onChange={(e) => setDeletionCheck(e.target.value)} value={deletionCheck} />
				</div>
				<button onClick={() => handleDelete()}>
					{t("Delete", {
						name: studentToDelete
							? studentToDelete.FirstName + " " + studentToDelete.LastName
							: classToDelete?.ClassName,
					})}
				</button>
			</div>
		</div>
	);
}
