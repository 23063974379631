import React from "react";
import { formatScore, spaceBeforeCapitals } from "../utils/sharedUtils";

export default function SortableTable({
	columns,
	data,
	handleSort,
	sortConfig,
	rowOnClick,
	additionalCellRender,
	putSpaceBeforeCapitals = true,
}: {
	columns: string[];
	data: any[]; // Consider typing more strictly based on usage
	handleSort: (index: number) => void;
	sortConfig: { index: number; direction: string } | null;
	rowOnClick: (id: string) => void;
	additionalCellRender?: (item: any) => JSX.Element | null;
	putSpaceBeforeCapitals?: boolean;
}) {
	return (
		<table>
			<thead className="theadCSS sortableTable">
				<tr>
					{columns.map((header, index) => (
						<th key={index} onClick={() => handleSort(index)} style={{ cursor: "pointer" }}>
							{header}
							{sortConfig && sortConfig.index === index && (sortConfig.direction === "asc" ? " ▲" : " ▼")}
						</th>
					))}
					{additionalCellRender && <th></th>}
				</tr>
			</thead>
			<tbody>
				{data.map((item, index) => (
					<tr key={index} className="clickableTableRow" onClick={() => rowOnClick(item[item.length - 1])}>
						{item.map(
							(cell: any, cellIndex: number) =>
								cellIndex < item.length - 1 && (
									<td className={cellIndex > 0 ? "alignRight" : ""} key={cellIndex}>
										{cell >= 1000
											? formatScore(cell)
											: putSpaceBeforeCapitals
											? spaceBeforeCapitals(cell)
											: cell}
										{percentage.includes(columns[cellIndex])
											? " %"
											: pts.includes(columns[cellIndex])
											? " pts"
											: time.includes(columns[cellIndex])
											? " min"
											: ""}
									</td>
								)
						)}
						{additionalCellRender && additionalCellRender(item)}
					</tr>
				))}
			</tbody>
		</table>
	);
}
// TODO: make this work for all languages
const percentage = [
	"Target",
	"Target completion",
	"Success rate",
	"Total progress",
	"Doel",
	"Doel voltooid",
	"Succespercentage",
	"Totale voortgang",
];
const pts = ["Weekly progress"];
const time = ["Practice time", "Oefentijd"];
