import * as e from "../../enums";
import * as intervalComparison from "./intervalComparison";
import * as intervalRecognition from "./intervalRecognition";
import * as intervalRecognition2 from "./intervalRecognitionOctaveHigher";
import * as harmonicContext from "./harmonicContext";
import * as harmonicContext_Chromatic from "./harmonicContext_Chromatic";
import * as scaleRecognition from "./scaleRecognition";
import * as melodicDictation from "./melodicDictation";
import * as chordRecognition from "./chordRecognition";
import * as triadInversions from "./triadInversions";
import * as progressionsMajor from "./progressionsMajor";
import * as progressionsMajorCombos from "./progressionsMajorCombos";
import * as progressionsMinor from "./progressionsMinor";
import * as progressionsMinorCombos from "./progressionsMinorCombos";
import * as progressionsJazz from "./progressionsJazz";
import * as progressionsJazzModulations from "./progressionsJazzModulations";
import * as progressionsJazzSecDomMajor from "./progressionsJazzSecDomMajor";
import * as progressionsJazzSecDomMinor from "./progressionsJazzSecDomMinor";

export const INTERVAL_COMPARISON: Record<e.DifficultyIntervalComparison, any> = {
	[e.DifficultyIntervalComparison.ONE_OCTAVE]: intervalComparison.INTERVAL_COMPARISON_SECTIONS,
};

export const INTERVAL_RECOGNITION: Record<e.DifficultyIntervalRecognition, any> = {
	[e.DifficultyIntervalRecognition.ONE_OCTAVE]: intervalRecognition.INTERVAL_RECOGNITION_SECTIONS,
	[e.DifficultyIntervalRecognition.OCTAVE_HIGHER]: intervalRecognition2.INTERVAL_RECOGNITION_OCTAVE_HIGHER_SECTIONS,
};

export const HARMONIC_CONTEXT: Record<e.DifficultyHarmonicContext, any> = {
	[e.DifficultyHarmonicContext.IONIAN]: harmonicContext.HARMONIC_CONTEXT_I_SECTIONS,
	[e.DifficultyHarmonicContext.DORIAN]: harmonicContext.HARMONIC_CONTEXT_II_SECTIONS,
	[e.DifficultyHarmonicContext.PHRYGIAN]: harmonicContext.HARMONIC_CONTEXT_III_SECTIONS,
	[e.DifficultyHarmonicContext.LYDIAN]: harmonicContext.HARMONIC_CONTEXT_IV_SECTIONS,
	[e.DifficultyHarmonicContext.MIXOLYDIAN]: harmonicContext.HARMONIC_CONTEXT_V_SECTIONS,
	[e.DifficultyHarmonicContext.AEOLIAN]: harmonicContext.HARMONIC_CONTEXT_VI_SECTIONS,
	[e.DifficultyHarmonicContext.LOCRIAN]: harmonicContext.HARMONIC_CONTEXT_VII_SECTIONS,
	[e.DifficultyHarmonicContext.CHROMATIC]: harmonicContext_Chromatic.HARMONIC_CONTEXT_CHROMATIC_SECTIONS,
};

export const SCALE_RECOGNITION: Record<e.DifficultyScaleRecognition, any> = {
	[e.DifficultyScaleRecognition.ALTERNATING]: scaleRecognition.SCALE_RECOGNITION_SECTIONS,
	[e.DifficultyScaleRecognition.ASCENDING]: scaleRecognition.SCALE_RECOGNITION_SECTIONS,
	[e.DifficultyScaleRecognition.DESCENDING]: scaleRecognition.SCALE_RECOGNITION_SECTIONS,
	[e.DifficultyScaleRecognition.MELODY]: scaleRecognition.SCALE_RECOGNITION_SECTIONS,
};

export const MELODIC_DICTATION: Record<e.DifficultyMelodicDictation, any> = {
	[e.DifficultyMelodicDictation.SHORT_ONE_OCTAVE]: melodicDictation.MELODIC_DICTATION_SECTIONS,
	[e.DifficultyMelodicDictation.SHORT_TWO_OCTAVES]: melodicDictation.MELODIC_DICTATION_TWO_OCTAVES_SECTIONS,
	[e.DifficultyMelodicDictation.LONG_ONE_OCTAVE]: melodicDictation.MELODIC_DICTATION_SECTIONS,
	[e.DifficultyMelodicDictation.LONG_TWO_OCTAVES]: melodicDictation.MELODIC_DICTATION_TWO_OCTAVES_SECTIONS,
};

export const CHORD_RECOGNITION: Record<e.DifficultyChordRecognition, any> = {
	[e.DifficultyChordRecognition.STANDARD_VOICING]: chordRecognition.CHORD_RECOGNITION_SECTIONS,
	[e.DifficultyChordRecognition.RANDOM_VOICING]: chordRecognition.CHORD_RECOGNITION_SECTIONS,
};

export const TRIAD_INVERSIONS: Record<e.DifficultyTriadInversions, any> = {
	[e.DifficultyTriadInversions.STANDARD_VOICING]: triadInversions.TRIAD_INVERSIONS,
	[e.DifficultyTriadInversions.RANDOM_VOICING]: triadInversions.TRIAD_INVERSIONS,
};

export const PROGRESSIONS_MAJOR: Record<e.DifficultyProgressions, any> = {
	[e.DifficultyProgressions.ROOT]: progressionsMajor.PROGRESSION_MAJOR_SECTIONS,
	[e.DifficultyProgressions.FIRST_INVERSION]: progressionsMajor.PROGRESSION_MAJOR_SECTIONS_FIRST_INVERSIONS,
	[e.DifficultyProgressions.ROOT_AND_FIRST_INVERSION]:
		progressionsMajorCombos.PROGRESSION_MAJOR_SECTIONS_ROOT_AND_FIRST_INVERSIONS,
	[e.DifficultyProgressions.SECOND_INVERSION]: progressionsMajor.PROGRESSION_MAJOR_SECTIONS_SECOND_INVERSIONS,
	[e.DifficultyProgressions.ROOT_AND_SECOND_INVERSION]:
		progressionsMajorCombos.PROGRESSION_MAJOR_SECTIONS_ROOT_AND_SECOND_INVERSIONS,
	[e.DifficultyProgressions.ALL]: progressionsMajorCombos.PROGRESSION_MAJOR_SECTIONS_ALL,
};

export const PROGRESSIONS_MINOR: Record<e.DifficultyProgressions, any> = {
	[e.DifficultyProgressions.ROOT]: progressionsMinor.PROGRESSION_MINOR_SECTIONS,
	[e.DifficultyProgressions.FIRST_INVERSION]: progressionsMinor.PROGRESSION_MINOR_SECTIONS_FIRST_INVERSIONS,
	[e.DifficultyProgressions.ROOT_AND_FIRST_INVERSION]:
		progressionsMinorCombos.PROGRESSION_MINOR_SECTIONS_ROOT_AND_FIRST_INVERSIONS,
	[e.DifficultyProgressions.SECOND_INVERSION]: progressionsMinor.PROGRESSION_MINOR_SECTIONS_SECOND_INVERSIONS,
	[e.DifficultyProgressions.ROOT_AND_SECOND_INVERSION]:
		progressionsMinorCombos.PROGRESSION_MINOR_SECTIONS_ROOT_AND_SECOND_INVERSIONS,
	[e.DifficultyProgressions.ALL]: progressionsMinorCombos.PROGRESSION_MINOR_SECTIONS_ALL,
};

export const PROGRESSIONS_JAZZ: Record<e.DifficultyProgressionsJazz, any> = {
	[e.DifficultyProgressionsJazz.COMMON_PROGRESSIONS]: progressionsJazz.PROGRESSION_JAZZ_SECTIONS,
	[e.DifficultyProgressionsJazz.MODULATIONS_MAJOR]: progressionsJazzModulations.PROGRESSION_JAZZ_MODULATIONS_MAJOR,
	[e.DifficultyProgressionsJazz.SECONDARY_DOMINANT_MAJOR]:
		progressionsJazzSecDomMajor.PROGRESSION_JAZZ_SEC_DOM_MAJOR_SECTIONS,
	[e.DifficultyProgressionsJazz.SECONDARY_DOMINANT_MINOR]:
		progressionsJazzSecDomMinor.PROGRESSION_JAZZ_SEC_DOM_MINOR_SECTIONS,
};
