import { createSection } from "./utils";
import * as e from "../../enums";

const j = e.DegreesJazz;

const secDomii = [j.IImaj7, j.ii7overII, j.V7overII];
const secDomiii = [j.IIImaj7, j.ii7overIII, j.V7overIII];
const secDomiv = [j.IVmaj7, j.ii7overIV, j.V7overIV];
const secDomv = [j.Vmaj7, j.ii7overV, j.V7overV];
const secDomvi = [j.VImaj7, j.ii7overVI, j.V7overVI];

const secDomiiTritone = [...secDomii, j.bII7overII];
const secDomiiiTritone = [...secDomiii, j.bII7overIII];
const secDomivTritone = [...secDomiv, j.bII7overIV];
const secDomvTritone = [...secDomv, j.bII7overV];
const secDomviTritone = [...secDomvi, j.bII7overVI];

const secondaryDominantsWithRelatedTwoChordsMinor1 = [
	{
		options: [j.i7, ...secDomiiTritone],
		levelName: "Secondary dominant and it's tritone substitution over II",
	},
	{
		options: [j.i7, ...secDomiiiTritone],
		levelName: "Secondary dominant and it's tritone substitution over III",
	},
	{
		options: [j.i7, ...secDomivTritone],
		levelName: "Secondary dominant and it's tritone substitution over IV",
	},
	{
		options: [j.i7, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over V",
	},
	{
		options: [j.i7, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over VI",
	},
];

const secondaryDominantsWithRelatedTwoChordsMinor2 = [
	{
		options: [j.i7, ...secDomii, ...secDomiii],
		levelName: "Secondary dominant over II and III",
	},
	{
		options: [j.i7, ...secDomii, ...secDomiv],
		levelName: "Secondary dominant over II and IV",
	},
	{
		options: [j.i7, ...secDomii, ...secDomv],
		levelName: "Secondary dominant over II and V",
	},
	{
		options: [j.i7, ...secDomii, ...secDomvi],
		levelName: "Secondary dominant over II and VI",
	},
	{
		options: [j.i7, ...secDomiii, ...secDomiv],
		levelName: "Secondary dominant over III and IV",
	},
];

const secondaryDominantsWithRelatedTwoChordsMinor3 = [
	{
		options: [j.i7, ...secDomiii, ...secDomv],
		levelName: "Secondary dominant over III and V",
	},
	{
		options: [j.i7, ...secDomiii, ...secDomvi],
		levelName: "Secondary dominant over III and VI",
	},
	{
		options: [j.i7, ...secDomiv, ...secDomv],
		levelName: "Secondary dominant over IV and V",
	},
	{
		options: [j.i7, ...secDomiv, ...secDomvi],
		levelName: "Secondary dominant over IV and VI",
	},
	{
		options: [j.i7, ...secDomv, ...secDomvi],
		levelName: "Secondary dominant over V and VI",
	},
];

const secondaryDominantsWithRelatedTwoChordsMinor4 = [
	{
		options: [j.i7, ...secDomiiTritone, ...secDomiiiTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II and III",
	},
	{
		options: [j.i7, ...secDomiiTritone, ...secDomivTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II and IV",
	},
	{
		options: [j.i7, ...secDomiiTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II and V",
	},
	{
		options: [j.i7, ...secDomiiTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II and VI",
	},
	{
		options: [j.i7, ...secDomiiiTritone, ...secDomivTritone],
		levelName: "Secondary dominant and it's tritone substitution over over III and IV",
	},
];

const secondaryDominantsWithRelatedTwoChordsMinor5 = [
	{
		options: [j.i7, ...secDomiiiTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over III and V",
	},
	{
		options: [j.i7, ...secDomiiiTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over III and VI",
	},
	{
		options: [j.i7, ...secDomivTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over IV and V",
	},
	{
		options: [j.i7, ...secDomivTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over IV and VI",
	},
	{
		options: [j.i7, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over V and VI",
	},
];

const secondaryDominantsWithRelatedTwoChordsMinor6 = [
	{
		options: [j.i7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomivTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II, III and IV",
	},
	{
		options: [j.i7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II, III and V",
	},
	{
		options: [j.i7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II, III and VI",
	},
	{
		options: [j.i7, ...secDomiiTritone, ...secDomivTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II, IV and V",
	},
];

const secondaryDominantsWithRelatedTwoChordsMinor7 = [
	{
		options: [j.i7, ...secDomiiTritone, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II, V and VI",
	},
	{
		options: [j.i7, ...secDomiiiTritone, ...secDomivTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over III, IV and V",
	},
	{
		options: [j.i7, ...secDomiiiTritone, ...secDomivTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over III, IV and VI",
	},
	{
		options: [j.i7, ...secDomivTritone, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over IV, V and VI",
	},
];

const secondaryDominantsWithRelatedTwoChordsMinor8 = [
	{
		options: [j.i7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomivTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II, III, IV, V",
	},
	{
		options: [j.i7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomivTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II, III, IV, VI",
	},
	{
		options: [j.i7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II, III, V, VI",
	},
	{
		options: [j.i7, ...secDomiiTritone, ...secDomivTritone, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over II, IV, V, VI",
	},
];
const secondaryDominantsWithRelatedTwoChordsMinor9 = [
	{
		options: [
			j.i7,
			...secDomiiTritone,
			...secDomiiiTritone,
			...secDomivTritone,
			...secDomvTritone,
			...secDomviTritone,
		],
		levelName: "All combinations of secondary dominant and it's tritone substitution",
	},
];

export const PROGRESSION_JAZZ_SEC_DOM_MINOR_SECTIONS = [
	createSection("Secondary Dominants With Related Two Chords 1", secondaryDominantsWithRelatedTwoChordsMinor1),
	createSection("Secondary Dominants With Related Two Chords 2", secondaryDominantsWithRelatedTwoChordsMinor2),
	createSection("Secondary Dominants With Related Two Chords 3", secondaryDominantsWithRelatedTwoChordsMinor3),
	createSection("Secondary Dominants With Related Two Chords 4", secondaryDominantsWithRelatedTwoChordsMinor4),
	createSection("Secondary Dominants With Related Two Chords 5", secondaryDominantsWithRelatedTwoChordsMinor5),
	createSection("Secondary Dominants With Related Two Chords 6", secondaryDominantsWithRelatedTwoChordsMinor6),
	createSection("Secondary Dominants With Related Two Chords 7", secondaryDominantsWithRelatedTwoChordsMinor7),
	createSection("Secondary Dominants With Related Two Chords 8", secondaryDominantsWithRelatedTwoChordsMinor8),
	createSection("Boss level", secondaryDominantsWithRelatedTwoChordsMinor9),
];
