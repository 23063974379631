import React, { useEffect, useState } from "react";
import * as e from "../../../data/enums";
import * as t from "../../../data/types";
import * as c from "../../../data/constants/constants";
import { useTeacherLoggedIn } from "../../../context/UserContext";
import {
	isDictationModule,
	spaceBeforeCapitals,
	spaceBeforeCaptialsNumbersAndEnpercent,
} from "../../../utils/sharedUtils";
import { determineDifficulties, determineMovement, getMovementDescription } from "./utils";
import SelectExamOptions from "./SelectExamOptions";
import { Diff } from "lucide-react";
import { useTranslation } from "react-i18next";

const OPTION_NAME: Record<e.Modules, string> = {
	[e.Modules.INTERVAL_COMPARISON]: "intervals",
	[e.Modules.INTERVAL_RECOGNITION]: "intervals",
	[e.Modules.HARMONIC_CONTEXT]: "intervals",
	[e.Modules.SCALE_RECOGNITION]: "scales",
	[e.Modules.MELODIC_DICTATION]: "intervals for the melodic dictation",
	[e.Modules.CHORD_RECOGNITION]: "chords",
	[e.Modules.TRIAD_INVERSIONS]: "inversions",
	[e.Modules.PROGRESSIONS_MAJOR]: "chord degrees",
	[e.Modules.PROGRESSIONS_MINOR]: "chord degrees",
	[e.Modules.PROGRESSIONS_JAZZ]: "chord degrees",
};

export default function CreateQuestionModal({
	examContent,
	setExamContent,
	showModal,
	onClose,
	questionToEdit,
	setQuestionToEdit,
}: {
	examContent: t.ExamContent[];
	setExamContent: t.ExamContent[] | any;
	showModal: boolean;
	onClose: () => void;
	questionToEdit: number | undefined;
	setQuestionToEdit: number | any;
}) {
	const { scrollContentRef } = useTeacherLoggedIn();
	const [module, setModule] = useState<e.Modules | null>(null);
	const [dynamic, setDynamic] = useState<e.Dynamics | null>(null);
	const [movement, setMovement] = useState<e.Movement | null>(null);
	const [movementOptions, setMovementOptions] = useState<e.Movement[]>([]);
	const [difficulty, setDifficulty] = useState<string | null>(null);
	const [difficultyOptions, setDifficultyOptions] = useState<string[]>([]);
	const [options, setOptions] = useState<string[]>([]);
	const [numberOfQuestions, setNumberOfQuestions] = useState<number | null>(10);
	const [questionReplays, setQuestionReplays] = useState<number | null>(0);
	const [responseTime, setResponseTime] = useState<number | null>(5);
	const [description, setDescription] = useState<string | null>(null);
	const { t } = useTranslation("ManageClasses");

	useEffect(() => {
		// console.log("useEffect", questionToEdit);
		if (questionToEdit === undefined) return;
		const question = examContent[questionToEdit];
		if (question.Module) setModule(question.Module);
		if (question.Difficulty) setDifficulty(question.Difficulty);
		if (question.Dynamic) setDynamic(question.Dynamic);
		if (question.Movement) setMovement(question.Movement);
		// if (question.Options) setOptions(question.Options);
		if (question.NumberOfQuestions) setNumberOfQuestions(question.NumberOfQuestions);
		if (question.Replays) setQuestionReplays(question.Replays);
		if (question.ResponseTime) setResponseTime(question.ResponseTime);
		if (question.Description) setDescription(question.Description);
	}, [questionToEdit]);

	useEffect(() => {
		if (module === null) return;
		determineDifficulties(module, setDifficultyOptions);
		if (questionToEdit !== undefined) return;
		setDifficulty(null);
		setMovement(null);
		setNumberOfQuestions(c.NUMBER_OF_QUESTION_MAP[module]);
		setResponseTime(c.RESPONSE_TIMES[module]);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [module]);

	useEffect(() => {
		if (!module || !difficulty) return;
		setOptions([]);
		determineMovement(module, difficulty, setMovementOptions);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [difficulty, module]);

	let optionTreshold = module && isDictationModule(module) ? 3 : 2;
	let readyToSubmit =
		module && dynamic && movement && options && options.length >= optionTreshold && numberOfQuestions;

	useEffect(() => {
		if (!showModal) {
			setModule(null);
			setDynamic(e.Dynamics.DYNAMIC);
			setMovement(null);
			setOptions([]);
			setNumberOfQuestions(null);
		}
	}, [showModal]);

	// console.log(options);

	// TODO: kan dit weg? of oplossen met useCallback of useState?
	useEffect(() => {
		optionTreshold = module && isDictationModule(module) ? 3 : 2;
		readyToSubmit =
			module &&
			dynamic &&
			movement &&
			// difficulty &&
			options &&
			options.length >= optionTreshold &&
			numberOfQuestions;
	}, [module]);

	const handleContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	const handleSetModule = (module: e.Modules) => {
		setModule(module);
		setOptions([]);
	};

	const handleSetDifficulty = (difficulty: string) => {
		setDifficulty(difficulty);
		setOptions([]);
	};

	const questionOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20];
	const replayOptions = [0, 1, 2, 3, 4, 5];
	const responseTimeOptions = [5, 10, 15, 20, 25, 30, 45, 60, 120, 180, 240];

	const addQuestionsToExam = () => {
		if (questionToEdit !== undefined) {
			examContent[questionToEdit] = {
				Module: module as e.Modules,
				Difficulty: difficulty as string,
				Dynamic: dynamic as e.Dynamics,
				Movement: movement as e.Movement,
				Options: options as string[],
				NumberOfQuestions: numberOfQuestions as number,
				Replays: questionReplays as number,
				ResponseTime: responseTime as number,
				Description: description as string,
			};
			setExamContent(examContent);
			setQuestionToEdit(undefined);
		} else
			setExamContent((prevContent: t.ExamContent[]) => [
				...prevContent,
				{
					Module: module,
					Difficulty: difficulty,
					Dynamic: dynamic,
					Movement: movement,
					Options: options,
					NumberOfQuestions: numberOfQuestions,
					Replays: questionReplays,
					ResponseTime: responseTime,
					Description: description,
				},
			]);
		onClose();
	};

	return (
		<div className={`modal ${showModal ? "show" : ""}`} onClick={onClose}>
			<div ref={scrollContentRef} className="modal-content" onClick={handleContentClick}>
				<div className="flexRow">
					<h2>{t("addQuestionsTitle")}</h2>
					<h2 className="close" onClick={onClose}>
						&times;
					</h2>
				</div>
				<div className="">
					{t("moduleLabel")}
					<select onChange={(e) => handleSetModule(e.target.value as e.Modules)} value={module || ""}>
						<option value="" disabled>
							{t("selectModulePlaceholder")}
						</option>
						{Object.values(e.Modules).map((moduleKey) => (
							<option key={moduleKey} value={moduleKey}>
								{spaceBeforeCapitals(moduleKey)}
							</option>
						))}
					</select>
				</div>
				<div className="flexRow">
					<div className="examOption">
						{t("modeLabel")}
						{!module ? (
							<select disabled>
								<option value="" disabled>
									{t("selectModuleFirst")}
								</option>
							</select>
						) : (
							<select onChange={(e) => handleSetDifficulty(e.target.value)} value={difficulty || ""}>
								<option value="" disabled>
									{t("selectModePlaceholder")}
								</option>
								{difficultyOptions &&
									difficultyOptions.map((option: string) => (
										<option key={option} value={option}>
											{spaceBeforeCaptialsNumbersAndEnpercent(option)}
										</option>
									))}
							</select>
						)}
					</div>
					<div className="examOption">
						{t("movementLabel")}
						{!module || !difficulty ? (
							<select disabled>
								<option value="" disabled>
									{t("selectModeFirst")}
								</option>
							</select>
						) : (
							<select onChange={(e) => setMovement(e.target.value as e.Movement)} value={movement || ""}>
								<option value="" disabled>
									{t("selectMovementPlaceholder")}
								</option>
								{movementOptions.map((movement: e.Movement) => (
									<option key={movement} value={movement}>
										{spaceBeforeCapitals(getMovementDescription(module, movement))}
									</option>
								))}
							</select>
						)}
					</div>
					<div className="examOption">
						{t("rootBehaviourLabel")}
						{!movement ? (
							<select disabled>
								<option value="" disabled>
									{t("selectMovementFirst")}
								</option>
							</select>
						) : (
							<select onChange={(e) => setDynamic(e.target.value as e.Dynamics)} value={dynamic || ""}>
								<option value="" disabled>
									{t("selectRootBehaviourPlaceholder")}
								</option>
								<option value={e.Dynamics.STATIC}>{t("staticRoot")}</option>
								<option value={e.Dynamics.DYNAMIC}>{t("dynamicRoot")}</option>
							</select>
						)}
					</div>
				</div>
				<div className="flexRow">
					<div className="examOption">
						{t("numberOfQuestionsLabel")}
						<select
							onChange={(e) => setNumberOfQuestions(Number(e.target.value))}
							value={numberOfQuestions || ""}
						>
							<option value="" disabled>
								{t("selectNumberOfQuestionsPlaceholder")}
							</option>
							{questionOptions.map((option: number) => (
								<option key={option} value={option}>
									{option}
								</option>
							))}
						</select>
					</div>

					<div className="examOption">
						{t("numberOfReplaysLabel")}
						<select
							onChange={(e) => setQuestionReplays(Number(e.target.value))}
							value={questionReplays === null ? "" : questionReplays}
						>
							<option value="" disabled>
								{t("selectNumberOfReplaysPlaceholder")}
							</option>
							{replayOptions.map((option: number) => (
								<option key={option} value={option}>
									{option} {t("numberOfReplaysSuffix")}
								</option>
							))}
						</select>
					</div>

					<div className="examOption">
						{t("responseTimeLabel")}
						<select onChange={(e) => setResponseTime(Number(e.target.value))} value={responseTime || ""}>
							<option value="" disabled>
								{t("selectResponseTimePlaceholder")}
							</option>
							{responseTimeOptions.map((option: number) => (
								<option key={option} value={option}>
									{option} {t("responseTimeSuffix")}
								</option>
							))}
						</select>
					</div>
				</div>

				<div className="">
					{t("leaveDescriptionLabel")}
					<textarea onChange={(e) => setDescription(e.target.value)} value={description || ""} />
				</div>

				{module && difficulty && (
					<>
						<div className="">
							<h3>
								{t("selectOptionsHeading", { optionName: OPTION_NAME[module] })}
							</h3>
							{t("randomlyGeneratedFromOptions")}
							<SelectExamOptions
								module={module}
								difficulty={difficulty}
								selection={options}
								setOption={setOptions}
							/>
						</div>
						{options && options.length < optionTreshold && (
							<p className="error">
								{t("selectAtLeastOptionsError", {
									optionTreshold: optionTreshold,
									optionName: OPTION_NAME[module],
								})}
							</p>
						)}
					</>
				)}
				<button
					onClick={addQuestionsToExam}
					className={readyToSubmit ? "" : "buttonDisabled"}
					disabled={!readyToSubmit}
				>
					{t("addQuestionsButton")}
				</button>
			</div>
		</div>
	);
}
