import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import addSchoolAPI from "../../services/api/addSchoolAPI";
import { isSafariBrowser } from "../../utils/deviceCheck";

export default function AddSchool(): JSX.Element {
	const [schoolName, setSchoolName] = useState("");
	const [schoolCountry, setSchoolCountry] = useState("");
	const [schoolEmailDomain, setSchoolEmailDomain] = useState("");
	const randomCoupon = Math.random().toString(36).substring(2, 10).toUpperCase();
	const [coupon, setCoupon] = useState(randomCoupon);

	const addSchool = async () => {
		try {
			const response = await addSchoolAPI(schoolName, schoolCountry, schoolEmailDomain, coupon);
			if (response.status === 200) {
				alert("School added successfully");
				window.location.reload();
			}
		} catch (error: any) {
			const axiosError = error as AxiosError;
			if (axiosError.response && axiosError.response.status === 400) alert("School already exists");
			console.error(error);
		}
	};

	return (
		<div className={`basicCard loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
			<h1>Add School</h1>
			<br /> <br />
			<input
				className="text-area"
				type="text"
				placeholder="School Name"
				onChange={(e) => setSchoolName(e.target.value)}
			/>
			<br /> <br />
			<input
				className="text-area"
				type="text"
				placeholder="Country"
				onChange={(e) => setSchoolCountry(e.target.value)}
			/>
			<br /> <br />
			<input
				className="text-area"
				type="email"
				placeholder="@schoolname.com"
				onInput={(e: any) => {
					const lowercaseValue = e.target.value.toLowerCase();
					e.target.value = lowercaseValue;
					setSchoolEmailDomain(lowercaseValue);
				}}
			/>
			<br /> <br />
			<input
				className="text-area"
				type="coupon"
				placeholder={coupon}
				value={coupon}
				onInput={(e: any) => {
					const uppercaseValue = e.target.value.toUpperCase();
					e.target.value = uppercaseValue;
					setCoupon(uppercaseValue);
				}}
			/>
			<br /> <br />
			<button onClick={addSchool}>Add School</button>
		</div>
	);
}
