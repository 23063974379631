import * as e from "../../enums";
import * as music from "../musical";
import { createSection } from "./utils";

const intervalLevels = [
	{ options: ["1", "b2"], levelName: "Minor 2nd" },
	{ options: ["1", "2"], levelName: "Major 2nd" },
	{ options: ["1", "b3"], levelName: "Minor 3rd" },
	{ options: ["1", "3"], levelName: "Major 3rd" },
	{ options: ["1", "4"], levelName: "Perfect 4th" },
	{ options: ["1", "b5"], levelName: "Tritone" },
	{ options: ["1", "5"], levelName: "Perfect 5th" },
	{ options: ["1", "b6"], levelName: "Minor 6th" },
	{ options: ["1", "6"], levelName: "Major 6th" },
	{ options: ["1", "b7"], levelName: "Minor 7th" },
	{ options: ["1", "7"], levelName: "Major 7th" },
];

const intervalLevels2 = [
	{ options: ["1", "b2", "7"], levelName: "Minor 2nd & Major 7th" },
	{ options: ["1", "2", "b7"], levelName: "Major 2nd & Minor 7th" },
	{ options: ["1", "b3", "6"], levelName: "Minor 3rd & Major 6th" },
	{ options: ["1", "3", "b5"], levelName: "Major 3rd & Tritone" },
	{ options: ["1", "4", "b6"], levelName: "Perfect 4th & Minor 6th" },
];

const triadsLevels = [
	{ options: music.CHORDS_MAP[e.Chords.MAJOR], levelName: "Major chord" },
	{ options: music.CHORDS_MAP[e.Chords.MINOR], levelName: "Minor chord" },
	{ options: music.CHORDS_MAP[e.Chords.AUG], levelName: "Augmented chord" },
	{ options: music.CHORDS_MAP[e.Chords.DIM], levelName: "Diminished chord" },
	{ options: music.CHORDS_MAP[e.Chords.SUS2], levelName: "Sus2 chord" },
	{ options: music.CHORDS_MAP[e.Chords.SUS4], levelName: "Sus4 chord" },
];
const seventhChordsLevels = [
	{ options: music.CHORDS_MAP[e.Chords.DOMINANT], levelName: "Dominant chord" },
	{ options: music.CHORDS_MAP[e.Chords.MINOR_7TH], levelName: "Minor 7th chord" },
	{ options: music.CHORDS_MAP[e.Chords.AUG_7TH], levelName: "Augmented 7th chord" },
	{ options: music.CHORDS_MAP[e.Chords.DIM_7TH], levelName: "Diminished 7th chord" },
	{ options: music.CHORDS_MAP[e.Chords.MAJOR_7TH], levelName: "Major 7th chord" },
	{ options: music.CHORDS_MAP[e.Chords.MINOR_MAJOR_7TH], levelName: "Minor/Major 7th chord" },
	{ options: music.CHORDS_MAP[e.Chords.AUG_MAJOR_7TH], levelName: "Augmented Major 7th chord" },
	{ options: music.CHORDS_MAP[e.Chords.HALF_DIM_7TH], levelName: "Half-dim 7th chord" },
];
const pentatonicScalesLevels = [
	{ options: music.SCALES_MAP[e.Scales.MAJOR_PENTATONIC], levelName: "Major pentatonic" },
	{ options: music.SCALES_MAP[e.Scales.MINOR_PENTATONIC], levelName: "Minor pentatonic" },
	{ options: music.SCALES_MAP[e.Scales.BLUES], levelName: "Blues" },
];
const churchModesLevels = [
	{ options: music.SCALES_MAP[e.Scales.IONIAN], levelName: "Major" },
	{ options: music.SCALES_MAP[e.Scales.AEOLIAN], levelName: "Minor" },
	{ options: music.SCALES_MAP[e.Scales.LYDIAN], levelName: "Lydian" },
	{ options: music.SCALES_MAP[e.Scales.MIXOLYDIAN], levelName: "Mixolydian" },
	{ options: music.SCALES_MAP[e.Scales.DORIAN], levelName: "Dorian" },
	{ options: music.SCALES_MAP[e.Scales.PHRYGIAN], levelName: "Phrygian" },
	{ options: music.SCALES_MAP[e.Scales.LOCRIAN], levelName: "Locrian" },
];
const alteredScaleLevels = [
	{ options: music.SCALES_MAP[e.Scales.ALTERED_I], levelName: "Altered I" },
	{ options: music.SCALES_MAP[e.Scales.ALTERED_II], levelName: "Altered II (Melodic minor)" },
	{ options: music.SCALES_MAP[e.Scales.ALTERED_III], levelName: "Altered III" },
	{ options: music.SCALES_MAP[e.Scales.ALTERED_IV], levelName: "Altered IV" },
	{ options: music.SCALES_MAP[e.Scales.ALTERED_V], levelName: "Altered V" },
	{ options: music.SCALES_MAP[e.Scales.ALTERED_VI], levelName: "Altered VI" },
	{ options: music.SCALES_MAP[e.Scales.ALTERED_VII], levelName: "Altered VII" },
];
const harmonicMajorLevels = [
	{
		options: music.SCALES_MAP[e.Scales.HARMONIC_MAJOR_I],
		levelName: "Harmonic Major I",
	},
	{
		options: music.SCALES_MAP[e.Scales.HARMONIC_MAJOR_II],
		levelName: "Harmonic Major II",
	},
	{
		options: music.SCALES_MAP[e.Scales.HARMONIC_MAJOR_III],
		levelName: "Harmonic Major III",
	},
	{
		options: music.SCALES_MAP[e.Scales.HARMONIC_MAJOR_IV],
		levelName: "Harmonic Major IV",
	},
	{
		options: music.SCALES_MAP[e.Scales.HARMONIC_MAJOR_V],
		levelName: "Harmonic Major V",
	},
	{
		options: music.SCALES_MAP[e.Scales.HARMONIC_MAJOR_VI],
		levelName: "Harmonic Major VI",
	},
	{
		options: music.SCALES_MAP[e.Scales.HARMONIC_MAJOR_VII],
		levelName: "Harmonic Major VII",
	},
];

const spanishPhrygianLevels = [
	{ options: music.SCALES_MAP[e.Scales.SPANISH_PHRYGIAN_I], levelName: "Spanish Phrygian I" },
	{ options: music.SCALES_MAP[e.Scales.SPANISH_PHRYGIAN_II], levelName: "Spanish Phrygian II" },
	{ options: music.SCALES_MAP[e.Scales.SPANISH_PHRYGIAN_III], levelName: "Spanish Phrygian III" },
	{ options: music.SCALES_MAP[e.Scales.SPANISH_PHRYGIAN_IV], levelName: "Spanish Phrygian IV" },
	{ options: music.SCALES_MAP[e.Scales.SPANISH_PHRYGIAN_V], levelName: "Spanish Phrygian V" },
	{ options: music.SCALES_MAP[e.Scales.SPANISH_PHRYGIAN_VI], levelName: "Spanish Phrygian VI (Harmonic minor)" },
	{ options: music.SCALES_MAP[e.Scales.SPANISH_PHRYGIAN_VII], levelName: "Spanish Phrygian VII" },
];

const jazzScalesLevels = [
	{ options: music.SCALES_MAP[e.Scales.BEBOP_DOMINANT], levelName: "Bebop dominant" },
	{ options: music.SCALES_MAP[e.Scales.BEBOP_MAJOR], levelName: "Bebop major" },
	{ options: music.SCALES_MAP[e.Scales.BEBOP_MELODIC_MINOR], levelName: "Bebop melodic minor" },
	{ options: music.SCALES_MAP[e.Scales.BEBOP_HARMONIC_MINOR], levelName: "Bebop harmonic minor" },
	{ options: music.SCALES_MAP[e.Scales.OCTATONIC], levelName: "Diminished Octatonic" },
	{ options: music.SCALES_MAP[e.Scales.COMPOSITE_BLUES], levelName: "Composite blues" },
	{ options: music.SCALES_MAP[e.Scales.ALTERED], levelName: "Altered" },
];
const bossModeLevels = [{ options: music.SCALES_MAP[e.Scales.CHROMATIC], levelName: "Chromatic" }];

export const MELODIC_DICTATION_SECTIONS = [
	createSection("High Contrast", intervalLevels2),
	createSection("Triads", triadsLevels),
	createSection("Seventh Chords", seventhChordsLevels),
	createSection("Pentatonic Scales", pentatonicScalesLevels),
	createSection("Church Modes", churchModesLevels),
	createSection("Altered Scales", alteredScaleLevels),
	createSection("Harmonic Major Scales", harmonicMajorLevels),
	createSection("Spanish Phrygian Scales", spanishPhrygianLevels),
	createSection("Jazz Scales", jazzScalesLevels),
	createSection("Boss Mode", bossModeLevels),
];

export const MELODIC_DICTATION_TWO_OCTAVES_SECTIONS = [
	createSection("Intervals ", intervalLevels),
	createSection("High Contrast", intervalLevels2),
	createSection("Triads", triadsLevels),
	createSection("Seventh Chords", seventhChordsLevels),
	createSection("Pentatonic Scales", pentatonicScalesLevels),
	createSection("Church Modes", churchModesLevels),
	createSection("Altered Scales", alteredScaleLevels),
	createSection("Harmonic Major Scales", harmonicMajorLevels),
	createSection("Spanish Phrygian Scales", spanishPhrygianLevels),
	createSection("Jazz Scales", jazzScalesLevels),
	createSection("Boss Mode", bossModeLevels),
];
