import * as e from "../../data/enums";
import * as t from "../../data/types";
import { capitalizeFirstLetter } from "../../utils/sharedUtils";

export function createStudentData(
	email: string,
	firstName: string,
	lastName: string,
	studentNumber: string,
	classID: string,
	className: string
): t.User {
	// TODO: check if this works as expected
	const createLevelArray = (): t.ModulesType => {
		return {
			difficulties: {},
			ModuleTotal: 0,
		};
	};

	const Progress: t.ProgressType = {
		modules: {
			[e.Modules.INTERVAL_COMPARISON]: createLevelArray(),
			[e.Modules.INTERVAL_RECOGNITION]: createLevelArray(),
			[e.Modules.SCALE_RECOGNITION]: createLevelArray(),
			[e.Modules.MELODIC_DICTATION]: createLevelArray(),
			[e.Modules.CHORD_RECOGNITION]: createLevelArray(),
			[e.Modules.TRIAD_INVERSIONS]: createLevelArray(),
			[e.Modules.PROGRESSIONS_MINOR]: createLevelArray(),
			[e.Modules.PROGRESSIONS_MAJOR]: createLevelArray(),
		},
		Total: 0,
	};

	const yesterdaysDate = new Date();
	yesterdaysDate.setDate(yesterdaysDate.getDate() - 1);

	const Streak: t.StreakType = {
		Current: 0,
		Longest: 0,
		LastPractice: yesterdaysDate,
		EquipedStreakFreeze: 2,
	};

	const DailyStats: t.StatsType[] = [];
	const WeeklyStats: t.StatsType = {
		Date: new Date(),
		PracticeTime: 0,
		Progress: 0,
	};

	return {
		// PERSONAL INFO
		FirstName: capitalizeFirstLetter(firstName),
		LastName: capitalizeFirstLetter(lastName),
		Email: email.toLocaleLowerCase().trim(),
		SubscriptionPlan: e.Subscription.STUDENT,

		// SCHOOL INFO
		ClassID: classID,
		StudentNumber: studentNumber,

		// PROGRESS
		Progress,
		Targets: [],

		// STATS
		Streak,
		DailyStats,
		WeeklyStats,
		MonthlyStats: { ...WeeklyStats },
	};
}
