import * as t from "../../data/types";
import React from "react";

export default function retrieveDataFromStorage(
	teacher: t.Teacher | null,
	setTeacher: React.Dispatch<React.SetStateAction<t.Teacher | null>>,
	setClassData: React.Dispatch<React.SetStateAction<t.Class[] | null>>
): boolean {
	if (teacher) return false;

	const sessionTeacher = localStorage.getItem("teacher");
	if (sessionTeacher) {
		const sessionTeacherData = JSON.parse(sessionTeacher);
		setTeacher(sessionTeacherData);
		console.log(
			"Teacher data from: <",
			sessionTeacherData.FirstName,
			sessionTeacherData.LastName,
			"> retrieved from localStorage"
		);
	}
	const sessionClasses = localStorage.getItem("classes");
	if (sessionClasses) {
		const sessionClassesData = JSON.parse(sessionClasses);
		setClassData(JSON.parse(sessionClasses));
		console.log("Class data: <", sessionClassesData, "> retrieved from localStorage");
	}

	return sessionTeacher && sessionClasses ? true : false;
}
