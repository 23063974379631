import React, { createContext, useContext, ReactNode, useState } from "react";
import * as t from "../data/types";

interface TeacherContextProps {
	teacher: t.Teacher | null;
	setTeacher: React.Dispatch<React.SetStateAction<t.Teacher | null>>;
}

interface TeacherLoggedInContextProps {
	teacher: t.Teacher;
	setTeacher: React.Dispatch<React.SetStateAction<t.Teacher | null>>;
	classData: t.Class[] | null;
	setClassData: React.Dispatch<React.SetStateAction<t.Class[] | null>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	clickOutside: boolean;
	scrollContentRef: React.RefObject<HTMLDivElement>;
}

const TeacherContext = createContext<TeacherContextProps | undefined>(undefined);
const TeacherLoggedInContext = createContext<TeacherLoggedInContextProps | undefined>(undefined);

export const TeacherProvider = ({ children }: { children: ReactNode }) => {
	const [teacher, setTeacher] = useState<t.Teacher | null>(null);

	return <TeacherContext.Provider value={{ teacher, setTeacher }}>{children}</TeacherContext.Provider>;
};

export const TeacherLoggedInProvider = ({
	children,
	teacher,
	setTeacher,
	classData,
	setClassData,
	loading,
	setLoading,
	clickOutside,
	scrollContentRef,
}: {
	children: ReactNode;
	teacher: t.Teacher;
	setTeacher: React.Dispatch<React.SetStateAction<t.Teacher | null>>;
	classData: t.Class[] | null;
	setClassData: React.Dispatch<React.SetStateAction<t.Class[] | null>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	clickOutside: boolean;
	scrollContentRef: React.RefObject<HTMLDivElement>;
}) => {
	return (
		<TeacherLoggedInContext.Provider
			value={{
				teacher,
				setTeacher,
				classData,
				setClassData,
				loading,
				setLoading,
				clickOutside,
				scrollContentRef,
			}}
		>
			{children}
		</TeacherLoggedInContext.Provider>
	);
};

export const useTeacher = () => {
	const context = useContext(TeacherContext);
	if (context === undefined) throw new Error("useTeacher must be used within a TeacherProvider");
	return context;
};

export const useTeacherLoggedIn = () => {
	const context = useContext(TeacherLoggedInContext);
	if (context === undefined) throw new Error("useTeacherLoggedIn must be used within a TeacherLoggedInProvider");
	return context;
};
