import * as t from "../../data/types";
import React, { useEffect, useState } from "react";
import { useTeacherLoggedIn } from "../../context/UserContext";
import getClassesByTeacherAPI from "../../services/api/getClassesByTeacherAPI";
import changeClassNameAPI from "../../services/api/changeClassNameAPI";
import { useAlert } from "../../context/AlertContext";
import AddStudents from "./AddStudents";
import DeleteStudents from "./DeleteStudents";
import EditStudents from "./EditStudents";
import { useTranslation } from "react-i18next";
import { checkForUnsafeCharacters } from "../../utils/utils";
import * as Sentry from "@sentry/react";
import { ADMIN_EMAIL } from "../../data/constants/constants";

enum SelectAction {
	ChangeName = "changeClassName",
	AddStudents = "addStudents",
	EditStudents = "editStudents",
	DeleteStudents = "deleteStudents",
}

export default function EditClass({
	classToEdit,
	setClassToEdit,
}: {
	classToEdit: t.Class | null;
	setClassToEdit: React.Dispatch<React.SetStateAction<t.Class | null>>;
}) {
	const [action, setAction] = useState<SelectAction | null>(SelectAction.AddStudents);
	const { t } = useTranslation("ManageClasses");

	useEffect(() => {
		if (!classToEdit) {
			setAction(SelectAction.AddStudents);
			document.getElementById(SelectAction.AddStudents)?.classList.add("buttonOpenSelected");
		}
	}, [classToEdit]);

	if (!classToEdit) return null;

	const handleActionChange = (newAction: SelectAction) => {
		setAction(newAction);
		const buttons = document.querySelectorAll(".buttonOpen");
		buttons.forEach((button) => button.classList.remove("buttonOpenSelected"));
		document.getElementById(newAction)?.classList.add("buttonOpenSelected");
	};
	return (
		<div>
			<div className="flexRow">
				<h2>
					{t("EditClass")} {classToEdit.ClassName}
				</h2>
				<h2 className="close" onClick={() => setClassToEdit(null)}>
					&times;
				</h2>
			</div>
			<div className="editClass">
				<div className="flexRow">
					{Object.values(SelectAction).map((actionType) => (
						<button
							key={actionType}
							id={actionType}
							className={`buttonOpen ${action === actionType ? "buttonOpenSelected" : ""}`}
							onClick={() => handleActionChange(actionType)}
						>
							{t(
								actionType
									.replace(/([A-Z])/g, " $1")
									.trim()
									.toLowerCase()
							)}
						</button>
					))}
				</div>
				{action === SelectAction.ChangeName && <ChangeClassName classToEdit={classToEdit} />}
				{action === SelectAction.AddStudents && <AddStudents selectedClass={classToEdit} />}
				{action === SelectAction.EditStudents && <EditStudents classToEdit={classToEdit} />}
				{action === SelectAction.DeleteStudents && <DeleteStudents classToEdit={classToEdit} />}
			</div>
		</div>
	);
}

function ChangeClassName({ classToEdit }: { classToEdit: t.Class | null }) {
	const { teacher, classData, setClassData, setLoading } = useTeacherLoggedIn();
	const { triggerAlert } = useAlert();
	const [className, setClassName] = React.useState(classToEdit?.ClassName);
	const [errorMessage, setErrorMessage] = React.useState("");
	const { t } = useTranslation("ManageClasses");

	useEffect(() => {
		setClassName(classToEdit?.ClassName);
	}, [classToEdit]);

	async function handleNameChange(e: any) {
		if (!classToEdit) return;
		e.preventDefault();

		if (!className) return setErrorMessage("Class name cannot be empty");
		if (className === classToEdit?.ClassName) return setErrorMessage("Class name cannot be the same");

		const classNameIsNotUnique = classData?.find((classObj) => classObj.ClassName === className);
		if (classNameIsNotUnique) setErrorMessage("Class name already exists");

		if (checkForUnsafeCharacters([className])) return setErrorMessage(t("ErrorMessageUnsafeCharacters"));

		setLoading(true);
		try {
			await changeClassNameAPI(classToEdit.id, className);
			await getClassesByTeacherAPI(teacher.id, setClassData);
			triggerAlert("Class name succesfully changed to " + className, "success");
		} catch (error) {
			triggerAlert("Something went wrong, please try again later", "error");
			if (teacher?.Email !== ADMIN_EMAIL) Sentry.captureException(error);
		} finally {
			setErrorMessage("");
			setLoading(false);
		}
	}

	if (!classToEdit) return null;
	return (
		<form>
			<label>
				{errorMessage && <div className="error">{errorMessage}</div>}
				<div className="flexRow">
					<input type="text" value={className} onChange={(e) => setClassName(e.target.value)} />
					<button type="submit" onClick={handleNameChange}>
						{t("ChangeClassName")}
					</button>
				</div>
			</label>
		</form>
	);
}
