import React, { useEffect, useState } from "react";
import { isSafariBrowser } from "../../utils/deviceCheck";
import { useTeacherLoggedIn } from "../../context/UserContext";
import fetchSchoolsAPI from "../../services/api/fetchSchools";
import { FirebaseManager } from "../../services/firebase/FirebaseManager";
import { useAlert } from "../../context/AlertContext";

export default function Coupons(): JSX.Element {
	const { teacher, classData } = useTeacherLoggedIn();
	const [schools, setSchools] = useState<any[]>([]); // Change to state
	const { triggerAlert } = useAlert();
	const allStudents = classData?.flatMap((classData) => classData.Students) || [];
	const [listOfCorrectCoupons, setListOfCorrectCoupons] = useState<any[]>([]);
	const [listOfIncorrectCoupons, setListOfIncorrectCoupons] = useState<any[]>([]);
	const [studentsWithIncorrectCoupons, setStudentsWithIncorrectCoupons] = useState<any[]>([]);

	useEffect(() => {
		const fetchSchools = async () => {
			if (schools.length > 0) return;

			// Check if the user is authenticated
			const authInstance = await FirebaseManager.getAuthInstance();
			if (!authInstance.currentUser) return; // Exit if not authenticated

			try {
				if (!teacher) return;
				const fetchedSchools = await fetchSchoolsAPI(teacher.Email);
				// console.log(fetchedSchools);
				setSchools(fetchedSchools);

				// get all coupons from the database
				for (const school of fetchedSchools) {
					if (school.Coupon) listOfCorrectCoupons.push(school.Coupon);
					for (const student of allStudents) {
						// console.log(student);
						// if (student.LastName === "Coupon3") console.log(student, student.Coupon);
						if (student.Coupon && student.Coupon === school.Coupon) {
							school.usage = (school?.usage || 0) + 1;
						}
					}
					//filter all classes with the same school id
					const classes = classData?.filter((classInSchool) => classInSchool.SchoolID === school.id);
					school.classes = classes?.length || 0;
					// get the total students in all the classes
					const totalStudents =
						classes?.reduce((total, classInSchool) => total + classInSchool.Students.length, 0) || 0;
					school.students = totalStudents;
				}

				for (const student of allStudents) {
					if (
						student.Coupon &&
						!listOfCorrectCoupons.includes(student.Coupon) &&
						!studentsWithIncorrectCoupons.includes(student.Email)
					) {
						listOfIncorrectCoupons.push(student.Coupon);
						studentsWithIncorrectCoupons.push(student.Email);
					}
				}
			} catch (error) {
				triggerAlert("Error fetching schools", "error");
			}
		};

		setTimeout(fetchSchools, 1000);
	}, []);

	return (
		<div className={`basicCard loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
			<h1>Coupons</h1>
			<div className="overviewTable">
				<table>
					<thead className="theadCSS theadCSSRounded">
						<tr>
							<th style={{ width: "40%" }}>School Name</th>
							<th style={{ width: "15%" }}>Classes</th>
							<th style={{ width: "15%" }}>Students</th>
							<th style={{ width: "15%" }}>Coupon</th>
							<th style={{ width: "15%" }}>Coupon usage</th>
						</tr>
					</thead>
					<tbody>
						{schools.map((school) => (
							<tr key={school.id}>
								<td>{school.SchoolName}</td>
								<td>{school.classes}</td>
								<td>{school.students}</td>
								<td>{school.Coupon}</td>
								<td>{school?.usage || 0}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<br />
			{listOfIncorrectCoupons.length > 0 && (
				<>
					<h2>Incorrect Coupons</h2>
					<table>
						<tbody>
							{listOfIncorrectCoupons.map((coupon, index) => (
								<tr key={index}>
									<td>{coupon}</td>
									<td>{studentsWithIncorrectCoupons[index]}</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			)}
		</div>
	);
}
