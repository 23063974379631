import { createSection } from "./utils";
import * as e from "../../enums";
import { allSections } from "./progressionsMajor";

const commonLowestNote1st = [
	// {
	// 	options: [e.Degrees.I, e.DegreesFirstInversion.vi],
	// 	levelName: e.Degrees.I + " " + e.DegreesFirstInversion.vi,
	// },
	{
		options: [e.Degrees.I, e.Degrees.ii, e.DegreesFirstInversion.viiDim],
		levelName: "Lowest note is a major 2nd interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.iii, e.DegreesFirstInversion.I],
		levelName: "Lowest note is a major 3rd interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.IV, e.DegreesFirstInversion.ii],
		levelName: "Lowest note is a perfect 4th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.V, e.DegreesFirstInversion.iii],
		levelName: "Lowest note is a perfect 5th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.vi, e.DegreesFirstInversion.IV],
		levelName: "Lowest note is a major 6th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.viiDim, e.DegreesFirstInversion.V],
		levelName: "Lowest note is a major 7th interval",
	},
];
const gettingFamiliar1st = [
	{
		options: [e.Degrees.I, e.Degrees.ii, e.DegreesFirstInversion.I, e.DegreesFirstInversion.ii],
		levelName: "I - ii",
	},
	{
		options: [e.Degrees.I, e.Degrees.iii, e.DegreesFirstInversion.I, e.DegreesFirstInversion.iii],
		levelName: "I - iii",
	},
	{
		options: [e.Degrees.I, e.Degrees.IV, e.DegreesFirstInversion.I, e.DegreesFirstInversion.IV],
		levelName: "I - IV",
	},
	{
		options: [e.Degrees.I, e.Degrees.V, e.DegreesFirstInversion.I, e.DegreesFirstInversion.V],
		levelName: "I - V",
	},
	{
		options: [e.Degrees.I, e.Degrees.vi, e.DegreesFirstInversion.I, e.DegreesFirstInversion.vi],
		levelName: "I - vi",
	},
	{
		options: [e.Degrees.I, e.Degrees.viiDim, e.DegreesFirstInversion.I, e.DegreesFirstInversion.viiDim],
		levelName: "I - viiDim",
	},
];
const commonLowestNote1st2 = [
	{
		options: [e.Degrees.I, e.DegreesFirstInversion.vi, e.Degrees.IV, e.DegreesFirstInversion.ii],
		levelName: "Lowest note is a root or perfect 4th interval",
	},

	{
		options: [e.Degrees.I, e.Degrees.ii, e.DegreesFirstInversion.viiDim, e.Degrees.V, e.DegreesFirstInversion.iii],
		levelName: "Lowest note is a major 2nd or perfect 5th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.iii, e.DegreesFirstInversion.I, e.Degrees.vi, e.DegreesFirstInversion.IV],
		levelName: "Lowest note is a major 3rd or major 6th interval",
	},

	{
		options: [
			e.Degrees.I,
			e.DegreesFirstInversion.vi,
			e.Degrees.V,
			e.DegreesFirstInversion.iii,
			e.Degrees.viiDim,
			e.DegreesFirstInversion.V,
		],
		levelName: "Lowest note is a root, perfect 5th or major 7th interval",
	},
];

const commonLowestNote2nd = [
	// {
	// 	options: [e.Degrees.I, e.DegreesSecondInversion.IV],
	// 	levelName: e.Degrees.I + e.DegreesSecondInversion.IV,
	// },
	{
		options: [e.Degrees.I, e.Degrees.ii, e.DegreesSecondInversion.V],
		levelName: "Lowest note is a major 2nd interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.iii, e.DegreesSecondInversion.vi],
		levelName: "Lowest note is a major 3rd interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.IV, e.DegreesSecondInversion.viiDim],
		levelName: "Lowest note is a perfect 4th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.V, e.DegreesSecondInversion.I],
		levelName: "Lowest note is a perfect 5th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.vi, e.DegreesSecondInversion.ii],
		levelName: "Lowest note is a major 6th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.viiDim, e.DegreesSecondInversion.iii],
		levelName: "Lowest note is a major 7th interval",
	},
];
const gettingFamiliar2nd = [
	{
		options: [e.Degrees.I, e.Degrees.ii, e.DegreesSecondInversion.I, e.DegreesSecondInversion.ii],
		levelName: "I - ii",
	},
	{
		options: [e.Degrees.I, e.Degrees.iii, e.DegreesSecondInversion.I, e.DegreesSecondInversion.iii],
		levelName: "I - iii",
	},
	{
		options: [e.Degrees.I, e.Degrees.IV, e.DegreesSecondInversion.I, e.DegreesSecondInversion.IV],
		levelName: "I - IV",
	},
	{
		options: [e.Degrees.I, e.Degrees.V, e.DegreesSecondInversion.I, e.DegreesSecondInversion.V],
		levelName: "I - V",
	},
	{
		options: [e.Degrees.I, e.Degrees.vi, e.DegreesSecondInversion.I, e.DegreesSecondInversion.vi],
		levelName: "I - vi",
	},
	{
		options: [e.Degrees.I, e.Degrees.viiDim, e.DegreesSecondInversion.I, e.DegreesSecondInversion.viiDim],
		levelName: "I - viiDim",
	},
];
const commonLowestNote2nd2 = [
	{
		options: [e.Degrees.I, e.DegreesSecondInversion.IV, e.Degrees.IV, e.DegreesSecondInversion.viiDim],
		levelName: "Lowest note is a root or perfect 4th interval",
	},

	{
		options: [e.Degrees.I, e.Degrees.ii, e.DegreesSecondInversion.V, e.Degrees.V, e.DegreesSecondInversion.I],
		levelName: "Lowest note is a major 2nd or perfect 5th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.iii, e.DegreesSecondInversion.vi, e.Degrees.vi, e.DegreesSecondInversion.ii],
		levelName: "Lowest note is a major 3rd or major 6th interval",
	},
	{
		options: [
			e.Degrees.I,
			e.DegreesSecondInversion.IV,
			e.Degrees.V,
			e.DegreesSecondInversion.I,
			e.Degrees.viiDim,
			e.DegreesSecondInversion.iii,
		],
		levelName: "Lowest note is a root, perfect 5th or major 7th interval",
	},
];

const commonLowestNoteAll = [
	{
		options: [e.Degrees.I, e.DegreesFirstInversion.vi, e.DegreesSecondInversion.IV],
		levelName: "Lowest note is a root interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.ii, e.DegreesFirstInversion.viiDim, e.DegreesSecondInversion.V],
		levelName: "Lowest note is a major 2nd interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.iii, e.DegreesFirstInversion.I, e.DegreesSecondInversion.vi],
		levelName: "Lowest note is a major 3rd interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.IV, e.DegreesFirstInversion.ii, e.DegreesSecondInversion.viiDim],
		levelName: "Lowest note is a perfect 4th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.V, e.DegreesFirstInversion.iii, e.DegreesSecondInversion.I],
		levelName: "Lowest note is a perfect 5th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.vi, e.DegreesFirstInversion.IV, e.DegreesSecondInversion.ii],
		levelName: "Lowest note is a major 6th interval",
	},
	{
		options: [e.Degrees.I, e.Degrees.viiDim, e.DegreesFirstInversion.V, e.DegreesSecondInversion.iii],
		levelName: "Lowest note is a major 7th interval",
	},
];
const gettingFamiliarAll = [
	{
		options: [
			e.Degrees.I,
			e.Degrees.ii,
			e.DegreesFirstInversion.I,
			e.DegreesFirstInversion.ii,
			e.DegreesSecondInversion.I,
			e.DegreesSecondInversion.ii,
		],
		levelName: "I - ii",
	},
	{
		options: [
			e.Degrees.I,
			e.Degrees.iii,
			e.DegreesFirstInversion.I,
			e.DegreesFirstInversion.iii,
			e.DegreesSecondInversion.I,
			e.DegreesSecondInversion.iii,
		],
		levelName: "I - iii",
	},
	{
		options: [
			e.Degrees.I,
			e.Degrees.IV,
			e.DegreesFirstInversion.I,
			e.DegreesFirstInversion.IV,
			e.DegreesSecondInversion.I,
			e.DegreesSecondInversion.IV,
		],
		levelName: "I - IV",
	},
	{
		options: [
			e.Degrees.I,
			e.Degrees.V,
			e.DegreesFirstInversion.I,
			e.DegreesFirstInversion.V,
			e.DegreesSecondInversion.I,
			e.DegreesSecondInversion.V,
		],
		levelName: "I - V",
	},
	{
		options: [
			e.Degrees.I,
			e.Degrees.vi,
			e.DegreesFirstInversion.I,
			e.DegreesFirstInversion.vi,
			e.DegreesSecondInversion.I,
			e.DegreesSecondInversion.vi,
		],
		levelName: "I - vi",
	},
	{
		options: [
			e.Degrees.I,
			e.Degrees.viiDim,
			e.DegreesFirstInversion.I,
			e.DegreesFirstInversion.viiDim,
			e.DegreesSecondInversion.I,
			e.DegreesSecondInversion.viiDim,
		],
		levelName: "I - viiDim",
	},
];
const commonLowestNoteAll2 = [
	{
		options: [
			e.Degrees.I,
			e.DegreesFirstInversion.vi,
			e.DegreesSecondInversion.IV,
			e.Degrees.IV,
			e.DegreesFirstInversion.ii,
			e.DegreesSecondInversion.viiDim,
		],
		levelName: "Lowest note is a root or perfect 4th interval",
	},

	{
		options: [
			e.Degrees.I,
			e.Degrees.ii,
			e.DegreesFirstInversion.viiDim,
			e.DegreesSecondInversion.V,
			e.Degrees.V,
			e.DegreesFirstInversion.iii,
			e.DegreesSecondInversion.I,
		],
		levelName: "Lowest note is a major 2nd or perfect 5th interval",
	},
	{
		options: [
			e.Degrees.I,
			e.Degrees.iii,
			e.DegreesFirstInversion.I,
			e.DegreesSecondInversion.vi,
			e.Degrees.vi,
			e.DegreesFirstInversion.IV,
			e.DegreesSecondInversion.ii,
		],
		levelName: "Lowest note is a major 3rd or major 6th interval",
	},

	{
		options: [
			e.Degrees.I,
			e.DegreesFirstInversion.vi,
			e.DegreesSecondInversion.IV,
			e.Degrees.V,
			e.DegreesFirstInversion.iii,
			e.DegreesSecondInversion.I,
			e.Degrees.viiDim,
			e.DegreesFirstInversion.V,
			e.DegreesSecondInversion.iii,
		],
		levelName: "Lowest note is a root, perfect 5th or major 7th interval",
	},
];

export const PROGRESSION_MAJOR_SECTIONS_ROOT_AND_FIRST_INVERSIONS = [
	createSection("Common Lowest Note", commonLowestNote1st),
	createSection("Getting familiar", gettingFamiliar1st),
	createSection("Common Lowest Note 2", commonLowestNote1st2),
	...allSections.map((section) => section.rootAndFirstInversions),
];
export const PROGRESSION_MAJOR_SECTIONS_ROOT_AND_SECOND_INVERSIONS = [
	createSection("Common Lowest Note", commonLowestNote2nd),
	createSection("Getting familiar", gettingFamiliar2nd),
	createSection("Common Lowest Note 2", commonLowestNote2nd2),
	...allSections.map((section) => section.rootAndSecondInversions),
];
export const PROGRESSION_MAJOR_SECTIONS_ALL = [
	createSection("Common Lowest Note 1", commonLowestNoteAll),
	createSection("Getting familiar", gettingFamiliarAll),
	createSection("Common Lowest Note 2", commonLowestNoteAll2),
	...allSections.map((section) => section.all),
];
