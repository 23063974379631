import React, { useEffect, useMemo } from "react";
import * as t from "../../data/types";
import * as c from "../../data/constants/constants";
import * as e from "../../data/enums";
import * as img from "../../data/constants/images";
import { convertDateToString, getTimeFromDate, parseDate } from "../../utils/dates";
import { spaceBeforeCapitals } from "../../utils/sharedUtils";
import useTableSort from "../../hooks/useTableSort";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import ExcelJS from "exceljs";

export default function ExamInfo({
	exam,
	selectedClass,
}: {
	exam: t.Exam;
	selectedClass: t.Class | undefined;
}): JSX.Element {
	const [selectedStudent, setSelectedStudent] = React.useState<t.User | undefined>(undefined);

	return (
		<>
			<div className="examDetails">
				<ExamDetails exam={exam} />
				<Results
					exam={exam}
					selectedClass={selectedClass}
					selectedStudent={selectedStudent}
					setSelectedStudent={setSelectedStudent}
				/>
			</div>
			{selectedStudent && (
				<ExamContentTable student={selectedStudent} exam={exam} onClose={() => setSelectedStudent(undefined)} />
			)}
		</>
	);
}

function ExamDetails({ exam }: { exam: t.Exam }): JSX.Element {
	const avgGrade = exam.Results?.reduce((acc, result) => acc + result.Grade, 0) / exam.Results?.length;
	const participants = exam.Results?.length;
	let duration = Math.round(
		(parseDate(exam.EndTime).getTime() - parseDate(exam.StartTime).getTime()) / (c.seconds * c.milliseconds)
	);

	return (
		<table className="overviewTable">
			<thead className="theadCSS theadCSSRounded">
				<tr>
					<th>Info</th>
					<th className="alignRight"></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Date</td>
					<td>{convertDateToString(exam.StartTime, "dd-mm-yyyy")}</td>
				</tr>
				<tr>
					<td>Start time</td>
					<td>{getTimeFromDate(exam.StartTime)}</td>
				</tr>
				<tr>
					<td>Duration (minutes)</td>
					<td>{duration}</td>
				</tr>
				<tr>
					<td>Average grade</td>
					<td>{parseScore(avgGrade)}</td>
				</tr>
				<tr>
					<td>Participants</td>
					<td>{participants.toPrecision(1)}</td>
				</tr>
			</tbody>
		</table>
	);
}

function Results({
	exam,
	selectedClass,
	selectedStudent,
	setSelectedStudent,
}: {
	exam: t.Exam;
	selectedClass: t.Class | undefined;
	selectedStudent: t.User | undefined;
	setSelectedStudent: React.Dispatch<React.SetStateAction<t.User | undefined>>;
}): JSX.Element {
	const tableData = useMemo(() => parseResultData(selectedClass?.Students, exam), [exam]);
	const { sortedData: sortedTargets, handleSort, sortConfig } = useTableSort(tableData);

	const handleStudentClick = (studentID: string) => {
		const student = selectedClass?.Students.find((student) => student.id === studentID);
		if (student) setSelectedStudent(student);
		else setSelectedStudent(undefined);
	};

	useEffect(() => {
		const firstStudent = selectedClass?.Students.find((student) => student.id === exam.Results?.[0]?.StudentID);
		setSelectedStudent(firstStudent);
	}, [exam]);

	const downloadCSV = () => {
		const csv = Papa.unparse(
			tableData.map((item) => ({ Student: item[0], "Student No.": item[1], Grade: item[2] }))
		);
		const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
		saveAs(blob, "results.csv");
	};

	const downloadExcel = async () => {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet("Results");

		worksheet.columns = [
			{ header: "Student", key: "student", width: 30 },
			{ header: "Student No.", key: "studentNo", width: 15 },
			{ header: "Grade", key: "grade", width: 10 },
		];

		tableData.forEach((item) => {
			worksheet.addRow({ student: item[0], studentNo: item[1], grade: item[2] });
		});

		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		saveAs(blob, "results.xlsx");
	};

	if (exam.Results?.length === 0) return <></>;
	return (
		<div>
			<div className="overviewTable">
				<table>
					<thead className="theadCSS theadCSSRounded sortableTable">
						<tr>
							{["Student", "Student No.", "Grade"].map((header, index) => (
								<th key={index} onClick={() => handleSort(index)} style={{ cursor: "pointer" }}>
									{header}
									{sortConfig &&
										sortConfig.index === index &&
										(sortConfig.direction === "asc" ? " ▲" : " ▼")}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{sortedTargets.map((item, index) => (
							<tr
								key={index}
								onClick={() => handleStudentClick(item[3])}
								className={
									selectedStudent?.StudentNumber === item[1]
										? "selected clickableTableRow"
										: "clickableTableRow"
								}
							>
								<td>{spaceBeforeCapitals(item[0])}</td>
								<td>{item[1]}</td>
								<td className="alignRight monospace">{item[2]}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="downloadResultsButtons">
				<div className="downloadContainer">
					<img src={img.ICONS[e.Icons.CSV]} onClick={downloadCSV} alt="download csv file" />
					Download as csv file
				</div>
				<div className="downloadContainer">
					<img src={img.ICONS[e.Icons.XLS]} onClick={downloadExcel} alt="download excel file" />
					Download as excel file
				</div>
			</div>
		</div>
	);
}
function ExamContentTable({ student, exam, onClose }: { student: t.User; exam: t.Exam; onClose: () => void }) {
	const scores = exam.Results?.find((result) => result.StudentID === student.id)?.Scores;

	const [uniqueModules, questionCountPerModule, sectionsPerModule, correctQuestionsPerModule] = React.useMemo(() => {
		return createOverviewData(exam.Content, scores || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exam.Content, scores]);

	const grade = parseScore(exam.Results?.find((result) => result.StudentID === student.id)?.Grade);

	return (
		<>
			<div className="flexRow">
				<h2>
					Detailed results for {student?.FirstName + " " + student?.LastName}: {grade}
				</h2>
				<h2 onClick={onClose}>&times;</h2>
			</div>
			<div className="overviewTable">
				<table>
					<thead className="theadCSS theadCSSRounded">
						<tr>
							{/* <th style={{ width: "1em" }}></th> */}
							<th>Module</th>
							{/* {window.innerWidth > 500 && <th className="alignRight">Sections</th>} */}
							<th className="alignRight" style={{ width: "1em" }}>
								Questions
							</th>
							<th className="alignRight" style={{ width: "1em" }}>
								Correct
							</th>
							<th className="alignRight" style={{ width: "2em" }}>
								Percentage
							</th>
						</tr>
					</thead>
					<tbody>
						{uniqueModules.map((module, index) => (
							<tr key={index}>
								{/* <td>
								<img
									className="examInfoImg"
									src={img.ICONS[module]}
									alt={module}
									onContextMenu={preventDefaultContext}
								/>
							</td> */}
								<td>{spaceBeforeCapitals(module)}</td>
								{/* {window.innerWidth > 500 && <td className="alignRight">{sectionsPerModule[index]}</td>} */}
								<td className="alignRight">{questionCountPerModule[index]}</td>
								<td className="alignRight">{parseScore(correctQuestionsPerModule[index])}</td>
								<td className="alignRight">
									{Math.round(
										(correctQuestionsPerModule[index] / questionCountPerModule[index]) * 100
									)}{" "}
									%
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
}

function createOverviewData(examContent: t.ExamContent[], examScores: number[]) {
	const uniqueModules = examContent.reduce((acc: string[], part: t.ExamContent) => {
		if (!acc.includes(part.Module)) acc.push(part.Module);
		return acc;
	}, []);

	const questionCountPerModule = uniqueModules.map((module) =>
		examContent.filter((part) => part.Module === module).reduce((acc, part) => acc + part.NumberOfQuestions, 0)
	);

	const sectionsPerModule = uniqueModules.map(
		(module) => examContent.filter((part) => part.Module === module).length
	);

	const segments = uniqueModules.map((module) => examContent.filter((part) => part.Module === module).length);
	const correctQuestionsPerModule: any[] = [];
	let scoreIndex = 0;
	for (let i = 0; i < uniqueModules.length; i++) {
		if (scoreIndex > examScores.length) correctQuestionsPerModule.push(undefined);
		else {
			let correctQuestions = 0;

			for (let j = 0; j < segments[i]; j++) {
				const score = examScores[scoreIndex] || 0;
				correctQuestions += score;
				scoreIndex++;
			}
			correctQuestionsPerModule.push(correctQuestions);
		}
	}

	return [uniqueModules, questionCountPerModule, sectionsPerModule, correctQuestionsPerModule];
}

function parseScore(score: number | undefined): string {
	if (!score || score === undefined) return "-";
	if (score % 1 !== 0) return score.toFixed(2);
	return score.toString();
}

export function parseResultData(students: t.User[] | undefined, exam: t.Exam): string[][] {
	const data: string[][] = [];
	if (!students || !exam || !exam.Results) return data;

	const results = exam.Results;

	results.map((result) => {
		const student = students?.find((student) => student.id === result.StudentID);

		const name = student?.FirstName + " " + student?.LastName;
		const studentNumber = student?.StudentNumber || "";
		const progress = result.Grade.toPrecision(3);
		const id = student?.id || "";
		data.push([name, studentNumber, progress, id]);
		return null;
	});
	return data;
}
