import { createSection } from "./utils";

const highContrastLevels = [
	{ options: ["b2", "8"], levelName: "Minor 2nd and Octave" },
	{ options: ["b2", "7"], levelName: "Minor 2nd and Major 7th" },
	{ options: ["2", "8"], levelName: "Major 2nd and Octave" },
	{ options: ["b2", "b7"], levelName: "Minor 2nd and Minor 7th" },
	{ options: ["2", "7"], levelName: "Major 2nd and Major 7th" },
	{ options: ["b3", "8"], levelName: "Minor 3rd and Octave" },
];
const minor6thDifferenceLevels = [
	{ options: ["b2", "6"], levelName: "Minor 2nd and Major 6th" },
	{ options: ["2", "b7"], levelName: "Major 2nd and Minor 7th" },
	{ options: ["b3", "7"], levelName: "Minor 3rd and Major 7th" },
	{ options: ["3", "8"], levelName: "Major 3rd and Octave" },
];
const perfect5thDifferenceLevels = [
	{ options: ["b2", "b6"], levelName: "Minor 2nd and Perfect 5th" },
	{ options: ["2", "6"], levelName: "Major 2nd and Minor 6th" },
	{ options: ["b3", "b7"], levelName: "Minor 3rd and Major 6th" },
	{ options: ["3", "7"], levelName: "Major 3rd and Minor 7th" },
	{ options: ["4", "8"], levelName: "Perfect 4th and Octave" },
];
const tritoneDifferenceLevels = [
	{ options: ["b2", "5"], levelName: "Minor 2nd and Triton" },
	{ options: ["2", "b6"], levelName: "Major 2nd and Minor 6th" },
	{ options: ["b3", "6"], levelName: "Minor 3rd and Major 6th" },
	{ options: ["3", "b7"], levelName: "Major 3rd and Minor 7th" },
	{ options: ["4", "7"], levelName: "Perfect 4th and Major 7th" },
	{ options: ["b5", "8"], levelName: "Tritone and Octave" },
];
const perfect4thDifferenceLevels = [
	{ options: ["b2", "b5"], levelName: "Minor 2nd and Perfect 4th" },
	{ options: ["2", "5"], levelName: "Major 2nd and Tritone" },
	{ options: ["b3", "b6"], levelName: "Minor 3rd and Minor 6th" },
	{ options: ["3", "6"], levelName: "Major 3rd and Major 6th" },
	{ options: ["4", "b7"], levelName: "Perfect 4th and Minor 7th" },
	{ options: ["b5", "7"], levelName: "Tritone and Major 7th" },
	{ options: ["5", "8"], levelName: "Perfect 5th and Octave" },
];
const major3rdDifferenceLevels = [
	{ options: ["b2", "4"], levelName: "Minor 2nd and Major 3rd" },
	{ options: ["2", "b5"], levelName: "Major 2nd and Perfect 4th" },
	{ options: ["b3", "5"], levelName: "Minor 3rd and Tritone" },
	{ options: ["3", "b6"], levelName: "Major 3rd and Minor 6th" },
	{ options: ["4", "6"], levelName: "Perfect 4th and Major 6th" },
	{ options: ["b5", "b7"], levelName: "Tritone and Minor 7th" },
	{ options: ["5", "7"], levelName: "Perfect 5th and Major 7th" },
	{ options: ["b6", "8"], levelName: "Minor 6th and Octave" },
];
const minor3rdDifferenceLevels = [
	{ options: ["b2", "3"], levelName: "Minor 2nd and Minor 3rd" },
	{ options: ["2", "4"], levelName: "Major 2nd and Major 3rd" },
	{ options: ["b3", "b5"], levelName: "Minor 3rd and Perfect 4th" },
	{ options: ["3", "5"], levelName: "Major 3rd and Tritone" },
	{ options: ["4", "b6"], levelName: "Perfect 4th and Minor 6th" },
	{ options: ["b5", "6"], levelName: "Tritone and Major 6th" },
	{ options: ["5", "b7"], levelName: "Perfect 5th and Minor 7th" },
	{ options: ["b6", "7"], levelName: "Minor 6th and Major 7th" },
	{ options: ["6", "8"], levelName: "Major 6th and Octave" },
];
const major2ndDifferenceLevels = [
	{ options: ["b2", "b3"], levelName: "Minor 2nd and Minor 3rd" },
	{ options: ["2", "3"], levelName: "Major 2nd and Major 3rd" },
	{ options: ["b3", "4"], levelName: "Minor 3rd and Perfect 4th" },
	{ options: ["3", "b5"], levelName: "Major 3rd and Tritone" },
	{ options: ["4", "5"], levelName: "Perfect 4th and Perfect 5th" },
	{ options: ["b5", "b6"], levelName: "Tritone and Minor 6th" },
	{ options: ["5", "6"], levelName: "Perfect 5th and Major 6th" },
	{ options: ["b6", "b7"], levelName: "Minor 6th and Minor 7th" },
	{ options: ["6", "7"], levelName: "Major 6th and Major 7th" },
	{ options: ["b7", "8"], levelName: "Minor 7th and Octave" },
];
const minor2ndDifferenceLevels = [
	{ options: ["b2", "2"], levelName: "Minor 2nd and Major 2nd" },
	{ options: ["2", "b3"], levelName: "Major 2nd and Minor 3rd" },
	{ options: ["b3", "3"], levelName: "Minor 3rd and Major 3rd" },
	{ options: ["3", "4"], levelName: "Major 3rd and Perfect 4th" },
	{ options: ["4", "b5"], levelName: "Perfect 4th and Tritone" },
	{ options: ["b5", "5"], levelName: "Tritone and Perfect 5th" },
	{ options: ["5", "b6"], levelName: "Perfect 5th and Minor 6th" },
	{ options: ["b6", "6"], levelName: "Minor 6th and Major 6th" },
	{ options: ["6", "b7"], levelName: "Major 6th and Minor 7th" },
	{ options: ["b7", "7"], levelName: "Minor 7th and Major 7th" },
	{ options: ["7", "8"], levelName: "Major 7th and Octave" },
];

// const randomDifferenceLevels = [
// 	{ options: ["b2", "6", "2", "b7", "b3", "7", "3", "8"], levelName: "Minor 6th difference" },
// 	{ options: ["b2", "b6", "2", "6", "b3", "b7", "3", "7", "4", "8"], levelName: "Perfect 5th difference" },
// 	{ options: ["b2", "5", "2", "b6", "b3", "6", "3", "b7", "4", "7", "8"], levelName: "Tritone difference" },
// 	{
// 		options: ["b2", "b5", "2", "5", "b3", "b6", "3", "6", "4", "b7", "5", "7", "8"],
// 		levelName: "Perfect 4th difference",
// 	},
// 	{
// 		options: ["b2", "4", "2", "b5", "b3", "5", "3", "b6", "4", "6", "b5", "b7", "5", "7", "8"],
// 		levelName: "Major 3rd difference",
// 	},
// 	{
// 		options: [
// 			"b2",
// 			"b3",
// 			"2",
// 			"3",
// 			"b3",
// 			"4",
// 			"3",
// 			"b5",
// 			"4",
// 			"5",
// 			"b5",
// 			"b6",
// 			"5",
// 			"6",
// 			"b6",
// 			"b7",
// 			"6",
// 			"7",
// 			"b7",
// 			"8",
// 		],
// 		levelName: "Minor 3rd difference",
// 	},
// 	{
// 		options: [
// 			"b2",
// 			"b3",
// 			"2",
// 			"3",
// 			"b3",
// 			"4",
// 			"3",
// 			"b5",
// 			"4",
// 			"5",
// 			"b5",
// 			"b6",
// 			"5",
// 			"6",
// 			"b6",
// 			"b7",
// 			"6",
// 			"7",
// 			"b7",
// 			"8",
// 		],
// 		levelName: "Major 2nd difference",
// 	},
// 	{
// 		options: [
// 			"b2",
// 			"b3",
// 			"2",
// 			"3",
// 			"b3",
// 			"4",
// 			"3",
// 			"b5",
// 			"4",
// 			"5",
// 			"b5",
// 			"b6",
// 			"5",
// 			"6",
// 			"b6",
// 			"b7",
// 			"6",
// 			"7",
// 			"b7",
// 			"8",
// 		],
// 		levelName: "Minor 2nd difference",
// 	},
// ];

export const INTERVAL_COMPARISON_SECTIONS = [
	createSection("High contrast", highContrastLevels),
	createSection("Minor 6th difference", minor6thDifferenceLevels),
	createSection("Perfect 5th difference", perfect5thDifferenceLevels),
	createSection("Tritone difference", tritoneDifferenceLevels),
	createSection("Perfect 4th difference", perfect4thDifferenceLevels),
	createSection("Major 3rd difference", major3rdDifferenceLevels),
	createSection("Minor 3rd difference", minor3rdDifferenceLevels),
	createSection("Major 2nd difference", major2ndDifferenceLevels),
	createSection("Minor 2nd difference", minor2ndDifferenceLevels),
	// createSection("Random differences", randomDifferenceLevels),
];
