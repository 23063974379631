import React, { useMemo, useState } from "react";
import * as t from "../../data/types";
import * as e from "../../data/enums";
import LineChart from "./LineChart";
import ProgressTable from "./ProgressTable";
import SpiderChart from "./SpiderChart";
import { parseDate } from "../../utils/dates";
import Achievements from "./Achievements";
import LineChartStacked from "./LineChartStacked";

export default function Progress({ user, selectedClass }: { user: t.User; selectedClass: t.Class }) {
	const { targets, studentProgress } = useMemo(() => parseProgressData(user, selectedClass), [user, selectedClass]);
	return (
		<div className="studentProgress">
			<div className="flexRowProgress">
				<div className="column">
					<Achievements user={user} classData={selectedClass} />
					<div className="progressChart">
						<h2>Targets</h2>
						<SpiderChart
							studentProgress={studentProgress}
							targets={targets}
							avgProgress={selectedClass.AverageProgress || []}
						/>
					</div>
				</div>
				<div className="column">
					<div className="progressChart">
						<h2>Practice time & progress</h2>
						<LineChartSelect user={user} stacked={false} />
					</div>
					<div className="progressChart">
						<h2>Progress per module</h2>
						<LineChartSelect user={user} stacked={true} />
					</div>
				</div>
			</div>
			<br />
			<div className="statsProgress">
				<h2>Progress</h2>
				<ProgressTable user={user} studentProgress={studentProgress} />
			</div>
		</div>
	);
}

function LineChartSelect({ user, stacked }: { user: t.User; stacked: boolean }) {
	const [selectTimeFrame, setSelectTimeFrame] = useState<e.TimeFrame>(e.TimeFrame.MONTH);

	return (
		<div className="lineChartSelect">
			<div className="flexRow">
				<h3
					className={selectTimeFrame === e.TimeFrame.ALL ? "selectedLink" : "link"}
					onClick={() => setSelectTimeFrame(e.TimeFrame.ALL)}
				>
					all time
				</h3>
				<h3
					className={selectTimeFrame === e.TimeFrame.YEAR ? "selectedLink" : "link"}
					onClick={() => setSelectTimeFrame(e.TimeFrame.YEAR)}
				>
					last year
				</h3>
				<h3
					className={selectTimeFrame === e.TimeFrame.MONTH ? "selectedLink" : "link"}
					onClick={() => setSelectTimeFrame(e.TimeFrame.MONTH)}
				>
					last 30 days
				</h3>
				<h3
					className={selectTimeFrame === e.TimeFrame.WEEK ? "selectedLink" : "link"}
					onClick={() => setSelectTimeFrame(e.TimeFrame.WEEK)}
				>
					last week
				</h3>
			</div>
			{stacked ? (
				<LineChartStacked user={user} timeframe={selectTimeFrame} />
			) : (
				<LineChart user={user} timeframe={selectTimeFrame} />
			)}
		</div>
	);
}

function parseProgressData(user: t.User, selectedClass: t.Class): { targets: number[]; studentProgress: number[] } {
	const modules = Object.values(e.Modules) as e.Modules[];
	const targets: number[] = [];
	const studentProgress: number[] = [];

	const currentTargets = selectedClass.Targets
		? selectedClass.Targets.filter((target) => parseDate(target.Deadline) > new Date())
		: [];
	modules.forEach((module) => {
		// calculate targets
		const moduleTarget = currentTargets?.filter((target) => target.Module === module);
		const targetedProgress = moduleTarget ? moduleTarget.reduce((acc, target) => acc + target.Percentage, 0) : 0;
		targets.push(targetedProgress);

		// calculate student progress
		studentProgress.push(user.Progress.modules[module]?.ModuleTotal || 0);
	});

	return { targets, studentProgress };
}
