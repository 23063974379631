import React from "react";
import { useTranslation } from "react-i18next";

export default function Contact(): JSX.Element {
	const { t } = useTranslation("Contact");

	return (
		<main>
			<div className="basicCard">
				<h1>{t("contact")}</h1>
				<br />
				{t("email")}
			</div>
		</main>
	);
}
