import React from "react";
import { useTranslation } from "react-i18next";

export default function ErrorPage({ message }: { message: string }): JSX.Element {
	const { t } = useTranslation("Error");

	return (
		<main>
			<div className="errorPage">
				<div className="errorMessage">
					<h1>{t("error")}</h1>
					<p>{message}</p>
				</div>
			</div>
		</main>
	);
}
