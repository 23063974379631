// a helper type to extract data from csv files in a structured manner
export type CSVHelperDataObject = { [key: string]: string };

export namespace CSVImportUtil {
	function detectDelimiter(line: string): string {
		const commaCount = (line.match(/,/g) || []).length;
		const semicolonCount = (line.match(/;/g) || []).length;
		const tabCount = (line.match(/\t/g) || []).length;

		let delimiter = ",";
		let maxCount = commaCount;

		if (semicolonCount > maxCount) {
			delimiter = ";";
			maxCount = semicolonCount;
		}
		if (tabCount > maxCount) {
			delimiter = "\t";
			maxCount = tabCount;
		}
		// console.log("commaCount: " + commaCount);
		// console.log("semicolonCount: " + semicolonCount);
		// console.log("tabCount: " + tabCount);
		// console.log("Line: " + line);
		// console.log("Delimiter: " + delimiter);

		return delimiter;
	}
	/**
	 * The csv data without column headers
	 */
	export function csvDataWithoutColumns(data: string): string {
		let splitted: string[] = data.split("\n");
		splitted.shift();
		return splitted.join("\n");
	}

	/**
	 * Get the column names
	 */
	export function getColumns(dataset: string): string[] {
		const lines = dataset.split("\n").filter((line) => line.trim() !== "");

		const firstLine = lines[0];
		const delimiter = detectDelimiter(firstLine);

		if (firstLine) {
			let columns = firstLine.split(delimiter).map((col) => col.trim());
			return columns;
		}
		return [];
	}

	/**
	 * Matches the columns of the csv file with the required columns
	 * @param columns
	 * @param requiredColumns
	 */
	export function validateColumns(columns: string[], requiredColumns: string[]) {
		for (let requiredColumn of requiredColumns) {
			if (!columns.includes(requiredColumn)) {
				throw new Error("Column " + requiredColumn + " is missing in the csv file");
			}
		}
	}

	/**
	 * Parse csv in a any json object holding all values
	 */
	export function parseCSV(csvString: string, columns: string[]): any[] {
		let dataset = []; // JSON representation of all data
		let rows = csvString.split("\n"); // Get rows by splitting by \n
		for (let rowRef in rows) {
			// For every row in rows (which is made of the csvString)
			let row = rows[rowRef];
			let splittedRow = row.split(","); // as we are using csv we are using a comma delimiter to seperate values from each other in rows
			let rowToAdd: { [key: string]: string } = {}; // Add index signature to the object
			for (const columnNameRef in columns) {
				// For every column index we apply the value to the created field in a new object
				let columnName = columns[columnNameRef]; // read in column name
				rowToAdd[columnName] = splittedRow[columnNameRef]; // Apply the value which corrolates to the field ref
			}
			dataset.push(rowToAdd);
		}
		return dataset;
	}

	/**
	 * Pass in the columns you want and get back a JSON object with the columns as keys prefilled with data
	 * Hacky dynamic function to get the columns you want
	 * Make sure to use headers without spaces.
	 * @warning USE WITH CARE! ONLY USE THIS FUNCTION ON VALIDATED CSV FILES.
	 */
	export function parseJsonFromCSV(csvString: string, columnsToMatch: string[]): CSVHelperDataObject[] {
		const lines = csvString.split("\n").filter((line) => line.trim() !== "");

		const firstLine = lines[0];
		const delimiter = detectDelimiter(firstLine);

		const headers = lines[0].split(delimiter).map((header) => header.trim());

		// Create a map of column names to their index
		const columnIndexMap: { [key: string]: number } = {};
		headers.forEach((header, index) => {
			if (columnsToMatch.includes(header)) {
				columnIndexMap[header] = index;
			}
		});

		// Initialize the array of data objects
		const data: CSVHelperDataObject[] = [];

		// Fill the data objects with values from the CSV
		for (let i = 1; i < lines.length; i++) {
			const values = lines[i].split(delimiter).map((value) => value.trim());
			const dataObject: CSVHelperDataObject = {};

			columnsToMatch.forEach((column) => {
				const index = columnIndexMap[column];
				if (index !== undefined && values[index] !== undefined) {
					dataObject[column] = values[index];
				}
			});

			if (Object.keys(dataObject).length > 0) {
				data.push(dataObject);
			}
		}

		return data;
	}
}
