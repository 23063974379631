import { useState, useEffect } from "react";
import { parseDate } from "../utils/dates";

export default function useTableSort<T>(initialData: T[]) {
	const [sortedData, setSortedData] = useState(initialData ? initialData : []);
	const [sortConfig, setSortConfig] = useState<{ index: number; direction: "asc" | "desc" }>({
		index: 0,
		direction: "asc",
	});

	useEffect(() => {
		const sorted = [...initialData].sort((a: any, b: any) => {
			if (parseInput(a[0]) < parseInput(b[0])) return -1;
			if (parseInput(a[0]) > parseInput(b[0])) return 1;
			return 0;
		});
		setSortedData(sorted);
	}, [initialData]);

	const handleSort = (index: number) => {
		let direction: "asc" | "desc" = "asc";
		if (sortConfig && sortConfig.index === index && sortConfig.direction === "asc") direction = "desc";

		const sorted = [...initialData].sort((a: any, b: any) => {
			if (parseInput(a[index]) < parseInput(b[index])) return direction === "asc" ? -1 : 1;
			if (parseInput(a[index]) > parseInput(b[index])) return direction === "asc" ? 1 : -1;
			return 0;
		});

		setSortedData(sorted);
		setSortConfig({ index, direction });
	};

	return { sortedData, handleSort, sortConfig };
}

function parseInput(input: any) {
	// if input is a string and the string has the following format: cc-cc-cccc (cc = character, c = number), return the parsed date
	if (typeof input === "string" && /^\d{2}-\d{2}-\d{4}$/.test(input)) {
		const [day, month, year] = input.split("-");
		return parseDate(`${year}-${month}-${day}`).getTime();
	}

	// if input is a date, return the parsed date
	if (input instanceof Date) return input.getTime();

	// if input is a number, return the parsed number
	const parsedInput = parseFloat(input);
	return isNaN(parsedInput) ? input : parsedInput;
}
