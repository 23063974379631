import * as e from "../enums";
import * as music from "./musical";
import * as levels from "./levels/levels";
import * as sublevels from "./sublevels";

export const ADMIN_EMAIL = "teacher1@school1.com";
export const ADMIN_ID = "EKCpx83FTeoszI8ExXWW";

export const seconds = 60;
export const milliseconds = 1000;

export const NUMBER_OF_QUESTION_MAP: Record<e.Modules, number> = {
	[e.Modules.INTERVAL_COMPARISON]: 10,
	[e.Modules.INTERVAL_RECOGNITION]: 10,
	[e.Modules.HARMONIC_CONTEXT]: 10,
	[e.Modules.SCALE_RECOGNITION]: 10,
	[e.Modules.MELODIC_DICTATION]: 5,
	[e.Modules.CHORD_RECOGNITION]: 10,
	[e.Modules.TRIAD_INVERSIONS]: 10,
	[e.Modules.PROGRESSIONS_MAJOR]: 5,
	[e.Modules.PROGRESSIONS_MINOR]: 5,
	[e.Modules.PROGRESSIONS_JAZZ]: 5,
};

export const RESPONSE_TIMES: Record<e.Modules, number> = {
	[e.Modules.INTERVAL_COMPARISON]: 5,
	[e.Modules.INTERVAL_RECOGNITION]: 5,
	[e.Modules.HARMONIC_CONTEXT]: 5,
	[e.Modules.SCALE_RECOGNITION]: 10,
	[e.Modules.MELODIC_DICTATION]: 15,
	[e.Modules.CHORD_RECOGNITION]: 5,
	[e.Modules.TRIAD_INVERSIONS]: 5,
	[e.Modules.PROGRESSIONS_MAJOR]: 15,
	[e.Modules.PROGRESSIONS_MINOR]: 15,
	[e.Modules.PROGRESSIONS_JAZZ]: 15,
};

///////////
// MODES //
///////////
export const MOVEMENT_MAP: Record<e.Modules, e.Movement[]> = {
	[e.Modules.INTERVAL_COMPARISON]: [
		e.Movement.ASCENDING_INTERVAL,
		e.Movement.DESCENDING_INTERVAL,
		e.Movement.HARMONY_INTERVAL,
	],
	[e.Modules.INTERVAL_RECOGNITION]: [
		e.Movement.ASCENDING_INTERVAL,
		e.Movement.DESCENDING_INTERVAL,
		e.Movement.HARMONY_INTERVAL,
	],
	[e.Modules.HARMONIC_CONTEXT]: [e.Movement.ONE_OCTAVE, e.Movement.OCTAVE_HIGHER, e.Movement.OCTAVE_LOWER],
	[e.Modules.CHORD_RECOGNITION]: [e.Movement.ASCENDING, e.Movement.DESCENDING, e.Movement.HARMONY],
	[e.Modules.TRIAD_INVERSIONS]: [
		e.Movement.ASCENDING,
		e.Movement.DESCENDING,
		e.Movement.HARMONY,
		e.Movement.ASCENDING_RANDOM_VOICING,
		e.Movement.DESCENDING_RANDOM_VOICING,
		e.Movement.HARMONY_RANDOM_VOICING,
	],
	[e.Modules.SCALE_RECOGNITION]: [
		e.Movement.ALTERNATING_1,
		e.Movement.ALTERNATING_2,
		e.Movement.ALTERNATING_3,
		e.Movement.ASCENDING_SLOW,
		e.Movement.ASCENDING,
		e.Movement.ASCENDING_FAST,
		e.Movement.DESCENDING_SLOW,
		e.Movement.DESCENDING,
		e.Movement.DESCENDING_FAST,
		e.Movement.RANDOM_EASY,
		e.Movement.RANDOM,
		e.Movement.RANDOM_HARD,
	],
	[e.Modules.MELODIC_DICTATION]: [
		e.Movement.THREE_NOTES,
		e.Movement.FOUR_NOTES,
		e.Movement.FIVE_NOTES,
		e.Movement.SIX_NOTES,
		e.Movement.SEVEN_NOTES,
	],
	[e.Modules.PROGRESSIONS_MAJOR]: [e.Movement.ASCENDING, e.Movement.DESCENDING, e.Movement.HARMONY],
	[e.Modules.PROGRESSIONS_MINOR]: [e.Movement.ASCENDING, e.Movement.DESCENDING, e.Movement.HARMONY],
	[e.Modules.PROGRESSIONS_JAZZ]: [e.Movement.SLOW, e.Movement.FAST, e.Movement.MELODY],
};

// WHICH BUTTONS ARE AVAILABLE AT EACH LEVEL
const j = e.DegreesJazz;
const JazzI = [j.Imaj7, j.bII7, "", j.V7];
const Jazzi = [j.i7, j.bII7, j.ii7b5, j.V7b9];

// const JazzDim = [j.SharpiDim, j.biiDim, j.SharpiiDim, j.biiiDim, j.SharpivDim, j.SharpvDim, j.bviDim];
export const JAZZ_PROGRESSIONS_MAJOR = [
	j.Imaj7,
	j.SharpiDim,
	j.bII7,
	j.bIImaj7,
	j.ii7,
	j.SharpiiDim,
	j.bIIImaj7,
	j.iii7,
	j.IVmaj7,
	j.SharpivDim,
	j.V7,
	j.V7b9overii,
	j.SharpvDim,
	j.bVImaj7,
	j.vi7,
];

// modulations
export const JazzbII = [j.bIImaj7, j.bII7overbII, j.ii7overbII, j.V7overbII];
export const JazzbIII = [j.bIIImaj7, j.bII7overbIII, j.ii7overbIII, j.V7overbIII];
export const JazzbVI = [j.bVImaj7, j.bII7overbVI, j.ii7overbVI, j.V7overbVI];
export const JazzVII = [j.VIImaj7, j.bII7overVII, j.ii7overVII, j.V7overVII];
export const JazzbVII = [j.bVIImaj7, j.bII7overbVII, j.ii7overbVII, j.V7overbVII];
export const JAZZ_MODULATION_MAJOR_CHORDS = [...JazzI, ...JazzbII, ...JazzbIII, ...JazzbVI, ...JazzbVII, ...JazzVII];

// secondary dominant major
export const Jazzii = [j.ii7, j.bII7overii, j.ii7b5overii, j.V7b9overii];
export const Jazziii = [j.iii7, j.bII7overiii, j.ii7b5overiii, j.V7b9overiii];
export const Jazziv = [j.iv7, j.bII7overiv, j.ii7b5overiv, j.V7b9overiv];
export const Jazzv = [j.v7, j.bII7overv, j.ii7b5overv, j.V7b9overv];
export const Jazzvi = [j.vi7, j.bII7overvi, j.ii7b5overvi, j.V7b9overvi];
export const JAZZ_SEC_DOM_MAJOR = [...JazzI, ...Jazzii, ...Jazziii, ...Jazziv, ...Jazzv, ...Jazzvi];

// secondary dominant minor
export const JazzII = [j.IImaj7, j.bII7overII, j.ii7overII, j.V7overII];
export const JazzIII = [j.IIImaj7, j.bII7overIII, j.ii7overIII, j.V7overIII];
export const JazzIV = [j.IVmaj7, j.bII7overIV, j.ii7overIV, j.V7overIV];
export const JazzV = [j.Vmaj7, j.bII7overV, j.ii7overV, j.V7overV];
export const JazzVI = [j.VImaj7, j.bII7overVI, j.ii7overVI, j.V7overVI];
export const JAZZ_SEC_DOM_MINOR = [...Jazzi, ...JazzII, ...JazzIII, ...JazzIV, ...JazzV, ...JazzVI];

export const MAJOR_PROGRESSIONS_ROOT = [
	e.Degrees.I,
	e.Degrees.ii,
	e.Degrees.iii,
	e.Degrees.IV,
	e.Degrees.V,
	e.Degrees.vi,
	e.Degrees.viiDim,
];
export const MAJOR_PROGRESSIONS_FIRST = [
	e.DegreesFirstInversion.I,
	e.DegreesFirstInversion.ii,
	e.DegreesFirstInversion.iii,
	e.DegreesFirstInversion.IV,
	e.DegreesFirstInversion.V,
	e.DegreesFirstInversion.vi,
	e.DegreesFirstInversion.viiDim,
];
export const MAJOR_PROGRESSIONS_SECOND = [
	e.DegreesSecondInversion.I,
	e.DegreesSecondInversion.ii,
	e.DegreesSecondInversion.iii,
	e.DegreesSecondInversion.IV,
	e.DegreesSecondInversion.V,
	e.DegreesSecondInversion.vi,
	e.DegreesSecondInversion.viiDim,
];
export const MINOR_PROGRESSIONS_ROOT = [
	e.Degrees.i,
	e.Degrees.iiDim,
	e.Degrees.bIII,
	e.Degrees.iv,
	e.Degrees.V,
	e.Degrees.bVI,
	e.Degrees.bVII,
];
export const MINOR_PROGRESSIONS_FIRST = [
	e.DegreesFirstInversion.i,
	e.DegreesFirstInversion.iiDim,
	e.DegreesFirstInversion.bIII,
	e.DegreesFirstInversion.iv,
	e.DegreesFirstInversion.V,
	e.DegreesFirstInversion.bVI,
	e.DegreesFirstInversion.bVII,
];
export const MINOR_PROGRESSIONS_SECOND = [
	e.DegreesSecondInversion.i,
	e.DegreesSecondInversion.iiDim,
	e.DegreesSecondInversion.bIII,
	e.DegreesSecondInversion.iv,
	e.DegreesSecondInversion.V,
	e.DegreesSecondInversion.bVI,
	e.DegreesSecondInversion.bVII,
];
export const BUTTONS: Record<e.Modules, string[] | string[][]> = {
	[e.Modules.INTERVAL_COMPARISON]: music.INTERVALS.slice(1, 13),
	[e.Modules.INTERVAL_RECOGNITION]: music.INTERVALS.slice(1, 13),
	[e.Modules.HARMONIC_CONTEXT]: music.INTERVALS.slice(0, 12),
	[e.Modules.SCALE_RECOGNITION]: [
		e.Scales.IONIAN,
		e.Scales.DORIAN,
		e.Scales.PHRYGIAN,
		e.Scales.LYDIAN,
		e.Scales.MIXOLYDIAN,
		e.Scales.AEOLIAN,
		e.Scales.LOCRIAN,
		e.Scales.HARMONIC_MINOR,
		e.Scales.MELODIC_MINOR,
		e.Scales.MAJOR_PENTATONIC,
		e.Scales.MINOR_PENTATONIC,
		e.Scales.BLUES,
		// e.Scales.HARMONIC_MAJOR_I,
		// e.Scales.HARMONIC_MAJOR_II,
		// e.Scales.HARMONIC_MAJOR_III,
		// e.Scales.HARMONIC_MAJOR_IV,
		// e.Scales.HARMONIC_MAJOR_V,
		// e.Scales.HARMONIC_MAJOR_VI,
		// e.Scales.HARMONIC_MAJOR_VII,
		// e.Scales.SPANISH_PHRYGIAN_I,
		// e.Scales.SPANISH_PHRYGIAN_II,
		// e.Scales.SPANISH_PHRYGIAN_III,
		// e.Scales.SPANISH_PHRYGIAN_IV,
		// e.Scales.SPANISH_PHRYGIAN_V,
		// e.Scales.SPANISH_PHRYGIAN_VI,
		// e.Scales.SPANISH_PHRYGIAN_VII,
		// e.Scales.ALTERED_I,
		// e.Scales.ALTERED_II,
		// e.Scales.ALTERED_III,
		// e.Scales.ALTERED_IV,
		// e.Scales.ALTERED_V,
		// e.Scales.ALTERED_VI,
		// e.Scales.ALTERED_VII,
	],
	[e.Modules.MELODIC_DICTATION]: music.INTERVALS.slice(0, 12),
	[e.Modules.CHORD_RECOGNITION]: Object.values(e.Chords),
	[e.Modules.TRIAD_INVERSIONS]: [
		e.Inversions.MAJOR_2ND_INVERSION,
		e.Inversions.MINOR_2ND_INVERSION,
		e.Inversions.MAJOR_1ST_INVERSION,
		e.Inversions.MINOR_1ST_INVERSION,
		e.Inversions.MAJOR_ROOT,
		e.Inversions.MINOR_ROOT,
	],
	[e.Modules.PROGRESSIONS_MAJOR]: [
		e.Degrees.I,
		e.Degrees.ii,
		e.Degrees.iii,
		e.Degrees.IV,
		e.Degrees.V,
		e.Degrees.vi,
		e.Degrees.viiDim,
	],
	[e.Modules.PROGRESSIONS_MINOR]: [
		e.Degrees.i,
		e.Degrees.iiDim,
		e.Degrees.bIII,
		e.Degrees.iv,
		e.Degrees.V,
		e.Degrees.bVI,
		e.Degrees.bVII,
	],
	[e.Modules.PROGRESSIONS_JAZZ]: [
		...JAZZ_PROGRESSIONS_MAJOR,
		// ...JazzI,

		// modulations
		// ...JazzbII,
		// ...JazzbIII,
		// ...JazzbVI,
		// ...JazzbVII,
		// ...JazzVII,

		// secondary dominant major
		// ...Jazzii,
		// ...Jazziii,
		// ...Jazziv,
		// ...Jazzv,
		// ...Jazzvi,

		// secondary dominant minor
		// ...JazzII,
		// ...JazzIII,
		// ...JazzIV,
		// ...JazzV,
		// ...JazzVI,
	],
};
export const BUTTONS_PROGRESSIONS_PRACTICE = [
	e.Degrees.I,
	e.DegreesFirstInversion.I,
	e.DegreesSecondInversion.I,
	e.Degrees.ii,
	e.DegreesFirstInversion.ii,
	e.DegreesSecondInversion.ii,
	e.Degrees.iii,
	e.DegreesFirstInversion.iii,
	e.DegreesSecondInversion.iii,
	e.Degrees.IV,
	e.DegreesFirstInversion.IV,
	e.DegreesSecondInversion.IV,
	e.Degrees.V,
	e.DegreesFirstInversion.V,
	e.DegreesSecondInversion.V,
	e.Degrees.vi,
	e.DegreesFirstInversion.vi,
	e.DegreesSecondInversion.vi,
	e.Degrees.viiDim,
	e.DegreesFirstInversion.viiDim,
	e.DegreesSecondInversion.viiDim,
];
export const BUTTONS_PROGRESSIONS_MINOR_PRACTICE = [
	e.Degrees.i,
	e.DegreesFirstInversion.i,
	e.DegreesSecondInversion.i,
	e.Degrees.iiDim,
	e.DegreesFirstInversion.iiDim,
	e.DegreesSecondInversion.iiDim,
	e.Degrees.bIII,
	e.DegreesFirstInversion.bIII,
	e.DegreesSecondInversion.bIII,
	e.Degrees.iv,
	e.DegreesFirstInversion.iv,
	e.DegreesSecondInversion.iv,
	e.Degrees.V,
	e.DegreesFirstInversion.V,
	e.DegreesSecondInversion.V,
	e.Degrees.bVI,
	e.DegreesFirstInversion.bVI,
	e.DegreesSecondInversion.bVI,
	e.Degrees.bVII,
	e.DegreesFirstInversion.bVII,
	e.DegreesSecondInversion.bVII,
];

// structure:
// module (e.Modules)
// - mode (string)
//     - section.levels
//         - levels (number)

export const LEVELS: Record<e.Modules, any> = {
	[e.Modules.INTERVAL_COMPARISON]: levels.INTERVAL_COMPARISON,
	[e.Modules.INTERVAL_RECOGNITION]: levels.INTERVAL_RECOGNITION,
	[e.Modules.HARMONIC_CONTEXT]: levels.HARMONIC_CONTEXT,
	[e.Modules.SCALE_RECOGNITION]: levels.SCALE_RECOGNITION,
	[e.Modules.MELODIC_DICTATION]: levels.MELODIC_DICTATION,
	[e.Modules.CHORD_RECOGNITION]: levels.CHORD_RECOGNITION,
	[e.Modules.TRIAD_INVERSIONS]: levels.TRIAD_INVERSIONS,
	[e.Modules.PROGRESSIONS_MAJOR]: levels.PROGRESSIONS_MAJOR,
	[e.Modules.PROGRESSIONS_MINOR]: levels.PROGRESSIONS_MINOR,
	[e.Modules.PROGRESSIONS_JAZZ]: levels.PROGRESSIONS_JAZZ,
};

export const SUBLEVELS: Record<e.Modules, any> = {
	[e.Modules.INTERVAL_COMPARISON]: sublevels.intervalComparison,
	[e.Modules.INTERVAL_RECOGNITION]: sublevels.intervalRecognition,
	[e.Modules.HARMONIC_CONTEXT]: sublevels.harmonicContext,
	[e.Modules.SCALE_RECOGNITION]: sublevels.scaleRecognition,
	[e.Modules.MELODIC_DICTATION]: sublevels.melodicDictation,
	[e.Modules.CHORD_RECOGNITION]: sublevels.chords,
	[e.Modules.TRIAD_INVERSIONS]: sublevels.triadInversions,
	[e.Modules.PROGRESSIONS_MAJOR]: sublevels.progressions,
	[e.Modules.PROGRESSIONS_MINOR]: sublevels.progressions,
	[e.Modules.PROGRESSIONS_JAZZ]: sublevels.progressionsJazz, // TODO: is this what we want?
};

export const MODULE_NAME_MAP: Record<e.Modules, string> = {
	[e.Modules.INTERVAL_COMPARISON]: "intervals",
	[e.Modules.INTERVAL_RECOGNITION]: "interval",
	[e.Modules.HARMONIC_CONTEXT]: "interval",
	[e.Modules.SCALE_RECOGNITION]: "scale",
	[e.Modules.MELODIC_DICTATION]: "melody",
	[e.Modules.CHORD_RECOGNITION]: "chord",
	[e.Modules.TRIAD_INVERSIONS]: "inversions",
	[e.Modules.PROGRESSIONS_MAJOR]: "progression",
	[e.Modules.PROGRESSIONS_MINOR]: "progression",
	[e.Modules.PROGRESSIONS_JAZZ]: "progression",
};
