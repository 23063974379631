import React, { useState, useCallback } from "react";
import * as t from "../../../data/types";
import * as e from "../../../data/enums";
import { handleError } from "../../../utils/sharedUtils";
import { useTeacherLoggedIn } from "../../../context/UserContext";
import getClassesByTeacherAPI from "../../../services/api/getClassesByTeacherAPI";
import { FirebaseManager } from "../../../services/firebase/FirebaseManager";
import { useAlert } from "../../../context/AlertContext";
import { checkForUnsafeCharacters } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import editStudentAPI from "../../../services/api/editStudentAPI";
import fetchStudentAPI from "../../../services/api/fetchStudentAPI";
import * as Sentry from "@sentry/react";
import { ADMIN_EMAIL } from "../../../data/constants/constants";

export default function CreateNewStudent({ selectedClass }: { selectedClass: any }): JSX.Element {
	const { teacher, classData, setClassData, setLoading } = useTeacherLoggedIn();
	const { triggerAlert } = useAlert();
	const { t } = useTranslation("ManageClasses");

	const [studentPassword, setStudentPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [studentNumber, setStudentNumber] = useState("");
	const [studentEmail, setStudentEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const handleSignUp = useCallback(async () => {
		if (!checkIfStudentIsUnique(firstName, lastName, studentNumber, studentEmail, classData, setErrorMessage))
			return;
		if (checkForUnsafeCharacters([firstName, lastName, studentNumber, studentEmail]))
			return setErrorMessage(t("ErrorMessageUnsafeCharacters"));

		const existingStudent = await fetchStudentAPI(studentEmail);
		if (
			existingStudent &&
			(existingStudent.FirstName.trim().toLowerCase() !== firstName.trim().toLowerCase() ||
				existingStudent.LastName.trim().toLowerCase() !== lastName.trim().toLowerCase())
		)
			return setErrorMessage(
				"There already is a student with this email in the database. We only transfer students if the name is exactly the same. This is not the case. The name of the student in the database is: " +
					existingStudent.FirstName +
					" " +
					existingStudent.LastName
			);

		setLoading(true);
		try {
			if (existingStudent) {
				existingStudent.ClassID = selectedClass.id;
				existingStudent.SubscriptionPlan = e.Subscription.STUDENT;
				existingStudent.StudentNumber = studentNumber;
				await editStudentAPI(existingStudent);
			} else {
				await FirebaseManager.createNewStudent(
					studentEmail,
					studentPassword,
					firstName,
					lastName,
					studentNumber,
					selectedClass
				);
			}
			await getClassesByTeacherAPI(teacher.id, setClassData);
			triggerAlert("Student " + firstName + " " + lastName + " succesfully created", "success");
		} catch (error) {
			triggerAlert(handleError(error).userMessage, "error");
			if (teacher?.Email !== ADMIN_EMAIL) Sentry.captureException(error);
		} finally {
			setErrorMessage("");
			setLoading(false);
		}
	}, [
		studentEmail,
		studentPassword,
		firstName,
		lastName,
		studentNumber,
		teacher,
		selectedClass,
		classData,
		setClassData,
		setLoading,
		triggerAlert,
	]);

	return (
		<div className="createNewContent">
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleSignUp();
				}}
			>
				<label className="flexRow">
					<h3>{t("First Name")}</h3>
					<input
						className="text-area"
						type="text"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
						required
					/>
				</label>
				<label className="flexRow">
					<h3>{t("Last Name")}</h3>
					<input
						className="text-area"
						type="text"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
						required
					/>
				</label>
				<label className="flexRow">
					<h3>{t("className")}</h3>
					<input className="text-area" type="" placeholder={selectedClass.ClassName} disabled />
				</label>
				<label className="flexRow">
					<h3>{t("Student Number")}</h3>
					<input
						className="text-area"
						type="text"
						value={studentNumber}
						onChange={(e) => setStudentNumber(e.target.value)}
						required
					/>
				</label>
				<label className="flexRow">
					<h3>{t("Email")}</h3>
					<input
						className="text-area"
						type="email"
						value={studentEmail}
						onChange={(e) => setStudentEmail(e.target.value.toLowerCase().trim())}
						required
					/>
				</label>
				<label className="flexRow">
					<h3>{t("temporaryPassword")}</h3>
					<input
						className="text-area"
						type="text"
						value={studentPassword}
						onChange={(e) => setStudentPassword(e.target.value)}
						required
					/>
				</label>
				{errorMessage && <div className="error">{errorMessage}</div>}
				<button type="submit" value="Sign Up">
					{t("registerButton")}
				</button>
			</form>
		</div>
	);
}

/**
 * This function will validate if the student is unique
 * @returns
 */
export function checkIfStudentIsUnique(
	studentFirstName: string,
	studentLastName: string,
	studentNumber: string,
	studentEmail: string,
	classData: t.Class[] | null,
	setErrorMessage: React.Dispatch<React.SetStateAction<string>>
): boolean {
	if (!classData) return true;

	for (const classes of classData) {
		const studentEmailExists = classes.Students?.find((student) => student.Email === studentEmail);
		if (studentEmailExists) {
			setErrorMessage("Student with this email already exists");
			return false;
		}

		const studentNumberExists = classes.Students?.find((student) => student.StudentNumber === studentNumber);
		if (studentNumberExists) {
			setErrorMessage("Student with this student number already exists");
			return false;
		}

		const studentNameExists = classes.Students?.find(
			(student) => student.FirstName === studentFirstName && student.LastName === studentLastName
		);
		if (studentNameExists) {
			setErrorMessage("Student with this name already exists");
			return false;
		}
	}

	return true;
}
