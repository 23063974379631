import * as t from "../data/types";
import { convertDateToString, getTimeFromDate } from "./dates";

export function checkIfClassExists(className: string, classData: t.Class[] | null) {
	if (!classData) return false;
	for (const classObj of classData) if (classObj.ClassName === className) return true;
	return false;
}

export function parseExamData(selectedClass: t.Class | undefined): string[][] {
	const examData: string[][] = [];
	if (!selectedClass) return examData;

	const exams = selectedClass.Exams;
	if (!exams) return examData;
	exams.map((exam) => {
		const date = convertDateToString(exam.StartTime, "dd-mm-yyyy");
		const name = exam.Name;
		const startTime = getTimeFromDate(exam.StartTime);
		const finished = exam.Results?.length.toString();
		const avgGrade = calculateAvgGrade(exam);
		const examID = exam.id;
		examData.push([date, name, startTime, finished, avgGrade, examID]);
		return null;
	});
	return examData;
}

function calculateAvgGrade(exam: t.Exam): string {
	if (!exam.Results) return "-";
	const sum = exam.Results.reduce((acc, result) => acc + result.Grade, 0);
	const avgGrade = sum ? (sum / exam.Results.length).toPrecision(3) : "-";
	return avgGrade;
}

/**
 * This function will validate the email (RFC 2822 by Google ;))
 * @param email
 * @returns
 */
export function isValidEmail(email: string): boolean {
	const re =
		/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
	return re.test(email);
}

export function checkForUnsafeCharacters(fields: string[]): boolean {
	const unsafePattern = /[\/\\?%*:|"<>]/g;
	for (const field of fields) {
		if (unsafePattern.test(field)) return true;
	}
	return false;
}
