import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Navigation(): JSX.Element {
	const currentYear = new Date().getFullYear();
	const { t } = useTranslation("Footer");

	return (
		<footer>
			<p>
				<Link to="/support">{t("support")}</Link>
				{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
				<Link to="/contact">{t("contact")}</Link>
			</p>
			<p>
				&copy; {currentYear} Auris Ear Training. {t("allRightsReserved")}
			</p>
		</footer>
	);
}
