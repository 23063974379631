import React from "react";
import * as e from "../enums";

///////////////////////////////
// GENERAL MUSICAL CONSTANTS //
///////////////////////////////
export const NOTES = [
	"C2",
	"CSharp2",
	"D2",
	"DSharp2",
	"E2",
	"F2",
	"FSharp2",
	"G2",
	"GSharp2",
	"A2",
	"ASharp2",
	"B2",
	"C3",
	"CSharp3",
	"D3",
	"DSharp3",
	"E3",
	"F3",
	"FSharp3",
	"G3",
	"GSharp3",
	"A3",
	"ASharp3",
	"B3",
	"C4",
	"CSharp4",
	"D4",
	"DSharp4",
	"E4",
	"F4",
];
export const INTERVALS = [
	"1",
	"b2",
	"2",
	"b3",
	"3",
	"4",
	"b5",
	"5",
	"b6",
	"6",
	"b7",
	"7",
	"8",
	"b9",
	"9",
	"#9",
	"10",
	"11",
	"#11",
	"12",
	"b13",
	"13",
	"b14",
	"14",
	"15",
	"b16",
	"16",
	"b17",
	"17",
	"18",
];
export const INTERVAL_MAP: Record<string, number> = {
	"1": 0,
	b2: 1,
	"2": 2,
	b3: 3,
	"3": 4,
	"4": 5,
	b5: 6,
	"5": 7,
	b6: 8,
	"6": 9,
	b7: 10,
	"7": 11,
	"8": 12,
	b9: 13,
	"9": 14,
	"#9": 15,
	"10": 16,
	"11": 17,
	"#11": 18,
	b12: 18,
	"12": 19,
	b13: 20,
	"13": 21,
	b14: 22,
	"14": 23,
	"15": 24,
	b16: 25,
	"16": 26,
	b17: 27,
	"17": 28,
	"18": 29,
};

export const INTERVALS_OCTAVE_LOWER: Record<string, string> = {
	"1": "1LowerOctave",
	b2: "b2LowerOctave",
	"2": "2LowerOctave",
	b3: "b3LowerOctave",
	"3": "3LowerOctave",
	"4": "4LowerOctave",
	b5: "b5LowerOctave",
	"5": "5LowerOctave",
	b6: "b6LowerOctave",
	"6": "6LowerOctave",
	b7: "b7LowerOctave",
	"7": "7LowerOctave",
};

export const INTERVALS_OCTAVE: Record<string, string> = {
	"8": "1",
	b9: "b2",
	"9": "2",
	"#9": "b3",
	"10": "3",
	"11": "4",
	"#11": "b5",
	"12": "5",
	b13: "b6",
	"13": "6",
	b14: "b7",
	"14": "7",
	"15": "8",
};

export const INTERVAL_NAMES_MAP: Record<string, string> = {
	"1": "Unison",
	b2: "Minor Second",
	"2": "Major Second",
	b3: "Minor Third",
	"3": "Major Third",
	"4": "Perfect Fourth",
	b5: "Tritone",
	"5": "Perfect Fifth",
	b6: "Minor Sixth",
	"6": "Major Sixth",
	b7: "Minor Seventh",
	"7": "Major Seventh",
	"8": "Octave",
};

export const CHORDS_MAP: Record<e.Chords, string[]> = {
	[e.Chords.MAJOR]: ["1", "3", "5"],
	[e.Chords.DOMINANT]: ["1", "3", "5", "b7"],
	[e.Chords.MAJOR_7TH]: ["1", "3", "5", "7"],

	[e.Chords.MINOR]: ["1", "b3", "5"],
	[e.Chords.MINOR_7TH]: ["1", "b3", "5", "b7"],
	[e.Chords.MINOR_MAJOR_7TH]: ["1", "b3", "5", "7"],

	[e.Chords.AUG]: ["1", "3", "b6"],
	[e.Chords.AUG_7TH]: ["1", "3", "b6", "b7"],
	[e.Chords.AUG_MAJOR_7TH]: ["1", "3", "b6", "7"],

	[e.Chords.DIM]: ["1", "b3", "b5"],
	[e.Chords.DIM_7TH]: ["1", "b3", "b5", "6"],
	[e.Chords.HALF_DIM_7TH]: ["1", "b3", "b5", "b7"],

	[e.Chords.SUS2]: ["1", "2", "5"],
	[e.Chords.SUS4]: ["1", "4", "5"],
};
export const CHORDS_SYMBOLS_MAP: Record<e.Chords, JSX.Element> = {
	[e.Chords.MAJOR]: <>M</>,
	[e.Chords.MINOR]: <>m</>,
	[e.Chords.DIM]: <>o</>,
	[e.Chords.AUG]: <>+</>,
	[e.Chords.SUS2]: (
		<div>
			<sup> sus </sup>2
		</div>
	),
	[e.Chords.SUS4]: (
		<div>
			<sup> sus </sup>4
		</div>
	),
	[e.Chords.DOMINANT]: <>7</>,
	[e.Chords.MINOR_7TH]: (
		<div>
			m <sup> 7 </sup>
		</div>
	),
	[e.Chords.DIM_7TH]: (
		<div>
			o <sup> 7 </sup>
		</div>
	),
	[e.Chords.AUG_7TH]: (
		<div>
			+ <sup> 7 </sup>
		</div>
	),
	[e.Chords.MAJOR_7TH]: (
		<div>
			Δ <sup> 7 </sup>
		</div>
	),
	[e.Chords.MINOR_MAJOR_7TH]: (
		<div>
			m <sup> Δ </sup>
		</div>
	),
	[e.Chords.HALF_DIM_7TH]: (
		<div>
			ø <sup> 7 </sup>
		</div>
	),
	[e.Chords.AUG_MAJOR_7TH]: (
		<div>
			+ <sup> Δ </sup>
		</div>
	),
};
export const INVERSIONS_MAP: Record<e.Inversions, string[]> = {
	[e.Inversions.MAJOR_ROOT]: ["1", "3", "5"],
	[e.Inversions.MAJOR_1ST_INVERSION]: ["3", "5", "1"],
	[e.Inversions.MAJOR_2ND_INVERSION]: ["5", "1", "3"],
	[e.Inversions.MINOR_ROOT]: ["1", "b3", "5"],
	[e.Inversions.MINOR_1ST_INVERSION]: ["b3", "5", "1"],
	[e.Inversions.MINOR_2ND_INVERSION]: ["5", "1", "b3"],
};

export const SCALES_MAP: Record<e.Scales, string[]> = {
	// 5 notes
	[e.Scales.MAJOR_PENTATONIC]: ["1", "2", "3", "5", "6"],
	[e.Scales.MINOR_PENTATONIC]: ["1", "b3", "4", "5", "b7"],

	// 6 notes
	[e.Scales.BLUES]: ["1", "b3", "4", "b5", "5", "b7"],
	[e.Scales.WHOLE_TONE]: ["1", "2", "3", "b5", "6", "b7"],

	// 7 notes
	// church modes
	[e.Scales.IONIAN]: ["1", "2", "3", "4", "5", "6", "7"],
	[e.Scales.DORIAN]: ["1", "2", "b3", "4", "5", "6", "b7"],
	[e.Scales.PHRYGIAN]: ["1", "b2", "b3", "4", "5", "b6", "b7"],
	[e.Scales.LYDIAN]: ["1", "2", "3", "b5", "5", "6", "7"],
	[e.Scales.MIXOLYDIAN]: ["1", "2", "3", "4", "5", "6", "b7"],
	[e.Scales.AEOLIAN]: ["1", "2", "b3", "4", "5", "b6", "b7"],
	[e.Scales.LOCRIAN]: ["1", "b2", "b3", "4", "b5", "b6", "b7"],

	// alterned scales
	[e.Scales.ALTERED_I]: ["1", "b2", "b3", "3", "b5", "b6", "b7"],
	[e.Scales.ALTERED_II]: ["1", "2", "b3", "4", "5", "6", "7"],
	[e.Scales.ALTERED_III]: ["1", "b2", "b3", "4", "5", "6", "b7"],
	[e.Scales.ALTERED_IV]: ["1", "2", "3", "b5", "b6", "6", "7"],
	[e.Scales.ALTERED_V]: ["1", "2", "3", "b5", "5", "6", "b7"],
	[e.Scales.ALTERED_VI]: ["1", "2", "3", "4", "5", "b6", "b7"],
	[e.Scales.ALTERED_VII]: ["1", "2", "b3", "4", "b5", "b6", "b7"],

	// harmonic major scales
	[e.Scales.HARMONIC_MAJOR_I]: ["1", "2", "3", "4", "5", "b6", "7"],
	[e.Scales.HARMONIC_MAJOR_II]: ["1", "2", "b3", "4", "b5", "6", "b7"],
	[e.Scales.HARMONIC_MAJOR_III]: ["1", "b2", "b3", "3", "5", "b6", "b7"],
	[e.Scales.HARMONIC_MAJOR_IV]: ["1", "2", "b3", "b5", "5", "6", "7"],
	[e.Scales.HARMONIC_MAJOR_V]: ["1", "b2", "3", "4", "5", "6", "b7"],
	[e.Scales.HARMONIC_MAJOR_VI]: ["1", "b3", "3", "b5", "b6", "6", "7"],
	[e.Scales.HARMONIC_MAJOR_VII]: ["1", "b2", "b3", "4", "b5", "b6", "6"],

	// spanish phrygian
	[e.Scales.SPANISH_PHRYGIAN_I]: ["1", "2", "3", "4", "b6", "6", "7"],
	[e.Scales.SPANISH_PHRYGIAN_II]: ["1", "2", "b3", "b5", "5", "6", "b7"],
	[e.Scales.SPANISH_PHRYGIAN_III]: ["1", "b2", "3", "4", "5", "b6", "b7"],
	[e.Scales.SPANISH_PHRYGIAN_IV]: ["1", "b3", "3", "b5", "5", "6", "7"],
	[e.Scales.SPANISH_PHRYGIAN_V]: ["1", "b2", "b3", "3", "b5", "b6", "6"],
	[e.Scales.SPANISH_PHRYGIAN_VI]: ["1", "2", "b3", "4", "5", "b6", "7"],
	[e.Scales.SPANISH_PHRYGIAN_VII]: ["1", "b2", "b3", "4", "b5", "6", "b7"],

	// minor modes
	[e.Scales.HARMONIC_MINOR]: ["1", "2", "b3", "4", "5", "b6", "7"],
	[e.Scales.MELODIC_MINOR]: ["1", "2", "b3", "4", "5", "6", "7"],

	// 8 notes
	[e.Scales.BEBOP_DOMINANT]: ["1", "2", "3", "4", "5", "6", "b7", "7"],
	[e.Scales.BEBOP_MAJOR]: ["1", "2", "3", "4", "5", "b6", "6", "7"],
	[e.Scales.BEBOP_MELODIC_MINOR]: ["1", "2", "b3", "3", "4", "5", "6", "7"],
	[e.Scales.BEBOP_HARMONIC_MINOR]: ["1", "2", "b3", "4", "5", "b6", "b7", "7"],
	[e.Scales.OCTATONIC]: ["1", "2", "b3", "4", "b5", "b6", "6", "7"],

	// 9 notes
	[e.Scales.COMPOSITE_BLUES]: ["1", "2", "b3", "3", "4", "b5", "5", "6", "b7"],

	// 11 notes
	[e.Scales.ALTERED]: ["1", "b2", "2", "b3", "3", "b5", "5", "b6", "6", "b7", "7"],

	// 12 notes
	[e.Scales.CHROMATIC]: ["1", "b2", "2", "b3", "3", "4", "b5", "5", "b6", "6", "b7", "7"],
};
export const CHORD_ROOT_MAP: Record<string, number> = {
	[e.Degrees.i]: 0,
	[e.Degrees.I]: 0,
	[e.Degrees.bii]: 1,
	[e.Degrees.bII]: 1,
	[e.Degrees.iiDim]: 2,
	[e.Degrees.ii]: 2,
	[e.Degrees.II]: 2,
	[e.Degrees.biii]: 3,
	[e.Degrees.bIII]: 3,
	[e.Degrees.iii]: 4,
	[e.Degrees.III]: 4,
	[e.Degrees.iv]: 5,
	[e.Degrees.IV]: 5,
	[e.Degrees.bv]: 6,
	[e.Degrees.bV]: 6,
	[e.Degrees.v]: 7,
	[e.Degrees.V]: 7,
	[e.Degrees.bvi]: 8,
	[e.Degrees.bVI]: 8,
	[e.Degrees.vi]: 9,
	[e.Degrees.VI]: 9,
	[e.Degrees.bvii]: 10,
	[e.Degrees.bVII]: 10,
	[e.Degrees.vii]: 11,
	[e.Degrees.VII]: 11,
	[e.Degrees.viiDim]: 11,
	[e.DegreesFirstInversion.i]: 0,
	[e.DegreesFirstInversion.I]: 0,
	[e.DegreesFirstInversion.bii]: 1,
	[e.DegreesFirstInversion.bII]: 1,
	[e.DegreesFirstInversion.iiDim]: 2,
	[e.DegreesFirstInversion.ii]: 2,
	[e.DegreesFirstInversion.II]: 2,
	[e.DegreesFirstInversion.biii]: 3,
	[e.DegreesFirstInversion.bIII]: 3,
	[e.DegreesFirstInversion.iii]: 4,
	[e.DegreesFirstInversion.III]: 4,
	[e.DegreesFirstInversion.iv]: 5,
	[e.DegreesFirstInversion.IV]: 5,
	[e.DegreesFirstInversion.bv]: 6,
	[e.DegreesFirstInversion.bV]: 6,
	[e.DegreesFirstInversion.v]: 7,
	[e.DegreesFirstInversion.V]: 7,
	[e.DegreesFirstInversion.bvi]: 8,
	[e.DegreesFirstInversion.bVI]: 8,
	[e.DegreesFirstInversion.vi]: 9,
	[e.DegreesFirstInversion.VI]: 9,
	[e.DegreesFirstInversion.bvii]: 10,
	[e.DegreesFirstInversion.bVII]: 10,
	[e.DegreesFirstInversion.vii]: 11,
	[e.DegreesFirstInversion.VII]: 11,
	[e.DegreesFirstInversion.viiDim]: 11,
	[e.DegreesSecondInversion.i]: 0,
	[e.DegreesSecondInversion.I]: 0,
	[e.DegreesSecondInversion.bii]: 1,
	[e.DegreesSecondInversion.bII]: 1,
	[e.DegreesSecondInversion.iiDim]: 2,
	[e.DegreesSecondInversion.ii]: 2,
	[e.DegreesSecondInversion.II]: 2,
	[e.DegreesSecondInversion.biii]: 3,
	[e.DegreesSecondInversion.bIII]: 3,
	[e.DegreesSecondInversion.iii]: 4,
	[e.DegreesSecondInversion.III]: 4,
	[e.DegreesSecondInversion.iv]: 5,
	[e.DegreesSecondInversion.IV]: 5,
	[e.DegreesSecondInversion.bv]: 6,
	[e.DegreesSecondInversion.bV]: 6,
	[e.DegreesSecondInversion.v]: 7,
	[e.DegreesSecondInversion.V]: 7,
	[e.DegreesSecondInversion.bvi]: 8,
	[e.DegreesSecondInversion.bVI]: 8,
	[e.DegreesSecondInversion.vi]: 9,
	[e.DegreesSecondInversion.VI]: 9,
	[e.DegreesSecondInversion.bvii]: 10,
	[e.DegreesSecondInversion.bVII]: 10,
	[e.DegreesSecondInversion.vii]: 11,
	[e.DegreesSecondInversion.VII]: 11,
	[e.DegreesSecondInversion.viiDim]: 11,
};

export const MAJOR_DEGREES_MAP: Record<string, e.Degrees> = {
	I: e.Degrees.i,
	ii: e.Degrees.ii,
	iii: e.Degrees.iii,
	IV: e.Degrees.iv,
	V: e.Degrees.v,
	vi: e.Degrees.vi,
	vii: e.Degrees.viiDim,
};
