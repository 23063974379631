import * as t from "../../data/types";
import React, { useMemo, useState } from "react";
import useTableSort from "../../hooks/useTableSort";
import SortableTable from "../../components/SortableTable";
import { useModal } from "../../hooks/useModal";
import TargetModal from "./TargetModal";
import { parsePastTargetData, parseTargetData } from "./utils";
import CreateNewTarget from "./CreateNewTarget";
import { useTranslation } from "react-i18next";

export default function Targets({ selectedClass }: { selectedClass: t.Class | undefined }): JSX.Element {
	const [showCreateNew, setShowCreateNew] = useState<boolean>(false);
	const [editTarget, setEditTarget] = useState<string | undefined>(undefined);

	const targets = selectedClass?.Targets || [];
	const previousTargets = selectedClass?.PastTargets || [];

	const { showModal, setShowModal, handleShowModal, selectedItem, setSelectedItem } = useModal<t.Target>();
	const targetData = useMemo(() => parseTargetData(selectedClass, targets), [selectedClass]);
	const { sortedData: sortedTargets, handleSort, sortConfig } = useTableSort(targetData);
	const { t } = useTranslation("Overview");

	const renderAdditionalCell = (target: any[]) => {
		return (
			<td
				className="alignRight link"
				onClick={(e) => {
					e.stopPropagation();
					setEditTarget(target[6]);
					setShowCreateNew(true);
				}}
			>
				{t("edit")}
			</td>
		);
	};

	if (!selectedClass) return <></>;
	return (
		<>
			<div className="overviewTable">
				<SortableTable
					columns={[t("date"), t("module"), t("difficulty"), t("target"), t("daysLeft"), t("successRate")]}
					data={sortedTargets}
					handleSort={handleSort}
					sortConfig={sortConfig}
					rowOnClick={(targetID) => {
						const target = selectedClass.Targets?.find((target) => target.id === targetID);
						handleShowModal(target);
					}}
					additionalCellRender={renderAdditionalCell}
				/>
				<TargetModal
					target={selectedItem}
					targets={targets}
					setSelectedTarget={setSelectedItem}
					selectedClass={selectedClass}
					showModal={showModal}
					setShowModal={setShowModal}
				/>
			</div>
			<br />

			{previousTargets.length > 0 && (
				<>
					<h2>{t("previousTargets")}</h2>
					<div className="overviewTable">
						<SortableTable
							columns={[t("date"), t("module"), t("difficulty"), t("target"), t("successRate")]}
							data={parsePastTargetData(selectedClass, previousTargets)}
							handleSort={handleSort}
							sortConfig={sortConfig}
							rowOnClick={(targetID) => {
								const target = selectedClass.PastTargets?.find((target) => target.id === targetID);
								handleShowModal(target);
							}}
						/>
					</div>
				</>
			)}

			<CreateNewSection
				selectedClass={selectedClass}
				targets={targets}
				showCreateNew={showCreateNew}
				setShowCreateNew={setShowCreateNew}
				selectedTargetID={editTarget}
				setSelectedTargetID={setEditTarget}
			/>
		</>
	);
}

function CreateNewSection({
	selectedClass,
	targets,
	showCreateNew,
	setShowCreateNew,
	selectedTargetID,
	setSelectedTargetID,
}: {
	selectedClass: t.Class | undefined;
	targets: t.Target[];
	showCreateNew: boolean;
	setShowCreateNew: any;
	selectedTargetID?: string | undefined;
	setSelectedTargetID: any;
}) {
	const { t } = useTranslation("Overview");

	const title = selectedTargetID ? t("editTarget") : t("createNewTarget");

	const selectedTarget = targets.find((target) => target.id === selectedTargetID);

	const handleCreateNewTarget = async () => {
		setSelectedTargetID(undefined);
		setShowCreateNew(true);
	};

	if (!selectedClass) return null;
	return (
		<div>
			<br />
			{showCreateNew ? (
				<>
					<div className="flexRow">
						<h2>{title}</h2>
						<h2 className="close" onClick={() => setShowCreateNew(false)}>
							&times;
						</h2>
					</div>
					<CreateNewTarget selectedClass={selectedClass} selectedTarget={selectedTarget} title={title} />
				</>
			) : (
				<button className="buttonRight" onClick={handleCreateNewTarget}>
					{t("createNew")}
				</button>
			)}
		</div>
	);
}
