import * as e from "../../../data/enums";
import { createSection } from "./utils";

const j = e.DegreesJazz;

const chords1 = [
	{
		options: [j.Imaj7, j.ii7, j.V7, j.vi7],
		levelName: "I vi ii V",
	},
	{
		options: [j.Imaj7, j.bII7, j.ii7, j.vi7],
		levelName: "Tritone substitution (bII7 instead of V)",
	},
	{
		options: [j.Imaj7, j.bII7, j.ii7, j.V7, j.vi7],
		levelName: "Bringing V back in the mix",
	},
	{
		options: [j.Imaj7, j.bII7, j.ii7, j.V7, j.vi7, j.V7b9overii],
		levelName: "Substituting ii7 with V7b9",
	},
	{
		options: [j.Imaj7, j.bII7, j.ii7, j.V7, j.vi7, j.V7b9overii, j.bIIImaj7, j.bVImaj7, j.bIImaj7],
		levelName: "Introducing I bII bVI bII",
	},
];

const chords2 = [
	{
		options: [j.Imaj7, j.SharpiDim, j.ii7, j.iii7, j.SharpiiDim], //A C
		levelName: "First passing diminished chords",
	},
	{
		options: [j.Imaj7, j.SharpiDim, j.ii7, j.IVmaj7, j.SharpivDim], //A D
		levelName: "First passing diminished chords",
	},
	{
		options: [j.Imaj7, j.SharpiDim, j.ii7, j.V7, j.SharpvDim, j.vi7], //A E
		levelName: "First passing diminished chords",
	},
	{
		options: [j.Imaj7, j.V7, j.SharpvDim, j.vi7], //E F
		levelName: "First passing diminished chords",
	},
	{
		options: [j.Imaj7, j.ii7, j.SharpiDim, j.ii7], //A G
		levelName: "First passing diminished chords",
	},
];
const chords3 = [
	{
		options: [j.Imaj7, j.ii7, j.SharpiiDim, j.iii7, j.ii7], //B C
		levelName: "First passing diminished chords",
	},
	{
		options: [j.Imaj7, j.ii7, j.SharpiiDim, j.iii7, j.IVmaj7, j.SharpivDim], //B D
		levelName: "First passing diminished chords",
	},
	{
		options: [j.Imaj7, j.ii7, j.SharpiiDim, j.iii7, j.V7, j.SharpvDim, j.vi7], //B E
		levelName: "First passing diminished chords",
	},
	{
		options: [j.Imaj7, j.IVmaj7, j.SharpivDim, j.V7, j.SharpvDim, j.vi7], //D E
		levelName: "First passing diminished chords",
	},
	{
		options: [j.Imaj7, j.SharpiDim, j.ii7, j.SharpiiDim, j.iii7, j.IVmaj7, j.SharpivDim], //A B C D
		levelName: "Passing diminished chords",
	},
];
const chords4 = [
	{
		options: [j.Imaj7, j.ii7, j.SharpiiDim, j.iii7, j.IVmaj7, j.SharpivDim, j.V7, j.SharpvDim, j.vi7], //B C D E
		levelName: "Passing diminished chords",
	},
	{
		options: [j.Imaj7, j.ii7, j.iii7, j.SharpiiDim, j.IVmaj7, j.SharpivDim, j.V7, j.SharpvDim, j.vi7, j.vi7], //C D E F
		levelName: "Passing diminished chords",
	},
	{
		options: [j.Imaj7, j.SharpiDim, j.ii7, j.IVmaj7, j.SharpivDim, j.V7, j.SharpvDim, j.vi7], //C D E G
		levelName: "Passing diminished chords",
	},
	{
		options: [j.Imaj7, j.SharpiDim, j.ii7, j.SharpiiDim, j.iii7, j.IVmaj7, j.SharpivDim, j.V7, j.SharpvDim, j.vi7],
		levelName: "All passing diminished chords",
	},
];
const chords5 = [
	{
		options: [
			j.Imaj7,
			j.bII7,
			j.ii7,
			j.V7,
			j.vi7,
			j.V7b9overii,
			j.bIIImaj7,
			j.bVImaj7,
			j.bIImaj7,

			j.SharpiDim,
			j.ii7,
			j.SharpiiDim,
			j.iii7,
			j.IVmaj7,
			j.SharpivDim,
			j.V7,
			j.SharpvDim,
			j.vi7,
		],
		levelName: "Boss Mode",
	},
];

export const PROGRESSION_JAZZ_SECTIONS = [
	createSection("Basic common Jazz progressions", chords1),
	createSection("Passing diminished chords 1", chords2),
	createSection("Passing diminished chords 2", chords3),
	createSection("Passing diminished chords 3", chords4),
	createSection("Boss level", chords5),
];

// Standard Progressions
// Imaj7	ii7 		V7 			Imaj7
// Cmaj7 	Dm7 		G7 			Cmaj7

// Imaj7	vi7			bVII7		Imaj7
// Cmaj7 	Am7 		Bb7 		Cmaj7

// Imaj7 	vi7			ii7 		V7 			Imaj7
// Cmaj7 	Am7			Dm7 		G7 			Cmaj7

// Imaj7 	V7b9/II		ii7 		V7 			Imaj7
// Cmaj7 	A7b9		Dm7 		G7 			Cmaj7

// Imaj7 	bIIImaj7 	bVImaj7 	bIImaj7 	Imaj7
// Cmaj7 	Ebmaj7 		Abmaj7 		Dbmaj7 		Cmaj7

// Passing Diminished Chords
// Imaj7	#Idim 		ii7
// Cmaj7	C#dim 		Dm7

// ii7 		#iiDim		iii7
// Dm7 		D#dim 		Em7

// iii7 	bIIIdim 	ii7
// Em7 		Ebdim 		Dm7

// IVmaj7 	#IVdim 		I (with V in bass)
// Fmaj7 	F#dim 		C/G

// V7		#Vdim		vi7
// G7 		G#dim 		Am7

// vi7 		bVIdim 		V7
// Am7 		Abdim 		G7

// ii7 		bIIdim 		Imaj7
// Dm7 		Dbdim 		Cmaj7

// Secondary Dominants with Related Two Chords (MAJOR)
// Imaj7	ii7b5/ii 	V7b9/ii 	ii7				or Imaj7	ii7/ii		V7b9/ii 	ii7
// Cmaj7 	Em7b5 		A7b9		Dm7				of Cmaj7 	Em7 		A7b9		Dm7

// Imaj7	ii7b5/iii	V7/iii		iii7
// Cmaj7 	F#m7b5 		B7	 		Em7

// Imaj7	ii7b5/iv	V7/iv		iv7
// Cmaj7 	Gm7b5 		C7	 		Fm7

// Imaj7	ii7b5/v		V7/v		v7
// Cmaj7 	Am7b5 		D7	 		Gm7

// Imaj7	ii7b5/vi	V7/vi		vi7
// Cmaj7 	Bm7b5 		E7	 		Am7

// Tritone Substitutions for Secondary Dominants and Related Two Chords (MAJOR)
// Imaj7	ii7b5/ii 	bII7/ii 	ii7
// Cmaj7 	Em7b5 		Eb7b9		Dm7

// Imaj7	ii7b5/iii	bII7/iii	iii7
// Cmaj7 	F#m7b5 		F7	 		Em7

// Imaj7	ii7b5/iv	bII7/iv		iv7
// Cmaj7 	Gm7b5 		Gb7	 		Fm7

// Imaj7	ii7b5/v		bII7/v		v7
// Cmaj7 	Am7b5 		Ab7	 		Gm7

// Imaj7	ii7b5/vi	bII7/vi		vi7
// Cmaj7 	Bm7b5 		Bb7	 		Am7

// Secondary Dominants with Related Two Chords (MINOR)
// i7		ii7/II 		V7/II	 	IImaj7
// Cm7 		Em7 		A7			Dmaj7

// i7		ii7/III		V7/III		IIImaj7
// Cm7 		Fm7 		Bb7			Ebmaj7

// i7		ii7/IV		V7/IV		IVmaj7
// Cm7 		Gm7 		C7			Fmaj7

// i7		ii7/V		V7/V		Vmaj7
// Cm7 		Am7 		D7			Gmaj7

// i7		ii7/VI		V7/VI		VImaj7
// Cm7 		Bm7 		E7			Amaj7

// Tritone Substitutions for Secondary Dominants and Related Two Chords (MINOR)
// i7		ii7/II 		bII7/II	 	IImaj7
// Cm7 		Em7 		Eb7			Dmaj7

// i7		ii7/III		bII7/III	IIImaj7
// Cm7 		Fm7 		E7			Ebmaj7

// i7		ii7/IV		bII7/IV		IVmaj7
// Cm7 		Gm7 		Gb7			Fmaj7

// i7		ii7/V		bII7/V		Vmaj7
// Cm7 		Am7 		Ab7			Gmaj7

// i7		ii7/VI		bII7/VI		VImaj7
// Cm7 		Bm7 		Bb7			Amaj7

// Transient Modulations:
// Modulation down a whole step:
// Imaj7	ii7/bVII	V7/bVII		bVIImaj7
// Cmaj7 	Cm7 		F7 			Bbmaj7

// Modulation down a half step:
// Imaj7	ii7/VII		V7/VII		VIImaj7
// Cmaj7 	C#m7 		F#7	 		Bmaj7

// Modulation up a half step:
// Imaj7	ii7/bII		V7/bII		bIImaj7
// Cmaj7 	Ebm7 		Ab7 		Dbmaj7

// Modulation Up a minor Third:
// Imaj7	ii7/bIII	V7/bIII		bIIImaj7
// Cmaj7 	Fm7 		Bb7 		Ebmaj7

// Modulation Down a major Third:
// Imaj7	ii7/III		V7/III		IIImaj7
// Cmaj7 	Bbm7 		Eb7 		Abmaj7

// PROGRESSION JAZZ OPTIONS
// Imaj7	#Idim
// Imaj7	ii7
// Imaj7 	bIIImaj7
// Imaj7 	V7b9/II
// Imaj7	vi7
// Imaj7 	vi7
// bIImaj7 	Imaj7
// ii7 		#iiDim
// ii7 		V7
// ii7 		V7
// ii7 		V7
// ii7 		bIIdim
// bIIImaj7 bVImaj7
// iii7 	bIIIdim
// IVmaj7 	#IVdim
// V7 		Imaj7
// V7 		Imaj7
// V7 		Imaj7
// V7		#Vdim
// vi7		bVII7
// vi7		ii7
// vi7 		bVIdim
// bVImaj7 	bIImaj7
// bVII7	Imaj7
// V7b9/II	ii7
// #Idim 	ii7
// #iiDim	iii7
// #IVdim 	I (with V in bass)
// #Vdim	vi7
// bVIdim 	V7
// bIIIdim 	ii7
// bIIdim 	Imaj7
