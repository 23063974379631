import * as e from "../../enums";
import { createSection } from "./utils";

const basicsLevels = [
	{ options: [e.Scales.IONIAN, e.Scales.AEOLIAN], levelName: "Major or Minor" },
	{
		options: [e.Scales.MAJOR_PENTATONIC, e.Scales.MINOR_PENTATONIC],
		levelName: "Major Pentatonic or Minor Pentatonic",
	},
	{
		options: [e.Scales.MAJOR_PENTATONIC, e.Scales.MINOR_PENTATONIC, e.Scales.IONIAN, e.Scales.AEOLIAN],
		levelName: "Major Pentatonic, Minor Pentatonic, Major or Minor",
	},
];
const majorScalesLevels = [
	{ options: [e.Scales.MIXOLYDIAN, e.Scales.LYDIAN], levelName: "Mixolydian or Lydian" },
	{ options: [e.Scales.IONIAN, e.Scales.MIXOLYDIAN], levelName: "Major or Mixolydian" },
	{ options: [e.Scales.IONIAN, e.Scales.LYDIAN], levelName: "Major or Lydian" },
	{
		options: [e.Scales.MAJOR_PENTATONIC, e.Scales.IONIAN, e.Scales.MIXOLYDIAN, e.Scales.LYDIAN],
		levelName: "Major Pentatonic, Major, Mixolydian or Lydian",
	},
];
const minorScalesLevels = [
	{ options: [e.Scales.AEOLIAN, e.Scales.DORIAN], levelName: "Minor or Dorian" },
	{
		options: [e.Scales.MINOR_PENTATONIC, e.Scales.AEOLIAN, e.Scales.DORIAN],
		levelName: "Minor Pentatonic, Minor or Dorian",
	},
	{ options: [e.Scales.AEOLIAN, e.Scales.LOCRIAN], levelName: "Minor or Locrian" },
	{ options: [e.Scales.AEOLIAN, e.Scales.PHRYGIAN], levelName: "Minor or Phrygian" },
	{ options: [e.Scales.PHRYGIAN, e.Scales.LOCRIAN], levelName: "Phrygian or Locrian" },
	{
		options: [e.Scales.MINOR_PENTATONIC, e.Scales.AEOLIAN, e.Scales.DORIAN, e.Scales.PHRYGIAN, e.Scales.LOCRIAN],
		levelName: "Minor Pentatonic, Minor, Dorian, Phrygian or Locrian",
	},
];
const churchModesLevels = [
	{
		options: [
			e.Scales.MAJOR_PENTATONIC,
			e.Scales.MINOR_PENTATONIC,
			e.Scales.IONIAN,
			e.Scales.AEOLIAN,
			e.Scales.LYDIAN,
			e.Scales.MIXOLYDIAN,
		],
		levelName: "Combo of church modes 1",
	},
	{
		options: [
			e.Scales.MAJOR_PENTATONIC,
			e.Scales.MINOR_PENTATONIC,
			e.Scales.IONIAN,
			e.Scales.AEOLIAN,
			e.Scales.LYDIAN,
			e.Scales.MIXOLYDIAN,
			e.Scales.DORIAN,
		],
		levelName: "Combo of church modes 2",
	},
	{
		options: [
			e.Scales.MAJOR_PENTATONIC,
			e.Scales.MINOR_PENTATONIC,
			e.Scales.IONIAN,
			e.Scales.AEOLIAN,
			e.Scales.LYDIAN,
			e.Scales.MIXOLYDIAN,
			e.Scales.DORIAN,
			e.Scales.PHRYGIAN,
		],
		levelName: "Combo of church modes 3",
	},
	{
		options: [
			e.Scales.IONIAN,
			e.Scales.AEOLIAN,
			e.Scales.LYDIAN,
			e.Scales.MIXOLYDIAN,
			e.Scales.DORIAN,
			e.Scales.PHRYGIAN,
			e.Scales.LOCRIAN,
		],
		levelName: "All church modes",
	},
];
const leavingChurchLevels = [
	{
		options: [e.Scales.MAJOR_PENTATONIC, e.Scales.MINOR_PENTATONIC, e.Scales.BLUES],
		levelName: "Blues or Pentatonics",
	},
	{ options: [e.Scales.AEOLIAN, e.Scales.LYDIAN, e.Scales.BLUES], levelName: "Minor, Lydian or Blues" },
	{
		options: [e.Scales.HARMONIC_MINOR, e.Scales.MELODIC_MINOR],
		levelName: "Harmonic Minor or Melodic Minor",
	},
	{
		options: [e.Scales.AEOLIAN, e.Scales.DORIAN, e.Scales.HARMONIC_MINOR, e.Scales.MELODIC_MINOR],
		levelName: "Minor, Dorian, Harmonic Minor or Melodic Minor",
	},
	{
		options: [
			e.Scales.MINOR_PENTATONIC,
			e.Scales.AEOLIAN,
			e.Scales.DORIAN,
			e.Scales.PHRYGIAN,
			e.Scales.LOCRIAN,
			e.Scales.HARMONIC_MINOR,
			e.Scales.MELODIC_MINOR,
		],
		levelName: "All minor scales",
	},
];

const alteredScaleLevels = [
	{
		options: [e.Scales.ALTERED_I, e.Scales.ALTERED_II, e.Scales.ALTERED_III],
		levelName: "Altered I, II or III",
	},
	{
		options: [e.Scales.ALTERED_II, e.Scales.ALTERED_III, e.Scales.ALTERED_IV],
		levelName: "Altered II, III or IV",
	},
	{
		options: [e.Scales.ALTERED_III, e.Scales.ALTERED_IV, e.Scales.ALTERED_V],
		levelName: "Altered III, IV or V",
	},
	{
		options: [e.Scales.ALTERED_IV, e.Scales.ALTERED_V, e.Scales.ALTERED_VI],
		levelName: "Altered IV, V or VI",
	},
	{
		options: [e.Scales.ALTERED_V, e.Scales.ALTERED_VI, e.Scales.ALTERED_VII],
		levelName: "Altered V, VI or VII",
	},
	{
		options: [e.Scales.ALTERED_VI, e.Scales.ALTERED_VII, e.Scales.ALTERED_I],
		levelName: "Altered VI, VII or I",
	},
	{
		options: [e.Scales.ALTERED_VII, e.Scales.ALTERED_I, e.Scales.ALTERED_II],
		levelName: "Altered VII, I or II",
	},
];

const harmonicMajorLevels = [
	{
		options: [e.Scales.HARMONIC_MAJOR_I, e.Scales.HARMONIC_MAJOR_II, e.Scales.HARMONIC_MAJOR_III],
		levelName: "Harmonic Major I, II or III",
	},
	{
		options: [e.Scales.HARMONIC_MAJOR_II, e.Scales.HARMONIC_MAJOR_III, e.Scales.HARMONIC_MAJOR_IV],
		levelName: "Harmonic Major II, III or IV",
	},
	{
		options: [e.Scales.HARMONIC_MAJOR_III, e.Scales.HARMONIC_MAJOR_IV, e.Scales.HARMONIC_MAJOR_V],
		levelName: "Harmonic Major III, IV or V",
	},
	{
		options: [e.Scales.HARMONIC_MAJOR_IV, e.Scales.HARMONIC_MAJOR_V, e.Scales.HARMONIC_MAJOR_VI],
		levelName: "Harmonic Major IV, V or VI",
	},
	{
		options: [e.Scales.HARMONIC_MAJOR_V, e.Scales.HARMONIC_MAJOR_VI, e.Scales.HARMONIC_MAJOR_VII],
		levelName: "Harmonic Major V, VI or VII",
	},
	{
		options: [e.Scales.HARMONIC_MAJOR_VI, e.Scales.HARMONIC_MAJOR_VII, e.Scales.HARMONIC_MAJOR_I],
		levelName: "Harmonic Major VI, VII or I",
	},
	{
		options: [e.Scales.HARMONIC_MAJOR_VII, e.Scales.HARMONIC_MAJOR_I, e.Scales.HARMONIC_MAJOR_II],
		levelName: "Harmonic Major VII, I or II",
	},
];

const spanishPhrygianLevels = [
	{
		options: [e.Scales.SPANISH_PHRYGIAN_I, e.Scales.SPANISH_PHRYGIAN_II, e.Scales.SPANISH_PHRYGIAN_III],
		levelName: "Spanish Phrygian I, II or III",
	},
	{
		options: [e.Scales.SPANISH_PHRYGIAN_II, e.Scales.SPANISH_PHRYGIAN_III, e.Scales.SPANISH_PHRYGIAN_IV],
		levelName: "Spanish Phrygian II, III or IV",
	},
	{
		options: [e.Scales.SPANISH_PHRYGIAN_III, e.Scales.SPANISH_PHRYGIAN_IV, e.Scales.SPANISH_PHRYGIAN_V],
		levelName: "Spanish Phrygian III, IV or V",
	},
	{
		options: [e.Scales.SPANISH_PHRYGIAN_IV, e.Scales.SPANISH_PHRYGIAN_V, e.Scales.SPANISH_PHRYGIAN_VI],
		levelName: "Spanish Phrygian IV, V or VI",
	},
	{
		options: [e.Scales.SPANISH_PHRYGIAN_V, e.Scales.SPANISH_PHRYGIAN_VI, e.Scales.SPANISH_PHRYGIAN_VII],
		levelName: "Spanish Phrygian V, VI or VII",
	},
	{
		options: [e.Scales.SPANISH_PHRYGIAN_VI, e.Scales.SPANISH_PHRYGIAN_VII, e.Scales.SPANISH_PHRYGIAN_I],
		levelName: "Spanish Phrygian VI, VII or I",
	},
	{
		options: [e.Scales.SPANISH_PHRYGIAN_VII, e.Scales.SPANISH_PHRYGIAN_I, e.Scales.SPANISH_PHRYGIAN_II],
		levelName: "Spanish Phrygian VII, I or II",
	},
];
const spanishHarmonicAlteredLevels1 = [
	{
		options: [
			e.Scales.ALTERED_I,
			e.Scales.ALTERED_II,
			e.Scales.ALTERED_III,
			e.Scales.ALTERED_IV,
			e.Scales.ALTERED_V,
			e.Scales.ALTERED_VI,
			e.Scales.ALTERED_VII,
		],
		levelName: "All Altered scales",
	},
	{
		options: [
			e.Scales.HARMONIC_MAJOR_I,
			e.Scales.HARMONIC_MAJOR_II,
			e.Scales.HARMONIC_MAJOR_III,
			e.Scales.HARMONIC_MAJOR_IV,
			e.Scales.HARMONIC_MAJOR_V,
			e.Scales.HARMONIC_MAJOR_VI,
			e.Scales.HARMONIC_MAJOR_VII,
		],
		levelName: "All Harmonic Major scales",
	},
	{
		options: [
			e.Scales.SPANISH_PHRYGIAN_I,
			e.Scales.SPANISH_PHRYGIAN_II,
			e.Scales.SPANISH_PHRYGIAN_III,
			e.Scales.SPANISH_PHRYGIAN_IV,
			e.Scales.SPANISH_PHRYGIAN_V,
			e.Scales.SPANISH_PHRYGIAN_VI,
			e.Scales.SPANISH_PHRYGIAN_VII,
		],
		levelName: "All Spanish Phrygian scales",
	},
	{
		options: [
			e.Scales.ALTERED_IV,
			e.Scales.ALTERED_V,
			e.Scales.ALTERED_VI,
			e.Scales.HARMONIC_MAJOR_I,
			e.Scales.HARMONIC_MAJOR_II,
			e.Scales.HARMONIC_MAJOR_III,
			e.Scales.HARMONIC_MAJOR_V,
			e.Scales.HARMONIC_MAJOR_VI,
			e.Scales.SPANISH_PHRYGIAN_I,
			e.Scales.SPANISH_PHRYGIAN_III,
			e.Scales.SPANISH_PHRYGIAN_IV,
		],
		levelName: "Major Altered, Harmonic Major and Spanish Phrygian scales",
	},
	{
		options: [
			e.Scales.ALTERED_I,
			e.Scales.ALTERED_II,
			e.Scales.ALTERED_III,
			e.Scales.ALTERED_VII,
			e.Scales.HARMONIC_MAJOR_III,
			e.Scales.HARMONIC_MAJOR_IV,
			e.Scales.HARMONIC_MAJOR_VI,
			e.Scales.HARMONIC_MAJOR_VII,
			e.Scales.SPANISH_PHRYGIAN_II,
			e.Scales.SPANISH_PHRYGIAN_V,
			e.Scales.SPANISH_PHRYGIAN_VI,
			e.Scales.SPANISH_PHRYGIAN_VII,
		],
		levelName: "Minor Altered, Harmonic Major and Spanish Phrygian scales",
	},
];

const spanishHarmonicAlteredLevels2 = [
	{
		options: [
			e.Scales.ALTERED_II,
			e.Scales.ALTERED_IV,
			e.Scales.ALTERED_V,
			e.Scales.ALTERED_VI,
			e.Scales.ALTERED_VII,
			e.Scales.HARMONIC_MAJOR_I,
			e.Scales.HARMONIC_MAJOR_II,
			e.Scales.HARMONIC_MAJOR_IV,
			e.Scales.SPANISH_PHRYGIAN_I,
			e.Scales.SPANISH_PHRYGIAN_II,
			e.Scales.SPANISH_PHRYGIAN_VI,
		],
		levelName: "Altered, Harmonic and Spanish Phrygian scales with a major 2nd",
	},
	{
		options: [
			e.Scales.ALTERED_I,
			e.Scales.ALTERED_III,
			e.Scales.HARMONIC_MAJOR_III,
			e.Scales.HARMONIC_MAJOR_V,
			e.Scales.HARMONIC_MAJOR_VII,
			e.Scales.SPANISH_PHRYGIAN_III,
			e.Scales.SPANISH_PHRYGIAN_IV,
			e.Scales.SPANISH_PHRYGIAN_V,
			e.Scales.SPANISH_PHRYGIAN_VII,
		],
		levelName: "Altered, Harmonic and Spanish Phrygian scales with a minor 2nd",
	},
	{
		options: [
			e.Scales.ALTERED_I,
			e.Scales.ALTERED_II,
			e.Scales.ALTERED_III,
			e.Scales.ALTERED_IV,
			e.Scales.ALTERED_V,
			e.Scales.ALTERED_VI,
			e.Scales.ALTERED_VII,
			e.Scales.HARMONIC_MAJOR_I,
			e.Scales.HARMONIC_MAJOR_II,
			e.Scales.HARMONIC_MAJOR_III,
			e.Scales.HARMONIC_MAJOR_IV,
			e.Scales.HARMONIC_MAJOR_V,
			e.Scales.HARMONIC_MAJOR_VI,
			e.Scales.HARMONIC_MAJOR_VII,
		],
		levelName: "Altered & Harmonic Major",
	},
	{
		options: [
			e.Scales.ALTERED_I,
			e.Scales.ALTERED_II,
			e.Scales.ALTERED_III,
			e.Scales.ALTERED_IV,
			e.Scales.ALTERED_V,
			e.Scales.ALTERED_VI,
			e.Scales.ALTERED_VII,
			e.Scales.SPANISH_PHRYGIAN_I,
			e.Scales.SPANISH_PHRYGIAN_II,
			e.Scales.SPANISH_PHRYGIAN_III,
			e.Scales.SPANISH_PHRYGIAN_IV,
			e.Scales.SPANISH_PHRYGIAN_V,
			e.Scales.SPANISH_PHRYGIAN_VI,
			e.Scales.SPANISH_PHRYGIAN_VII,
		],
		levelName: "Altered & Spanish Phrygian",
	},
	{
		options: [
			e.Scales.HARMONIC_MAJOR_I,
			e.Scales.HARMONIC_MAJOR_II,
			e.Scales.HARMONIC_MAJOR_III,
			e.Scales.HARMONIC_MAJOR_IV,
			e.Scales.HARMONIC_MAJOR_V,
			e.Scales.HARMONIC_MAJOR_VI,
			e.Scales.HARMONIC_MAJOR_VII,
			e.Scales.SPANISH_PHRYGIAN_I,
			e.Scales.SPANISH_PHRYGIAN_II,
			e.Scales.SPANISH_PHRYGIAN_III,
			e.Scales.SPANISH_PHRYGIAN_IV,
			e.Scales.SPANISH_PHRYGIAN_V,
			e.Scales.SPANISH_PHRYGIAN_VI,
			e.Scales.SPANISH_PHRYGIAN_VII,
		],
		levelName: "Harmonic Major & Spanish Phrygian",
	},
];
const bossModeLevels = [
	{
		options: [
			e.Scales.MAJOR_PENTATONIC,
			e.Scales.MINOR_PENTATONIC,
			e.Scales.IONIAN,
			e.Scales.AEOLIAN,
			e.Scales.LYDIAN,
			e.Scales.MIXOLYDIAN,
			e.Scales.DORIAN,
			e.Scales.PHRYGIAN,
			e.Scales.LOCRIAN,
			e.Scales.BLUES,
			e.Scales.HARMONIC_MINOR,
			e.Scales.MELODIC_MINOR,
		],
		levelName: "All scales",
	},

	{
		options: [
			e.Scales.ALTERED_I,
			e.Scales.ALTERED_II,
			e.Scales.ALTERED_III,
			e.Scales.ALTERED_IV,
			e.Scales.ALTERED_V,
			e.Scales.ALTERED_VI,
			e.Scales.ALTERED_VII,
			e.Scales.HARMONIC_MAJOR_I,
			e.Scales.HARMONIC_MAJOR_II,
			e.Scales.HARMONIC_MAJOR_III,
			e.Scales.HARMONIC_MAJOR_IV,
			e.Scales.HARMONIC_MAJOR_V,
			e.Scales.HARMONIC_MAJOR_VI,
			e.Scales.HARMONIC_MAJOR_VII,
			e.Scales.SPANISH_PHRYGIAN_I,
			e.Scales.SPANISH_PHRYGIAN_II,
			e.Scales.SPANISH_PHRYGIAN_III,
			e.Scales.SPANISH_PHRYGIAN_IV,
			e.Scales.SPANISH_PHRYGIAN_V,
			e.Scales.SPANISH_PHRYGIAN_VI,
			e.Scales.SPANISH_PHRYGIAN_VII,
		],
		levelName: "All Altered, Harmonic Major & Spanish Phrygian Scales",
	},
];
export const SCALE_RECOGNITION_SECTIONS = [
	createSection("Basics", basicsLevels),
	createSection("Major scales", majorScalesLevels),
	createSection("Minor scales", minorScalesLevels),
	createSection("Church modes", churchModesLevels),
	createSection("Leaving church", leavingChurchLevels),
	createSection("Altered scale", alteredScaleLevels),
	createSection("Harmonic major", harmonicMajorLevels),
	createSection("Spanish Phrygian", spanishPhrygianLevels),
	createSection("Altered, Harmonic Major & Spanish Phrygian 1", spanishHarmonicAlteredLevels1),
	createSection("Altered, Harmonic Major & Spanish Phrygian 2", spanishHarmonicAlteredLevels2),
	createSection("Boss Mode", bossModeLevels),
];
