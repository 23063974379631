import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function deleteClassAPI(classID: string) {
	console.log("deleteClassAPI called");

	const url = `https://auris-db-backend.vercel.app/deleteClass?classID=${classID}`;
	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		await fetch(url, {
			method: "DELETE",
			headers: {
				Authorization: idToken,
			},
		});
	} catch (error) {
		console.log(error);
		if (classID !== "VgMzrqHOOjOMLusgmzDD") Sentry.captureException(error);
		throw error;
	}
}
