import React, { useEffect } from "react";
import AddSchool from "./AddSchool";
import SignUpTeacher from "./SignUpTeacher";
import UpgradeStudent from "./UpgradeStudent";
import { useTeacher } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { ADMIN_EMAIL } from "../../data/constants/constants";

export default function DBManager(): JSX.Element {
	const { teacher } = useTeacher();

	const navigate = useNavigate();
	useEffect(() => {
		if (teacher?.Email !== ADMIN_EMAIL) {
			navigate("/");
		}
	}, [teacher]);

	return (
		<main className="stats">
			<UpgradeStudent />
			<AddSchool />
			<SignUpTeacher />
		</main>
	);
}
