import * as t from "../../data/types";
import * as e from "../../data/enums";
import React, { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ChartOptions,
} from "chart.js";
import { convertDateToStringDayMonth, parseDate } from "../../utils/dates";
import { customLegend, generateDateSeries, useDateRange } from "./lineChartUtils";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function LineChart({ user, timeframe }: { user: t.User; timeframe: e.TimeFrame }) {
	const userStartDate = user.DailyStats[0] ? parseDate(user.DailyStats[0].Date) : new Date();
	const { startDate, endDate } = useDateRange(timeframe, userStartDate);
	const { labels, datasets } = useChartData(user, startDate, endDate);
	const [visibility, setVisibility] = useState(new Array(datasets.length).fill(true));

	const options = chartOptions(colorSettings.practice.border, colorSettings.progress.border);

	const toggleDataset = (index: number) => {
		const newVisibility = visibility.slice();
		newVisibility[index] = !newVisibility[index];
		setVisibility(newVisibility);
	};

	return (
		<>
			<Line data={{ labels, datasets: datasets.filter((_, i) => visibility[i]) }} options={options} />
			{customLegend(datasets, visibility, toggleDataset, `50%`)}
		</>
	);
}

// useChartData
const colorSettings = {
	practice: { border: "100, 175, 200" },
	progress: { border: "130, 120, 200" },
};
const useChartData = (user: t.User, startDate: Date, endDate: Date) =>
	useMemo(() => {
		const dateLabels = generateDateSeries(startDate, endDate);
		const practiceData = processData(user.DailyStats, "PracticeTime");
		const progressData = processData(user.DailyStats, "Progress");

		return {
			labels: dateLabels,
			datasets: [
				createDataset("Practice time (minutes)", practiceData, dateLabels, colorSettings.practice.border, "y"),
				createDataset("Progress (pts)", progressData, dateLabels, colorSettings.progress.border, "y1"),
			],
		};
	}, [user, startDate, endDate]);

function processData(stats: any[], key: keyof any): Record<string, string> {
	return stats.reduce(
		(acc, entry) => {
			const date = convertDateToStringDayMonth(parseDate(entry.Date));
			acc[date] =
				key === "PracticeTime" ? (entry[key] / 60).toPrecision(2) : Math.round(entry[key] / 100).toString();
			return acc;
		},
		{} as Record<string, string>
	);
}

function createDataset(
	label: string,
	dataMap: Record<string, string>,
	labels: string[],
	color: string,
	axisID: string
) {
	return {
		label,
		data: labels.map((label) => dataMap[label] || "0"),
		borderColor: `rgb(${color})`,
		backgroundColor: `rgba(${color}, 0.5)`,
		pointBackgroundColor: `rgb(${color})`,
		pointBorderColor: `rgb(${color})`,
		yAxisID: axisID,
		cubicInterpolationMode: "monotone" as "monotone" | "default",
		tension: 0.4,
	};
}

// chartOptions
function chartOptions(practiceColor: string, progressColor: string): ChartOptions<"line"> {
	return {
		scales: {
			y: {
				type: "linear",
				display: true,
				position: "left",
				ticks: { color: `rgb(${practiceColor})` },
			},
			y1: {
				type: "linear",
				display: true,
				position: "right",
				grid: { drawOnChartArea: false },
				ticks: { color: `rgb(${progressColor})` },
			},
		},
		plugins: {
			legend: {
				display: false,
			},
		},
	};
}
