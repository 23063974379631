export function parseDate(date: any): Date {
	if (date && typeof date === "string") {
		if (date.includes("T")) return new Date(date);
		else return new Date(date + "T00:00:00");
	}
	if (date && "toDate" in date) return date.toDate();
	if (date && date instanceof Date) return date;
	if (date && "_seconds" in date && "_nanoseconds" in date)
		return new Date(date._seconds * 1000 + date._nanoseconds / 1000000);
	console.log("Unexpected format for date:", date);
	return new Date();
}

export function getDayDifference(date1: Date, date2: Date): number {
	const parsedDate1 = parseDate(date1);
	const parsedDate2 = parseDate(date2);
	parsedDate1.setHours(0, 0, 0, 0);
	parsedDate2.setHours(0, 0, 0, 0);
	const timeDiff = Math.abs(parsedDate1.getTime() - parsedDate2.getTime());
	const daysDiff = timeDiff / (1000 * 3600 * 24);
	return Math.floor(daysDiff);
}

export function convertDateToString(date: any, format: "dd-mm-yyyy" | "yyyy-mm-dd") {
	const parsedDate = parseDate(date);
	const dd = String(parsedDate.getDate()).padStart(2, "0");
	const mm = String(parsedDate.getMonth() + 1).padStart(2, "0"); // January is 0!
	const yyyy = parsedDate.getFullYear();
	if (format === "yyyy-mm-dd") return `${yyyy}-${mm}-${dd}`;
	return `${dd}-${mm}-${yyyy}`;
}

export function convertDateToStringDayMonth(date: any) {
	const parsedDate = parseDate(date);
	const dd = String(parsedDate.getDate()).padStart(2, "0");
	const mm = String(parsedDate.getMonth() + 1).padStart(2, "0"); // January is 0!
	return `${dd}-${mm}`;
}

export function getTodayDate() {
	const today = new Date();
	const dd = String(today.getDate()).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0");
	const yyyy = today.getFullYear();
	return yyyy + "-" + mm + "-" + dd;
}

export function getTimeFromDate(date: any): string {
	const parsedDate = parseDate(date);
	const hour = parsedDate.getHours();
	const minute = parsedDate.getMinutes();

	const hh = String(hour).padStart(2, "0");
	const mm = String(minute).padStart(2, "0");
	return `${hh}:${mm}`;
}
