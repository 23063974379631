import { capitalizeFirstLetter } from "../../utils/sharedUtils";
import * as e from "../enums";

// INTERVAL COMPARISON
function createIntervalComparison(dynamics: e.Dynamics, movement: e.Movement, descriptionDetail: string): any {
	return {
		dynamics,
		movement,
		description: `${descriptionDetail} with a ${dynamics === e.Dynamics.STATIC ? "static" : "changing"} root`,
		descriptionShort: `${descriptionDetail.toLowerCase()} intervals. Which one is larger?`,
	};
}

export const intervalComparison: Record<e.DifficultyIntervalComparison, any> = {
	[e.DifficultyIntervalComparison.ONE_OCTAVE]: {
		0: createIntervalComparison(e.Dynamics.STATIC, e.Movement.ASCENDING_INTERVAL, "Ascending"),
		1: createIntervalComparison(e.Dynamics.STATIC, e.Movement.DESCENDING_INTERVAL, "Descending"),
		2: createIntervalComparison(e.Dynamics.STATIC, e.Movement.HARMONY_INTERVAL, "Harmonic"),
		3: createIntervalComparison(e.Dynamics.DYNAMIC, e.Movement.ASCENDING_INTERVAL, "Ascending"),
		4: createIntervalComparison(e.Dynamics.DYNAMIC, e.Movement.DESCENDING_INTERVAL, "Descending"),
		5: createIntervalComparison(e.Dynamics.DYNAMIC, e.Movement.HARMONY_INTERVAL, "Harmonic"),
	},
};

// INTERVAL RECOGNITION
function createIntervalRecognition(dynamics: e.Dynamics, movement: e.Movement, descriptionDetail: string): any {
	const prefix = "aeiou".includes(descriptionDetail.charAt(0).toLowerCase()) ? "an" : "a";
	return {
		dynamics,
		movement,
		description: `${descriptionDetail} with a ${dynamics === e.Dynamics.STATIC ? "static" : "changing"} root`,
		descriptionShort: `${prefix} ${descriptionDetail.toLowerCase()} interval`,
	};
}

export const intervalRecognition: Record<e.DifficultyIntervalRecognition, any> = {
	[e.DifficultyIntervalRecognition.ONE_OCTAVE]: {
		0: createIntervalRecognition(e.Dynamics.STATIC, e.Movement.ASCENDING_INTERVAL, "Ascending"),
		1: createIntervalRecognition(e.Dynamics.STATIC, e.Movement.DESCENDING_INTERVAL, "Descending"),
		2: createIntervalRecognition(e.Dynamics.STATIC, e.Movement.HARMONY_INTERVAL, "Harmonic"),
		3: createIntervalRecognition(e.Dynamics.DYNAMIC, e.Movement.ASCENDING_INTERVAL, "Ascending"),
		4: createIntervalRecognition(e.Dynamics.DYNAMIC, e.Movement.DESCENDING_INTERVAL, "Descending"),
		5: createIntervalRecognition(e.Dynamics.DYNAMIC, e.Movement.HARMONY_INTERVAL, "Harmonic"),
	},
	[e.DifficultyIntervalRecognition.OCTAVE_HIGHER]: {
		0: createIntervalRecognition(e.Dynamics.STATIC, e.Movement.ASCENDING_INTERVAL, "Ascending"),
		1: createIntervalRecognition(e.Dynamics.STATIC, e.Movement.DESCENDING_INTERVAL, "Descending"),
		2: createIntervalRecognition(e.Dynamics.STATIC, e.Movement.HARMONY_INTERVAL, "Harmonic"),
		3: createIntervalRecognition(e.Dynamics.DYNAMIC, e.Movement.ASCENDING_INTERVAL, "Ascending"),
		4: createIntervalRecognition(e.Dynamics.DYNAMIC, e.Movement.DESCENDING_INTERVAL, "Descending"),
		5: createIntervalRecognition(e.Dynamics.DYNAMIC, e.Movement.HARMONY_INTERVAL, "Harmonic"),
	},
};

// HARMONIC CONTEXT
function createHarmonicContext(chord: e.Chords | string, dynamics: e.Dynamics, movement: e.Movement): any {
	return {
		dynamics,
		movement,
		description: `${chord} chord followed by an interval (${
			dynamics === e.Dynamics.STATIC ? "static" : "changing"
		} root)`,
		descriptionShort: `a ${chord} chord followed by a note. What is the interval between this note and the root of the chord?`,
	};
}

export const harmonicContext: Record<e.DifficultyHarmonicContext, any> = {
	[e.DifficultyHarmonicContext.IONIAN]: {
		0: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.STATIC, e.Movement.ONE_OCTAVE),
		1: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_HIGHER),
		2: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_LOWER),
		3: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.DYNAMIC, e.Movement.ONE_OCTAVE),
		4: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_HIGHER),
		5: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_LOWER),
	},
	[e.DifficultyHarmonicContext.DORIAN]: {
		0: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.STATIC, e.Movement.ONE_OCTAVE),
		1: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_HIGHER),
		2: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_LOWER),
		3: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.DYNAMIC, e.Movement.ONE_OCTAVE),
		4: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_HIGHER),
		5: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_LOWER),
	},
	[e.DifficultyHarmonicContext.PHRYGIAN]: {
		0: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.STATIC, e.Movement.ONE_OCTAVE),
		1: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_HIGHER),
		2: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_LOWER),
		3: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.DYNAMIC, e.Movement.ONE_OCTAVE),
		4: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_HIGHER),
		5: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_LOWER),
	},
	[e.DifficultyHarmonicContext.LYDIAN]: {
		0: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.STATIC, e.Movement.ONE_OCTAVE),
		1: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_HIGHER),
		2: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_LOWER),
		3: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.DYNAMIC, e.Movement.ONE_OCTAVE),
		4: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_HIGHER),
		5: createHarmonicContext(e.Chords.MAJOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_LOWER),
	},
	[e.DifficultyHarmonicContext.MIXOLYDIAN]: {
		0: createHarmonicContext(e.Chords.DOMINANT, e.Dynamics.STATIC, e.Movement.ONE_OCTAVE),
		1: createHarmonicContext(e.Chords.DOMINANT, e.Dynamics.STATIC, e.Movement.OCTAVE_HIGHER),
		2: createHarmonicContext(e.Chords.DOMINANT, e.Dynamics.STATIC, e.Movement.OCTAVE_LOWER),
		3: createHarmonicContext(e.Chords.DOMINANT, e.Dynamics.DYNAMIC, e.Movement.ONE_OCTAVE),
		4: createHarmonicContext(e.Chords.DOMINANT, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_HIGHER),
		5: createHarmonicContext(e.Chords.DOMINANT, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_LOWER),
	},
	[e.DifficultyHarmonicContext.AEOLIAN]: {
		0: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.STATIC, e.Movement.ONE_OCTAVE),
		1: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_HIGHER),
		2: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_LOWER),
		3: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.DYNAMIC, e.Movement.ONE_OCTAVE),
		4: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_HIGHER),
		5: createHarmonicContext(e.Chords.MINOR_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_LOWER),
	},
	[e.DifficultyHarmonicContext.LOCRIAN]: {
		0: createHarmonicContext(e.Chords.HALF_DIM_7TH, e.Dynamics.STATIC, e.Movement.ONE_OCTAVE),
		1: createHarmonicContext(e.Chords.HALF_DIM_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_HIGHER),
		2: createHarmonicContext(e.Chords.HALF_DIM_7TH, e.Dynamics.STATIC, e.Movement.OCTAVE_LOWER),
		3: createHarmonicContext(e.Chords.HALF_DIM_7TH, e.Dynamics.DYNAMIC, e.Movement.ONE_OCTAVE),
		4: createHarmonicContext(e.Chords.HALF_DIM_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_HIGHER),
		5: createHarmonicContext(e.Chords.HALF_DIM_7TH, e.Dynamics.DYNAMIC, e.Movement.OCTAVE_LOWER),
	},
	[e.DifficultyHarmonicContext.CHROMATIC]: {
		0: createHarmonicContext("Random", e.Dynamics.STATIC, e.Movement.ONE_OCTAVE),
		1: createHarmonicContext("Random", e.Dynamics.STATIC, e.Movement.OCTAVE_HIGHER),
		2: createHarmonicContext("Random", e.Dynamics.STATIC, e.Movement.OCTAVE_LOWER),
		3: createHarmonicContext("Random", e.Dynamics.DYNAMIC, e.Movement.ONE_OCTAVE),
		4: createHarmonicContext("Random", e.Dynamics.DYNAMIC, e.Movement.OCTAVE_HIGHER),
		5: createHarmonicContext("Random", e.Dynamics.DYNAMIC, e.Movement.OCTAVE_LOWER),
	},
};

// SCALE RECOGNITION
function createScaleRecognitionAlternating(
	dynamics: e.Dynamics,
	movement: e.Movement,
	descriptionDetail: string,
	repetition: number
): any {
	return {
		dynamics,
		movement,
		description: `${descriptionDetail} with a ${
			dynamics === e.Dynamics.STATIC ? "static" : "changing"
		} root, which is played every ${repetition} notes`,
		descriptionShort: `an ${descriptionDetail.toLowerCase()} scale, where the root note is repeated every ${repetition} notes`,
	};
}
function createScaleRecognition(dynamics: e.Dynamics, movement: e.Movement, descriptionDetail: string): any {
	const descriptionDetailShort = "aeiou".includes(descriptionDetail.charAt(0).toLowerCase()) ? "an" : "a";
	return {
		dynamics,
		movement,
		description: `${descriptionDetail} with a ${dynamics === e.Dynamics.STATIC ? "static" : "changing"} root`,
		descriptionShort: `${descriptionDetailShort} ${descriptionDetail.toLowerCase()} scale`,
	};
}
function createScaleRecognitionMelody(dynamics: e.Dynamics, movement: e.Movement, repetition?: number): any {
	const addedInfo1 = repetition ? `, playing the root every ${repetition} notes` : "";
	const addedInfo2 = repetition ? `, where the root note is repeated every ${repetition} notes` : "";

	return {
		dynamics,
		movement,
		description: `Melody with a ${dynamics === e.Dynamics.STATIC ? "static" : "changing"} root${addedInfo1}`,
		descriptionShort: `a melody${addedInfo2}`,
	};
}

export const scaleRecognition: Record<e.DifficultyScaleRecognition, any> = {
	[e.DifficultyScaleRecognition.ALTERNATING]: {
		0: createScaleRecognitionAlternating(e.Dynamics.STATIC, e.Movement.ALTERNATING_1, "Ascending", 2),
		1: createScaleRecognitionAlternating(e.Dynamics.DYNAMIC, e.Movement.ALTERNATING_1, "Ascending", 2),
		2: createScaleRecognitionAlternating(e.Dynamics.STATIC, e.Movement.ALTERNATING_2, "Ascending", 3),
		3: createScaleRecognitionAlternating(e.Dynamics.DYNAMIC, e.Movement.ALTERNATING_2, "Ascending", 3),
		4: createScaleRecognitionAlternating(e.Dynamics.STATIC, e.Movement.ALTERNATING_3, "Ascending", 4),
		5: createScaleRecognitionAlternating(e.Dynamics.DYNAMIC, e.Movement.ALTERNATING_3, "Ascending", 4),
	},
	[e.DifficultyScaleRecognition.ASCENDING]: {
		0: createScaleRecognition(e.Dynamics.STATIC, e.Movement.ASCENDING_SLOW, "Slowly ascending"),
		1: createScaleRecognition(e.Dynamics.DYNAMIC, e.Movement.ASCENDING_SLOW, "Slowly ascending"),
		2: createScaleRecognition(e.Dynamics.STATIC, e.Movement.ASCENDING, "Ascending"),
		3: createScaleRecognition(e.Dynamics.DYNAMIC, e.Movement.ASCENDING, "Ascending"),
		4: createScaleRecognition(e.Dynamics.STATIC, e.Movement.ASCENDING_FAST, "Fast ascending"),
		5: createScaleRecognition(e.Dynamics.DYNAMIC, e.Movement.ASCENDING_FAST, "Fast ascending"),
	},
	[e.DifficultyScaleRecognition.DESCENDING]: {
		0: createScaleRecognition(e.Dynamics.STATIC, e.Movement.DESCENDING_SLOW, "Slowly descending"),
		1: createScaleRecognition(e.Dynamics.DYNAMIC, e.Movement.DESCENDING_SLOW, "Slowly descending"),
		2: createScaleRecognition(e.Dynamics.STATIC, e.Movement.DESCENDING, "Descending"),
		3: createScaleRecognition(e.Dynamics.DYNAMIC, e.Movement.DESCENDING, "Descending"),
		4: createScaleRecognition(e.Dynamics.STATIC, e.Movement.DESCENDING_FAST, "Fast descending"),
		5: createScaleRecognition(e.Dynamics.DYNAMIC, e.Movement.DESCENDING_FAST, "Fast descending"),
	},
	[e.DifficultyScaleRecognition.MELODY]: {
		0: createScaleRecognitionMelody(e.Dynamics.STATIC, e.Movement.RANDOM_EASY, 3),
		1: createScaleRecognitionMelody(e.Dynamics.DYNAMIC, e.Movement.RANDOM_EASY, 3),
		2: createScaleRecognitionMelody(e.Dynamics.STATIC, e.Movement.RANDOM, 4),
		3: createScaleRecognitionMelody(e.Dynamics.DYNAMIC, e.Movement.RANDOM, 4),
		4: createScaleRecognitionMelody(e.Dynamics.STATIC, e.Movement.RANDOM_HARD),
		5: createScaleRecognitionMelody(e.Dynamics.DYNAMIC, e.Movement.RANDOM_HARD),
	},
};

// MELODIC DICTATION
function createMelodicDictation(dynamics: e.Dynamics, movement: e.Movement, notesCount: number): any {
	return {
		dynamics,
		movement,
		description: `${notesCount} note melody with a ${dynamics === e.Dynamics.STATIC ? "static" : "changing"} root`,
		descriptionShort: `a ${notesCount} note melody`,
	};
}

export const melodicDictation: Record<e.DifficultyMelodicDictation, any> = {
	[e.DifficultyMelodicDictation.SHORT_ONE_OCTAVE]: {
		0: createMelodicDictation(e.Dynamics.STATIC, e.Movement.THREE_NOTES, 3),
		1: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.THREE_NOTES, 3),
		2: createMelodicDictation(e.Dynamics.STATIC, e.Movement.FOUR_NOTES, 4),
		3: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.FOUR_NOTES, 4),
		4: createMelodicDictation(e.Dynamics.STATIC, e.Movement.FIVE_NOTES, 5),
		5: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.FIVE_NOTES, 5),
	},
	[e.DifficultyMelodicDictation.SHORT_TWO_OCTAVES]: {
		0: createMelodicDictation(e.Dynamics.STATIC, e.Movement.THREE_NOTES, 3),
		1: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.THREE_NOTES, 3),
		2: createMelodicDictation(e.Dynamics.STATIC, e.Movement.FOUR_NOTES, 4),
		3: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.FOUR_NOTES, 4),
		4: createMelodicDictation(e.Dynamics.STATIC, e.Movement.FIVE_NOTES, 5),
		5: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.FIVE_NOTES, 5),
	},
	[e.DifficultyMelodicDictation.LONG_ONE_OCTAVE]: {
		0: createMelodicDictation(e.Dynamics.STATIC, e.Movement.FIVE_NOTES, 5),
		1: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.FIVE_NOTES, 5),
		2: createMelodicDictation(e.Dynamics.STATIC, e.Movement.SIX_NOTES, 6),
		3: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.SIX_NOTES, 6),
		4: createMelodicDictation(e.Dynamics.STATIC, e.Movement.SEVEN_NOTES, 7),
		5: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.SEVEN_NOTES, 7),
	},
	[e.DifficultyMelodicDictation.LONG_TWO_OCTAVES]: {
		0: createMelodicDictation(e.Dynamics.STATIC, e.Movement.FIVE_NOTES, 5),
		1: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.FIVE_NOTES, 5),
		2: createMelodicDictation(e.Dynamics.STATIC, e.Movement.SIX_NOTES, 6),
		3: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.SIX_NOTES, 6),
		4: createMelodicDictation(e.Dynamics.STATIC, e.Movement.SEVEN_NOTES, 7),
		5: createMelodicDictation(e.Dynamics.DYNAMIC, e.Movement.SEVEN_NOTES, 7),
	},
};

// INVERSIONS
function createTriadInversions(
	dynamics: e.Dynamics,
	movement: e.Movement,
	descriptionDetail: string,
	voicing?: boolean
): any {
	const addedInfo = voicing ? `The voicing will change each time. Pay attention to the root note` : "";
	return {
		dynamics,
		movement,
		description: `${descriptionDetail} with a ${dynamics === e.Dynamics.STATIC ? "static" : "changing"} root`,
		descriptionShort: `${descriptionDetail.toLowerCase()} inversion. ${addedInfo}`,
	};
}

export const triadInversions: Record<e.DifficultyTriadInversions, any> = {
	[e.DifficultyTriadInversions.STANDARD_VOICING]: {
		0: createTriadInversions(e.Dynamics.STATIC, e.Movement.ASCENDING, "Ascending"),
		1: createTriadInversions(e.Dynamics.STATIC, e.Movement.DESCENDING, "Descending"),
		2: createTriadInversions(e.Dynamics.STATIC, e.Movement.HARMONY, "Harmonic"),
		3: createTriadInversions(e.Dynamics.DYNAMIC, e.Movement.ASCENDING, "Ascending"),
		4: createTriadInversions(e.Dynamics.DYNAMIC, e.Movement.DESCENDING, "Descending"),
		5: createTriadInversions(e.Dynamics.DYNAMIC, e.Movement.HARMONY, "Harmonic"),
	},
	[e.DifficultyTriadInversions.RANDOM_VOICING]: {
		0: createTriadInversions(e.Dynamics.STATIC, e.Movement.ASCENDING_RANDOM_VOICING, "Ascending", true),
		1: createTriadInversions(e.Dynamics.STATIC, e.Movement.DESCENDING_RANDOM_VOICING, "Descending", true),
		2: createTriadInversions(e.Dynamics.STATIC, e.Movement.HARMONY_RANDOM_VOICING, "Harmonic", true),
		3: createTriadInversions(e.Dynamics.DYNAMIC, e.Movement.ASCENDING_RANDOM_VOICING, "Ascending", true),
		4: createTriadInversions(e.Dynamics.DYNAMIC, e.Movement.DESCENDING_RANDOM_VOICING, "Descending", true),
		5: createTriadInversions(e.Dynamics.DYNAMIC, e.Movement.HARMONY_RANDOM_VOICING, "Harmonic", true),
	},
};

// CHORDS
function createChords(dynamics: e.Dynamics, movement: e.Movement, descriptionDetail: string): any {
	const prefix = "aeiou".includes(descriptionDetail.charAt(0).toLowerCase()) ? "an" : "a";
	return {
		dynamics,
		movement,
		description: `${descriptionDetail} with a ${dynamics === e.Dynamics.STATIC ? "static" : "changing"} root`,
		descriptionShort: `${prefix} ${descriptionDetail.toLowerCase()} chord`,
	};
}

export const chords: Record<e.DifficultyChordRecognition, any> = {
	[e.DifficultyChordRecognition.STANDARD_VOICING]: {
		0: createChords(e.Dynamics.STATIC, e.Movement.ASCENDING, "Ascending"),
		1: createChords(e.Dynamics.STATIC, e.Movement.DESCENDING, "Descending"),
		2: createChords(e.Dynamics.STATIC, e.Movement.HARMONY, "Harmonic"),
		3: createChords(e.Dynamics.DYNAMIC, e.Movement.ASCENDING, "Ascending"),
		4: createChords(e.Dynamics.DYNAMIC, e.Movement.DESCENDING, "Descending"),
		5: createChords(e.Dynamics.DYNAMIC, e.Movement.HARMONY, "Harmonic"),
	},
	[e.DifficultyChordRecognition.RANDOM_VOICING]: {
		0: createChords(e.Dynamics.STATIC, e.Movement.ASCENDING_RANDOM_VOICING, "Ascending"),
		1: createChords(e.Dynamics.STATIC, e.Movement.DESCENDING_RANDOM_VOICING, "Descending"),
		2: createChords(e.Dynamics.STATIC, e.Movement.HARMONY_RANDOM_VOICING, "Harmonic"),
		3: createChords(e.Dynamics.DYNAMIC, e.Movement.ASCENDING_RANDOM_VOICING, "Ascending"),
		4: createChords(e.Dynamics.DYNAMIC, e.Movement.DESCENDING_RANDOM_VOICING, "Descending"),
		5: createChords(e.Dynamics.DYNAMIC, e.Movement.HARMONY_RANDOM_VOICING, "Harmonic"),
	},
};

// PROGRESSIONS
type ProgressionDetail = {
	dynamics: e.Dynamics;
	movement: e.Movement;
	description: string;
	descriptionShort: string;
};

function createProgressionDetail(dynamics: e.Dynamics, movement: e.Movement, rootChange: string): ProgressionDetail {
	const rootDescription = rootChange === "static" ? "with a static root" : "with a changing root";
	const movementDescription =
		movement === e.Movement.ASCENDING
			? "ascending"
			: movement === e.Movement.DESCENDING
			? "descending"
			: "harmonic";

	const movementDescriptionShort =
		movement === e.Movement.ASCENDING
			? "ascending arpeggios"
			: movement === e.Movement.DESCENDING
			? "descending arpeggios"
			: "chords played harmonically";

	return {
		dynamics,
		movement,
		description: `${capitalizeFirstLetter(movementDescription)} ${rootDescription}`,
		descriptionShort: `${movementDescriptionShort}`,
	};
}

function createProgressions(): Record<e.DifficultyProgressions, Record<number, ProgressionDetail>> {
	const commonProgressions = {
		0: createProgressionDetail(e.Dynamics.STATIC, e.Movement.ASCENDING, "static"),
		1: createProgressionDetail(e.Dynamics.STATIC, e.Movement.DESCENDING, "static"),
		2: createProgressionDetail(e.Dynamics.STATIC, e.Movement.HARMONY, "static"),
		3: createProgressionDetail(e.Dynamics.DYNAMIC, e.Movement.ASCENDING, "dynamic"),
		4: createProgressionDetail(e.Dynamics.DYNAMIC, e.Movement.DESCENDING, "dynamic"),
		5: createProgressionDetail(e.Dynamics.DYNAMIC, e.Movement.HARMONY, "dynamic"),
	};

	return {
		[e.DifficultyProgressions.ROOT]: commonProgressions,
		[e.DifficultyProgressions.FIRST_INVERSION]: commonProgressions,
		[e.DifficultyProgressions.ROOT_AND_FIRST_INVERSION]: commonProgressions,
		[e.DifficultyProgressions.SECOND_INVERSION]: commonProgressions,
		[e.DifficultyProgressions.ROOT_AND_SECOND_INVERSION]: commonProgressions,
		[e.DifficultyProgressions.ALL]: commonProgressions,
	};
}

export const progressions = createProgressions();

// JAZZ PROGRESSIONS
type ProgressionJazzDetail = {
	dynamics: e.Dynamics;
	movement: e.Movement;
	description: string;
	descriptionShort: string;
};

function createProgressionJazzDetail(
	dynamics: e.Dynamics,
	movement: e.Movement,
	rootChange: string
): ProgressionJazzDetail {
	const rootDescription = rootChange === "static" ? "with a static root" : "with a changing root";
	const movementDescription =
		movement === e.Movement.SLOW ? "slow" : movement === e.Movement.FAST ? "fast" : "melody on top";

	const movementDescriptionShort =
		movement === e.Movement.SLOW
			? "slow progression"
			: movement === e.Movement.FAST
			? "fast progression"
			: "fast progression with a melody on top";

	return {
		dynamics,
		movement,
		description: `${capitalizeFirstLetter(movementDescription)} ${rootDescription}`,
		descriptionShort: `${movementDescriptionShort}`,
	};
}

function createProgressionsJazz(): Record<e.DifficultyProgressionsJazz, Record<number, ProgressionDetail>> {
	const commonProgressions = {
		0: createProgressionJazzDetail(e.Dynamics.STATIC, e.Movement.SLOW, "static"),
		3: createProgressionJazzDetail(e.Dynamics.DYNAMIC, e.Movement.SLOW, "dynamic"),
		1: createProgressionJazzDetail(e.Dynamics.STATIC, e.Movement.FAST, "static"),
		4: createProgressionJazzDetail(e.Dynamics.DYNAMIC, e.Movement.FAST, "dynamic"),
		2: createProgressionJazzDetail(e.Dynamics.STATIC, e.Movement.MELODY, "static"),
		5: createProgressionJazzDetail(e.Dynamics.DYNAMIC, e.Movement.MELODY, "dynamic"),
	};

	return {
		[e.DifficultyProgressionsJazz.COMMON_PROGRESSIONS]: commonProgressions,
		[e.DifficultyProgressionsJazz.MODULATIONS_MAJOR]: commonProgressions,
		[e.DifficultyProgressionsJazz.SECONDARY_DOMINANT_MAJOR]: commonProgressions,
		[e.DifficultyProgressionsJazz.SECONDARY_DOMINANT_MINOR]: commonProgressions,
	};
}

export const progressionsJazz = createProgressionsJazz();
