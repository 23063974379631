import * as t from "../../data/types";
import React, { useEffect, useState } from "react";
import { useTeacherLoggedIn } from "../../context/UserContext";
import { useAlert } from "../../context/AlertContext";
import { isValidEmail } from "../../utils/utils";
import editStudentAPI from "../../services/api/editStudentAPI";
import getClassesByTeacherAPI from "../../services/api/getClassesByTeacherAPI";
import { useTranslation } from "react-i18next";

export default function EditStudents({ classToEdit }: { classToEdit: t.Class }) {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [objectToEdit, setObjectToEdit] = useState<t.User | null>(null);
	const { t } = useTranslation("ManageClasses");

	function handleEdit(object: t.User) {
		setObjectToEdit(object);
		setShowModal(true);
	}
	return (
		<>
			<div className="overviewTable">
				<table>
					<thead className="theadCSS">
						<tr>
							<th>{t("Name")}</th>
							<th>{t("Student ID")}</th>
							<th>{t("Email")}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{classToEdit.Students?.map((student, id) => (
							<tr key={id}>
								<td>{student.FirstName + " " + student.LastName}</td>
								<td>{student.StudentNumber}</td>
								<td>{student.Email}</td>
								<td className="error link" onClick={() => handleEdit(student)}>
									{t("edit")}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{objectToEdit && (
				<EditSingleStudent
					student={objectToEdit}
					setStudent={setObjectToEdit}
					showModal={showModal}
					closeModal={() => setShowModal(false)}
				/>
			)}
		</>
	);
}

function EditSingleStudent({
	student,
	setStudent,
	showModal,
	closeModal,
}: {
	student: t.User;
	setStudent: React.Dispatch<React.SetStateAction<t.User | null>>;
	showModal: boolean;
	closeModal: () => void;
}): JSX.Element {
	const { scrollContentRef } = useTeacherLoggedIn();
	const { teacher, classData, setClassData, setLoading } = useTeacherLoggedIn();
	const { triggerAlert } = useAlert();

	const [firstName, setFirstName] = useState(student.FirstName);
	const [lastName, setLastName] = useState(student.LastName);
	const [studentNumber, setStudentNumber] = useState(student.StudentNumber);
	const [studentEmail, setStudentEmail] = useState(student.Email);
	const [studentClass, setStudentClass] = useState(student.ClassID);

	const [errorMessage, setErrorMessage] = useState<string>("");
	const { t } = useTranslation("ManageClasses");

	// get studentClass based on class.id
	useEffect(() => {
		if (!studentClass) return;
		const _studentClass = classData?.find((c) => c.id === student.ClassID);
		if (_studentClass) setStudentClass(_studentClass.id);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [classData]);

	const handleCloseModal = () => {
		setErrorMessage("");
		setStudent(null);
		closeModal();
	};

	const handleContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	async function saveEditedInfo() {
		if (!isValidEmail(studentEmail)) return setErrorMessage("Email is not valid");
		if (!checkIfEmailAndStudentNumberAreUnique(student.id, studentNumber, studentEmail, classData, setErrorMessage))
			return;

		try {
			setLoading(true);

			const classID = classData?.find((c) => c.id === studentClass)?.id || student.ClassID;

			const editedStudent = {
				...student,
				FirstName: firstName,
				LastName: lastName,
				StudentNumber: studentNumber,
				Email: studentEmail.toLocaleLowerCase().trim(),
				ClassID: classID,
			};
			// console.log(editedStudent);
			await editStudentAPI(editedStudent);
			await getClassesByTeacherAPI(teacher.id, setClassData);
			triggerAlert(t("Student successfully updated"), "success");
		} catch (error: any) {
			triggerAlert(error.response.data.message, "error");
			// console.log(error);
		} finally {
			setErrorMessage("");
			setLoading(false);
			setStudent(null);
			closeModal();
		}
	}
	return (
		<div className={`modal ${showModal ? "show" : ""}`} onClick={handleCloseModal}>
			<div ref={scrollContentRef} className="modal-content createNewContent" onClick={handleContentClick}>
				<div className="flexRow">
					<h2>{t("Edit student info")}</h2>
					<h2 className="close" onClick={handleCloseModal}>
						&times;
					</h2>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						saveEditedInfo();
					}}
				>
					<label className="flexRow">
						<h3>{t("First Name")}</h3>
						<input
							className="text-area"
							type="text"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							required
						/>
					</label>
					<label className="flexRow">
						<h3>{t("Last Name")}</h3>
						<input
							className="text-area"
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							required
						/>
					</label>
					<label className="flexRow">
						<h3>{t("Student Number")}</h3>
						<input
							className="text-area"
							type="text"
							value={studentNumber}
							onChange={(e) => {
								setStudentNumber(e.target.value);
							}}
							required
						/>
					</label>
					<label className="flexRow">
						<h3>{t("Email")}</h3>
						<input
							className="text-area"
							type="email"
							value={studentEmail}
							onChange={(e) => setStudentEmail(e.target.value)}
							required
						/>
					</label>
					<label className="flexRow">
						<h3>{t("Class")}</h3>
						<select
							className="text-area"
							value={studentClass}
							onChange={(e) => setStudentClass(e.target.value)}
							required
						>
							{classData?.map((cls) => (
								<option key={cls.id} value={cls.id}>
									{cls.ClassName}
								</option>
							))}
						</select>
					</label>
					{errorMessage && <p className="error">{errorMessage}</p>}
					<button type="submit" value="Update student info">
						{t("Update student info")}
					</button>
				</form>
			</div>
		</div>
	);
}

function checkIfEmailAndStudentNumberAreUnique(
	id: string | undefined,
	studentNumber: string,
	studentEmail: string,
	classData: t.Class[] | null,
	setErrorMessage: React.Dispatch<React.SetStateAction<string>>
): boolean {
	if (!classData) return true;
	if (!id) {
		setErrorMessage("Error: cannot find student, please try again later");
		return false;
	}

	for (const classes of classData) {
		const studentEmailExists = classes.Students?.find((student) => student.Email === studentEmail);
		if (studentEmailExists && studentEmailExists.id !== id) {
			setErrorMessage("Student with this email already exists");
			return false;
		}

		const studentNumberExists = classes.Students?.find((student) => student.StudentNumber === studentNumber);
		if (studentNumberExists && studentNumberExists.id !== id) {
			setErrorMessage("Student with this student number already exists");
			return false;
		}
	}

	return true;
}
