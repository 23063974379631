import { createSection } from "./utils";
import * as e from "../../enums";

const j = e.DegreesJazz;

const secDomii = [j.ii7, j.ii7b5overii, j.V7b9overii];
const secDomiii = [j.iii7, j.ii7b5overiii, j.V7b9overiii];
const secDomiv = [j.iv7, j.ii7b5overiv, j.V7b9overiv];
const secDomv = [j.v7, j.ii7b5overv, j.V7b9overv];
const secDomvi = [j.vi7, j.ii7b5overvi, j.V7b9overvi];

const secDomiiTritone = [j.ii7, j.bII7overii, j.ii7b5overii, j.V7b9overii];
const secDomiiiTritone = [j.iii7, j.bII7overiii, j.ii7b5overiii, j.V7b9overiii];
const secDomivTritone = [j.iv7, j.bII7overiv, j.ii7b5overiv, j.V7b9overiv];
const secDomvTritone = [j.v7, j.bII7overv, j.ii7b5overv, j.V7b9overv];
const secDomviTritone = [j.vi7, j.bII7overvi, j.ii7b5overvi, j.V7b9overvi];

const secondaryDominantsWithRelatedTwoChordsMajor1 = [
	{
		options: [j.Imaj7, ...secDomiiTritone],
		levelName: "Secondary dominant and it's tritone substitution over ii",
	},
	{
		options: [j.Imaj7, ...secDomiiiTritone],
		levelName: "Secondary dominant and it's tritone substitution over iii",
	},
	{
		options: [j.Imaj7, ...secDomivTritone],
		levelName: "Secondary dominant and it's tritone substitution over iv",
	},
	{
		options: [j.Imaj7, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over v",
	},
	{
		options: [j.Imaj7, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over vi",
	},
];

const secondaryDominantsWithRelatedTwoChordsMajor2 = [
	{
		options: [j.Imaj7, ...secDomii, ...secDomiii],
		levelName: "Secondary dominant over ii and iii",
	},
	{
		options: [j.Imaj7, ...secDomii, ...secDomiv],
		levelName: "Secondary dominant over ii and iv",
	},
	{
		options: [j.Imaj7, ...secDomii, ...secDomv],
		levelName: "Secondary dominant over ii and v",
	},
	{
		options: [j.Imaj7, ...secDomii, ...secDomvi],
		levelName: "Secondary dominant over ii and vi",
	},
	{
		options: [j.Imaj7, ...secDomiii, ...secDomiv],
		levelName: "Secondary dominant over iii and iv",
	},
];

const secondaryDominantsWithRelatedTwoChordsMajor3 = [
	{
		options: [j.Imaj7, ...secDomiii, ...secDomv],
		levelName: "Secondary dominant over iii and v",
	},
	{
		options: [j.Imaj7, ...secDomiii, ...secDomvi],
		levelName: "Secondary dominant over iii and vi",
	},
	{
		options: [j.Imaj7, ...secDomiv, ...secDomv],
		levelName: "Secondary dominant over iv and v",
	},
	{
		options: [j.Imaj7, ...secDomiv, ...secDomvi],
		levelName: "Secondary dominant over iv and vi",
	},
	{
		options: [j.Imaj7, ...secDomv, ...secDomvi],
		levelName: "Secondary dominant over v and vi",
	},
];

const secondaryDominantsWithRelatedTwoChordsMajor4 = [
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomiiiTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii and iii",
	},
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomivTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii and iv",
	},
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii and v",
	},
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii and vi",
	},
	{
		options: [j.Imaj7, ...secDomiiiTritone, ...secDomivTritone],
		levelName: "Secondary dominant and it's tritone substitution over over iii and iv",
	},
];

const secondaryDominantsWithRelatedTwoChordsMajor5 = [
	{
		options: [j.Imaj7, ...secDomiiiTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over iii and v",
	},
	{
		options: [j.Imaj7, ...secDomiiiTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over iii and vi",
	},
	{
		options: [j.Imaj7, ...secDomivTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over iv and v",
	},
	{
		options: [j.Imaj7, ...secDomivTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over iv and vi",
	},
	{
		options: [j.Imaj7, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over v and vi",
	},
];

const secondaryDominantsWithRelatedTwoChordsMajor6 = [
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomivTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii, iii and iv",
	},
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii, iii and v",
	},
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii, iii and vi",
	},
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomivTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii, iv and v",
	},
];

const secondaryDominantsWithRelatedTwoChordsMajor7 = [
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii, v and vi",
	},
	{
		options: [j.Imaj7, ...secDomiiiTritone, ...secDomivTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over iii, iv and v",
	},
	{
		options: [j.Imaj7, ...secDomiiiTritone, ...secDomivTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over iii, iv and vi",
	},
	{
		options: [j.Imaj7, ...secDomivTritone, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over iv, v and vi",
	},
];

const secondaryDominantsWithRelatedTwoChordsMajor8 = [
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomivTritone, ...secDomvTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii, iii, iv, v",
	},
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomivTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii, iii, iv, vi",
	},
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomiiiTritone, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii, iii, v, vi",
	},
	{
		options: [j.Imaj7, ...secDomiiTritone, ...secDomivTritone, ...secDomvTritone, ...secDomviTritone],
		levelName: "Secondary dominant and it's tritone substitution over over ii, iv, v, vi",
	},
];
const secondaryDominantsWithRelatedTwoChordsMajor9 = [
	{
		options: [
			j.Imaj7,
			...secDomiiTritone,
			...secDomiiiTritone,
			...secDomivTritone,
			...secDomvTritone,
			...secDomviTritone,
		],
		levelName: "All combinations of secondary dominant and it's tritone substitution",
	},
];

export const PROGRESSION_JAZZ_SEC_DOM_MAJOR_SECTIONS = [
	createSection("Secondary Dominants With Related Two Chords 1", secondaryDominantsWithRelatedTwoChordsMajor1),
	createSection("Secondary Dominants With Related Two Chords 2", secondaryDominantsWithRelatedTwoChordsMajor2),
	createSection("Secondary Dominants With Related Two Chords 3", secondaryDominantsWithRelatedTwoChordsMajor3),
	createSection("Secondary Dominants With Related Two Chords 4", secondaryDominantsWithRelatedTwoChordsMajor4),
	createSection("Secondary Dominants With Related Two Chords 5", secondaryDominantsWithRelatedTwoChordsMajor5),
	createSection("Secondary Dominants With Related Two Chords 6", secondaryDominantsWithRelatedTwoChordsMajor6),
	createSection("Secondary Dominants With Related Two Chords 7", secondaryDominantsWithRelatedTwoChordsMajor7),
	createSection("Secondary Dominants With Related Two Chords 8", secondaryDominantsWithRelatedTwoChordsMajor8),
	createSection("Boss level", secondaryDominantsWithRelatedTwoChordsMajor9),
];
