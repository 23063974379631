enum ErrorCode {
	INVALID_EMAIL = "auth/invalid-email",
	USER_NOT_FOUND = "auth/user-not-found",
	WRONG_PASSWORD = "auth/wrong-password",
	TOO_MANY_REQUESTS = "auth/too-many-requests",
	EMAIL_ALREADY_IN_USE = "auth/email-already-in-use",
	INTERNAL_ERROR = "auth/internal-error",
	WEAK_PASSWORD = "auth/weak-password",
}

export type IAuthError = {
	code: ErrorCode;
	message: string;
}

export type UserFriendlyError = {
	userMessage: string;
	logMessage?: string;
	errorCode?: string;
}

export const ERROR_MESSAGES: Record<ErrorCode, UserFriendlyError> = {
	[ErrorCode.INVALID_EMAIL]: {
		userMessage: "The email address is not valid. Please check and try again.",
		logMessage: "Invalid email format provided by the user.",
		errorCode: ErrorCode.INVALID_EMAIL,
	},
	[ErrorCode.USER_NOT_FOUND]: {
		userMessage: "No account found with the provided email address. Please sign up.",
		logMessage: "User attempted sign-in with non-existent account.",
		errorCode: ErrorCode.USER_NOT_FOUND,
	},
	[ErrorCode.WRONG_PASSWORD]: {
		userMessage: "The password you entered is incorrect. Please try again.",
		logMessage: "Incorrect password entered by the user.",
		errorCode: ErrorCode.WRONG_PASSWORD,
	},
	[ErrorCode.TOO_MANY_REQUESTS]: {
		userMessage: "Too many login attempts. Please try again later.",
		logMessage: "User locked out due to too many login attempts.",
		errorCode: ErrorCode.TOO_MANY_REQUESTS,
	},
	[ErrorCode.EMAIL_ALREADY_IN_USE]: {
		userMessage: "The email address is already in use. Please use a different one.",
		logMessage: "Attempted to create a new account with an existing email.",
		errorCode: ErrorCode.EMAIL_ALREADY_IN_USE,
	},
	[ErrorCode.INTERNAL_ERROR]: {
		userMessage: "An unexpected error occurred. Please try again later.",
		logMessage: "Internal error reported from authentication system.",
		errorCode: ErrorCode.INTERNAL_ERROR,
	},
	[ErrorCode.WEAK_PASSWORD]: {
		userMessage: "The password is too weak. Please use a stronger password.",
		logMessage: "User attempted to set a weak password.",
		errorCode: ErrorCode.WEAK_PASSWORD,
	},
};
