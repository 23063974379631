import { createSection } from "./utils";

const highContrastLevels = [
	{ options: ["b5", "1"], levelName: "Root & Tritone" },
	{ options: ["b2", "5"], levelName: "Minor 2nd & Perfect 5th" },
	{ options: ["2", "7"], levelName: "Major 2nd & Major 7th" },
	{ options: ["b3", "b7"], levelName: "Minor 3rd & Minor 7th" },
	{ options: ["3", "6"], levelName: "Major 3rd & Major 6th" },
	{ options: ["4", "b6"], levelName: "Perfect 4th & Minor 6th" },
];
const relatedIntervalsLevels = [
	{ options: ["b2", "7"], levelName: "Minor 2nd & Major 7th" },
	{ options: ["2", "b7"], levelName: "Major 2nd & Minor 7th" },
	{ options: ["b3", "6"], levelName: "Minor 3rd & Major 6th" },
	{ options: ["3", "b6"], levelName: "Major 3rd & Minor 6th" },
	{ options: ["4", "5"], levelName: "Perfect 4th & Perfect 5th" },
];
const threeOptionsPart1Levels = [
	{ options: ["1", "3", "b6"], levelName: "Root, Major 3rd & Minor 6th" },
	{ options: ["b2", "4", "6"], levelName: "Minor 2nd, Perfect 4th & Major 6th" },
	{ options: ["2", "b5", "b7"], levelName: "Major 2nd, Tritone & Minor 7th" },
	{ options: ["b3", "5", "7"], levelName: "Minor 3rd, Perfect 5th & Major 7th" },
];

const threeOptionsPart2Levels = [
	{ options: ["1", "3", "5"], levelName: "Root, Major 3rd & Perfect 5th" },
	{ options: ["b2", "4", "b6"], levelName: "Minor 2nd, Perfect 4th & Minor 6th" },
	{ options: ["2", "b5", "6"], levelName: "Major 2nd, Tritone & Major 6th" },
	{ options: ["b3", "5", "b7"], levelName: "Minor 3rd, Perfect 5th & Minor 7th" },
	{ options: ["3", "b6", "7"], levelName: "Major 3rd, Minor 6th & Major 7th" },
	{ options: ["4", "6", "1"], levelName: "Root, Perfect 4th & Major 6th" },
];
const threeOptionsPart3Levels = [
	{ options: ["b5", "b7", "b2"], levelName: "Minor 2nd, Tritone, Minor 7th" },
	{ options: ["5", "7", "2"], levelName: "Major 2nd, Perfect 5th & Minor 7th" },
	{ options: ["b6", "1", "b3"], levelName: "Root, Minor 3rd & Minor 6th" },
	{ options: ["6", "b2", "3"], levelName: "Major 6th, Minor 2nd & Major 3rd" },
	{ options: ["b7", "2", "4"], levelName: "Minor 7th, Major 2nd & Perfect 4th" },
	{ options: ["7", "b3", "b5"], levelName: "Minor 3rd, Perfect 5th & Major 7th" },
];
const lowContrastPart1Levels = [
	{ options: ["1", "b2"], levelName: "Root & Minor 2nd" },
	{ options: ["b2", "2"], levelName: "Minor 2nd & Major 2nd" },
	{ options: ["2", "b3"], levelName: "Major 2nd & Minor 3rd" },
	{ options: ["b3", "3"], levelName: "Minor 3rd & Major 3rd" },
	{ options: ["3", "4"], levelName: "Major 3rd & Perfect 4th" },
	{ options: ["4", "b5"], levelName: "Perfect 4th & Tritone" },
	{ options: ["b5", "5"], levelName: "Tritone & Perfect 5th" },
];
const lowContrastPart2Levels = [
	{ options: ["5", "b6"], levelName: "Perfect 5th & Minor 6th" },
	{ options: ["b6", "6"], levelName: "Minor 6th & Major 6th" },
	{ options: ["6", "b7"], levelName: "Major 6th & Minor 7th" },
	{ options: ["b7", "7"], levelName: "Minor 7th & Major 7th" },
	{ options: ["7", "1"], levelName: "Root & Major 7th" },
	{ options: ["5", "1"], levelName: "Root & Perfect 5th" },
];
const threeOptionsPart4Levels = [
	{ options: ["1", "b3", "b5"], levelName: "Root, Minor 3rd & Tritone" },
	{ options: ["b2", "3", "5"], levelName: "Minor 2nd, Major 3rd & Perfect 5th" },
	{ options: ["2", "4", "b6"], levelName: "Major 2nd, Perfect 4th & Minor 6th" },
	{ options: ["b3", "b5", "6"], levelName: "Minor 3rd, Tritone & Major 6th" },
	{ options: ["3", "5", "b7"], levelName: "Major 3rd, Perfect 5th & Minor 7th" },
	{ options: ["4", "b6", "7"], levelName: "Perfect 4th, Minor 6th & Major 7th" },
];
const threeOptionsPart5Levels = [
	{ options: ["b5", "6", "1"], levelName: "Root, Tritone & Major 6th" },
	{ options: ["5", "b7", "b2"], levelName: "Minor 2nd, Perfect 5th & Minor 7th " },
	{ options: ["b6", "7", "2"], levelName: "Major 2nd, Minor 6th & Major 7th" },
	{ options: ["6", "1", "b3"], levelName: "Root, Minor 3rd & Major 6th" },
	{ options: ["b7", "b2", "3"], levelName: "Major 2nd, Minor 7th & Major 3rd" },
	{ options: ["7", "2", "4"], levelName: "Major 2nd, Perfect 4th & Major 7th" },
];
const threeOptionsPart6Levels = [
	{ options: ["1", "2", "3"], levelName: "Root, Major 2nd & Major 3rd" },
	{ options: ["b2", "b3", "4"], levelName: "Minor 2nd, Minor 3rd & Perfect 4th" },
	{ options: ["2", "3", "b5"], levelName: "Major 2nd, Major 3rd & Tritone" },
	{ options: ["b3", "4", "5"], levelName: "Minor 3rd, Perfect 4th, & Perfect 5th" },
	{ options: ["3", "b5", "b6"], levelName: "Major 3rd, Tritone & Minor 6th" },
	{ options: ["4", "5", "6"], levelName: "Perfect 4th, Perfect 5th & Major 6th" },
];
const threeOptionsPart7Levels = [
	{ options: ["b5", "b6", "b7"], levelName: "Tritone, Minor 6th & Minor 7th" },
	{ options: ["5", "6", "7"], levelName: "Perfect 5th, Major 6th & Major 7th" },
	{ options: ["b6", "b7", "1"], levelName: "Root, Minor 6th & Minor 7th" },
	{ options: ["6", "7", "b2"], levelName: "Minor 2nd, Major 6th & Major 7th" },
	{ options: ["b7", "1", "2"], levelName: "Root, Major 2nd & Minor 7th" },
	{ options: ["7", "b2", "b3"], levelName: "Minor 2nd, Minor 3rd & Major 7th" },
];
const fourOptionsPart1Levels = [
	{ options: ["5", "6", "7", "b2"], levelName: "Minor 2nd, Perfect 5th, Major 6th & Major 7th" },
	{ options: ["b6", "b7", "1", "2"], levelName: "Root, Major 2nd, Minor 6th & Minor 7th" },
	{ options: ["6", "7", "b2", "b3"], levelName: "Minor 2nd, Minor 3rd, Major 6th & Major 7th" },
	{ options: ["b7", "1", "2", "3"], levelName: "Root, Major 2nd, Major 3rd & Minor 7th" },
	{ options: ["7", "b2", "b3", "4"], levelName: "Minor 2nd, Minor 3rd, Perfect 4th & Major 7th" },
	{ options: ["1", "2", "3", "b5"], levelName: "Root, Major 2nd, Major 3rd & Tritone" },
];
const fourOptionsPart2Levels = [
	{ options: ["b2", "b3", "4", "5"], levelName: "Minor 2nd, Minor 3rd, Perfect 4th & Perfect 5th" },
	{ options: ["2", "3", "b5", "b6"], levelName: "Major 2nd, Major 3rd, Tritone & Minor 6th" },
	{ options: ["b3", "4", "5", "6"], levelName: "Minor 3rd, Perfect 4th, Perfect 5th & Major 6th" },
	{ options: ["3", "b5", "b6", "b7"], levelName: "Major 3rd, Tritone, Minor 6th & Minor 7th" },
	{ options: ["4", "5", "6", "7"], levelName: "Perfect 4th, Perfect 5th, Major 6th & Major 7th" },
	{ options: ["b5", "b6", "b7", "1"], levelName: "Root, Tritone, Minor 6th & Minor 7th" },
];
const threeOptionsPart8Levels = [
	{ options: ["1", "b2", "2"], levelName: "Starting from 1" },
	{ options: ["b2", "2", "b3"], levelName: "Starting from ♭2" },
	{ options: ["2", "b3", "3"], levelName: "Starting from 2" },
	{ options: ["b3", "3", "4"], levelName: "Starting from ♭3" },
	{ options: ["3", "4", "b5"], levelName: "Starting from 3" },
	{ options: ["4", "b5", "5"], levelName: "Starting from 4" },
];
const threeOptionsPart9Levels = [
	{ options: ["b5", "5", "b6"], levelName: "Starting from ♭5" },
	{ options: ["5", "b6", "6"], levelName: "Starting from 5" },
	{ options: ["b6", "6", "b7"], levelName: "Starting from ♭6" },
	{ options: ["6", "b7", "7"], levelName: "Starting from 6" },
	{ options: ["b7", "7", "1"], levelName: "Starting from ♭7" },
	{ options: ["7", "1", "b2"], levelName: "Starting from 7" },
];
const fourOptionsPart3Levels = [
	{ options: ["1", "b2", "2", "b3"], levelName: "Starting from 1" },
	{ options: ["b2", "2", "b3", "3"], levelName: "Starting from ♭2" },
	{ options: ["2", "b3", "3", "4"], levelName: "Starting from 2" },
	{ options: ["b3", "3", "4", "b5"], levelName: "Starting from ♭3" },
	{ options: ["3", "4", "b5", "5"], levelName: "Starting from 3" },
	{ options: ["4", "b5", "5", "b6"], levelName: "Starting from 4" },
];
const fourOptionsPart4Levels = [
	{ options: ["b5", "5", "b6", "6"], levelName: "Starting from ♭5" },
	{ options: ["5", "b6", "6", "b7"], levelName: "Starting from 5" },
	{ options: ["b6", "6", "b7", "7"], levelName: "Starting from ♭6" },
	{ options: ["6", "b7", "7", "1"], levelName: "Starting from 6" },
	{ options: ["b7", "7", "1", "b2"], levelName: "Starting from ♭7" },
	{ options: ["7", "1", "b2", "2"], levelName: "Starting from 7" },
];
const fiveOptionsPart1Levels = [
	{ options: ["1", "b2", "2", "b3", "3"], levelName: "Starting from 1" },
	{ options: ["b2", "2", "b3", "3", "4"], levelName: "Starting from ♭2" },
	{ options: ["2", "b3", "3", "4", "b5"], levelName: "Starting from 2" },
	{ options: ["b3", "3", "4", "b5", "5"], levelName: "Starting from ♭3" },
	{ options: ["3", "4", "b5", "5", "b6"], levelName: "Starting from 3" },
	{ options: ["4", "b5", "5", "b6", "6"], levelName: "Starting from 4" },
];
const fiveOptionsPart2Levels = [
	{ options: ["b5", "5", "b6", "6", "b7"], levelName: "Starting from ♭5" },
	{ options: ["5", "b6", "6", "b7", "7"], levelName: "Starting from 5" },
	{ options: ["b6", "6", "b7", "7", "1"], levelName: "Starting from ♭6" },
	{ options: ["6", "b7", "7", "1", "b2"], levelName: "Starting from 6" },
	{ options: ["b7", "7", "1", "b2", "2"], levelName: "Starting from ♭7" },
	{ options: ["7", "1", "b2", "2", "b3"], levelName: "Starting from 7" },
];
const sixOptionsPart1Levels = [
	{ options: ["1", "b2", "2", "b3", "3", "4"], levelName: "Starting from 1" },
	{ options: ["b2", "2", "b3", "3", "4", "b5"], levelName: "Starting from ♭2" },
	{ options: ["2", "b3", "3", "4", "b5", "5"], levelName: "Starting from 2" },
	{ options: ["b3", "3", "4", "b5", "5", "b6"], levelName: "Starting from ♭3" },
	{ options: ["3", "4", "b5", "5", "b6", "6"], levelName: "Starting from 3" },
	{ options: ["4", "b5", "5", "b6", "6", "b7"], levelName: "Starting from 4" },
];
const sixOptionsPart2Levels = [
	{ options: ["b5", "5", "b6", "6", "b7", "7"], levelName: "Starting from ♭5" },
	{ options: ["5", "b6", "6", "b7", "7", "1"], levelName: "Starting from 5" },
	{ options: ["b6", "6", "b7", "7", "1", "b2"], levelName: "Starting from ♭6" },
	{ options: ["6", "b7", "7", "1", "b2", "2"], levelName: "Starting from 6" },
	{ options: ["b7", "7", "1", "b2", "2", "b3"], levelName: "Starting from ♭7" },
	{ options: ["7", "1", "b2", "2", "b3", "3"], levelName: "Starting from 7" },
];
const sevenOptionsPart1Levels = [
	{ options: ["1", "b2", "2", "b3", "3", "4", "b5"], levelName: "Starting from 1" },
	{ options: ["b2", "2", "b3", "3", "4", "b5", "5"], levelName: "Starting from ♭2" },
	{ options: ["2", "b3", "3", "4", "b5", "5", "b6"], levelName: "Starting from 2" },
	{ options: ["b3", "3", "4", "b5", "5", "b6", "6"], levelName: "Starting from ♭3" },
	{ options: ["3", "4", "b5", "5", "b6", "6", "b7"], levelName: "Starting from 3" },
	{ options: ["4", "b5", "5", "b6", "6", "b7", "7"], levelName: "Starting from 4" },
];
const sevenOptionsPart2Levels = [
	{ options: ["b5", "5", "b6", "6", "b7", "7", "1"], levelName: "Starting from ♭5" },
	{ options: ["5", "b6", "6", "b7", "7", "1", "b2"], levelName: "Starting from 5" },
	{ options: ["b6", "6", "b7", "7", "1", "b2", "2"], levelName: "Starting from ♭6" },
	{ options: ["6", "b7", "7", "1", "b2", "2", "b3"], levelName: "Starting from 6" },
	{ options: ["b7", "7", "1", "b2", "2", "b3", "3"], levelName: "Starting from ♭7" },
	{ options: ["7", "1", "b2", "2", "b3", "3", "4"], levelName: "Starting from 7" },
];
const eightOptionsPart1Levels = [
	{ options: ["1", "b2", "2", "b3", "3", "4", "b5", "5"], levelName: "Starting from 1" },
	{ options: ["b2", "2", "b3", "3", "4", "b5", "5", "b6"], levelName: "Starting from ♭2" },
	{ options: ["2", "b3", "3", "4", "b5", "5", "b6", "6"], levelName: "Starting from 2" },
	{ options: ["b3", "3", "4", "b5", "5", "b6", "6", "b7"], levelName: "Starting from ♭3" },
	{ options: ["3", "4", "b5", "5", "b6", "6", "b7", "7"], levelName: "Starting from 3" },
	{ options: ["4", "b5", "5", "b6", "6", "b7", "7", "1"], levelName: "Starting from 4" },
];
const eightOptionsPart2Levels = [
	{ options: ["b5", "5", "b6", "6", "b7", "7", "1", "b2"], levelName: "Starting from ♭5" },
	{ options: ["5", "b6", "6", "b7", "7", "1", "b2", "2"], levelName: "Starting from 5" },
	{ options: ["b6", "6", "b7", "7", "1", "b2", "2", "b3"], levelName: "Starting from ♭6" },
	{ options: ["6", "b7", "7", "1", "b2", "2", "b3", "3"], levelName: "Starting from 6" },
	{ options: ["b7", "7", "1", "b2", "2", "b3", "3", "4"], levelName: "Starting from ♭7" },
	{ options: ["7", "1", "b2", "2", "b3", "3", "4", "b5"], levelName: "Starting from 7" },
];
const nineOptionsPart1Levels = [
	{ options: ["1", "b2", "2", "b3", "3", "4", "b5", "5", "b6"], levelName: "Starting from 1" },
	{ options: ["b2", "2", "b3", "3", "4", "b5", "5", "b6", "6"], levelName: "Starting from ♭2" },
	{ options: ["2", "b3", "3", "4", "b5", "5", "b6", "6", "b7"], levelName: "Starting from 2" },
	{ options: ["b3", "3", "4", "b5", "5", "b6", "6", "b7", "7"], levelName: "Starting from ♭3" },
	{ options: ["3", "4", "b5", "5", "b6", "6", "b7", "7", "1"], levelName: "Starting from 3" },
	{ options: ["4", "b5", "5", "b6", "6", "b7", "7", "1", "b2"], levelName: "Starting from 4" },
];
const nineOptionsPart2Levels = [
	{ options: ["b5", "5", "b6", "6", "b7", "7", "1", "b2", "2"], levelName: "Starting from ♭5" },
	{ options: ["5", "b6", "6", "b7", "7", "1", "b2", "2", "b3"], levelName: "Starting from 5" },
	{ options: ["b6", "6", "b7", "7", "1", "b2", "2", "b3", "3"], levelName: "Starting from ♭6" },
	{ options: ["6", "b7", "7", "1", "b2", "2", "b3", "3", "4"], levelName: "Starting from 6" },
	{ options: ["b7", "7", "1", "b2", "2", "b3", "3", "4", "b5"], levelName: "Starting from ♭7" },
	{ options: ["7", "1", "b2", "2", "b3", "3", "4", "b5", "5"], levelName: "Starting from 7" },
];
const tenOptionsPart1Levels = [
	{ options: ["1", "b2", "2", "b3", "3", "4", "b5", "5", "b6", "6"], levelName: "Starting from 1" },
	{ options: ["b2", "2", "b3", "3", "4", "b5", "5", "b6", "6", "b7"], levelName: "Starting from ♭2" },
	{ options: ["2", "b3", "3", "4", "b5", "5", "b6", "6", "b7", "7"], levelName: "Starting from 2" },
	{ options: ["b3", "3", "4", "b5", "5", "b6", "6", "b7", "7", "1"], levelName: "Starting from ♭3" },
	{ options: ["3", "4", "b5", "5", "b6", "6", "b7", "7", "1", "b2"], levelName: "Starting from 3" },
	{ options: ["4", "b5", "5", "b6", "6", "b7", "7", "1", "b2", "2"], levelName: "Starting from 4" },
];
const tenOptionsPart2Levels = [
	{ options: ["b5", "5", "b6", "6", "b7", "7", "1", "b2", "2", "b3"], levelName: "Starting from ♭5" },
	{ options: ["5", "b6", "6", "b7", "7", "1", "b2", "2", "b3", "3"], levelName: "Starting from 5" },
	{ options: ["b6", "6", "b7", "7", "1", "b2", "2", "b3", "3", "4"], levelName: "Starting from ♭6" },
	{ options: ["6", "b7", "7", "1", "b2", "2", "b3", "3", "4", "b5"], levelName: "Starting from 6" },
	{ options: ["b7", "7", "1", "b2", "2", "b3", "3", "4", "b5", "5"], levelName: "Starting from ♭7" },
	{ options: ["7", "1", "b2", "2", "b3", "3", "4", "b5", "5", "b6"], levelName: "Starting from 7" },
];
const elevenOptionsPart1Levels = [
	{ options: ["1", "b2", "2", "b3", "3", "4", "b5", "5", "b6", "6", "b7"], levelName: "Starting from 1" },
	{ options: ["b2", "2", "b3", "3", "4", "b5", "5", "b6", "6", "b7", "7"], levelName: "Starting from ♭2" },
	{ options: ["2", "b3", "3", "4", "b5", "5", "b6", "6", "b7", "7", "1"], levelName: "Starting from 2" },
	{ options: ["b3", "3", "4", "b5", "5", "b6", "6", "b7", "7", "1", "b2"], levelName: "Starting from ♭3" },
	{ options: ["3", "4", "b5", "5", "b6", "6", "b7", "7", "1", "b2", "2"], levelName: "Starting from 3" },
	{ options: ["4", "b5", "5", "b6", "6", "b7", "7", "1", "b2", "2", "b3"], levelName: "Starting from 4" },
];
const elevenOptionsPart2Levels = [
	{ options: ["b5", "5", "b6", "6", "b7", "7", "1", "b2", "2", "b3", "3"], levelName: "Starting from ♭5" },
	{ options: ["5", "b6", "6", "b7", "7", "1", "b2", "2", "b3", "3", "4"], levelName: "Starting from 5" },
	{ options: ["b6", "6", "b7", "7", "1", "b2", "2", "b3", "3", "4", "b5"], levelName: "Starting from ♭6" },
	{ options: ["6", "b7", "7", "1", "b2", "2", "b3", "3", "4", "b5", "5"], levelName: "Starting from 6" },
	{ options: ["b7", "7", "1", "b2", "2", "b3", "3", "4", "b5", "5", "b6"], levelName: "Starting from ♭7" },
	{ options: ["7", "1", "b2", "2", "b3", "3", "4", "b5", "5", "b6", "6"], levelName: "Starting from 7" },
];
const bossModeLevels = [
	{ options: ["b2", "2", "b3", "3", "4", "b5", "5", "b6", "6", "b7", "7", "1"], levelName: "All intervals" },
];

export const HARMONIC_CONTEXT_CHROMATIC_SECTIONS = [
	createSection("High contrast", highContrastLevels),
	createSection("Related intervals", relatedIntervalsLevels),
	createSection("Three options 1", threeOptionsPart1Levels),
	createSection("Three options 2", threeOptionsPart2Levels),
	createSection("Three options 3", threeOptionsPart3Levels),
	createSection("Low contrast 1", lowContrastPart1Levels),
	createSection("Low contrast 2", lowContrastPart2Levels),
	createSection("Three options 4", threeOptionsPart4Levels),
	createSection("Three options 5", threeOptionsPart5Levels),
	createSection("Three options 6", threeOptionsPart6Levels),
	createSection("Three options 7", threeOptionsPart7Levels),
	createSection("Four options 1", fourOptionsPart1Levels),
	createSection("Four options 2", fourOptionsPart2Levels),
	createSection("Three options 8", threeOptionsPart8Levels),
	createSection("Three options 9", threeOptionsPart9Levels),
	createSection("Four options 3", fourOptionsPart3Levels),
	createSection("Four options 4", fourOptionsPart4Levels),
	createSection("Five options 1", fiveOptionsPart1Levels),
	createSection("Five options 2", fiveOptionsPart2Levels),
	createSection("Six options 1", sixOptionsPart1Levels),
	createSection("Six options 2", sixOptionsPart2Levels),
	createSection("Seven options 1", sevenOptionsPart1Levels),
	createSection("Seven options 2", sevenOptionsPart2Levels),
	createSection("Eight options 1", eightOptionsPart1Levels),
	createSection("Eight options 2", eightOptionsPart2Levels),
	createSection("Nine options 1", nineOptionsPart1Levels),
	createSection("Nine options 2", nineOptionsPart2Levels),
	createSection("Ten options 1", tenOptionsPart1Levels),
	createSection("Ten options 2", tenOptionsPart2Levels),
	createSection("Eleven options 1", elevenOptionsPart1Levels),
	createSection("Eleven options 2", elevenOptionsPart2Levels),
	createSection("Boss Mode", bossModeLevels),
];
