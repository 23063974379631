import React from "react";

export default function Loading(): JSX.Element {
	return (
		<div className="loading">
			<div className="ring">
				<div className="ring2">
					<div className="ring3"></div>
				</div>
			</div>
		</div>
	);
}
