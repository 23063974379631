import React, { useEffect, useState } from "react";
import { isSafariBrowser } from "../../utils/deviceCheck";
import { Line } from "react-chartjs-2";
import { useTeacherLoggedIn } from "../../context/UserContext";
import { parseDate } from "../../utils/dates";
import { parseISO, startOfISOWeek, formatISO } from "date-fns";

const timeframes = {
	lastWeek: 7,
	lastMonth: 30,
	lastSixMonths: 182,
	lastYear: 365,
	allTime: 10000,
};

export default function UsageStats(): JSX.Element {
	const { teacher, classData } = useTeacherLoggedIn();
	const [selectedTimeframe, setSelectedTimeframe] = useState<keyof typeof timeframes>("lastWeek");
	const minutes = 60;

	const allStudents = classData?.flatMap((classData) => classData.Students) || [];
	const studentCount = allStudents.length;
	const lastWeekPracticeTime = allStudents.reduce((acc, student) => acc + student.WeeklyStats.PracticeTime, 0);
	const lastMonthPracticeTime = allStudents.reduce((acc, student) => acc + student.MonthlyStats.PracticeTime, 0);

	const [chartData, setChartData] = useState<any>(null);

	useEffect(() => {
		if (!classData) return;

		const allStudents = classData.flatMap((classData) => classData.Students) || [];
		const allDailyStats = allStudents.flatMap((student) => student.DailyStats);

		// Group the DailyStats by date
		const groupedByDate: { [key: string]: { totalPracticeTime: number; userCount: number } } = {};

		allDailyStats.forEach((stat) => {
			const dateKey = parseDate(stat.Date).toISOString().split("T")[0];
			if (!groupedByDate[dateKey]) {
				groupedByDate[dateKey] = { totalPracticeTime: 0, userCount: 0 };
			}
			groupedByDate[dateKey].totalPracticeTime += Math.round(stat.PracticeTime / minutes);
			groupedByDate[dateKey].userCount += 1;
		});

		// Prepare data arrays for the chart
		const dates = Object.keys(groupedByDate);
		const totalPracticeTimes = dates.map((date) => groupedByDate[date].totalPracticeTime);
		const userCounts = dates.map((date) => groupedByDate[date].userCount);
		const practiceTimePerUser = dates.map(
			(date) => groupedByDate[date].totalPracticeTime / groupedByDate[date].userCount
		);

		// Set the chart data
		setChartData({
			dates,
			totalPracticeTimes,
			userCounts,
			practiceTimePerUser,
		});
	}, [classData]);

	if (!chartData) return <div>Loading...</div>;

	// Helper to group data by weeks
	const groupByWeeks = (dates: string[], data: number[]) => {
		const groupedData: { [key: string]: number } = {};

		dates.forEach((date, index) => {
			// Get the start of the week (e.g., Monday) for each date
			const startOfWeek = startOfISOWeek(parseISO(date));
			const weekKey = formatISO(startOfWeek, { representation: "date" }); // This gives us the ISO string for the start of the week

			if (!groupedData[weekKey]) {
				groupedData[weekKey] = 0;
			}
			// Sum up the data points for the same week
			groupedData[weekKey] += data[index];
		});

		return {
			dates: Object.keys(groupedData), // List of week start dates
			data: Object.values(groupedData), // Aggregated data by week
		};
	};

	// Function to filter and process data based on selected timeframe
	const filterDataByTimeframe = (days: number) => {
		const now = new Date();
		const filteredIndexes = chartData.dates
			.map((date: string, index: number) => {
				const dataDate = new Date(date);
				const diffInDays = (now.getTime() - dataDate.getTime()) / (1000 * 60 * 60 * 24);
				return diffInDays <= days ? index : null;
			})
			.filter((index: number | null) => index !== null) as number[];

		let filteredDates = filteredIndexes.map((index) => chartData.dates[index]);
		let filteredTotalPracticeTimes = filteredIndexes.map((index) => chartData.totalPracticeTimes[index]);
		let filteredUserCounts = filteredIndexes.map((index) => chartData.userCounts[index]);

		// Group data by weeks for selected timeframes longer than 6 months
		if (days >= timeframes.lastSixMonths) {
			const groupedTotal = groupByWeeks(filteredDates, filteredTotalPracticeTimes);
			filteredDates = groupedTotal.dates;
			filteredTotalPracticeTimes = groupedTotal.data;

			const groupedUsers = groupByWeeks(filteredDates, filteredUserCounts);
			filteredUserCounts = groupedUsers.data;
		}

		// Reverse the data for rendering
		filteredDates.reverse();
		filteredTotalPracticeTimes.reverse();
		filteredUserCounts.reverse();

		return {
			dates: filteredDates,
			totalPracticeTimes: filteredTotalPracticeTimes,
			userCounts: filteredUserCounts,
		};
	};

	// Filtered data based on the selected timeframe
	const filteredData = filterDataByTimeframe(timeframes[selectedTimeframe]);

	return (
		<div className={`basicCard loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
			<h1>Usage Stats</h1>

			{/* Timeframe Buttons */}
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "space-between",
					margin: "5px 0 0 0",
				}}
			>
				{Object.keys(timeframes).map((timeframeKey) => (
					<h3
						key={timeframeKey}
						onClick={() => setSelectedTimeframe(timeframeKey as keyof typeof timeframes)}
						style={{
							marginRight: "10px",
							padding: "10px 20px",
							backgroundColor: selectedTimeframe === timeframeKey ? "#4CAF50" : "#f0f0f0",
							color: selectedTimeframe === timeframeKey ? "#fff" : "#000",
							border: "none",
							borderRadius: "1em",
							cursor: "pointer",
						}}
					>
						{timeframeKey.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
					</h3>
				))}
			</div>

			{/* Total Practice Time Chart */}
			<div style={{ height: "400px", marginBottom: "50px" }}>
				<Line
					data={{
						labels: filteredData.dates,
						datasets: [
							{
								label: "Total Practice Time",
								data: filteredData.totalPracticeTimes,
								borderColor: "rgba(75, 192, 192, 1)",
								backgroundColor: "rgba(75, 192, 192, 0.2)",
								fill: false,
							},
						],
					}}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							legend: { display: false },
							title: { display: true, text: "Practice time" },
						},
						scales: {
							x: { ticks: { autoSkip: true, maxTicksLimit: 10 } },
						},
					}}
				/>
			</div>

			{/* Total Users Chart */}
			<div style={{ height: "400px", marginBottom: "50px" }}>
				<Line
					data={{
						labels: filteredData.dates,
						datasets: [
							{
								label: "Active Users",
								data: filteredData.userCounts,
								borderColor: "rgba(153, 102, 255, 1)",
								backgroundColor: "rgba(153, 102, 255, 0.2)",
								fill: false,
							},
						],
					}}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							legend: { display: false },
							title: { display: true, text: "Active users" },
						},
						scales: {
							x: { ticks: { autoSkip: true, maxTicksLimit: 10 } },
						},
					}}
				/>
			</div>

			<div className="overviewTable">
				<table>
					<tbody>
						<tr>
							<td style={{ width: "70%" }}>Totals accounts:</td>
							<td>{studentCount}</td>
						</tr>
						<tr>
							<td>Avg daily practice time past 7 dags:</td>
							<td>{Math.round(lastWeekPracticeTime / minutes)}</td>
						</tr>
						<tr>
							<td>Avg daily practice time past 30 dags:</td>
							<td>{Math.round(lastMonthPracticeTime / minutes)}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}
