import React, { useMemo, useState } from "react";
import { Radar } from "react-chartjs-2";
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from "chart.js";
import * as e from "../../data/enums";
import { customLegend } from "./lineChartUtils";
import { spaceBeforeCapitals } from "../../utils/sharedUtils";
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const colorSettings = {
	studentProgress: "100, 180, 180",
	targets: "25, 100, 140",
	avgProgress: "140, 120, 200",
};

const options = {
	scales: {
		r: {
			angleLines: {
				display: false,
			},
			ticks: {
				beginAtZero: true,
				backdropColor: "transparent",
				maxTicksLimit: 6,
			},
			min: 0,
			// pointLabels: {
			// 	display: false, // Hide point labels
			// },
		},
	},
	plugins: {
		tooltip: {
			enabled: true,
			callbacks: {
				label: function (context: any) {
					// Customize tooltip label
					return `${context.dataset.label}: ${context.raw}`;
				},
				title: function (tooltipItems: any) {
					// Display label name on hover
					return tooltipItems[0].label;
				},
			},
		},
		legend: {
			display: false,
		},
	},
};

export default function SpiderChart({
	studentProgress,
	targets,
	avgProgress,
}: {
	studentProgress: number[];
	targets: number[];
	avgProgress: number[];
}) {
	const { labels, datasets } = useChartData(studentProgress, targets, avgProgress);
	const [visibility, setVisibility] = useState(new Array(datasets.length).fill(true));
	const toggleDataset = (index: number) => {
		const newVisibility = visibility.slice();
		newVisibility[index] = !newVisibility[index];
		setVisibility(newVisibility);
	};

	return (
		<div className="spiderChartContainer">
			<div className="spiderChart">
				<Radar data={{ labels, datasets: datasets.filter((_, i) => visibility[i]) }} options={options} />
				{customLegend(datasets, visibility, toggleDataset, `33%`)}
			</div>
		</div>
	);
}

const useChartData = (studentProgress: number[], targets: number[], avgProgress: number[]) =>
	useMemo(() => {
		const labels = Object.values(e.Modules).map((label) => spaceBeforeCapitals(label)); // Use map to update each label

		return {
			labels: labels,
			datasets: [
				createDataset("Progress", studentProgress, colorSettings.studentProgress),
				createDataset("Target", targets, colorSettings.targets),
				createDataset("Class average", avgProgress, colorSettings.avgProgress),
			],
		};
	}, [studentProgress, targets, avgProgress]);

function createDataset(label: string, data: number[], color: string) {
	return {
		label,
		data,
		borderColor: `rgb(${color})`,
		backgroundColor: `rgba(${color}, 0.5)`,
		pointBackgroundColor: `rgb(${color})`,
		pointBorderColor: `rgb(${color})`,
		hidden: false,
		fill: true,
		pointHoverBackgroundColor: "#fff",
		pointHoverBorderColor: `rgb(${color})`,
	};
}
