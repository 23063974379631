import axios from "axios";
import * as t from "../../data/types";
import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function createNewTarget(targetData: t.Target) {
	console.log("createNewTarget called");

	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		await axios.put("https://auris-db-backend.vercel.app/createNewTarget", targetData, {
			headers: {
				Authorization: idToken,
			},
		});
	} catch (error: any) {
		if (targetData.ClassID !== "VgMzrqHOOjOMLusgmzDD") Sentry.captureException(error);
		throw error;
	}
}
