import React from "react";
import { useTeacherLoggedIn } from "../context/UserContext";
import * as e from "../data/enums";
import * as image from "../data/constants/images";

export function CommonModal({
	showModal,
	setShowModal,
	children,
}: {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	children: React.ReactNode;
}) {
	const { scrollContentRef } = useTeacherLoggedIn();
	const handleOutsideClick = () => setShowModal(false);
	const handleContentClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

	return (
		<div className={`modal ${showModal ? "show" : ""}`} onClick={handleOutsideClick}>
			<div ref={scrollContentRef} className="modal-content" onClick={handleContentClick}>
				<h2 className="closeModal" onClick={handleOutsideClick}>
					&times;
				</h2>
				{children}
			</div>
		</div>
	);
}

export function ModalNavigation({
	currentIndex,
	totalItems,
	onNavigate,
	navigationLabel,
}: {
	currentIndex: number;
	totalItems: number;
	onNavigate: (direction: "left" | "right") => void;
	navigationLabel: React.ReactNode;
}) {
	return (
		<div className="modalNavigation">
			<img
				onClick={() => onNavigate("left")}
				className={currentIndex === 0 ? "arrow arrowDisabled" : "arrow"}
				src={image.ICONS[e.Icons.ARROW_LEFT]}
				alt="arrow left"
			/>
			{navigationLabel}
			<img
				onClick={() => onNavigate("right")}
				className={currentIndex === totalItems - 1 ? "arrow arrowDisabled" : "arrow"}
				src={image.ICONS[e.Icons.ARROW_RIGHT]}
				alt="arrow right"
			/>
		</div>
	);
}
