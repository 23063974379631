import React, { useState } from "react";
import * as t from "../../data/types";
import * as e from "../../data/enums";
import * as c from "../../data/constants/constants";
import * as image from "../../data/constants/images";
import { createExerciseID, getUserProgressLevel, spaceBeforeCapitals } from "../../utils/sharedUtils";

export default function ProgressTable({
	user,
	studentProgress,
}: {
	user: t.User;
	studentProgress: number[];
}): JSX.Element {
	const modules = Object.values(e.Modules) as e.Modules[];
	const [selectedModule, setSelectedModule] = useState<e.Modules | null>(null);

	const toggleModule = (module: e.Modules) => setSelectedModule((prev) => (prev === module ? null : module));

	return (
		<div className="overviewTable">
			<table>
				<TableHeader />
				<tbody>
					{modules.map((module, index) => (
						<React.Fragment key={module}>
							<ModuleRow
								module={module}
								index={index}
								studentProgress={studentProgress}
								onClick={() => toggleModule(module)}
							/>
							{selectedModule === module && <ModuleProgress user={user} module={selectedModule} />}
						</React.Fragment>
					))}
				</tbody>
			</table>
		</div>
	);
}
function TableHeader() {
	return (
		<thead className="theadCSS theadCSSRounded">
			<tr>
				<th style={{ width: "3em" }}></th>
				<th>Module</th>
				<th className="alignRight"></th>
				<th style={{ width: "3em" }} className="alignRight">
					Progress
				</th>
			</tr>
		</thead>
	);
}

function ModuleRow({
	module,
	index,
	studentProgress,
	onClick,
}: {
	module: e.Modules;
	index: number;
	studentProgress: number[];
	onClick: () => void;
}) {
	return (
		<tr onClick={onClick} key={module}>
			<td>
				<img className="smallModuleImg" src={image.ICONS[module]} alt={module} />
			</td>
			<td>{spaceBeforeCapitals(module)}</td>
			<td style={{ width: "60%" }}>
				<div className="progressBar progressOne">
					<div className="progress" style={{ width: `${studentProgress[index]}%` }}></div>
				</div>
			</td>
			<td className="alignRight">{`${studentProgress[index].toFixed(1)}%`}</td>
		</tr>
	);
}

function ModuleProgress({ user, module }: { user: t.User; module: e.Modules }) {
	const difficulties = Object.keys(c.LEVELS[module]);
	const [selectedDifficulty, setSelectedDifficulty] = useState<string | null>(null);

	function showDifficultyProgress(difficulty: string) {
		setSelectedDifficulty((prevDifficulty) => (prevDifficulty === difficulty ? null : difficulty));
	}

	const progressMapping = difficulties.map((difficulty) => {
		const difficultyProgress = user.Progress.modules[module]?.difficulties?.[difficulty]?.DifficultyTotal || 0;
		return { difficulty, progress: difficultyProgress };
	});

	return (
		<>
			{progressMapping.map(({ difficulty, progress }) => (
				<React.Fragment key={difficulty}>
					<tr onClick={() => showDifficultyProgress(difficulty)}>
						<td></td>
						<td style={{ paddingLeft: "1em" }}>{difficulty}</td>
						<td style={{ width: "60%" }}>
							<div className="progressBar progressTwo">
								<div className="progress" style={{ width: `${progress || 0}%` }}></div>
							</div>
						</td>
						<td style={{ width: "5em" }}>{`${(progress || 0).toFixed(1)}%`}</td>
					</tr>
					{selectedDifficulty === difficulty && (
						<DifficultyProgress user={user} module={module} difficulty={difficulty} />
					)}
				</React.Fragment>
			))}
		</>
	);
}

function DifficultyProgress({ user, module, difficulty }: { user: t.User; module: e.Modules; difficulty: string }) {
	const sections = Object.keys(c.LEVELS[module][difficulty]);
	const [selectedSection, setSelectedSection] = useState<string | null>(null);

	function showSectionProgress(section: string) {
		setSelectedSection(section);
		if (selectedSection === section) setSelectedSection(null);
	}

	const progress = calculateSectionProgress(user, module, difficulty, sections);
	const sectionNames = sections.map((section) => c.LEVELS[module][difficulty][Number(section)].sectionName);
	return (
		<>
			{Array.from({ length: sections.length }, (_, index) => (
				<React.Fragment key={`${difficulty}-${sections[index]}`}>
					<tr onClick={() => showSectionProgress(sections[index])}>
						<td></td>
						<td style={{ paddingLeft: "2em" }}>{"Section " + index + ": " + sectionNames[index]}</td>
						<td style={{ width: "60%" }}>
							<div className="progressBar progressThree">
								<div className="progress" style={{ width: `${progress[index] || 0}%` }}></div>
							</div>
						</td>
						<td style={{ width: "5em" }}>{`${(progress[index] || 0).toFixed(1)}%`}</td>{" "}
					</tr>
					{selectedSection === sections[index] && (
						<SectionProgress user={user} section={index} difficulty={difficulty} module={module} />
					)}
				</React.Fragment>
			))}
		</>
	);
}

function SectionProgress({
	user,
	module,
	difficulty,
	section,
}: {
	user: t.User;
	module: e.Modules;
	difficulty: string;
	section: number;
}) {
	const levels = c.LEVELS[module][difficulty][section].levels;

	const ids = levels.map((level: any) => createExerciseID(module, difficulty, level.options));
	const progress = ids.map((id: string) => getUserProgressLevel(user, module, difficulty, id));

	return (
		<>
			{Array.from({ length: progress.length }, (_, index) => (
				<tr key={`${section}-${index}`}>
					<td></td>
					<td style={{ paddingLeft: "3em" }}>{levels[index].levelName}</td>
					<td style={{ width: "60%" }}>
						<div className="progressBar progressFour">
							<div className="progress" style={{ width: `${progress[index] || 0}%` }}></div>
						</div>
					</td>
					<td style={{ width: "5em" }}>{`${(progress[index]?.ExerciseTotal ?? 0).toFixed(1)}%`}</td>{" "}
				</tr>
			))}
		</>
	);
}

// Helper functions
function calculateSectionProgress(user: t.User, module: e.Modules, difficulty: string, sections: string[]): number[] {
	const progress: number[] = [];
	sections.forEach((section) => {
		const levels = c.LEVELS[module][difficulty][Number(section)].levels;
		const ids = levels.map((level: any) => createExerciseID(module, difficulty, level.options));
		const progressTotal = ids.reduce(
			(acc: any, id: any) => acc + (getUserProgressLevel(user, module, difficulty, id)?.ExerciseTotal || 0),
			0
		);
		progress.push(Math.round(progressTotal / ids.length));
	});
	return progress;
}
