// import * as t from "../../data/types";
import * as e from "../../data/enums";
import React, { useMemo } from "react";
import { convertDateToStringDayMonth } from "../../utils/dates";

export const useDateRange = (timeframe: e.TimeFrame, userStartDate: Date) =>
	useMemo(() => {
		const endDate = new Date();
		let startDate: Date;
		const tempDate = new Date();
		switch (timeframe) {
			case e.TimeFrame.WEEK:
				startDate = new Date(tempDate.setDate(endDate.getDate() - 7));
				break;
			case e.TimeFrame.MONTH:
				startDate = new Date(tempDate.setMonth(endDate.getMonth() - 1));
				break;
			case e.TimeFrame.YEAR:
				startDate = new Date(tempDate.setFullYear(endDate.getFullYear() - 1));
				break;
			case e.TimeFrame.ALL:
				startDate = userStartDate;
				break;
			default:
				startDate = new Date();
				break;
		}
		if (startDate < userStartDate) startDate = new Date(userStartDate);
		return { startDate, endDate };
	}, [timeframe, userStartDate]);

export function generateDateSeries(startDate: Date, endDate: Date): string[] {
	let currentDate = startDate;
	const dateArray = [];
	while (currentDate <= endDate) {
		dateArray.push(convertDateToStringDayMonth(currentDate));
		currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
	}
	return dateArray;
}

export function customLegend(
	datasets: any[],
	visibility: boolean[],
	toggleDataset: (index: number) => void,
	width: string
) {
	return (
		<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", margin: "5px 0 0 0" }}>
			{datasets.map((dataset, index) => (
				<div
					key={dataset.label}
					onClick={() => toggleDataset(index)}
					style={{
						width: width,
						cursor: "pointer",
						display: "flex",
						alignItems: "center",
						textAlign: "left",
						color: visibility[index] ? "inherit" : "#ccc",
						fontSize: 11,
						margin: "2px 0",
					}}
				>
					<span
						style={{
							backgroundColor: dataset.backgroundColor,
							width: 20,
							height: 20,
							display: "inline-block",
							marginRight: 10,
							verticalAlign: "middle",
						}}
					></span>
					{dataset.label}
				</div>
			))}
		</div>
	);
}
