import axios from "axios";
import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function removeStudentFromClassAPI(studentID: string) {
	console.log("removeStudentFromClassAPI called");
	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		await axios.put(
			"https://auris-db-backend.vercel.app/removeStudentFromClass",
			{
				StudentID: studentID,
			},
			{
				headers: {
					Authorization: idToken,
				},
			}
		);
	} catch (error) {
		Sentry.captureException(error);
		throw error;
	}
}
