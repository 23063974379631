import axios from "axios";
import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function fetchStudentAPI(email: string | null) {
	if (!email) return null;
	console.log("API CALL: fetchStudentAPI");

	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		if (!idToken) throw new Error("Id token is null");
		const response = await axios.get(`https://auris-db-backend.vercel.app/getStudentByEmail?email=${email}`, {
			headers: {
				Authorization: idToken,
			},
		});
		return response.data;
	} catch (error: any) {
		Sentry.captureException(error);
		console.log(error);
		// throw error;
	}
}
