import axios from "axios";
import * as t from "../../data/types";
import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";
import { ADMIN_EMAIL } from "../../data/constants/constants";

export default async function addClassAPI(className: string, teacher: t.Teacher) {
	console.log("addClassAPI called");

	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		await axios.post(
			"https://auris-db-backend.vercel.app/addClass",
			{
				ClassName: className,
				SchoolID: teacher.SchoolID,
				SchoolName: teacher.SchoolName,
				Teachers: [teacher.id],
				Ranking: [],
			},
			{
				headers: {
					Authorization: idToken,
				},
			}
		);
	} catch (error: any) {
		if (teacher?.Email !== ADMIN_EMAIL) Sentry.captureException(error);
		throw error;
	}
}
