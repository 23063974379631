import axios from "axios";
import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function createTeacherAPI(
	firstName: string,
	lastName: string,
	email: string,
	schoolName: string,
	schoolID: string
) {
	console.log("API CALL: createTeacherAPI");
	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		await axios.post(
			"https://auris-db-backend.vercel.app/addTeacher",
			{
				Email: email,
				FirstName: firstName,
				LastName: lastName,
				SchoolName: schoolName,
				SchoolID: schoolID,
			},
			{
				headers: {
					Authorization: idToken,
				},
			}
		);
	} catch (error) {
		Sentry.captureException(error);
		throw error;
	}
}
