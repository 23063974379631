import { createSection } from "./utils";
import * as e from "../../enums";
import { allSections } from "./progressionsMinor";

const commonLowestNote1st = [
	// {
	// 	options: [e.Degrees.i, e.DegreesFirstInversion.bVI],
	// 	levelName: e.Degrees.i + " " + e.DegreesFirstInversion.bVI,
	// },
	{
		options: [e.Degrees.i, e.Degrees.iiDim, e.DegreesFirstInversion.bVII],
		levelName: "Lowest note is a major 2nd interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bIII, e.DegreesFirstInversion.i],
		levelName: "Lowest note is a minor 3rd interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.iv, e.DegreesFirstInversion.iiDim],
		levelName: "Lowest note is a perfect 4th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.V, e.DegreesFirstInversion.bIII],
		levelName: "Lowest note is a perfect 5th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVI, e.DegreesFirstInversion.iv],
		levelName: "Lowest note is a minor 6th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVII, e.DegreesFirstInversion.V],
		levelName: "Lowest note is a minor 7th interval",
	},
];
const gettingFamiliar1st = [
	{
		options: [e.Degrees.i, e.Degrees.iiDim, e.DegreesFirstInversion.i, e.DegreesFirstInversion.iiDim],
		levelName: "i - iiDim",
	},
	{
		options: [e.Degrees.i, e.Degrees.bIII, e.DegreesFirstInversion.i, e.DegreesFirstInversion.bIII],
		levelName: "i - III",
	},
	{
		options: [e.Degrees.i, e.Degrees.iv, e.DegreesFirstInversion.i, e.DegreesFirstInversion.iv],
		levelName: "i - iv",
	},
	{
		options: [e.Degrees.i, e.Degrees.V, e.DegreesFirstInversion.i, e.DegreesFirstInversion.V],
		levelName: "I - V",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVI, e.DegreesFirstInversion.i, e.DegreesFirstInversion.bVI],
		levelName: "i - VI",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVII, e.DegreesFirstInversion.i, e.DegreesFirstInversion.bVII],
		levelName: "i - VII",
	},
];
const commonLowestNote1st2 = [
	{
		options: [e.Degrees.i, e.DegreesFirstInversion.bVI, e.Degrees.iv, e.DegreesFirstInversion.iiDim],
		levelName: "Lowest note is a root or perfect 4th interval",
	},

	{
		options: [
			e.Degrees.i,
			e.Degrees.iiDim,
			e.DegreesFirstInversion.bVII,
			e.Degrees.V,
			e.DegreesFirstInversion.bIII,
		],
		levelName: "Lowest note is a major 2nd or perfect 5th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bIII, e.DegreesFirstInversion.i, e.Degrees.bVI, e.DegreesFirstInversion.iv],
		levelName: "Lowest note is a minor 3rd or minor 6th interval",
	},

	{
		options: [
			e.Degrees.i,
			e.DegreesFirstInversion.bVI,
			e.Degrees.V,
			e.DegreesFirstInversion.bIII,
			e.Degrees.bVII,
			e.DegreesFirstInversion.V,
		],
		levelName: "Lowest note is a root, perfect 5th or minor 7th interval",
	},
];

const commonLowestNote2nd = [
	// {
	// 	options: [e.Degrees.i, e.DegreesSecondInversion.iv],
	// 	levelName: e.Degrees.i + e.DegreesSecondInversion.iv,
	// },
	{
		options: [e.Degrees.i, e.Degrees.iiDim, e.DegreesSecondInversion.V],
		levelName: "Lowest note is a major 2nd interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bIII, e.DegreesSecondInversion.bVI],
		levelName: "Lowest note is a major 3rd interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.iv, e.DegreesSecondInversion.bVII],
		levelName: "Lowest note is a perfect 4th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.V, e.DegreesSecondInversion.i],
		levelName: "Lowest note is a perfect 5th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVI, e.DegreesSecondInversion.iiDim],
		levelName: "Lowest note is a major 6th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVII, e.DegreesSecondInversion.bIII],
		levelName: "Lowest note is a major 7th interval",
	},
];
const gettingFamiliar2nd = [
	{
		options: [e.Degrees.i, e.Degrees.iiDim, e.DegreesSecondInversion.i, e.DegreesSecondInversion.iiDim],
		levelName: "i - iiDim",
	},
	{
		options: [e.Degrees.i, e.Degrees.bIII, e.DegreesSecondInversion.i, e.DegreesSecondInversion.bIII],
		levelName: "i - III",
	},
	{
		options: [e.Degrees.i, e.Degrees.iv, e.DegreesSecondInversion.i, e.DegreesSecondInversion.iv],
		levelName: "i - iv",
	},
	{
		options: [e.Degrees.i, e.Degrees.V, e.DegreesSecondInversion.i, e.DegreesSecondInversion.V],
		levelName: "I - V",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVI, e.DegreesSecondInversion.i, e.DegreesSecondInversion.bVI],
		levelName: "i - VI",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVII, e.DegreesSecondInversion.i, e.DegreesSecondInversion.bVII],
		levelName: "i - VII",
	},
];
const commonLowestNote2nd2 = [
	{
		options: [e.Degrees.i, e.DegreesSecondInversion.iv, e.Degrees.iv, e.DegreesSecondInversion.bVII],
		levelName: "Lowest note is a root or perfect 4th interval",
	},

	{
		options: [e.Degrees.i, e.Degrees.iiDim, e.DegreesSecondInversion.V, e.Degrees.V, e.DegreesSecondInversion.i],
		levelName: "Lowest note is a major 2nd or perfect 5th interval",
	},
	{
		options: [
			e.Degrees.i,
			e.Degrees.bIII,
			e.DegreesSecondInversion.bVI,
			e.Degrees.bVI,
			e.DegreesSecondInversion.iiDim,
		],
		levelName: "Lowest note is a major 3rd or major 6th interval",
	},
	{
		options: [
			e.Degrees.i,
			e.DegreesSecondInversion.iv,
			e.Degrees.V,
			e.DegreesSecondInversion.i,
			e.Degrees.bVII,
			e.DegreesSecondInversion.bIII,
		],
		levelName: "Lowest note is a root, perfect 5th or 7th interval",
	},
];
const gettingFamiliarAll = [
	{
		options: [
			e.Degrees.i,
			e.Degrees.iiDim,
			e.DegreesFirstInversion.i,
			e.DegreesFirstInversion.iiDim,
			e.DegreesSecondInversion.i,
			e.DegreesSecondInversion.iiDim,
		],
		levelName: "i - iiDim",
	},
	{
		options: [
			e.Degrees.i,
			e.Degrees.bIII,
			e.DegreesFirstInversion.i,
			e.DegreesFirstInversion.bIII,
			e.DegreesSecondInversion.i,
			e.DegreesSecondInversion.bIII,
		],
		levelName: "i - III",
	},
	{
		options: [
			e.Degrees.i,
			e.Degrees.iv,
			e.DegreesFirstInversion.i,
			e.DegreesFirstInversion.iv,
			e.DegreesSecondInversion.i,
			e.DegreesSecondInversion.iv,
		],
		levelName: "i - iv",
	},
	{
		options: [
			e.Degrees.i,
			e.Degrees.V,
			e.DegreesFirstInversion.i,
			e.DegreesFirstInversion.V,
			e.DegreesSecondInversion.i,
			e.DegreesSecondInversion.V,
		],
		levelName: "I - V",
	},
	{
		options: [
			e.Degrees.i,
			e.Degrees.bVI,
			e.DegreesFirstInversion.i,
			e.DegreesFirstInversion.bVI,
			e.DegreesSecondInversion.i,
			e.DegreesSecondInversion.bVI,
		],
		levelName: "i - VI",
	},
	{
		options: [
			e.Degrees.i,
			e.Degrees.bVII,
			e.DegreesFirstInversion.i,
			e.DegreesFirstInversion.bVII,
			e.DegreesSecondInversion.i,
			e.DegreesSecondInversion.bVII,
		],
		levelName: "i - VII",
	},
];
const commonLowestNoteAll = [
	{
		options: [e.Degrees.i, e.DegreesFirstInversion.bVI, e.DegreesSecondInversion.iv],
		levelName: "Lowest note is a root interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.iiDim, e.DegreesFirstInversion.bVII, e.DegreesSecondInversion.V],
		levelName: "Lowest note is a major 2nd interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bIII, e.DegreesFirstInversion.i, e.DegreesSecondInversion.bVI],
		levelName: "Lowest note is a major 3rd interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.iv, e.DegreesFirstInversion.iiDim, e.DegreesSecondInversion.bVII],
		levelName: "Lowest note is a perfect 4th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.V, e.DegreesFirstInversion.bIII, e.DegreesSecondInversion.i],
		levelName: "Lowest note is a perfect 5th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVI, e.DegreesFirstInversion.iv, e.DegreesSecondInversion.iiDim],
		levelName: "Lowest note is a major 6th interval",
	},
	{
		options: [e.Degrees.i, e.Degrees.bVII, e.DegreesFirstInversion.V, e.DegreesSecondInversion.bIII],
		levelName: "Lowest note is a 7th interval",
	},
];
const commonLowestNoteAll2 = [
	{
		options: [
			e.Degrees.i,
			e.DegreesFirstInversion.bVI,
			e.DegreesSecondInversion.iv,
			e.Degrees.iv,
			e.DegreesFirstInversion.iiDim,
			e.DegreesSecondInversion.bVII,
		],
		levelName: "Lowest note is a root or perfect 4th interval",
	},

	{
		options: [
			e.Degrees.i,
			e.Degrees.iiDim,
			e.DegreesFirstInversion.bVII,
			e.DegreesSecondInversion.V,
			e.Degrees.V,
			e.DegreesFirstInversion.bIII,
			e.DegreesSecondInversion.i,
		],
		levelName: "Lowest note is a major 2nd or perfect 5th interval",
	},
	{
		options: [
			e.Degrees.i,
			e.Degrees.bIII,
			e.DegreesFirstInversion.i,
			e.DegreesSecondInversion.bVI,
			e.Degrees.bVI,
			e.DegreesFirstInversion.iv,
			e.DegreesSecondInversion.iiDim,
		],
		levelName: "Lowest note is a major 3rd or major 6th interval",
	},

	{
		options: [
			e.Degrees.i,
			e.DegreesFirstInversion.bVI,
			e.DegreesSecondInversion.iv,
			e.Degrees.V,
			e.DegreesFirstInversion.bIII,
			e.DegreesSecondInversion.i,
			e.Degrees.bVII,
			e.DegreesFirstInversion.V,
			e.DegreesSecondInversion.bIII,
		],
		levelName: "Lowest note is a root, perfect 5th or 7th interval",
	},
];

export const PROGRESSION_MINOR_SECTIONS_ROOT_AND_FIRST_INVERSIONS = [
	createSection("Common Lowest Note", commonLowestNote1st),
	createSection("Getting familiar", gettingFamiliar1st),
	createSection("Common Lowest Note 2", commonLowestNote1st2),
	...allSections.map((section) => section.rootAndFirstInversions),
];
export const PROGRESSION_MINOR_SECTIONS_ROOT_AND_SECOND_INVERSIONS = [
	createSection("Common Lowest Note", commonLowestNote2nd),
	createSection("Getting familiar", gettingFamiliar2nd),
	createSection("Common Lowest Note 2", commonLowestNote2nd2),
	...allSections.map((section) => section.rootAndSecondInversions),
];
export const PROGRESSION_MINOR_SECTIONS_ALL = [
	createSection("Common Lowest Note 1", commonLowestNoteAll),
	createSection("Getting familiar", gettingFamiliarAll),
	createSection("Common Lowest Note 2", commonLowestNoteAll2),
	...allSections.map((section) => section.all),
];
