import React, { useEffect } from "react";
import * as e from "../../../data/enums";
import * as c from "../../../data/constants/constants";
import * as music from "../../../data/constants/musical";
import { replaceBwithFlat } from "../../../utils/sharedUtils";

export default function SelectExamOptions({
	module,
	difficulty,
	selection,
	setOption,
}: {
	module: e.Modules;
	difficulty: string | null;
	selection: string[];
	setOption: (option: string[]) => void;
}) {
	const [selectedButtons, setSelectedButtons] = React.useState<string[]>([]);

	useEffect(() => {
		setSelectedButtons([]);
	}, [module, difficulty]);

	const handleOptionClick = (selection: string[], selected: any) => {
		const updatedSelection = updateSelectionByModule(module, selection, selected, selectedButtons, difficulty);
		// console.log("updatedSelection", updatedSelection);
		setOption(updatedSelection);

		const updatedSelectedButtons = updateSelection(selectedButtons, selected);
		setSelectedButtons(updatedSelectedButtons);
	};

	const renderOptionButton = (option: any) => {
		let className = selectedButtons.includes(option as string) ? "buttonChoice selected" : "buttonChoice";
		const flatIconColor = className.includes("selected") ? "white" : "dark";
		return (
			<div key={option.toString()} className={className} onClick={() => handleOptionClick(selection, option)}>
				{replaceBwithFlat(option, flatIconColor)}
			</div>
		);
	};

	const options = getOptions(module, difficulty);
	const buttonChoices = options.map((option: any) => renderOptionButton(option));

	return <div className="selectButtons">{buttonChoices}</div>;
}

function updateSelectionByModule(
	module: e.Modules,
	selection: string[],
	selected: string,
	selectedButtons: string[],
	difficulty: string | null
): string[] {
	let updatedSelection =
		module === e.Modules.PROGRESSIONS_JAZZ
			? updateJazzSelection(selection, selected, selectedButtons)
			: updateSelection(selection, selected);

	switch (module) {
		case e.Modules.MELODIC_DICTATION:
			if (!updatedSelection.includes("1")) updatedSelection.push("1");
			break;
		case e.Modules.PROGRESSIONS_MAJOR:
			if (!updatedSelection.includes("I")) updatedSelection.push("I");
			break;
		case e.Modules.PROGRESSIONS_MINOR:
			if (!updatedSelection.includes("i")) updatedSelection.push("i");
			break;
		case e.Modules.PROGRESSIONS_JAZZ:
			if (
				difficulty === e.DifficultyProgressionsJazz.SECONDARY_DOMINANT_MINOR &&
				!updatedSelection.includes("i7")
			)
				updatedSelection.push("i7");
			else if (
				difficulty !== e.DifficultyProgressionsJazz.SECONDARY_DOMINANT_MINOR &&
				!updatedSelection.includes("Imaj7")
			)
				updatedSelection.push("Imaj7");

			break;
	}
	return updatedSelection;
}

function updateJazzSelection(selection: string[], selected: string, selectedButtons: string[]): string[] {
	const chords = getJazzChords(selected);
	const chordsNeedToBeAdded = selectedButtons.includes(selected);
	if (!chordsNeedToBeAdded) return [...selection, ...chords];
	// else remove one instance of each chord in chords
	else {
		let updatedSelection = [...selection];
		for (const chord of chords) updatedSelection = updateSelection(updatedSelection, chord);
		return updatedSelection;
	}
}

function getJazzChords(selected: string): string[] {
	const jazzChordMap: Record<string, string[]> = {
		[commonProgressionsOptions[0]]: commonProgressionsChords[0],
		[commonProgressionsOptions[1]]: commonProgressionsChords[1],
		[commonProgressionsOptions[2]]: commonProgressionsChords[2],
		[commonProgressionsOptions[3]]: commonProgressionsChords[3],
		[commonProgressionsOptions[4]]: commonProgressionsChords[4],
		[commonProgressionsOptions[5]]: commonProgressionsChords[5],
		[commonProgressionsOptions[6]]: commonProgressionsChords[6],
		[commonProgressionsOptions[7]]: commonProgressionsChords[7],
		[commonProgressionsOptions[8]]: commonProgressionsChords[8],
		[modulationOptions[0]]: c.JazzbII,
		[modulationOptions[1]]: c.JazzbIII,
		[modulationOptions[2]]: c.JazzbVI,
		[modulationOptions[3]]: c.JazzbVII,
		[modulationOptions[4]]: c.JazzVII,
		[secondaryDominantMajorOptions[0]]: c.Jazzii,
		[secondaryDominantMajorOptions[1]]: c.Jazziii,
		[secondaryDominantMajorOptions[2]]: c.Jazziv,
		[secondaryDominantMajorOptions[3]]: c.Jazzv,
		[secondaryDominantMajorOptions[4]]: c.Jazzvi,
		[secondaryDominantMinorOptions[0]]: c.JazzII,
		[secondaryDominantMinorOptions[1]]: c.JazzIII,
		[secondaryDominantMinorOptions[2]]: c.JazzIV,
		[secondaryDominantMinorOptions[3]]: c.JazzV,
		[secondaryDominantMinorOptions[4]]: c.JazzVI,
	};
	return jazzChordMap[selected] || [];
}

function updateSelection(selection: string[], selected: string) {
	const currentSelection = [...selection];
	const index = currentSelection.indexOf(selected);
	if (index > -1) currentSelection.splice(index, 1);
	else currentSelection.push(selected);
	return sortButtons(currentSelection);
}

function sortButtons(buttons: string[]): string[] {
	return buttons.sort((a, b) => trimContent(a).localeCompare(trimContent(b)));
}

function trimContent(content: string): string {
	if (content.endsWith("o")) content = content.slice(0, -1);
	content = content.startsWith("b") ? content.slice(1) + "0" : content + "1";
	return content.toLocaleLowerCase();
}

function getOptions(module: e.Modules, difficulty: string | null) {
	if (module === e.Modules.INTERVAL_RECOGNITION) {
		if (difficulty === e.DifficultyIntervalRecognition.OCTAVE_HIGHER) return music.INTERVALS.slice(13, 25);
		return c.BUTTONS[module];
	}
	if (module === e.Modules.HARMONIC_CONTEXT) return getHarmonicContextOptions(difficulty);
	if (module === e.Modules.PROGRESSIONS_MAJOR) {
		if (difficulty === e.DifficultyProgressions.ROOT) return c.MAJOR_PROGRESSIONS_ROOT;
		if (difficulty === e.DifficultyProgressions.FIRST_INVERSION) return c.MAJOR_PROGRESSIONS_FIRST;
		if (difficulty === e.DifficultyProgressions.SECOND_INVERSION) return c.MAJOR_PROGRESSIONS_SECOND;
		if (difficulty === e.DifficultyProgressions.ROOT_AND_FIRST_INVERSION)
			return [...c.MAJOR_PROGRESSIONS_ROOT, ...c.MAJOR_PROGRESSIONS_FIRST];
		if (difficulty === e.DifficultyProgressions.ROOT_AND_SECOND_INVERSION)
			return [...c.MAJOR_PROGRESSIONS_ROOT, ...c.MAJOR_PROGRESSIONS_SECOND];
		return c.BUTTONS_PROGRESSIONS_PRACTICE;
	}
	if (module === e.Modules.PROGRESSIONS_MINOR) {
		if (difficulty === e.DifficultyProgressions.ROOT) return c.MINOR_PROGRESSIONS_ROOT;
		if (difficulty === e.DifficultyProgressions.FIRST_INVERSION) return c.MINOR_PROGRESSIONS_FIRST;
		if (difficulty === e.DifficultyProgressions.SECOND_INVERSION) return c.MINOR_PROGRESSIONS_SECOND;
		if (difficulty === e.DifficultyProgressions.ROOT_AND_FIRST_INVERSION)
			return [...c.MINOR_PROGRESSIONS_ROOT, ...c.MINOR_PROGRESSIONS_FIRST];
		if (difficulty === e.DifficultyProgressions.ROOT_AND_SECOND_INVERSION)
			return [...c.MINOR_PROGRESSIONS_ROOT, ...c.MINOR_PROGRESSIONS_SECOND];
		return c.BUTTONS_PROGRESSIONS_MINOR_PRACTICE;
	}
	if (module === e.Modules.PROGRESSIONS_JAZZ) return getJazzOptions(difficulty);
	return c.BUTTONS[module];
}

function getHarmonicContextOptions(difficulty: string | null): string[] {
	const harmonicContextMap: Record<string, string[]> = {
		[e.DifficultyHarmonicContext.IONIAN]: music.SCALES_MAP[e.Scales.IONIAN],
		[e.DifficultyHarmonicContext.DORIAN]: music.SCALES_MAP[e.Scales.DORIAN],
		[e.DifficultyHarmonicContext.PHRYGIAN]: music.SCALES_MAP[e.Scales.PHRYGIAN],
		[e.DifficultyHarmonicContext.LYDIAN]: music.SCALES_MAP[e.Scales.LYDIAN],
		[e.DifficultyHarmonicContext.MIXOLYDIAN]: music.SCALES_MAP[e.Scales.MIXOLYDIAN],
		[e.DifficultyHarmonicContext.AEOLIAN]: music.SCALES_MAP[e.Scales.AEOLIAN],
		[e.DifficultyHarmonicContext.LOCRIAN]: music.SCALES_MAP[e.Scales.LOCRIAN],
	};
	return harmonicContextMap[difficulty as string] || music.SCALES_MAP[e.Scales.CHROMATIC];
}

function getJazzOptions(difficulty: string | null): string[] {
	const jazzOptionsMap: Record<string, string[]> = {
		[e.DifficultyProgressionsJazz.COMMON_PROGRESSIONS]: commonProgressionsOptions,
		[e.DifficultyProgressionsJazz.MODULATIONS_MAJOR]: modulationOptions,
		[e.DifficultyProgressionsJazz.SECONDARY_DOMINANT_MAJOR]: secondaryDominantMajorOptions,
		[e.DifficultyProgressionsJazz.SECONDARY_DOMINANT_MINOR]: secondaryDominantMinorOptions,
	};
	return jazzOptionsMap[difficulty as string] || [];
}

const secondaryDominantMajorOptions = [
	"Secondary dominant of ii",
	"Secondary dominant of iii",
	"Secondary dominant of iv",
	"Secondary dominant of v",
	"Secondary dominant of vi",
];

const secondaryDominantMinorOptions = [
	"Secondary dominant of II",
	"Secondary dominant of III",
	"Secondary dominant of IV",
	"Secondary dominant of V",
	"Secondary dominant of VI",
];

const modulationOptions = [
	"Modulation to ♭II",
	"Modulation to ♭III",
	"Modulation to ♭VI",
	"Modulation to ♭VII",
	"Modulation to VII",
];

const commonProgressionsOptions = [
	"ii7 - V7 - Imaj7",
	"vi7 - ♭II7 - Imaj7",
	"vi7 - ii7 - V7 - Imaj7",
	"V7♭9/ii - ii7 - V7 - Imaj7",
	"♭IIImaj7 - ♭VImaj7 - ♭IImaj7 - Imaj7",
	"Imaj7 - #Idim - ii7",
	"ii7 - #IIdim - iii7",
	"IVmaj7 - #IVdim - Imaj7",
	"V7 - #Vdim - vi7",
];

const commonProgressionsChords = [
	[e.DegreesJazz.ii7, e.DegreesJazz.V7, e.DegreesJazz.Imaj7],
	[e.DegreesJazz.vi7, e.DegreesJazz.bII7, e.DegreesJazz.Imaj7],
	[e.DegreesJazz.vi7, e.DegreesJazz.ii7, e.DegreesJazz.V7, e.DegreesJazz.Imaj7],
	[e.DegreesJazz.V7b9overii, e.DegreesJazz.ii7, e.DegreesJazz.V7, e.DegreesJazz.Imaj7],
	[e.DegreesJazz.bIIImaj7, e.DegreesJazz.bVImaj7, e.DegreesJazz.bIImaj7, e.DegreesJazz.Imaj7],

	[e.DegreesJazz.Imaj7, e.DegreesJazz.SharpiDim, e.DegreesJazz.ii7],
	[e.DegreesJazz.ii7, e.DegreesJazz.SharpiiDim, e.DegreesJazz.iii7],
	[e.DegreesJazz.IVmaj7, e.DegreesJazz.SharpivDim, e.DegreesJazz.Imaj7],
	[e.DegreesJazz.V7, e.DegreesJazz.SharpvDim, e.DegreesJazz.vi7],
];
