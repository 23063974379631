import React, { useState } from "react";
import { useTeacherLoggedIn } from "../../../context/UserContext";
import { CommonModal } from "../../../components/CommonModal";
import * as t from "../../../data/types";
import { convertDateToString } from "../../../utils/dates";
import { spaceBeforeCapitals } from "../../../utils/sharedUtils";
import { getMovementDescription } from "./utils";
import { useTranslation } from "react-i18next";

export default function SelectTemplate({
	currentSelectedClass,
	setExamContent,
	showModal,
	onClose,
}: {
	currentSelectedClass: t.Class;
	setExamContent: any;
	showModal: boolean;
	onClose: () => void;
}): JSX.Element {
	const { t } = useTranslation("Exams");
	const { classData } = useTeacherLoggedIn();
	const [selectedClassName, setSelectedClassName] = useState(currentSelectedClass.ClassName);
	const selectedClass = classData?.find((c) => c.ClassName === selectedClassName);
	const exams = selectedClass?.Exams;

	const handleSelectExam = (exam: t.Exam) => {
		setExamContent(exam.Content);
		onClose();
	};

	return (
		<CommonModal showModal={showModal} setShowModal={onClose}>
			<div className="flexRow">
				<h2 style={{ width: "15em" }}>{t("select_class")}</h2>
				<select onChange={(e) => setSelectedClassName(e.target.value)} value={selectedClassName || ""}>
					<option value="" disabled>
						{t("select_class_placeholder")}
					</option>
					{classData?.map((classItem) => (
						<option key={classItem.id} value={classItem.ClassName}>
							{classItem.ClassName}
						</option>
					))}
				</select>
			</div>

			{exams?.map((exam) => (
				<div key={exam.id} className="progressCard">
					<div className="flexRow">
						<h2>{exam.Name}</h2>
						<p>{convertDateToString(exam.StartTime, "dd-mm-yyyy")}</p>
					</div>
					<table className="overviewTable">
						<thead className="theadCSS theadCSSRounded">
							<tr>
								<th>{t("modules")}</th>
								<th>{t("mode")}</th>
								<th>{t("movement")}</th>
								<th>{t("root")}</th>
								<th>{t("options")}</th>
								<th>{t("questions")}</th>
							</tr>
						</thead>
						<tbody>
							{exam.Content.map((question, index) => (
								<tr key={index}>
									<td>{spaceBeforeCapitals(question.Module)}</td>
									<td>{spaceBeforeCapitals(question.Difficulty)}</td>
									<td>{getMovementDescription(question.Module, question.Movement)}</td>
									<td>{question.Dynamic}</td>
									<td className="alignRight">{question.Options?.length}</td>
									<td className="alignRight">{question.NumberOfQuestions}</td>
								</tr>
							))}
						</tbody>
					</table>

					<button onClick={() => handleSelectExam(exam)}>{t("select_as_template")}</button>
				</div>
			))}
		</CommonModal>
	);
}
