import * as e from "../../enums";

type LevelData = {
	options: string[];
	levelName: string;
};

export function createSection(sectionName: string, levelsData: LevelData[]) {
	return {
		sectionName,
		levels: levelsData.map(({ options, levelName }) => ({ options, levelName })),
	};
}

function createSectionProgressions(
	sectionName: string,
	combinations: string[][],
	inversionType: "root" | "first" | "second" | "rootAndFirst" | "rootAndSecond" | "all"
) {
	return {
		sectionName,
		levels: combinations.map((combination) => ({
			options: combination.flatMap((degree) => {
				switch (inversionType) {
					case "root":
						return [e.Degrees[degree as keyof typeof e.Degrees]];
					case "first":
						return [e.DegreesFirstInversion[degree as keyof typeof e.DegreesFirstInversion]];
					case "second":
						return [e.DegreesSecondInversion[degree as keyof typeof e.DegreesSecondInversion]];
					case "rootAndFirst":
						return [
							e.Degrees[degree as keyof typeof e.Degrees],
							e.DegreesFirstInversion[degree as keyof typeof e.DegreesFirstInversion],
						];
					case "rootAndSecond":
						return [
							e.Degrees[degree as keyof typeof e.Degrees],
							e.DegreesSecondInversion[degree as keyof typeof e.DegreesSecondInversion],
						];
					case "all":
						return [
							e.Degrees[degree as keyof typeof e.Degrees],
							e.DegreesFirstInversion[degree as keyof typeof e.DegreesFirstInversion],
							e.DegreesSecondInversion[degree as keyof typeof e.DegreesSecondInversion],
						];
				}
			}),
			levelName: combination.join(" - "),
		})),
	};
}

export function createSectionsForDegreeTypes(sectionName: string, combinations: string[][]) {
	return {
		standard: createSectionProgressions(sectionName, combinations, "root"),
		firstInversions: createSectionProgressions(sectionName, combinations, "first"),
		rootAndFirstInversions: createSectionProgressions(sectionName, combinations, "rootAndFirst"),
		secondInversions: createSectionProgressions(sectionName, combinations, "second"),
		rootAndSecondInversions: createSectionProgressions(sectionName, combinations, "rootAndSecond"),
		all: createSectionProgressions(sectionName, combinations, "all"),
	};
}
