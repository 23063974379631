import * as c from "../../../data/constants/constants";
import * as e from "../../../data/enums";
import * as t from "../../../data/types";
import { parseDate } from "../../../utils/dates";
import { TFunction } from "i18next";

// utils
export function getMovementDescription(module: e.Modules, movement: e.Movement): string {
	switch (movement) {
		case e.Movement.ASCENDING || e.Movement.ASCENDING_INTERVAL:
			if (module === e.Modules.SCALE_RECOGNITION) return movement;
			if (module === e.Modules.INTERVAL_RECOGNITION) return "Ascending intervals";
			return "Ascending arppegios";
		case e.Movement.DESCENDING || e.Movement.DESCENDING_INTERVAL:
			if (module === e.Modules.SCALE_RECOGNITION) return movement;
			if (module === e.Modules.INTERVAL_RECOGNITION) return "Descending intervals";
			return "Descending arppegios";
		case e.Movement.HARMONY || e.Movement.HARMONY_INTERVAL:
			if (module === e.Modules.INTERVAL_RECOGNITION) return "Intervals played harmonically";
			return "Chords played harmonically";
		case e.Movement.ONE_OCTAVE:
			return "Same octave as root";
		case e.Movement.OCTAVE_HIGHER:
			return "One octave higher than root";
		case e.Movement.OCTAVE_LOWER:
			return "One octave lower than root";
		case e.Movement.ALTERNATING_1:
			return "Ascending with a recurring root note every other note";
		case e.Movement.ALTERNATING_2:
			return "Ascending with a recurring root note every 2 notes";
		case e.Movement.ALTERNATING_3:
			return "Ascending with a recurring root note every 3 notes";
		case e.Movement.RANDOM_EASY:
			return "Melody with frequent recurring root note";
		case e.Movement.RANDOM:
			return "Melody with recurring root note";
		case e.Movement.RANDOM_HARD:
			return "Melody";
		case e.Movement.SLOW:
			return "Slow progression";
		case e.Movement.FAST:
			return "Fast progression";
		case e.Movement.MELODY:
			return "Fast progression with melody";

		default:
			if (module === e.Modules.MELODIC_DICTATION) return movement.slice(0, -1) + " melody";
			return movement;
	}
}

export function checkValidInput(
	examName: string | undefined,
	startDate: string | undefined,
	endDate: string | undefined,
	startTime: string | undefined,
	endTime: string | undefined,
	examContent: t.ExamContent[],
	setErrorMessage: any,
	t: TFunction
) {
	if (!examName) {
		setErrorMessage(t("exam_name_required"));
		return false;
	}
	if (!startDate) {
		setErrorMessage(t("start_date_required"));
		return false;
	}
	if (!endDate) {
		setErrorMessage(t("end_date_required"));
		return false;
	}
	if (!startTime) {
		setErrorMessage(t("start_time_required"));
		return false;
	}
	if (!endTime) {
		setErrorMessage(t("end_time_required"));
		return false;
	}

	if (startTime && endTime) {
		const start = parseDate(startDate + "T" + startTime);
		const end = parseDate(endDate + "T" + endTime);
		const durationInMinutes = (end.getTime() - start.getTime()) / 1000 / 60;
		const thirtyMinutes = 30;
		if (durationInMinutes < 0) setErrorMessage(t("invalid_exam_duration"));
		else if (durationInMinutes < thirtyMinutes)
			setErrorMessage(t("minimum_duration", { minutes: durationInMinutes }));
		if (durationInMinutes < thirtyMinutes) return false;
	}
	if (!examContent || (examContent && examContent.length === 0)) {
		setErrorMessage(t("no_questions_added"));
		return false;
	}
	return true;
}

export function determineMovement(module: e.Modules, difficulty: string, setMovementOptions: any) {
	const intervalMov = [e.Movement.ASCENDING_INTERVAL, e.Movement.DESCENDING_INTERVAL, e.Movement.HARMONY_INTERVAL];
	switch (module) {
		case e.Modules.INTERVAL_COMPARISON:
			return setMovementOptions(intervalMov);
		case e.Modules.INTERVAL_RECOGNITION:
			return setMovementOptions(intervalMov);
		case e.Modules.HARMONIC_CONTEXT:
			return setMovementOptions([e.Movement.ONE_OCTAVE, e.Movement.OCTAVE_HIGHER, e.Movement.OCTAVE_LOWER]);
		case e.Modules.MELODIC_DICTATION:
			return setMovementOptions(determineMelodicMovement(difficulty));
		case e.Modules.SCALE_RECOGNITION:
			return setMovementOptions(determineScaleMovement(difficulty));
		case e.Modules.CHORD_RECOGNITION:
			return setMovementOptions([e.Movement.ASCENDING, e.Movement.DESCENDING, e.Movement.HARMONY]);
		case e.Modules.TRIAD_INVERSIONS:
			return setMovementOptions([e.Movement.ASCENDING, e.Movement.DESCENDING, e.Movement.HARMONY]);
		case e.Modules.PROGRESSIONS_MAJOR:
			return setMovementOptions([e.Movement.ASCENDING, e.Movement.DESCENDING, e.Movement.HARMONY]);
		case e.Modules.PROGRESSIONS_MINOR:
			return setMovementOptions([e.Movement.ASCENDING, e.Movement.DESCENDING, e.Movement.HARMONY]);
		case e.Modules.PROGRESSIONS_JAZZ:
			return setMovementOptions([e.Movement.SLOW, e.Movement.FAST, e.Movement.MELODY]);
		default:
			return setMovementOptions([]);
	}
}

function determineMelodicMovement(difficulty: string) {
	switch (difficulty) {
		case e.DifficultyMelodicDictation.SHORT_ONE_OCTAVE:
		case e.DifficultyMelodicDictation.SHORT_TWO_OCTAVES:
			return [e.Movement.THREE_NOTES, e.Movement.FOUR_NOTES, e.Movement.FIVE_NOTES];
		case e.DifficultyMelodicDictation.LONG_ONE_OCTAVE:
		case e.DifficultyMelodicDictation.LONG_TWO_OCTAVES:
			return [e.Movement.FIVE_NOTES, e.Movement.SIX_NOTES, e.Movement.SEVEN_NOTES];
		default:
			return [];
	}
}

function determineScaleMovement(difficulty: string) {
	switch (difficulty) {
		case e.DifficultyScaleRecognition.ALTERNATING:
			return [e.Movement.ALTERNATING_1, e.Movement.ALTERNATING_2, e.Movement.ALTERNATING_3];
		case e.DifficultyScaleRecognition.ASCENDING:
			return [e.Movement.ASCENDING_SLOW, e.Movement.ASCENDING, e.Movement.ASCENDING_FAST];
		case e.DifficultyScaleRecognition.DESCENDING:
			return [e.Movement.DESCENDING_SLOW, e.Movement.DESCENDING, e.Movement.DESCENDING_FAST];
		case e.DifficultyScaleRecognition.MELODY:
			return [e.Movement.RANDOM_EASY, e.Movement.RANDOM, e.Movement.RANDOM_HARD];
		default:
			return [];
	}
}

export function determineDifficulties(module: e.Modules, setDifficultyOptions: any) {
	switch (module) {
		case e.Modules.INTERVAL_COMPARISON:
			return setDifficultyOptions(Object.values(e.DifficultyIntervalComparison));
		case e.Modules.INTERVAL_RECOGNITION:
			return setDifficultyOptions(Object.values(e.DifficultyIntervalRecognition));
		case e.Modules.HARMONIC_CONTEXT:
			return setDifficultyOptions(Object.values(e.DifficultyHarmonicContext));
		case e.Modules.MELODIC_DICTATION:
			return setDifficultyOptions(Object.values(e.DifficultyMelodicDictation));
		case e.Modules.SCALE_RECOGNITION:
			return setDifficultyOptions(Object.values(e.DifficultyScaleRecognition));
		case e.Modules.CHORD_RECOGNITION:
			return setDifficultyOptions(Object.values(e.DifficultyChordRecognition));
		case e.Modules.TRIAD_INVERSIONS:
			return setDifficultyOptions(Object.values(e.DifficultyTriadInversions));
		case e.Modules.PROGRESSIONS_MAJOR:
			return setDifficultyOptions(Object.values(e.DifficultyProgressions));
		case e.Modules.PROGRESSIONS_MINOR:
			return setDifficultyOptions(Object.values(e.DifficultyProgressions));
		case e.Modules.PROGRESSIONS_JAZZ:
			return setDifficultyOptions(Object.values(e.DifficultyProgressionsJazz));
		default:
			return setDifficultyOptions([]);
	}
}
