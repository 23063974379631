import * as t from "../data/types";
import React, { useState } from "react";
import EditClass from "../features/ManageClasses/EditClass";
import { useTeacherLoggedIn } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { checkForUnsafeCharacters, checkIfClassExists } from "../utils/utils";
import addClassAPI from "../services/api/addClassAPI";
import getClassesByTeacherAPI from "../services/api/getClassesByTeacherAPI";
import { useAlert } from "../context/AlertContext";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { ADMIN_EMAIL } from "../data/constants/constants";

export default function ManageClasses(): JSX.Element {
	const [classToEdit, setClassToEdit] = React.useState<t.Class | null>(null);
	return (
		<main className="teacherPortal">
			<ClassSelector classToEdit={classToEdit} setClassToEdit={setClassToEdit} />
			<EditClass classToEdit={classToEdit} setClassToEdit={setClassToEdit} />
			{!classToEdit && <CreateNewClass />}
		</main>
	);
}

function ClassSelector({
	classToEdit,
	setClassToEdit,
}: {
	classToEdit: t.Class | null;
	setClassToEdit: React.Dispatch<React.SetStateAction<t.Class | null>>;
}) {
	const { teacher, classData } = useTeacherLoggedIn();
	const classNames = classData?.map((classObj) => classObj.ClassName);
	const sortedClassData = classData?.sort((a, b) => a.ClassName.localeCompare(b.ClassName));
	const { t } = useTranslation("ManageClasses");

	const navigate = useNavigate();
	function handleSelectClass(classObj: t.Class) {
		navigate("/" + classObj.ClassName);
	}

	function handleEditClick(classObj: t.Class) {
		if (classToEdit?.id !== classObj.id) {
			const classSortedByName: t.Class = { ...classObj };
			classSortedByName.Students.sort((a, b) => a.FirstName.localeCompare(b.FirstName));
			setClassToEdit(classSortedByName);
		}
	}

	if (!classNames || (classNames && classNames?.length === 0)) return <></>;
	return (
		<>
			<div className="overviewTable">
				<table>
					<thead className="theadCSS">
						<tr>
							<th>{t("ClassName")}</th>
							{teacher.Email === ADMIN_EMAIL && <th>School</th>}
							<th className="alignRight">{t("Students")}</th>
							<th className="alignRight"></th>
						</tr>
					</thead>
					<tbody>
						{sortedClassData &&
							sortedClassData.map((classes: any, id: number) => (
								<tr className="clickableTableRow" key={id}>
									<td onClick={() => handleSelectClass(classes)}>{classes.ClassName}</td>
									{teacher.Email === ADMIN_EMAIL && <td>{classes.SchoolName}</td>}
									<td className="alignRight">
										{classes?.Students?.length} {t("Students")}
									</td>
									<td onClick={() => handleEditClick(classes)} className="error link">
										{t("Edit")}
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</>
	);
}

function CreateNewClass(): JSX.Element {
	const { teacher, classData, setClassData, setLoading } = useTeacherLoggedIn();
	const { triggerAlert } = useAlert();
	const [className, setClassName] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const { t } = useTranslation("ManageClasses");

	const addClass = async () => {
		if (className === "") return setErrorMessage(t("ErrorMessageEmptyClassName"));
		const classNameTrimmed = className.trim();
		if (classNameTrimmed === "") return setErrorMessage(t("ErrorMessageEmptyClassName"));

		if (checkForUnsafeCharacters([classNameTrimmed])) return setErrorMessage(t("ErrorMessageUnsafeCharacters"));

		if (checkIfClassExists(classNameTrimmed, classData)) return setErrorMessage(t("ErrorMessageClassExists"));
		setLoading(true);
		try {
			await addClassAPI(classNameTrimmed, teacher);
			await getClassesByTeacherAPI(teacher.id, setClassData);
			triggerAlert(t("ClassCreationSuccess1") + classNameTrimmed + t("ClassCreationSuccess2"), "success");
		} catch (error: any) {
			triggerAlert(t("ClassCreationError", { error: error.message }), "error");
			if (teacher?.Email !== ADMIN_EMAIL) Sentry.captureException(error);
		} finally {
			setClassName("");
			setLoading(false);
		}
	};

	return (
		<div>
			{errorMessage && <div className="error">{errorMessage}</div>}
			<div className="flexRow">
				<input
					className="text-area"
					type="text"
					placeholder={t("ClassName")}
					onChange={(e) => setClassName(e.target.value)}
				/>

				<button className="darkButton" onClick={addClass}>
					{t("CreateNewClassButton")}
				</button>
			</div>
		</div>
	);
}
