import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AlertProvider } from "./context/AlertContext";
import { TeacherProvider } from "./context/UserContext";
import "./i18n/i18n";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://80fedc568831baae93039dbd8f5c9463@o4506851839442944.ingest.sentry.io/4506851853795328",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["https://auriseartraining.netlify.app", "https://auris-db-backend.vercel.app"],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<AlertProvider>
			<TeacherProvider>
				<App />
			</TeacherProvider>
		</AlertProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
