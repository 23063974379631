import React from "react";
import { useTranslation } from "react-i18next";

export default function Support(): JSX.Element {
	const { t } = useTranslation("Support");

	return (
		<main>
			<div className="basicCard">
				<h1>{t("supportAndFAQ")}</h1>
				{/* {t("comingSoon")} <br /> */}
				<br />
				{t("contactInfo")}
			</div>
		</main>
	);
}
