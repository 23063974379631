import { createSection } from "./utils";
// import * as music from "../../constants/musical";
import { INTERVAL_NAMES_MAP as i } from "../../constants/musical";
import { SCALES_MAP as scales } from "../../constants/musical";
import * as e from "../../enums";

function twoOptions1(s: string[]) {
	return [
		{ options: [s[0], s[6]], levelName: i[s[0]] + " & " + i[s[6]] },
		{ options: [s[0], s[5]], levelName: i[s[0]] + " & " + i[s[5]] },
		{ options: [s[1], s[6]], levelName: i[s[1]] + " & " + i[s[6]] },
		{ options: [s[0], s[4]], levelName: i[s[0]] + " & " + i[s[4]] },
		{ options: [s[1], s[5]], levelName: i[s[1]] + " & " + i[s[5]] },
		{ options: [s[2], s[6]], levelName: i[s[2]] + " & " + i[s[6]] },
	];
}

function twoOptions2(s: string[]) {
	return [
		{ options: [s[0], s[3]], levelName: i[s[0]] + " & " + i[s[3]] },
		{ options: [s[1], s[4]], levelName: i[s[1]] + " & " + i[s[4]] },
		{ options: [s[2], s[5]], levelName: i[s[2]] + " & " + i[s[5]] },
		{ options: [s[3], s[6]], levelName: i[s[3]] + " & " + i[s[6]] },
	];
}

function twoOptions3(s: string[]) {
	return [
		{ options: [s[0], s[2]], levelName: i[s[0]] + " & " + i[s[2]] },
		{ options: [s[1], s[3]], levelName: i[s[1]] + " & " + i[s[3]] },
		{ options: [s[2], s[4]], levelName: i[s[2]] + " & " + i[s[4]] },
		{ options: [s[3], s[5]], levelName: i[s[3]] + " & " + i[s[5]] },
		{ options: [s[4], s[6]], levelName: i[s[1]] + " & " + i[s[6]] },
	];
}

function twoOptions4(s: string[]) {
	return [
		{ options: [s[0], s[1]], levelName: i[s[0]] + " & " + i[s[1]] },
		{ options: [s[1], s[2]], levelName: i[s[1]] + " & " + i[s[2]] },
		{ options: [s[2], s[3]], levelName: i[s[2]] + " & " + i[s[3]] },
		{ options: [s[3], s[4]], levelName: i[s[3]] + " & " + i[s[4]] },
		{ options: [s[4], s[5]], levelName: i[s[1]] + " & " + i[s[5]] },
		{ options: [s[5], s[6]], levelName: i[s[5]] + " & " + i[s[6]] },
	];
}
function threeOptions1(s: string[]) {
	return [
		{ options: [s[0], s[2], s[6]], levelName: i[s[0]] + ", " + i[s[2]] + " & " + i[s[6]] },
		{ options: [s[0], s[3], s[6]], levelName: i[s[0]] + ", " + i[s[3]] + " & " + i[s[6]] },
		{ options: [s[0], s[4], s[6]], levelName: i[s[0]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[0], s[2], s[4]], levelName: i[s[0]] + ", " + i[s[2]] + " & " + i[s[4]] },
		{ options: [s[1], s[3], s[5]], levelName: i[s[1]] + ", " + i[s[3]] + " & " + i[s[5]] },
	];
}

function threeOptions2(s: string[]) {
	return [
		{ options: [s[2], s[4], s[6]], levelName: i[s[2]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[0], s[3], s[5]], levelName: i[s[0]] + ", " + i[s[3]] + " & " + i[s[5]] },
		{ options: [s[1], s[4], s[6]], levelName: i[s[1]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[0], s[2], s[5]], levelName: i[s[0]] + ", " + i[s[2]] + " & " + i[s[5]] },
		{ options: [s[1], s[3], s[6]], levelName: i[s[1]] + ", " + i[s[3]] + " & " + i[s[6]] },
	];
}
function threeOptions3(s: string[]) {
	return [
		{ options: [s[1], s[3], s[4]], levelName: i[s[1]] + ", " + i[s[3]] + " & " + i[s[4]] },
		{ options: [s[2], s[4], s[5]], levelName: i[s[2]] + ", " + i[s[4]] + " & " + i[s[5]] },
		{ options: [s[1], s[2], s[6]], levelName: i[s[1]] + ", " + i[s[2]] + " & " + i[s[6]] },
		{ options: [s[0], s[1], s[5]], levelName: i[s[0]] + ", " + i[s[1]] + " & " + i[s[5]] },
		{ options: [s[0], s[2], s[3]], levelName: i[s[0]] + ", " + i[s[2]] + " & " + i[s[3]] },
		{ options: [s[3], s[5], s[6]], levelName: i[s[3]] + ", " + i[s[5]] + " & " + i[s[6]] },
	];
}

function threeOptions4(s: string[]) {
	return [
		{ options: [s[0], s[1], s[4]], levelName: i[s[0]] + ", " + i[s[1]] + " & " + i[s[4]] },
		{ options: [s[1], s[2], s[5]], levelName: i[s[1]] + ", " + i[s[2]] + " & " + i[s[5]] },
		{ options: [s[2], s[3], s[6]], levelName: i[s[2]] + ", " + i[s[3]] + " & " + i[s[6]] },
		{ options: [s[0], s[3], s[4]], levelName: i[s[0]] + ", " + i[s[3]] + " & " + i[s[4]] },
		{ options: [s[1], s[4], s[5]], levelName: i[s[1]] + ", " + i[s[4]] + " & " + i[s[5]] },
		{ options: [s[2], s[5], s[6]], levelName: i[s[2]] + ", " + i[s[5]] + " & " + i[s[6]] },
	];
}

function threeOptions5(s: string[]) {
	return [
		{ options: [s[0], s[1], s[3]], levelName: i[s[0]] + ", " + i[s[1]] + " & " + i[s[3]] },
		{ options: [s[1], s[2], s[4]], levelName: i[s[1]] + ", " + i[s[2]] + " & " + i[s[4]] },
		{ options: [s[2], s[3], s[5]], levelName: i[s[2]] + ", " + i[s[3]] + " & " + i[s[5]] },
		{ options: [s[3], s[4], s[6]], levelName: i[s[3]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[0], s[4], s[5]], levelName: i[s[0]] + ", " + i[s[4]] + " & " + i[s[5]] },
		{ options: [s[1], s[5], s[6]], levelName: i[s[1]] + ", " + i[s[5]] + " & " + i[s[6]] },
	];
}

function threeOptions6(s: string[]) {
	return [
		{ options: [s[0], s[1], s[2]], levelName: i[s[0]] + ", " + i[s[1]] + ", " + i[s[2]] },
		{ options: [s[1], s[2], s[3]], levelName: i[s[1]] + ", " + i[s[2]] + " & " + i[s[3]] },
		{ options: [s[2], s[3], s[4]], levelName: i[s[2]] + ", " + i[s[3]] + " & " + i[s[4]] },
		{ options: [s[3], s[4], s[5]], levelName: i[s[3]] + ", " + i[s[4]] + " & " + i[s[5]] },
		{ options: [s[4], s[5], s[6]], levelName: i[s[1]] + ", " + i[s[5]] + " & " + i[s[6]] },
		{ options: [s[0], s[5], s[6]], levelName: i[s[0]] + ", " + i[s[5]] + " & " + i[s[6]] },
		{ options: [s[0], s[1], s[6]], levelName: i[s[0]] + ", " + i[s[1]] + " & " + i[s[6]] },
	];
}

function fourOptions1(s: string[]) {
	return [
		{ options: [s[0], s[2], s[4], s[6]], levelName: i[s[0]] + ", " + i[s[2]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[0], s[2], s[4], s[5]], levelName: i[s[0]] + ", " + i[s[2]] + ", " + i[s[4]] + " & " + i[s[5]] },
		{ options: [s[1], s[2], s[4], s[6]], levelName: i[s[1]] + ", " + i[s[2]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[0], s[1], s[3], s[5]], levelName: i[s[0]] + ", " + i[s[1]] + ", " + i[s[3]] + " & " + i[s[5]] },
		{ options: [s[0], s[1], s[3], s[6]], levelName: i[s[0]] + ", " + i[s[1]] + ", " + i[s[3]] + " & " + i[s[4]] },
		{ options: [s[0], s[1], s[4], s[6]], levelName: i[s[0]] + ", " + i[s[1]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[0], s[2], s[3], s[5]], levelName: i[s[0]] + ", " + i[s[2]] + ", " + i[s[3]] + " & " + i[s[5]] },
		{ options: [s[0], s[2], s[3], s[6]], levelName: i[s[0]] + ", " + i[s[2]] + ", " + i[s[3]] + " & " + i[s[6]] },
		{ options: [s[0], s[2], s[5], s[6]], levelName: i[s[0]] + ", " + i[s[2]] + ", " + i[s[5]] + " & " + i[s[6]] },
	];
}
function fourOptions2(s: string[]) {
	return [
		{ options: [s[0], s[3], s[4], s[6]], levelName: i[s[0]] + ", " + i[s[3]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[0], s[3], s[5], s[6]], levelName: i[s[0]] + ", " + i[s[3]] + ", " + i[s[5]] + " & " + i[s[6]] },
		{ options: [s[1], s[3], s[4], s[6]], levelName: i[s[1]] + ", " + i[s[3]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[1], s[3], s[5], s[6]], levelName: i[s[1]] + ", " + i[s[3]] + ", " + i[s[5]] + " & " + i[s[6]] },
		{ options: [s[0], s[1], s[3], s[4]], levelName: i[s[0]] + ", " + i[s[1]] + ", " + i[s[3]] + " & " + i[s[4]] },
		{ options: [s[0], s[1], s[4], s[5]], levelName: i[s[0]] + ", " + i[s[1]] + ", " + i[s[4]] + " & " + i[s[5]] },
		{ options: [s[1], s[2], s[4], s[5]], levelName: i[s[1]] + ", " + i[s[2]] + ", " + i[s[4]] + " & " + i[s[5]] },
		{ options: [s[1], s[2], s[5], s[6]], levelName: i[s[1]] + ", " + i[s[2]] + ", " + i[s[5]] + " & " + i[s[6]] },
		{ options: [s[2], s[3], s[5], s[6]], levelName: i[s[2]] + ", " + i[s[3]] + ", " + i[s[4]] + " & " + i[s[6]] },
	];
}

function fourOptions3(s: string[]) {
	return [
		{ options: [s[0], s[1], s[2], s[4]], levelName: i[s[0]] + ", " + i[s[1]] + ", " + i[s[2]] + " & " + i[s[4]] },
		{ options: [s[0], s[1], s[2], s[5]], levelName: i[s[0]] + ", " + i[s[1]] + ", " + i[s[2]] + " & " + i[s[5]] },
		{ options: [s[1], s[2], s[3], s[5]], levelName: i[s[1]] + ", " + i[s[2]] + ", " + i[s[3]] + " & " + i[s[5]] },
		{ options: [s[1], s[2], s[3], s[6]], levelName: i[s[1]] + ", " + i[s[2]] + ", " + i[s[3]] + " & " + i[s[6]] },
		{ options: [s[2], s[3], s[4], s[6]], levelName: i[s[2]] + ", " + i[s[3]] + ", " + i[s[4]] + " & " + i[s[6]] },
		{ options: [s[0], s[2], s[3], s[4]], levelName: i[s[0]] + ", " + i[s[2]] + ", " + i[s[3]] + " & " + i[s[4]] },
		{ options: [s[0], s[3], s[4], s[5]], levelName: i[s[0]] + ", " + i[s[3]] + ", " + i[s[4]] + " & " + i[s[5]] },
		{ options: [s[1], s[3], s[4], s[5]], levelName: i[s[1]] + ", " + i[s[3]] + ", " + i[s[4]] + " & " + i[s[5]] },
		{ options: [s[1], s[4], s[5], s[6]], levelName: i[s[1]] + ", " + i[s[4]] + ", " + i[s[5]] + " & " + i[s[6]] },
		{ options: [s[2], s[4], s[5], s[6]], levelName: i[s[2]] + ", " + i[s[4]] + ", " + i[s[4]] + " & " + i[s[6]] },
	];
}
function fourOptions4(s: string[]) {
	return [
		{ options: [s[0], s[1], s[2], s[3]], levelName: "Starting from 1" },
		{ options: [s[1], s[2], s[3], s[4]], levelName: "Starting from 2" },
		{ options: [s[2], s[3], s[4], s[5]], levelName: "Starting from 3" },
		{ options: [s[3], s[4], s[5], s[6]], levelName: "Starting from 4" },
		{ options: [s[0], s[4], s[5], s[6]], levelName: "Starting from 5" },
		{ options: [s[0], s[1], s[5], s[6]], levelName: "Starting from 6" },
		{ options: [s[0], s[1], s[2], s[6]], levelName: "Starting from 7" },
	];
}

function fiveOptions(s: string[]) {
	return [
		{ options: [s[0], s[1], s[2], s[3], s[4]], levelName: "Starting from 1" },
		{ options: [s[1], s[2], s[3], s[4], s[5]], levelName: "Starting from 2" },
		{ options: [s[2], s[3], s[4], s[5], s[6]], levelName: "Starting from 3" },
		{ options: [s[3], s[4], s[5], s[6], s[0]], levelName: "Starting from 4" },
		{ options: [s[4], s[5], s[6], s[0], s[1]], levelName: "Starting from 5" },
		{ options: [s[5], s[6], s[0], s[1], s[2]], levelName: "Starting from 6" },
		{ options: [s[6], s[0], s[0], s[2], s[3]], levelName: "Starting from 7" },
	];
}
function sixOptions(s: string[]) {
	return [
		{ options: [s[0], s[1], s[2], s[3], s[4], s[5]], levelName: "Starting from 1" },
		{ options: [s[1], s[2], s[3], s[4], s[5], s[6]], levelName: "Starting from 2" },
		{ options: [s[2], s[3], s[4], s[5], s[6], s[0]], levelName: "Starting from 3" },
		{ options: [s[3], s[4], s[5], s[6], s[0], s[1]], levelName: "Starting from 4" },
		{ options: [s[4], s[5], s[6], s[0], s[1], s[2]], levelName: "Starting from 5" },
		{ options: [s[5], s[6], s[0], s[1], s[2], s[3]], levelName: "Starting from 6" },
		{ options: [s[6], s[0], s[1], s[2], s[3], s[4]], levelName: "Starting from 7" },
	];
}

function bossModeLevels1(s: string[]) {
	return [{ options: [s[0], s[1], s[2], s[3], s[4], s[5], s[6]], levelName: "All scale" }];
}

export const HARMONIC_CONTEXT_I_SECTIONS = [
	createSection("Two options 1", twoOptions1(scales[e.Scales.IONIAN])),
	createSection("Two options 2", twoOptions2(scales[e.Scales.IONIAN])),
	createSection("Two options 3", twoOptions3(scales[e.Scales.IONIAN])),
	createSection("Two options 4", twoOptions4(scales[e.Scales.IONIAN])),
	createSection("Three options 1", threeOptions1(scales[e.Scales.IONIAN])),
	createSection("Three options 2", threeOptions2(scales[e.Scales.IONIAN])),
	createSection("Three options 3", threeOptions3(scales[e.Scales.IONIAN])),
	createSection("Three options 4", threeOptions4(scales[e.Scales.IONIAN])),
	createSection("Three options 5", threeOptions5(scales[e.Scales.IONIAN])),
	createSection("Three options 6", threeOptions6(scales[e.Scales.IONIAN])),
	createSection("Four options 1", fourOptions1(scales[e.Scales.IONIAN])),
	createSection("Four options 2", fourOptions2(scales[e.Scales.IONIAN])),
	createSection("Four options 3", fourOptions3(scales[e.Scales.IONIAN])),
	createSection("Four options 4", fourOptions4(scales[e.Scales.IONIAN])),
	createSection("Five options", fiveOptions(scales[e.Scales.IONIAN])),
	createSection("Six options", sixOptions(scales[e.Scales.IONIAN])),
	createSection("Major Boss Mode", bossModeLevels1(scales[e.Scales.IONIAN])),
];

export const HARMONIC_CONTEXT_II_SECTIONS = [
	createSection("Two options 1", twoOptions1(scales[e.Scales.DORIAN])),
	createSection("Two options 2", twoOptions2(scales[e.Scales.DORIAN])),
	createSection("Two options 3", twoOptions3(scales[e.Scales.DORIAN])),
	createSection("Two options 4", twoOptions4(scales[e.Scales.DORIAN])),
	createSection("Three options 1", threeOptions1(scales[e.Scales.DORIAN])),
	createSection("Three options 2", threeOptions2(scales[e.Scales.DORIAN])),
	createSection("Three options 3", threeOptions3(scales[e.Scales.DORIAN])),
	createSection("Three options 4", threeOptions4(scales[e.Scales.DORIAN])),
	createSection("Three options 5", threeOptions5(scales[e.Scales.DORIAN])),
	createSection("Three options 6", threeOptions6(scales[e.Scales.DORIAN])),
	createSection("Four options 1", fourOptions1(scales[e.Scales.DORIAN])),
	createSection("Four options 2", fourOptions2(scales[e.Scales.DORIAN])),
	createSection("Four options 3", fourOptions3(scales[e.Scales.DORIAN])),
	createSection("Four options 4", fourOptions4(scales[e.Scales.DORIAN])),
	createSection("Five options", fiveOptions(scales[e.Scales.DORIAN])),
	createSection("Six options", sixOptions(scales[e.Scales.DORIAN])),
	createSection("Major Boss Mode", bossModeLevels1(scales[e.Scales.DORIAN])),
];

export const HARMONIC_CONTEXT_III_SECTIONS = [
	createSection("Two options 1", twoOptions1(scales[e.Scales.PHRYGIAN])),
	createSection("Two options 2", twoOptions2(scales[e.Scales.PHRYGIAN])),
	createSection("Two options 3", twoOptions3(scales[e.Scales.PHRYGIAN])),
	createSection("Two options 4", twoOptions4(scales[e.Scales.PHRYGIAN])),
	createSection("Three options 1", threeOptions1(scales[e.Scales.PHRYGIAN])),
	createSection("Three options 2", threeOptions2(scales[e.Scales.PHRYGIAN])),
	createSection("Three options 3", threeOptions3(scales[e.Scales.PHRYGIAN])),
	createSection("Three options 4", threeOptions4(scales[e.Scales.PHRYGIAN])),
	createSection("Three options 5", threeOptions5(scales[e.Scales.PHRYGIAN])),
	createSection("Three options 6", threeOptions6(scales[e.Scales.PHRYGIAN])),
	createSection("Four options 1", fourOptions1(scales[e.Scales.PHRYGIAN])),
	createSection("Four options 2", fourOptions2(scales[e.Scales.PHRYGIAN])),
	createSection("Four options 3", fourOptions3(scales[e.Scales.PHRYGIAN])),
	createSection("Four options 4", fourOptions4(scales[e.Scales.PHRYGIAN])),
	createSection("Five options", fiveOptions(scales[e.Scales.PHRYGIAN])),
	createSection("Six options", sixOptions(scales[e.Scales.PHRYGIAN])),
	createSection("Major Boss Mode", bossModeLevels1(scales[e.Scales.PHRYGIAN])),
];

export const HARMONIC_CONTEXT_IV_SECTIONS = [
	createSection("Two options 1", twoOptions1(scales[e.Scales.LYDIAN])),
	createSection("Two options 2", twoOptions2(scales[e.Scales.LYDIAN])),
	createSection("Two options 3", twoOptions3(scales[e.Scales.LYDIAN])),
	createSection("Two options 4", twoOptions4(scales[e.Scales.LYDIAN])),
	createSection("Three options 1", threeOptions1(scales[e.Scales.LYDIAN])),
	createSection("Three options 2", threeOptions2(scales[e.Scales.LYDIAN])),
	createSection("Three options 3", threeOptions3(scales[e.Scales.LYDIAN])),
	createSection("Three options 4", threeOptions4(scales[e.Scales.LYDIAN])),
	createSection("Three options 5", threeOptions5(scales[e.Scales.LYDIAN])),
	createSection("Three options 6", threeOptions6(scales[e.Scales.LYDIAN])),
	createSection("Four options 1", fourOptions1(scales[e.Scales.LYDIAN])),
	createSection("Four options 2", fourOptions2(scales[e.Scales.LYDIAN])),
	createSection("Four options 3", fourOptions3(scales[e.Scales.LYDIAN])),
	createSection("Four options 4", fourOptions4(scales[e.Scales.LYDIAN])),
	createSection("Five options", fiveOptions(scales[e.Scales.LYDIAN])),
	createSection("Six options", sixOptions(scales[e.Scales.LYDIAN])),
	createSection("Major Boss Mode", bossModeLevels1(scales[e.Scales.LYDIAN])),
];

export const HARMONIC_CONTEXT_V_SECTIONS = [
	createSection("Two options 1", twoOptions1(scales[e.Scales.MIXOLYDIAN])),
	createSection("Two options 2", twoOptions2(scales[e.Scales.MIXOLYDIAN])),
	createSection("Two options 3", twoOptions3(scales[e.Scales.MIXOLYDIAN])),
	createSection("Two options 4", twoOptions4(scales[e.Scales.MIXOLYDIAN])),
	createSection("Three options 1", threeOptions1(scales[e.Scales.MIXOLYDIAN])),
	createSection("Three options 2", threeOptions2(scales[e.Scales.MIXOLYDIAN])),
	createSection("Three options 3", threeOptions3(scales[e.Scales.MIXOLYDIAN])),
	createSection("Three options 4", threeOptions4(scales[e.Scales.MIXOLYDIAN])),
	createSection("Three options 5", threeOptions5(scales[e.Scales.MIXOLYDIAN])),
	createSection("Three options 6", threeOptions6(scales[e.Scales.MIXOLYDIAN])),
	createSection("Four options 1", fourOptions1(scales[e.Scales.MIXOLYDIAN])),
	createSection("Four options 2", fourOptions2(scales[e.Scales.MIXOLYDIAN])),
	createSection("Four options 3", fourOptions3(scales[e.Scales.MIXOLYDIAN])),
	createSection("Four options 4", fourOptions4(scales[e.Scales.MIXOLYDIAN])),
	createSection("Five options", fiveOptions(scales[e.Scales.MIXOLYDIAN])),
	createSection("Six options", sixOptions(scales[e.Scales.MIXOLYDIAN])),
	createSection("Major Boss Mode", bossModeLevels1(scales[e.Scales.MIXOLYDIAN])),
];

export const HARMONIC_CONTEXT_VI_SECTIONS = [
	createSection("Two options 1", twoOptions1(scales[e.Scales.AEOLIAN])),
	createSection("Two options 2", twoOptions2(scales[e.Scales.AEOLIAN])),
	createSection("Two options 3", twoOptions3(scales[e.Scales.AEOLIAN])),
	createSection("Two options 4", twoOptions4(scales[e.Scales.AEOLIAN])),
	createSection("Three options 1", threeOptions1(scales[e.Scales.AEOLIAN])),
	createSection("Three options 2", threeOptions2(scales[e.Scales.AEOLIAN])),
	createSection("Three options 3", threeOptions3(scales[e.Scales.AEOLIAN])),
	createSection("Three options 4", threeOptions4(scales[e.Scales.AEOLIAN])),
	createSection("Three options 5", threeOptions5(scales[e.Scales.AEOLIAN])),
	createSection("Three options 6", threeOptions6(scales[e.Scales.AEOLIAN])),
	createSection("Four options 1", fourOptions1(scales[e.Scales.AEOLIAN])),
	createSection("Four options 2", fourOptions2(scales[e.Scales.AEOLIAN])),
	createSection("Four options 3", fourOptions3(scales[e.Scales.AEOLIAN])),
	createSection("Four options 4", fourOptions4(scales[e.Scales.AEOLIAN])),
	createSection("Five options", fiveOptions(scales[e.Scales.AEOLIAN])),
	createSection("Six options", sixOptions(scales[e.Scales.AEOLIAN])),
	createSection("Major Boss Mode", bossModeLevels1(scales[e.Scales.AEOLIAN])),
];

export const HARMONIC_CONTEXT_VII_SECTIONS = [
	createSection("Two options 1", twoOptions1(scales[e.Scales.LOCRIAN])),
	createSection("Two options 2", twoOptions2(scales[e.Scales.LOCRIAN])),
	createSection("Two options 3", twoOptions3(scales[e.Scales.LOCRIAN])),
	createSection("Two options 4", twoOptions4(scales[e.Scales.LOCRIAN])),
	createSection("Three options 1", threeOptions1(scales[e.Scales.LOCRIAN])),
	createSection("Three options 2", threeOptions2(scales[e.Scales.LOCRIAN])),
	createSection("Three options 3", threeOptions3(scales[e.Scales.LOCRIAN])),
	createSection("Three options 4", threeOptions4(scales[e.Scales.LOCRIAN])),
	createSection("Three options 5", threeOptions5(scales[e.Scales.LOCRIAN])),
	createSection("Three options 6", threeOptions6(scales[e.Scales.LOCRIAN])),
	createSection("Four options 1", fourOptions1(scales[e.Scales.LOCRIAN])),
	createSection("Four options 2", fourOptions2(scales[e.Scales.LOCRIAN])),
	createSection("Four options 3", fourOptions3(scales[e.Scales.LOCRIAN])),
	createSection("Four options 4", fourOptions4(scales[e.Scales.LOCRIAN])),
	createSection("Five options", fiveOptions(scales[e.Scales.LOCRIAN])),
	createSection("Six options", sixOptions(scales[e.Scales.LOCRIAN])),
	createSection("Major Boss Mode", bossModeLevels1(scales[e.Scales.LOCRIAN])),
];
