import React from "react";

export default function CustomDatePicker({
	value,
	min,
	onDateSelect,
}: {
	value: string;
	min: string;
	onDateSelect: any;
}) {
	const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newDate = new Date(e.target.value);
		if (onDateSelect) onDateSelect(newDate);
	};

	return <input type="date" value={value} onChange={handleDateChange} min={min} className="datePicker" />;
}
