import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

/**
 * Provides a simple upload button
 * On upload, the content of the file is read and passed to the callback function
 * @param props (callback: (content: string | any[], fileType: string) => void) The callback function to be called when a file is uploaded
 */
const CsvUploader = (props: { callback: (content: string | any[], fileType: string) => void }) => {
	const [file, setFile] = useState<any>(null);
	const [error, setError] = useState("");
	const { t } = useTranslation("ManageClasses");

	const handleFileChange = (event: any) => {
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			const fileName = selectedFile.name;
			const fileExtension = fileName.substr(fileName.lastIndexOf(".")).toLowerCase();
			if (fileExtension === ".csv" || fileExtension === ".xlsx" || fileExtension === ".xls") {
				setFile(selectedFile);
				setError("");
			} else {
				setError(t("errorValidCsv"));
			}
		}
	};

	const handleFileUpload = () => {
		if (file) {
			const fileName = file.name;
			const fileExtension = fileName.substr(fileName.lastIndexOf(".")).toLowerCase();
			if (fileExtension === ".csv") {
				const reader = new FileReader();
				reader.onload = (event: any) => {
					const content = event.target.result;
					props.callback(content, "csv");
				};
				reader.readAsText(file);
			} else if (fileExtension === ".xlsx" || fileExtension === ".xls") {
				// Use XLSX to parse the file
				const reader = new FileReader();
				reader.onload = (event: any) => {
					const data = new Uint8Array(event.target.result);
					const workbook = XLSX.read(data, { type: "array" });
					// Assuming the first sheet is the one we want
					const firstSheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[firstSheetName];
					const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
					props.callback(jsonData, "excel");
				};
				reader.readAsArrayBuffer(file);
			} else {
				setError(t("errorValidCsv"));
			}
		}
	};

	return (
		<div>
			<input type="file" accept=".csv, .xlsx, .xls" onChange={handleFileChange} />
			<button onClick={handleFileUpload}>{t("upload")}</button>
			{error && <p style={{ color: "red" }}>{error}</p>}
		</div>
	);
};

export default CsvUploader;
