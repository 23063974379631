import React from "react";
import * as t from "../../data/types";
import { determineSuperscriptText } from "../../utils/sharedUtils";
import { useTranslation } from "react-i18next";

const minutes: number = 60;
const multiplierToGetValuesAfterDecimal: number = 10;
export default function Achievements({ user, classData }: { user: t.User; classData: t.Class }) {
	const practiceTimeLastWeek =
		Math.round((user.WeeklyStats.PracticeTime / minutes) * multiplierToGetValuesAfterDecimal) /
		multiplierToGetValuesAfterDecimal;
	const practiceTimeLastMonth =
		user.MonthlyStats === undefined
			? 0
			: Math.round((user.MonthlyStats.PracticeTime / minutes) * multiplierToGetValuesAfterDecimal) /
			  multiplierToGetValuesAfterDecimal;

	const students = classData.Students;

	const currentStreak = calcPerformance(user.Streak.Current, students, (student) => student.Streak.Current);
	const bestStreak = calcPerformance(user.Streak.Longest, students, (student) => student.Streak.Longest);
	const practiceTimeWeek = calcPerformance(
		user.WeeklyStats.PracticeTime,
		students,
		(student) => student.WeeklyStats.PracticeTime
	);
	const practiceTimeMonth =
		user.MonthlyStats === undefined
			? 0
			: calcPerformance(
					user.MonthlyStats.PracticeTime,
					students,
					(student) => student.MonthlyStats?.PracticeTime
			  );

	const lastWeekProgress = calcPerformance(
		user.WeeklyStats.Progress,
		students,
		(student) => student.WeeklyStats.Progress
	);
	const totalProgress = calcPerformance(user.Progress.Total, students, (student) => student.Progress.Total);

	const currentRank = calcRankPerformance(user.CurrentRank, classData.Students.length);
	const bestRank = calcRankPerformance(user.BestRank, classData.Students.length);
	const { t } = useTranslation("Overview");

	return (
		<>
			<table className="overviewTable">
				<thead className="theadCSS theadCSSRounded">
					<tr>
						<th>{t("Stats")}</th>
						<th colSpan={2}></th>
						<th className="alignRight">{t("Rank")}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{t("CurrentStreak")}</td>
						<td className="alignRight">{user.Streak.Current}</td>
						<td>
							<sup>{t("days")}</sup>
						</td>
						<td>
							{currentStreak} <sup>%</sup>
						</td>
					</tr>
					<tr>
						<td>{t("LongestStreak")}</td>
						<td className="alignRight">{user.Streak.Longest}</td>
						<td>
							<sup>{t("days")}</sup>
						</td>
						<td>
							{bestStreak} <sup>%</sup>
						</td>
					</tr>
					<tr>
						<td>{t("CurrentRanking")}</td>
						<td className="alignRight">{user.CurrentRank}</td>
						<td>
							<sup>{determineSuperscriptText(user.CurrentRank || 0)}</sup>
						</td>
						<td>
							{currentRank} <sup>%</sup>
						</td>
					</tr>
					<tr>
						<td>{t("BestRankingEver")}</td>
						<td className="alignRight">{user.BestRank}</td>
						<td>
							<sup>{determineSuperscriptText(user.BestRank || 0)}</sup>
						</td>
						<td>
							{bestRank} <sup>%</sup>
						</td>
					</tr>
					<tr>
						<td>{t("LastWeekPracticeTime")}</td>
						<td className="alignRight">{practiceTimeLastWeek}</td>
						<td>
							<sup>{t("minPerDay")}</sup>
						</td>
						<td>
							{practiceTimeWeek} <sup>%</sup>
						</td>
					</tr>
					<tr>
						<td>{t("LastMonthPracticeTime")}</td>
						<td className="alignRight">{practiceTimeLastMonth}</td>
						<td>
							<sup>{t("minPerDay")}</sup>
						</td>
						<td>
							{practiceTimeMonth} <sup>%</sup>
						</td>
					</tr>
					<tr>
						<td>{t("LastWeekProgress")}</td>
						<td className="alignRight">{user.WeeklyStats.Progress}</td>
						<td>
							<sup>{t("pts")}</sup>
						</td>
						<td>
							{lastWeekProgress} <sup>%</sup>
						</td>
					</tr>
					<tr>
						<td>{t("TotalProgress")}</td>
						<td className="alignRight">{user.Progress.Total}</td>
						<td>
							<sup>%</sup>
						</td>
						<td>
							{totalProgress} <sup>%</sup>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
}

function calcRankPerformance(rank: number | undefined, totalStudents: number): string {
	if (!rank) return "N/A";
	const percentage = Math.round((rank / totalStudents) * 100);
	return `${percentage}`;
}

function calcPerformance<T>(value: T, students: t.User[], path: (user: t.User) => T): string {
	const values = students.map(path);
	const sortedValues = values.sort((a: any, b: any) => b - a);
	const index = sortedValues.indexOf(value);
	const percentage = Math.round((index / students.length) * 100);
	return `${percentage}`;
}
