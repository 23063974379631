import React, { createContext, useContext, ReactNode, useState } from "react";
import * as t from "../data/types";

interface AlertContextProps {
	showAlert: boolean;
	alertConfig: t.AlertConfig;
	triggerAlert: (message: string, type?: "error" | "success" | "") => void;
	triggerConfirm: (message: string, onConfirm: () => void, onCancel?: () => void) => void;
	closeAlert: () => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const AlertProvider = ({ children }: { children: ReactNode }) => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertConfig, setAlertConfig] = useState<t.AlertConfig>({ message: "", type: "" });

	const triggerAlert = (message: string, type?: "error" | "success" | "") => {
		if (type === undefined) type = "";
		setShowAlert(true);
		setAlertConfig({ message: message, type: type });
	};

	const triggerConfirm = (message: string, onConfirm: () => void, onCancel?: () => void) => {
		setShowAlert(true);
		setAlertConfig({ message, type: "", onConfirm, onCancel });
	};

	const closeAlert = () => {
		setShowAlert(false);
		setAlertConfig({ message: "", type: "" });
	};

	return (
		<AlertContext.Provider value={{ showAlert, alertConfig, triggerAlert, triggerConfirm, closeAlert }}>
			{children}
		</AlertContext.Provider>
	);
};

export const useAlert = () => {
	const context = useContext(AlertContext);
	if (context === undefined) throw new Error("useAlert must be used within a AlertProvider");
	return context;
};
