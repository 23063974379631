import { createSectionsForDegreeTypes } from "./utils";

const chords2 = [
	["i", "iv", "V"],
	["i", "iiDim", "V"],
	["i", "bIII", "V"],
	["i", "iv", "bVI"],
	["i", "V", "bVI"],
];
const chords3 = [
	["i", "bIII", "iv"],
	["i", "bIII", "bVI"],
	["i", "bIII", "bVII"],
	["i", "iv", "bVII"],
	["i", "V", "bVII"],
];
const chords4 = [
	["i", "bVI", "bVII"],
	["i", "iiDim", "bIII"],
	["i", "iiDim", "iv"],
	["i", "iiDim", "bVI"],
	["i", "iiDim", "bVII"],
];
const chords5 = [
	["i", "iiDim", "bIII", "V"],
	["i", "iiDim", "iv", "V"],
	["i", "iiDim", "V", "bVI"],
	["i", "iiDim", "V", "bVII"],
	["i", "bIII", "iv", "V"],
];
const chords6 = [
	["i", "bIII", "V", "bVI"],
	["i", "bIII", "V", "bVII"],
	["i", "iv", "V", "bVI"],
	["i", "iv", "V", "bVII"],
	["i", "V", "bVI", "bVII"],
];
const chords7 = [
	["i", "iiDim", "bIII", "iv", "V"],
	["i", "iiDim", "bIII", "V", "bVI"],
	["i", "iiDim", "bIII", "V", "bVII"],
	["i", "iiDim", "iv", "V", "bVI"],
	["i", "iiDim", "V", "bVI", "bVII"],
];
const chords8 = [
	["i", "bIII", "iv", "V", "bVI"],
	["i", "bIII", "iv", "V", "bVII"],
	["i", "bIII", "V", "bVI", "bVII"],
	["i", "iv", "V", "bVI", "bVII"],
];
const chords9 = [
	["i", "iiDim", "bIII", "iv", "V", "bVI"],
	["i", "iiDim", "bIII", "V", "bVI", "bVII"],
	["i", "iiDim", "iv", "V", "bVI", "bVII"],
	["i", "bIII", "iv", "V", "bVI", "bVII"],
];
const chords10 = [["i", "iiDim", "bIII", "iv", "V", "bVI", "bVII"]];

const chords = [
	{ name: "Three chord combos 1", combinations: chords2 },
	{ name: "Three chord combos 2", combinations: chords3 },
	{ name: "Three chord combos 3", combinations: chords4 },
	{ name: "Four chord combos 1", combinations: chords5 },
	{ name: "Four chord combos 2", combinations: chords6 },
	{ name: "Five chord combos 1", combinations: chords7 },
	{ name: "Five chord combos 2", combinations: chords8 },
	{ name: "Six chord combos", combinations: chords9 },
	{ name: "Boss Mode", combinations: chords10 },
];

export const allSections = chords.map(({ name, combinations }) => createSectionsForDegreeTypes(name, combinations));

export const PROGRESSION_MINOR_SECTIONS = allSections.map((section) => section.standard);
export const PROGRESSION_MINOR_SECTIONS_FIRST_INVERSIONS = allSections.map((section) => section.firstInversions);
export const PROGRESSION_MINOR_SECTIONS_SECOND_INVERSIONS = allSections.map((section) => section.secondInversions);
