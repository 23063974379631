import { convertDateToString, parseDate } from "../../utils/dates";
import { spaceBeforeCapitals } from "../../utils/sharedUtils";
import * as t from "../../data/types";

export function calculateDaysLeft(deadline: any) {
	const parsedDate = parseDate(deadline);
	const today = new Date();
	const diffTime = Math.abs(parsedDate.getTime() - today.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
	return diffDays;
}

export function parseTargetData(selectedClass: t.Class | undefined, targets: t.Target[] | undefined): string[][] {
	const targetData: string[][] = [];
	if (!selectedClass || !targets) return targetData;
	targets.map((target) => {
		const module = target.Module;
		const difficulty = target.Difficulty;
		const percentage = target.Percentage ? target.Percentage.toString() : "0";
		const deadline = convertDateToString(target.Deadline, "dd-mm-yyyy");
		const daysLeft = calculateDaysLeft(target.Deadline).toString();
		const successRate = calculateSuccesRate(selectedClass, target).toString();
		const targetID = target.id;
		targetData.push([deadline, module, difficulty, percentage, daysLeft, successRate, targetID]);
		return null;
	});
	return targetData;
}

export function parsePastTargetData(selectedClass: t.Class | undefined, targets: t.Target[] | undefined): string[][] {
	const targetData: string[][] = [];
	if (!selectedClass || !targets) return targetData;
	targets.map((target) => {
		const module = target.Module;
		const difficulty = target.Difficulty;
		const percentage = target.Percentage ? target.Percentage.toString() : "0";
		const deadline = convertDateToString(target.Deadline, "dd-mm-yyyy");
		const successRate = calculateSuccesRatePastTarget(selectedClass, target).toString();
		const targetID = target.id;
		targetData.push([deadline, module, difficulty, percentage, successRate, targetID]);
		return null;
	});
	return targetData;
}

function calculateSuccesRate(classData: t.Class, target: t.Target): number {
	let successRate = 0;

	classData?.Students?.forEach((student) => {
		const moduleProgress =
			student.Progress?.modules[target.Module]?.difficulties[target.Difficulty]?.DifficultyTotal || 0;
		if (moduleProgress >= target.Percentage) successRate++;
	});
	return Math.round((successRate / classData?.Students?.length) * 100);
}

function calculateSuccesRatePastTarget(classData: t.Class, target: t.Target): number {
	let successRate = 0;

	classData?.Students?.forEach((student) => {
		if (!target.Results) return;
		const studentResult = target.Results.find((result) => result.StudentID === student.id);
		if (studentResult && studentResult?.Progress >= 100) successRate++;
	});
	return Math.round((successRate / classData?.Students?.length) * 100);
}
