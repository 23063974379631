import * as e from "../../enums";
import { createSection } from "./utils";

const meetTriadsLevels = [
	{ options: [e.Chords.MAJOR, e.Chords.MINOR], levelName: "Major or Minor" },
	{ options: [e.Chords.MAJOR, e.Chords.AUG], levelName: "Major or Augmented" },
	{ options: [e.Chords.MAJOR, e.Chords.DIM], levelName: "Major or Diminished" },
	{ options: [e.Chords.MINOR, e.Chords.AUG], levelName: "Minor or Augmented" },
	{ options: [e.Chords.MINOR, e.Chords.DIM], levelName: "Minor or Diminished" },
	{ options: [e.Chords.AUG, e.Chords.DIM], levelName: "Augmented or Diminished" },
];

const masteringTriadsLevels = [
	{
		options: [e.Chords.MAJOR, e.Chords.MINOR, e.Chords.AUG, e.Chords.DIM],
		levelName: "Major, Minor, Augmented or Diminished",
	},
	{ options: [e.Chords.SUS2, e.Chords.SUS4], levelName: "Sus2 or Sus4" },
	{
		options: [e.Chords.MAJOR, e.Chords.MINOR, e.Chords.SUS2, e.Chords.SUS4],
		levelName: "Major, Minor, Sus2 or Sus4",
	},
	{
		options: [e.Chords.AUG, e.Chords.DIM, e.Chords.SUS2, e.Chords.SUS4],
		levelName: "Augmented, Diminished, Sus2 or Sus4",
	},
	{
		options: [e.Chords.MAJOR, e.Chords.MINOR, e.Chords.AUG, e.Chords.DIM, e.Chords.SUS2, e.Chords.SUS4],
		levelName: "All the triads",
	},
];
const meetFirst7thLevels = [
	{ options: [e.Chords.DOMINANT, e.Chords.MINOR_7TH], levelName: "Dominant or Minor 7th" },
	{ options: [e.Chords.DOMINANT, e.Chords.AUG_7TH], levelName: "Dominant or Augmented 7th" },
	{ options: [e.Chords.DOMINANT, e.Chords.DIM_7TH], levelName: "Dominant or Diminished 7th" },
	{ options: [e.Chords.MINOR_7TH, e.Chords.AUG_7TH], levelName: "Minor 7th or Augmented 7th" },
	{ options: [e.Chords.MINOR_7TH, e.Chords.DIM_7TH], levelName: "Minor 7th or Diminished 7th" },
	{ options: [e.Chords.AUG_7TH, e.Chords.DIM_7TH], levelName: "Augmented 7th or Diminished 7th" },
];
const diggingInLevels = [
	{
		options: [e.Chords.DOMINANT, e.Chords.MINOR_7TH, e.Chords.AUG_7TH],
		levelName: "Dominant, Minor 7th or Augmented 7th",
	},
	{
		options: [e.Chords.DOMINANT, e.Chords.MINOR_7TH, e.Chords.DIM_7TH],
		levelName: "Dominant, Minor 7th or Diminished 7th",
	},
	{
		options: [e.Chords.DOMINANT, e.Chords.DIM_7TH, e.Chords.AUG_7TH],
		levelName: "Dominant, Augmented 7th, Diminished 7th",
	},
	{
		options: [e.Chords.MINOR_7TH, e.Chords.DIM_7TH, e.Chords.AUG_7TH],
		levelName: "Minor 7th, Augmented 7th or Diminished 7th",
	},
	{
		options: [e.Chords.DOMINANT, e.Chords.MINOR_7TH, e.Chords.DIM_7TH, e.Chords.AUG_7TH],
		levelName: "All the 7th chords so far",
	},
];
const diggingDeeperLevels = [
	{
		options: [
			e.Chords.MAJOR,
			e.Chords.MINOR,
			e.Chords.AUG,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.DOMINANT,
			e.Chords.MINOR_7TH,
			e.Chords.AUG_7TH,
		],
		levelName: "All chords so far except the diminished chords",
	},
	{
		options: [
			e.Chords.MAJOR,
			e.Chords.MINOR,
			e.Chords.DIM,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.DOMINANT,
			e.Chords.MINOR_7TH,
			e.Chords.DIM_7TH,
		],
		levelName: "All chords so far except the augmented chords",
	},
	{
		options: [
			e.Chords.MAJOR,
			e.Chords.AUG,
			e.Chords.DIM,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.DOMINANT,
			e.Chords.AUG_7TH,
			e.Chords.DIM_7TH,
		],
		levelName: "All chords so far except the minor chords",
	},
	{
		options: [
			e.Chords.MINOR,
			e.Chords.AUG,
			e.Chords.DIM,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.MINOR_7TH,
			e.Chords.AUG_7TH,
			e.Chords.DIM_7TH,
		],
		levelName: "All chords so far except the major chords",
	},
	{
		options: [
			e.Chords.MAJOR,
			e.Chords.MINOR,
			e.Chords.AUG,
			e.Chords.DIM,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.DOMINANT,
			e.Chords.MINOR_7TH,
			e.Chords.DIM_7TH,
			e.Chords.AUG_7TH,
		],
		levelName: "All the chords so far",
	},
];
const introducingNew7thLevels = [
	{
		options: [e.Chords.MAJOR_7TH, e.Chords.MINOR_MAJOR_7TH],
		levelName: "Major 7th or Minor Major 7th",
	},
	{
		options: [e.Chords.MAJOR_7TH, e.Chords.AUG_MAJOR_7TH],
		levelName: "Major 7th or Augmented Major 7th",
	},
	{
		options: [e.Chords.MAJOR_7TH, e.Chords.HALF_DIM_7TH],
		levelName: "Major 7th or Half Diminished 7th",
	},
	{
		options: [e.Chords.MINOR_MAJOR_7TH, e.Chords.AUG_MAJOR_7TH],
		levelName: "Minor Major 7th or Augmented Major 7th",
	},
	{
		options: [e.Chords.MINOR_MAJOR_7TH, e.Chords.HALF_DIM_7TH],
		levelName: "Minor Major 7th or Half Diminished 7th",
	},
	{
		options: [e.Chords.AUG_MAJOR_7TH, e.Chords.HALF_DIM_7TH],
		levelName: "Augmented Major 7th or Half Diminished 7th",
	},
];
const majorMinorLevels = [
	{
		options: [e.Chords.DOMINANT, e.Chords.MAJOR_7TH],
		levelName: "Dominant or Major 7th",
	},
	{
		options: [e.Chords.DOMINANT, e.Chords.MINOR_MAJOR_7TH],
		levelName: "Dominant or Minor Major 7th",
	},
	{
		options: [e.Chords.MINOR_7TH, e.Chords.MAJOR_7TH],
		levelName: "Minor 7th or Major 7th",
	},
	{
		options: [e.Chords.MINOR_7TH, e.Chords.MINOR_MAJOR_7TH],
		levelName: "Minor 7th or Minor Major 7th",
	},
	{
		options: [e.Chords.DOMINANT, e.Chords.MAJOR_7TH, e.Chords.MINOR_7TH, e.Chords.MINOR_MAJOR_7TH],
		levelName: "Dominant, Major 7th, Minor 7th or Minor Major 7th",
	},
];
const diminishedAugmentedLevels = [
	{
		options: [e.Chords.AUG_7TH, e.Chords.AUG_MAJOR_7TH],
		levelName: "Augmented 7th or Augmented Major 7th",
	},
	{
		options: [e.Chords.AUG_7TH, e.Chords.HALF_DIM_7TH],
		levelName: "Augmented 7th or Half Diminished 7th",
	},
	{
		options: [e.Chords.DIM_7TH, e.Chords.AUG_MAJOR_7TH],
		levelName: "Diminished 7th or Augmented Major 7th",
	},
	{
		options: [e.Chords.DIM_7TH, e.Chords.HALF_DIM_7TH],
		levelName: "Diminished 7th or Half Diminished 7th",
	},
	{
		options: [e.Chords.AUG_7TH, e.Chords.AUG_MAJOR_7TH, e.Chords.DIM_7TH, e.Chords.HALF_DIM_7TH],
		levelName: "Augmented 7th, Augmented Major 7th, Diminished 7th or Half Diminished 7th",
	},
];
const majorDiminishedAugmentedLevels = [
	{
		options: [e.Chords.DOMINANT, e.Chords.AUG_MAJOR_7TH],
		levelName: "Dominant or Augmented Major 7th",
	},
	{
		options: [e.Chords.DOMINANT, e.Chords.HALF_DIM_7TH],
		levelName: "Dominant or Half Diminished 7th",
	},
	{
		options: [e.Chords.MAJOR_7TH, e.Chords.AUG_7TH],
		levelName: "Major 7th or Augmented 7th",
	},
	{
		options: [e.Chords.MAJOR_7TH, e.Chords.DIM_7TH],
		levelName: "Major 7th or Diminished 7th",
	},
	{
		options: [
			e.Chords.DOMINANT,
			e.Chords.MAJOR_7TH,
			e.Chords.AUG_7TH,
			e.Chords.AUG_MAJOR_7TH,
			e.Chords.DIM_7TH,
			e.Chords.HALF_DIM_7TH,
		],
		levelName: "Dominant, Major 7th, Aug 7th, Aug Major 7th, Dim 7th or Half Dim 7th",
	},
];
const minorDiminishedAugmentedLevels = [
	{
		options: [e.Chords.MINOR_7TH, e.Chords.AUG_MAJOR_7TH],
		levelName: "Minor 7th or Augmented Major 7th",
	},
	{
		options: [e.Chords.MINOR_7TH, e.Chords.HALF_DIM_7TH],
		levelName: "Minor 7th or Half Diminished 7th",
	},
	{
		options: [e.Chords.MINOR_MAJOR_7TH, e.Chords.AUG_7TH],
		levelName: "Minor Major 7th or Augmented 7th",
	},
	{
		options: [e.Chords.MINOR_MAJOR_7TH, e.Chords.DIM_7TH],
		levelName: "Minor Major 7th or Diminished 7th",
	},
	{
		options: [
			e.Chords.MINOR_7TH,
			e.Chords.MINOR_MAJOR_7TH,
			e.Chords.AUG_7TH,
			e.Chords.AUG_MAJOR_7TH,
			e.Chords.DIM_7TH,
			e.Chords.HALF_DIM_7TH,
		],
		levelName: "Minor 7th, Minor Major 7th, Aug 7th, Aug Major 7th, Dim 7th or Half Dim 7th",
	},
];
const mastering7thLevels = [
	{
		options: [
			e.Chords.MAJOR,
			e.Chords.MINOR,
			e.Chords.AUG,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.DOMINANT,
			e.Chords.MINOR_7TH,
			e.Chords.AUG_7TH,
			e.Chords.MAJOR_7TH,
			e.Chords.MINOR_MAJOR_7TH,
			e.Chords.AUG_MAJOR_7TH,
		],
		levelName: "All the 7th chords except for the diminished chords",
	},
	{
		options: [
			e.Chords.MAJOR,
			e.Chords.MINOR,
			e.Chords.DIM,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.DOMINANT,
			e.Chords.MINOR_7TH,
			e.Chords.DIM_7TH,
			e.Chords.MAJOR_7TH,
			e.Chords.MINOR_MAJOR_7TH,
			e.Chords.HALF_DIM_7TH,
		],
		levelName: "All the 7th chords except for the augmented chords",
	},
	{
		options: [
			e.Chords.MAJOR,
			e.Chords.AUG,
			e.Chords.DIM,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.DOMINANT,
			e.Chords.DIM_7TH,
			e.Chords.AUG_7TH,
			e.Chords.MAJOR_7TH,
			e.Chords.HALF_DIM_7TH,
			e.Chords.AUG_MAJOR_7TH,
		],
		levelName: "All the 7th chords except for the minor chords",
	},
	{
		options: [
			e.Chords.MINOR,
			e.Chords.AUG,
			e.Chords.DIM,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.MINOR_7TH,
			e.Chords.DIM_7TH,
			e.Chords.AUG_7TH,
			e.Chords.MINOR_MAJOR_7TH,
			e.Chords.HALF_DIM_7TH,
			e.Chords.AUG_MAJOR_7TH,
		],
		levelName: "All the 7th chords except for the major chords",
	},
];
const bossLevelLevels = [
	{
		options: [
			e.Chords.MAJOR,
			e.Chords.MINOR,
			e.Chords.AUG,
			e.Chords.DIM,
			e.Chords.SUS2,
			e.Chords.SUS4,
			e.Chords.DOMINANT,
			e.Chords.MINOR_7TH,
			e.Chords.DIM_7TH,
			e.Chords.AUG_7TH,
			e.Chords.MAJOR_7TH,
			e.Chords.MINOR_MAJOR_7TH,
			e.Chords.HALF_DIM_7TH,
			e.Chords.AUG_MAJOR_7TH,
		],
		levelName: "All chords",
	},
];

export const CHORD_RECOGNITION_SECTIONS = [
	createSection("Meet the triads", meetTriadsLevels),
	createSection("Mastering the triads", masteringTriadsLevels),
	createSection("Meet the first 7th chords", meetFirst7thLevels),
	createSection("Digging in", diggingInLevels),
	createSection("Digging deeper", diggingDeeperLevels),
	createSection("Introducing new 7th chords", introducingNew7thLevels),
	createSection("Major Minor", majorMinorLevels),
	createSection("Dimineshed Augmented", diminishedAugmentedLevels),
	createSection("Major Diminished Augmented", majorDiminishedAugmentedLevels),
	createSection("Minor Diminished Augmented", minorDiminishedAugmentedLevels),
	createSection("Mastering the 7th chords", mastering7thLevels),
	createSection("Boss Mode", bossLevelLevels),
];

// Major 7th (Maj7)	:			Major 9th (Maj9):		Major 11th (Maj11):			Major 13th (Maj13):
// 1 - 3 - 5 - 7				1 - 3 - 5 - 7 - 9		1 - 3 - 5 - 7 - 9 - 11		1 - 3 - 5 - 7 - 9 - 11 - 13

// Dominant 7th (7):			Dominant 9th (9):		Dominant 11th (11):			Dominant 13th (13):
// 1 - 3 - 5 - b7				1 - 3 - 5 - b7 - 9		1 - 3 - 5 - b7 - 9 - 11		1 - 3 - 5 - b7 - 9 - 11 - 13

// Minor 7th (m7):				Minor 9th (m9):			Minor 11th (m11):			Minor 13th (m13):
// 1 - b3 - 5 - b7				1 - b3 - 5 - b7 - 9		1 - b3 - 5 - b7 - 9 - 11	1 - b3 - 5 - b7 - 9 - 11 - 13
