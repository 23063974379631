import React from "react";
import { capitalizeFirstLetter } from "../utils/sharedUtils";
import { useAlert } from "../context/AlertContext";

export default function CustomAlert(): JSX.Element {
	const { alertConfig, closeAlert } = useAlert();

	const handleConfirm = () => {
		if (alertConfig.onConfirm) {
			alertConfig.onConfirm();
		}
		closeAlert();
	};

	const handleCancel = () => {
		if (alertConfig.onCancel) {
			alertConfig.onCancel();
		}
		closeAlert();
	};

	return (
		<div className="alert">
			<div className={`alert-content ${alertConfig.type}`}>
				{alertConfig.type !== "" && <h2>{capitalizeFirstLetter(alertConfig.type)}</h2>}
				{alertConfig.message}
				{alertConfig.onConfirm ? (
					<div className="flexRow">
						<button className="redButton" style={{ padding: "0.7em" }} onClick={handleConfirm}>
							Yes
						</button>
						<button className="buttonOpen" onClick={handleCancel}>
							No
						</button>
					</div>
				) : (
					<button onClick={closeAlert}>Continue</button>
				)}
			</div>
		</div>
	);
}
