import axios from "axios";
import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function updateStudentSubscriptionAPI(email: string | null, plan: "student" | "free" | "monthly") {
	if (!email) return null;
	console.log("API CALL: fetchStudentAPI");

	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		const response = await axios.put(
			"https://auris-db-backend.vercel.app/updateStudentSubscriptionPlan",
			{ email: email, plan },
			{ headers: { Authorization: idToken } }
		);

		return response.data;
	} catch (error: any) {
		Sentry.captureException(error);
		throw error;
	}
}
