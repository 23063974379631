import { createSectionsForDegreeTypes } from "./utils";

const chords2 = [
	["I", "IV", "V"],
	["I", "ii", "V"],
	["I", "V", "vi"],
	["I", "IV", "vi"],
	["I", "ii", "IV"],
];
const chords3 = [
	["I", "ii", "iii"],
	["I", "ii", "vi"],
	["I", "iii", "IV"],
	["I", "iii", "V"],
	["I", "iii", "vi"],
];
const chords4 = [
	["I", "ii", "viiDim"],
	["I", "iii", "viiDim"],
	["I", "IV", "viiDim"],
	["I", "V", "viiDim"],
	["I", "vi", "viiDim"],
];
const chords5 = [
	["I", "ii", "IV", "V"],
	["I", "ii", "V", "vi"],
	["I", "IV", "V", "vi"],
	["I", "ii", "iii", "V"],
	["I", "iii", "IV", "V"],
];
const chords6 = [
	["I", "iii", "V", "vi"],
	["I", "ii", "V", "viiDim"],
	["I", "iii", "V", "viiDim"],
	["I", "IV", "V", "viiDim"],
	["I", "V", "vi", "viiDim"],
];
const chords7 = [
	["I", "ii", "iii", "IV", "V"],
	["I", "ii", "iii", "V", "vi"],
	["I", "ii", "iii", "V", "viiDim"],
	["I", "ii", "IV", "V", "vi"],
	["I", "ii", "V", "vi", "viiDim"],
];
const chords8 = [
	["I", "iii", "IV", "V", "vi"],
	["I", "iii", "IV", "V", "viiDim"],
	["I", "iii", "V", "vi", "viiDim"],
	["I", "IV", "V", "vi", "viiDim"],
];
const chords9 = [
	["I", "ii", "iii", "IV", "V", "vi"],
	["I", "ii", "iii", "V", "vi", "viiDim"],
	["I", "ii", "IV", "V", "vi", "viiDim"],
	["I", "iii", "IV", "V", "vi", "viiDim"],
];
const chords10 = [["I", "ii", "iii", "IV", "V", "vi", "viiDim"]];

const chords = [
	{ name: "Three chord combos 1", combinations: chords2 },
	{ name: "Three chord combos 2", combinations: chords3 },
	{ name: "Three chord combos 3", combinations: chords4 },
	{ name: "Four chord combos 1", combinations: chords5 },
	{ name: "Four chord combos 2", combinations: chords6 },
	{ name: "Five chord combos 1", combinations: chords7 },
	{ name: "Five chord combos 2", combinations: chords8 },
	{ name: "Six chord combos", combinations: chords9 },
	{ name: "Boss Mode", combinations: chords10 },
];

export const allSections = chords.map(({ name, combinations }) => createSectionsForDegreeTypes(name, combinations));

export const PROGRESSION_MAJOR_SECTIONS = allSections.map((section) => section.standard);
export const PROGRESSION_MAJOR_SECTIONS_FIRST_INVERSIONS = allSections.map((section) => section.firstInversions);
export const PROGRESSION_MAJOR_SECTIONS_SECOND_INVERSIONS = allSections.map((section) => section.secondInversions);
