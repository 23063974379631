import * as t from "../../data/types";
import { handleError } from "../../utils/sharedUtils";
import createStudentAPI from "../api/createStudentAPI";
import createTeacherAPI from "../api/createTeacherAPI";
import fetchAndSetTeacher from "../api/fetchAndSetTeacherAPI";
import getClassesByTeacherAPI from "../api/getClassesByTeacherAPI";
import { createStudentData } from "./firebaseUtils";
import * as Sentry from "@sentry/react";

export class FirebaseManager {
	private static initialized = false;
	private static initializing = false; // To prevent concurrent initializations
	private static appInstance: any = null;
	private static authInstance: any = null;
	private static userCredential: any = null;

	private static firebaseConfig = {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
	};

	private static async ensureInitialized() {
		if (this.initialized) return;

		if (this.initializing) {
			// Wait until initialization is complete
			await new Promise((resolve, reject) => {
				const checkInit = () => {
					if (this.initialized) resolve(true);
					else if (!this.initializing) reject(new Error("Initialization failed."));
					else setTimeout(checkInit, 100);
				};
				checkInit();
			});
			return;
		}

		this.initializing = true;

		try {
			const { initializeApp } = await import("firebase/app");
			const { getAuth, setPersistence, browserLocalPersistence } = await import("firebase/auth");

			// Validate Firebase configuration
			const missingConfigKeys = Object.entries(this.firebaseConfig)
				.filter(([key, value]) => !value)
				.map(([key]) => key);

			if (missingConfigKeys.length > 0)
				throw new Error(`Missing Firebase configuration keys: ${missingConfigKeys.join(", ")}`);

			this.appInstance = initializeApp(this.firebaseConfig);
			this.authInstance = getAuth(this.appInstance);

			// Set persistence
			await setPersistence(this.authInstance, browserLocalPersistence);

			if (this.appInstance && this.authInstance) this.initialized = true;
			else throw new Error("Failed to initialize Firebase app and auth.");
		} catch (error) {
			Sentry.captureException(error);
			this.initialized = false;
			// Re-throw the error to alert calling functions
			throw error;
		} finally {
			this.initializing = false;
		}
	}

	public static async monitorAuthState(
		setTeacher: React.Dispatch<React.SetStateAction<t.Teacher | null>>,
		setClassData: React.Dispatch<React.SetStateAction<t.Class[] | null>>
	) {
		await this.ensureInitialized();
		const { onAuthStateChanged } = await import("firebase/auth");
		return onAuthStateChanged(this.authInstance!, async (firebaseUser) => {
			try {
				console.log("Auth state changed");
				if (firebaseUser) {
					const sessionUser = localStorage.getItem("teacher");
					const sessionClasses = localStorage.getItem("classes");
					// console.log("Session data: <", sessionUser, sessionClasses, "> retrieved from localStorage");
					const lastDataFetch = localStorage.getItem("lastDataFetch");
					const currentTime = new Date().getTime();
					const oneHour = 60 * 60 * 1000;
					const lastDataFetchIsOld = lastDataFetch ? currentTime - parseInt(lastDataFetch) > oneHour : true;
					if (!sessionUser || lastDataFetchIsOld || !sessionClasses) {
						const userData = await fetchAndSetTeacher(firebaseUser.email, setTeacher);
						if (userData && userData.id && (!sessionClasses || lastDataFetchIsOld))
							await getClassesByTeacherAPI(userData.id, setClassData);
						localStorage.setItem("lastDataFetch", currentTime.toString());
					}
				} else {
					setTeacher(null);
					setClassData(null);
				}
			} catch (error) {
				console.error("Error in monitorAuthState", error);
			}
		});
	}

	public static async login(
		email: string,
		password: string,
		setTeacher: React.Dispatch<React.SetStateAction<t.Teacher | null>>
	) {
		await this.ensureInitialized();
		try {
			const { signInWithEmailAndPassword } = await import("firebase/auth");
			this.userCredential = await signInWithEmailAndPassword(this.authInstance, email, password);
			const firebaseUser = this.userCredential.user;
			const idTokenResult = await firebaseUser.getIdTokenResult();

			try {
				if (idTokenResult.claims.role !== "Student") await fetchAndSetTeacher(firebaseUser.email, setTeacher);
				else {
					console.log(idTokenResult.claims.role);
					this.authInstance.signOut();
					throw new Error("User is not a teacher.");
				}
			} catch (error: any) {
				throw error;
			}
		} catch (error) {
			throw error;
		}
	}

	public static async logout(setTeacher: Function) {
		await this.ensureInitialized();
		try {
			const { signOut } = await import("firebase/auth");
			await signOut(this.authInstance);
			sessionStorage.clear();
			localStorage.removeItem("teacher");
			localStorage.removeItem("classes");
			setTeacher(null);
			// window.location.href = "https://teacher.auriseartraining.com";
		} catch (error) {
			throw error;
		}
	}

	public static async createNewStudent(
		studentEmail: string,
		password: string,
		firstName: string,
		lastName: string,
		studentNumber: string,
		selectedClass: t.Class
	) {
		const parsedEmail = studentEmail.toLowerCase().trim();
		await this.ensureInitialized();
		try {
			const { createUserWithEmailAndPassword } = await import("firebase/auth");
			const userCredential = await createUserWithEmailAndPassword(this.authInstance, parsedEmail, password);
			if (!userCredential || !userCredential.user) {
				console.log("Error:", this.authInstance, userCredential, userCredential.user);
				await userCredential.user.delete();
				throw new Error("Oops! Something went wrong. Please try again.");
			}
			const studentData = createStudentData(
				parsedEmail,
				firstName,
				lastName,
				studentNumber,
				selectedClass.id,
				selectedClass.ClassName
			);
			await createStudentAPI(studentData);
		} catch (error) {
			throw error;
		}
	}

	public static async signUpTeacher(
		firstName: string,
		lastName: string,
		email: string,
		schoolName: string,
		schoolID: string,
		password: string,
		setErrorMessage: React.Dispatch<React.SetStateAction<string>>
	) {
		await this.ensureInitialized();
		try {
			const { createUserWithEmailAndPassword } = await import("firebase/auth");
			const trimmedEmail = email.toLocaleLowerCase().trim();
			this.userCredential = await createUserWithEmailAndPassword(this.authInstance, trimmedEmail, password);
			this.createTeacher(trimmedEmail, firstName, lastName, schoolName, schoolID, setErrorMessage);
		} catch (error) {
			console.error("Signup failed:", error);
			throw error;
		}
	}

	// TODO: make public if signUpWithGoogle is needed in the future?
	private static async createTeacher(
		email: string,
		firstName: string,
		lastName: string,
		schoolName: string,
		schoolID: string,
		setErrorMessage: React.Dispatch<React.SetStateAction<string>>
	) {
		await this.ensureInitialized();
		try {
			await createTeacherAPI(firstName, lastName, email, schoolName, schoolID);
		} catch (error) {
			await this.userCredential.user?.delete();
			setErrorMessage(handleError(error).userMessage);
		}
	}

	public static async resetPassword(email: string) {
		await this.ensureInitialized();
		try {
			const { sendPasswordResetEmail } = await import("firebase/auth");
			await sendPasswordResetEmail(this.authInstance, email);
		} catch (error) {
			throw error;
		}
	}

	public static async updatePassword(currentPassword: string, newPassword: string) {
		await this.ensureInitialized();
		try {
			const authUser = this.authInstance.currentUser;
			const { EmailAuthProvider, reauthenticateWithCredential, updatePassword } = await import("firebase/auth");
			const credential = EmailAuthProvider.credential(authUser.email, currentPassword);
			await reauthenticateWithCredential(authUser, credential);
			await updatePassword(authUser, newPassword);
		} catch (error) {
			throw error;
		}
	}

	public static async deleteUser() {
		await this.ensureInitialized();
		try {
			const authUser = this.authInstance.currentUser;
			await authUser.delete();
		} catch (error) {
			throw error;
		}
	}

	public static async getAppInstance(): Promise<any> {
		await this.ensureInitialized();
		return this.appInstance;
	}

	public static async getAuthInstance(): Promise<any> {
		await this.ensureInitialized();
		return this.authInstance;
	}

	public static async getAuthIdToken(): Promise<string> {
		await this.ensureInitialized();
		if (!this.authInstance) throw new Error("Auth instance is not initialized.");

		const user = this.authInstance.currentUser;
		if (!user) throw new Error("No authenticated user available to retrieve ID token.");

		try {
			return await user.getIdToken(); // Fetch a fresh token; Firebase SDK will refresh it if expired
		} catch (error) {
			throw new Error(`Error retrieving ID token: ${error}`);
		}
	}
}
