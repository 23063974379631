import React, { useEffect, useState } from "react";
import * as e from "../data/enums";
import * as t from "../data/types";
import Students from "../features/Students/Students";
import Targets from "../features/Targets/Targets";
import Exams from "../features/Exams/Exams";
import { useNavigate, useParams } from "react-router-dom";
import { useTeacherLoggedIn } from "../context/UserContext";
import { checkIfClassExists } from "../utils/utils";
import { useTranslation } from "react-i18next";

export default function Overview() {
	const { classData } = useTeacherLoggedIn();
	const { classParam, overviewParam } = useParams<{ classParam: string; overviewParam: e.Overview }>();
	const navigate = useNavigate();
	const overview = parseOverview(overviewParam);
	const selectedClass = classData?.find((classObj) => classObj.ClassName === classParam);

	useEffect(() => {
		if (!classData?.length) navigate("/manage_classes");
	}, [classData, navigate]);

	const classNames = classData?.map((classObj) => classObj.ClassName);
	// change order of classes so that selected class is first
	if (classNames && classParam && selectedClass && checkIfClassExists(classParam, classData)) {
		const index = classNames.indexOf(classParam);
		classNames.splice(index, 1);
		classNames.unshift(classParam);
	}

	if (!selectedClass)
		return (
			<main className="teacherPortal">
				<SelectClassComponent classNames={classData?.map((c) => c.ClassName) ?? []} overview={overview} />
			</main>
		);
	return (
		<main className="teacherPortal">
			<SelectClassComponent classNames={classData?.map((c) => c.ClassName) ?? []} overview={overview} />
			<OverviewContent overview={overview} selectedClass={selectedClass} />
		</main>
	);
}

function SelectClassComponent({ classNames, overview }: { classNames: string[]; overview: e.Overview }) {
	const navigate = useNavigate();
	const { t } = useTranslation("Overview");

	const handleSelection = (selectedClass: string) => {
		navigate(`/${selectedClass}/${overview}`);
	};

	return (
		<div>
			<select defaultValue="" onChange={(e) => handleSelection(e.target.value)}>
				<option value="" disabled>
					{t("selectClass")}
				</option>
				{classNames.map((name) => (
					<option key={name}>{name}</option>
				))}
			</select>
		</div>
	);
}

function OverviewContent({ overview, selectedClass }: { overview: e.Overview; selectedClass: t.Class }) {
	const navigate = useNavigate();
	const { t } = useTranslation("Overview");

	return (
		<div className="overview">
			<div className="overviewOptions">
				{Object.values(e.Overview).map((option) => (
					<h2
						key={option}
						className={overview === option ? "current" : ""}
						onClick={() => navigate(`/${selectedClass.ClassName}/${option}`)}
					>
						{t(option.toLowerCase())}
					</h2>
				))}
			</div>
			{overview === e.Overview.STUDENTS ? (
				<Students selectedClass={selectedClass} />
			) : overview === e.Overview.TARGETS ? (
				<Targets selectedClass={selectedClass} />
			) : overview === e.Overview.EXAMS ? (
				<Exams selectedClass={selectedClass} />
			) : null}
		</div>
	);
}

function parseOverview(overviewParam: string | undefined) {
	switch (overviewParam) {
		case e.Overview.STUDENTS:
			return e.Overview.STUDENTS;
		case e.Overview.TARGETS:
			return e.Overview.TARGETS;
		case e.Overview.EXAMS:
			return e.Overview.EXAMS;
		default:
			return e.Overview.STUDENTS;
	}
}
