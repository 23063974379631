import React, { useEffect } from "react";
import { useTeacherLoggedIn } from "../context/UserContext";

export function useModal<T>() {
	const [showModal, setShowModal] = React.useState(false);
	const [selectedItem, setSelectedItem] = React.useState<T | undefined>(undefined);

	const handleShowModal = (item?: T) => {
		setSelectedItem(item);
		setShowModal(true);
	};

	const handleHideModal = () => setShowModal(false);

	const { clickOutside } = useTeacherLoggedIn();
	useEffect(() => {
		setShowModal(false);
	}, [clickOutside]);

	return { showModal, setShowModal, selectedItem, setSelectedItem, handleShowModal, handleHideModal };
}
