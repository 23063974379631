import { createSection } from "./utils";

const highContrastLevels = [
	{ options: ["b9", "12"], levelName: "Minor 9th & Perfect 12th" },
	{ options: ["9", "14"], levelName: "Major 9th & Major 14th" },
	{ options: ["#9", "b14"], levelName: "Minor 10th & Minor 14th" },
	{ options: ["10", "13"], levelName: "Major 10th & Major 13th" },
	{ options: ["11", "b13"], levelName: "Perfect 11th & Minor 13th" },
	{ options: ["#11", "15"], levelName: "Diminished 12th & Double Octave" },
];
const relatedIntervalsLevels = [
	{ options: ["b9", "14"], levelName: "Minor 9th & Major 14th" },
	{ options: ["9", "b14"], levelName: "Major 9th & Minor 14th" },
	{ options: ["#9", "13"], levelName: "Minor 10th & Major 13th" },
	{ options: ["10", "b13"], levelName: "Major 10th & Minor 13th" },
	{ options: ["11", "12"], levelName: "Perfect 11th & Perfect 12th" },
];
const threeOptionsPart1Levels = [
	{ options: ["b9", "11", "13"], levelName: "Minor 9th, Perfect 11th & Major 13th" },
	{ options: ["9", "#11", "b14"], levelName: "Major 9th, Diminished 12th & Minor 14th" },
	{ options: ["#9", "12", "14"], levelName: "Minor 10th, Perfect 12th & Major 14th" },
	{ options: ["10", "b13", "15"], levelName: "Major 10th, Minor 13th & Double Octave" },
];
const threeOptionsPart2Levels = [
	{ options: ["b9", "11", "b13"], levelName: "Minor 9th, Perfect 11th & Minor 13th" },
	{ options: ["9", "#11", "13"], levelName: "Major 9th, Diminished 12th & Major 13th" },
	{ options: ["#9", "12", "b14"], levelName: "Minor 10th, Perfect 12th & Minor 14th" },
	{ options: ["10", "b13", "14"], levelName: "Major 10th, Minor 13th & Major 14th" },
	{ options: ["11", "13", "15"], levelName: "Perfect 11th, Major 13th & Double Octave" },
	{ options: ["#11", "b14", "b9"], levelName: "Minor 9th, Diminished 12th, Minor 14th" },
];
const threeOptionsPart3Levels = [
	{ options: ["12", "14", "9"], levelName: "Major 9th, Perfect 12th & Minor 14th" },
	{ options: ["b13", "15", "#9"], levelName: "Minor 10th,Minor 13th & Double Octave" },
	{ options: ["13", "b9", "10"], levelName: "Major 13th, Minor 9th & Major 10th" },
	{ options: ["b14", "9", "11"], levelName: "Minor 14th, Major 9th & Perfect 11th" },
	{ options: ["14", "#9", "#11"], levelName: "Minor 10th, Perfect 12th & Major 14th" },
	{ options: ["15", "10", "12"], levelName: "Double Octave, Major 10th & Diminished 12th" },
];
const lowContrastPart1Levels = [
	{ options: ["b9", "9"], levelName: "Minor 9th & Major 9th" },
	{ options: ["9", "#9"], levelName: "Major 9th & Minor 10th" },
	{ options: ["#9", "10"], levelName: "Minor 10th & Major 10th" },
	{ options: ["10", "11"], levelName: "Major 10th & Perfect 11th" },
	{ options: ["11", "#11"], levelName: "Perfect 11th & Diminished 12th" },
	{ options: ["#11", "12"], levelName: "Diminished 12th & Perfect 12th" },
];
const lowContrastPart2Levels = [
	{ options: ["12", "b13"], levelName: "Perfect 12th & Minor 13th" },
	{ options: ["b13", "13"], levelName: "Minor 13th & Major 13th" },
	{ options: ["13", "b14"], levelName: "Major 13th & Minor 14th" },
	{ options: ["b14", "14"], levelName: "Minor 14th & Major 14th" },
	{ options: ["14", "15"], levelName: "Major 14th & Double Octave" },
	{ options: ["12", "15"], levelName: "Perfect 12th & Double Octave" },
];
const threeOptionsPart4Levels = [
	{ options: ["b9", "10", "12"], levelName: "Minor 9th, Major 10th & Perfect 12th" },
	{ options: ["9", "11", "b13"], levelName: "Major 9th, Perfect 11th & Minor 13th" },
	{ options: ["#9", "#11", "13"], levelName: "Minor 10th, Diminished 12th & Major 13th" },
	{ options: ["10", "12", "b14"], levelName: "Major 10th, Perfect 12th & Minor 14th" },
	{ options: ["11", "b13", "14"], levelName: "Perfect 11th, Minor 13th & Major 14th" },
	{ options: ["#11", "13", "15"], levelName: "Diminished 12th, Major 13th & Double Octave" },
];
const threeOptionsPart5Levels = [
	{ options: ["12", "b14", "b9"], levelName: "Minor 9th, Perfect 12th & Minor 14th " },
	{ options: ["b13", "14", "9"], levelName: "Major 9th, Minor 13th & Major 14th" },
	{ options: ["13", "15", "#9"], levelName: "Minor 10th, Major 13th & Double Octave" },
	{ options: ["b14", "b9", "10"], levelName: "Major 9th, Minor 14th & Major 10th" },
	{ options: ["14", "9", "11"], levelName: "Major 9th, Perfect 11th & Major 14th" },
	{ options: ["15", "#9", "#11"], levelName: "Minor 10th, Diminished 12th & Double Octave" },
];
const threeOptionsPart6Levels = [
	{ options: ["b9", "#9", "11"], levelName: "Minor 9th, Minor 10th & Perfect 11th" },
	{ options: ["9", "10", "#11"], levelName: "Major 9th, Major 10th & Diminished 12th" },
	{ options: ["#9", "11", "12"], levelName: "Minor 10th, Perfect 11th, & Perfect 12th" },
	{ options: ["10", "#11", "b13"], levelName: "Major 10th, Diminished 12th & Minor 13th" },
	{ options: ["11", "12", "13"], levelName: "Perfect 11th, Perfect 12th & Major 13th" },
	{ options: ["#11", "b13", "b14"], levelName: "Diminished 12th, Minor 13th & Minor 14th" },
];
const threeOptionsPart7Levels = [
	{ options: ["12", "13", "14"], levelName: "Perfect 12th, Major 13th & Major 14th" },
	{ options: ["b13", "b14", "15"], levelName: "Minor 13th, Minor 14th & Double Octave" },
	{ options: ["13", "14", "b9"], levelName: "Minor 9th, Major 13th & Major 14th" },
	{ options: ["b14", "15", "9"], levelName: "Major 9th, Minor 14th & Double Octave" },
	{ options: ["14", "b9", "#9"], levelName: "Minor 9th, Minor 10th & Major 14th" },
	{ options: ["15", "9", "10"], levelName: "Major 9th, Major 10th & Double Octave" },
];
const fourOptionsPart1Levels = [
	{
		options: ["12", "13", "14", "b9"],
		levelName: "Minor 9th, Perfect 12th, Major 13th & Major 14th",
	},
	{ options: ["b13", "b14", "15", "9"], levelName: "Major 9th, Minor 13th, Minor 14th & Double Octave" },
	{ options: ["13", "14", "b9", "#9"], levelName: "Minor 9th, Minor 10th, Major 13th & Major 14th" },
	{ options: ["b14", "15", "9", "10"], levelName: "Major 9th, Major 10th, Minor 14th & Double Octave" },
	{ options: ["14", "b9", "#9", "11"], levelName: "Minor 9th, Minor 10th, Perfect 11th & Major 14th" },
	{ options: ["15", "9", "10", "#11"], levelName: "Major 9th, Major 10th, Diminished 12th & Double Octave" },
];
const fourOptionsPart2Levels = [
	{ options: ["b9", "#9", "11", "12"], levelName: "Minor 9th, Minor 10th, Perfect 11th & Perfect 12th" },
	{
		options: ["9", "10", "#11", "b13"],
		levelName: "Major 9th, Major 10th, Diminished 12th & Minor 13th",
	},
	{
		options: ["#9", "11", "12", "13"],
		levelName: "Minor 10th, Perfect 11th, Perfect 12th & Major 13th",
	},
	{
		options: ["10", "#11", "b13", "b14"],
		levelName: "Major 10th, Diminished 12th, Minor 13th & Minor 14th",
	},
	{
		options: ["11", "12", "13", "14"],
		levelName: "Perfect 11th, Perfect 12th, Major 13th & Major 14th",
	},
	{
		options: ["#11", "b13", "b14", "15"],
		levelName: "Diminished 12th, Minor 13th, Minor 14th & Double Octave",
	},
];
const threeOptionsPart8Levels = [
	{ options: ["b9", "9", "#9"], levelName: "Starting from ♭9" },
	{ options: ["9", "#9", "10"], levelName: "Starting from 9" },
	{ options: ["#9", "10", "11"], levelName: "Starting from ♭10" },
	{ options: ["10", "11", "#11"], levelName: "Starting from 10" },
	{ options: ["11", "#11", "12"], levelName: "Starting from 11" },
	{ options: ["#11", "12", "b13"], levelName: "Starting from ♭12" },
];
const threeOptionsPart9Levels = [
	{ options: ["12", "b13", "13"], levelName: "Starting from 12" },
	{ options: ["b13", "13", "b14"], levelName: "Starting from ♭13" },
	{ options: ["13", "b14", "14"], levelName: "Starting from 13" },
	{ options: ["b14", "14", "15"], levelName: "Starting from ♭14" },
	{ options: ["14", "15", "b9"], levelName: "Starting from 14" },
	{ options: ["15", "b9", "9"], levelName: "Starting from 15" },
];
const fourOptionsPart3Levels = [
	{ options: ["b14", "14", "15", "b9"], levelName: "Starting from ♭14" },
	{ options: ["14", "15", "b9", "9"], levelName: "Starting from 14" },
	{ options: ["15", "b9", "9", "#9"], levelName: "Starting from 15" },
	{ options: ["b9", "9", "#9", "10"], levelName: "Starting from ♭9" },
	{ options: ["9", "#9", "10", "11"], levelName: "Starting from 9" },
	{ options: ["#9", "10", "11", "#11"], levelName: "Starting from ♭10" },
];
const fourOptionsPart4Levels = [
	{ options: ["10", "11", "#11", "12"], levelName: "Starting from 10" },
	{ options: ["11", "#11", "12", "b13"], levelName: "Starting from 11" },
	{ options: ["#11", "12", "b13", "13"], levelName: "Starting from ♭12" },
	{ options: ["12", "b13", "13", "b14"], levelName: "Starting from 12" },
	{ options: ["b13", "13", "b14", "14"], levelName: "Starting from ♭13" },
	{ options: ["13", "b14", "14", "15"], levelName: "Starting from 13" },
];
const fiveOptionsPart1Levels = [
	{ options: ["13", "b14", "14", "15", "b9"], levelName: "Starting from 13" },
	{ options: ["b14", "14", "15", "b9", "9"], levelName: "Starting from ♭14" },
	{ options: ["14", "15", "b9", "9", "#9"], levelName: "Starting from 14" },
	{ options: ["15", "b9", "9", "#9", "10"], levelName: "Starting from 15" },
	{ options: ["b9", "9", "#9", "10", "11"], levelName: "Starting from ♭9" },
	{ options: ["9", "#9", "10", "11", "#11"], levelName: "Starting from 9" },
];
const fiveOptionsPart2Levels = [
	{ options: ["#9", "10", "11", "#11", "12"], levelName: "Starting from ♭10" },
	{ options: ["10", "11", "#11", "12", "b13"], levelName: "Starting from 10" },
	{ options: ["11", "#11", "12", "b13", "13"], levelName: "Starting from 11" },
	{ options: ["#11", "12", "b13", "13", "b14"], levelName: "Starting from ♭12" },
	{ options: ["12", "b13", "13", "b14", "14"], levelName: "Starting from 12" },
	{ options: ["b13", "13", "b14", "14", "15"], levelName: "Starting from ♭13" },
];
const sixOptionsPart1Levels = [
	{ options: ["b13", "13", "b14", "14", "15", "b9"], levelName: "Starting from ♭13" },
	{ options: ["13", "b14", "14", "15", "b9", "9"], levelName: "Starting from 13" },
	{ options: ["b14", "14", "15", "b9", "9", "#9"], levelName: "Starting from ♭14" },
	{ options: ["14", "15", "b9", "9", "#9", "10"], levelName: "Starting from 14" },
	{ options: ["15", "b9", "9", "#9", "10", "11"], levelName: "Starting from 15" },
	{ options: ["b9", "9", "#9", "10", "11", "#11"], levelName: "Starting from ♭9" },
];
const sixOptionsPart2Levels = [
	{ options: ["9", "#9", "10", "11", "#11", "12"], levelName: "Starting from 9" },
	{ options: ["#9", "10", "11", "#11", "12", "b13"], levelName: "Starting from ♭10" },
	{ options: ["10", "11", "#11", "12", "b13", "13"], levelName: "Starting from 10" },
	{ options: ["11", "#11", "12", "b13", "13", "b14"], levelName: "Starting from 11" },
	{ options: ["#11", "12", "b13", "13", "b14", "14"], levelName: "Starting from ♭12" },
	{ options: ["12", "b13", "13", "b14", "14", "15"], levelName: "Starting from 12" },
];
const sevenOptionsPart1Levels = [
	{ options: ["12", "b13", "13", "b14", "14", "15", "b9"], levelName: "Starting from 12" },
	{ options: ["b13", "13", "b14", "14", "15", "b9", "9"], levelName: "Starting from ♭13" },
	{ options: ["13", "b14", "14", "15", "b9", "9", "#9"], levelName: "Starting from 13" },
	{ options: ["b14", "14", "15", "b9", "9", "#9", "10"], levelName: "Starting from ♭14" },
	{ options: ["14", "15", "b9", "9", "#9", "10", "11"], levelName: "Starting from 14" },
	{ options: ["15", "b9", "9", "#9", "10", "11", "#11"], levelName: "Starting from 15" },
];
const sevenOptionsPart2Levels = [
	{ options: ["b9", "9", "#9", "10", "11", "#11", "12"], levelName: "Starting from ♭9" },
	{ options: ["9", "#9", "10", "11", "#11", "12", "b13"], levelName: "Starting from 9" },
	{ options: ["#9", "10", "11", "#11", "12", "b13", "13"], levelName: "Starting from ♭10" },
	{ options: ["10", "11", "#11", "12", "b13", "13", "b14"], levelName: "Starting from 10" },
	{ options: ["11", "#11", "12", "b13", "13", "b14", "14"], levelName: "Starting from 11" },
	{ options: ["#11", "12", "b13", "13", "b14", "14", "15"], levelName: "Starting from ♭12" },
];
const eightOptionsPart1Levels = [
	{ options: ["#11", "12", "b13", "13", "b14", "14", "15", "b9"], levelName: "Starting from ♭12" },
	{ options: ["12", "b13", "13", "b14", "14", "15", "b9", "9"], levelName: "Starting from 12" },
	{ options: ["b13", "13", "b14", "14", "15", "b9", "9", "#9"], levelName: "Starting from ♭13" },
	{ options: ["13", "b14", "14", "15", "b9", "9", "#9", "10"], levelName: "Starting from 13" },
	{ options: ["b14", "14", "15", "b9", "9", "#9", "10", "11"], levelName: "Starting from ♭14" },
	{ options: ["14", "15", "b9", "9", "#9", "10", "11", "#11"], levelName: "Starting from 14" },
];
const eightOptionsPart2Levels = [
	{ options: ["15", "b9", "9", "#9", "10", "11", "#11", "12"], levelName: "Starting from 15" },
	{ options: ["b9", "9", "#9", "10", "11", "#11", "12", "b13"], levelName: "Starting from ♭9" },
	{ options: ["9", "#9", "10", "11", "#11", "12", "b13", "13"], levelName: "Starting from 9" },
	{ options: ["#9", "10", "11", "#11", "12", "b13", "13", "b14"], levelName: "Starting from ♭10" },
	{ options: ["10", "11", "#11", "12", "b13", "13", "b14", "14"], levelName: "Starting from 10" },
	{ options: ["11", "#11", "12", "b13", "13", "b14", "14", "15"], levelName: "Starting from 11" },
];
const nineOptionsPart1Levels = [
	{ options: ["11", "#11", "12", "b13", "13", "b14", "14", "15", "b9"], levelName: "Starting from 11" },
	{ options: ["#11", "12", "b13", "13", "b14", "14", "15", "b9", "9"], levelName: "Starting from ♭12" },
	{ options: ["12", "b13", "13", "b14", "14", "15", "b9", "9", "#9"], levelName: "Starting from 12" },
	{ options: ["b13", "13", "b14", "14", "15", "b9", "9", "#9", "10"], levelName: "Starting from ♭13" },
	{ options: ["13", "b14", "14", "15", "b9", "9", "#9", "10", "11"], levelName: "Starting from 13" },
	{ options: ["b14", "14", "15", "b9", "9", "#9", "10", "11", "#11"], levelName: "Starting from ♭14" },
];
const nineOptionsPart2Levels = [
	{ options: ["14", "15", "b9", "9", "#9", "10", "11", "#11", "12"], levelName: "Starting from 14" },
	{ options: ["15", "b9", "9", "#9", "10", "11", "#11", "12", "b13"], levelName: "Starting from 15" },
	{ options: ["b9", "9", "#9", "10", "11", "#11", "12", "b13", "13"], levelName: "Starting from ♭9" },
	{ options: ["9", "#9", "10", "11", "#11", "12", "b13", "13", "b14"], levelName: "Starting from 9" },
	{ options: ["#9", "10", "11", "#11", "12", "b13", "13", "b14", "14"], levelName: "Starting from ♭10" },
	{ options: ["10", "11", "#11", "12", "b13", "13", "b14", "14", "15"], levelName: "Starting from 10" },
];
const tenOptionsPart1Levels = [
	{ options: ["b9", "9", "#9", "10", "11", "#11", "12", "b13", "13", "b14"], levelName: "Starting from ♭9" },
	{ options: ["9", "#9", "10", "11", "#11", "12", "b13", "13", "b14", "14"], levelName: "Starting from 9" },
	{ options: ["#9", "10", "11", "#11", "12", "b13", "13", "b14", "14", "15"], levelName: "Starting from ♭10" },
	{ options: ["10", "11", "#11", "12", "b13", "13", "b14", "14", "15", "b9"], levelName: "Starting from 10" },
	{ options: ["11", "#11", "12", "b13", "13", "b14", "14", "15", "b9", "9"], levelName: "Starting from 11" },
	{ options: ["#11", "12", "b13", "13", "b14", "14", "15", "b9", "9", "#9"], levelName: "Starting from ♭12" },
];
const tenOptionsPart2Levels = [
	{ options: ["12", "b13", "13", "b14", "14", "15", "b9", "9", "#9", "10"], levelName: "Starting from 12" },
	{ options: ["b13", "13", "b14", "14", "15", "b9", "9", "#9", "10", "11"], levelName: "Starting from ♭13" },
	{ options: ["13", "b14", "14", "15", "b9", "9", "#9", "10", "11", "#11"], levelName: "Starting from 13" },
	{ options: ["b14", "14", "15", "b9", "9", "#9", "10", "11", "#11", "12"], levelName: "Starting from ♭14" },
	{ options: ["14", "15", "b9", "9", "#9", "10", "11", "#11", "12", "b13"], levelName: "Starting from 14" },
	{ options: ["15", "b9", "9", "#9", "10", "11", "#11", "12", "b13", "13"], levelName: "Starting from 15" },
];
const elevenOptionsPart1Levels = [
	{ options: ["b9", "9", "#9", "10", "11", "#11", "12", "b13", "13", "b14", "14"], levelName: "Starting from ♭9" },
	{ options: ["9", "#9", "10", "11", "#11", "12", "b13", "13", "b14", "14", "15"], levelName: "Starting from 9" },
	{ options: ["#9", "10", "11", "#11", "12", "b13", "13", "b14", "14", "15", "b9"], levelName: "Starting from ♭10" },
	{ options: ["10", "11", "#11", "12", "b13", "13", "b14", "14", "15", "b9", "9"], levelName: "Starting from 10" },
	{ options: ["11", "#11", "12", "b13", "13", "b14", "14", "15", "b9", "9", "#9"], levelName: "Starting from 11" },
	{ options: ["#11", "12", "b13", "13", "b14", "14", "15", "b9", "9", "#9", "10"], levelName: "Starting from ♭12" },
];
const elevenOptionsPart2Levels = [
	{ options: ["12", "b13", "13", "b14", "14", "15", "b9", "9", "#9", "10", "11"], levelName: "Starting from 12" },
	{ options: ["b13", "13", "b14", "14", "15", "b9", "9", "#9", "10", "11", "#11"], levelName: "Starting from ♭13" },
	{ options: ["13", "b14", "14", "15", "b9", "9", "#9", "10", "11", "#11", "12"], levelName: "Starting from 13" },
	{ options: ["b14", "14", "15", "b9", "9", "#9", "10", "11", "#11", "12", "b13"], levelName: "Starting from ♭14" },
	{ options: ["14", "15", "b9", "9", "#9", "10", "11", "#11", "12", "b13", "13"], levelName: "Starting from 14" },
	{ options: ["15", "b9", "9", "#9", "10", "11", "#11", "12", "b13", "13", "b14"], levelName: "Starting from 15" },
];
const bossModeLevels = [
	{
		options: ["b9", "9", "#9", "10", "11", "#11", "12", "b13", "13", "b14", "14", "15"],
		levelName: "All intervals",
	},
];

export const INTERVAL_RECOGNITION_OCTAVE_HIGHER_SECTIONS = [
	createSection("High contrast", highContrastLevels),
	createSection("Related intervals", relatedIntervalsLevels),
	createSection("Three options 1", threeOptionsPart1Levels),
	createSection("Three options 9", threeOptionsPart2Levels),
	createSection("Three options 10", threeOptionsPart3Levels),
	createSection("Low contrast 1", lowContrastPart1Levels),
	createSection("Low contrast 9", lowContrastPart2Levels),
	createSection("Three options 11", threeOptionsPart4Levels),
	createSection("Three options 12", threeOptionsPart5Levels),
	createSection("Three options 13", threeOptionsPart6Levels),
	createSection("Three options 14", threeOptionsPart7Levels),
	createSection("Four options 1", fourOptionsPart1Levels),
	createSection("Four options 9", fourOptionsPart2Levels),
	createSection("Three options 15", threeOptionsPart8Levels),
	createSection("Three options 9", threeOptionsPart9Levels),
	createSection("Four options 10", fourOptionsPart3Levels),
	createSection("Four options 11", fourOptionsPart4Levels),
	createSection("Five options 1", fiveOptionsPart1Levels),
	createSection("Five options 9", fiveOptionsPart2Levels),
	createSection("Six options 1", sixOptionsPart1Levels),
	createSection("Six options 9", sixOptionsPart2Levels),
	createSection("Seven options 1", sevenOptionsPart1Levels),
	createSection("Seven options 9", sevenOptionsPart2Levels),
	createSection("Eight options 1", eightOptionsPart1Levels),
	createSection("Eight options 9", eightOptionsPart2Levels),
	createSection("Nine options 1", nineOptionsPart1Levels),
	createSection("Nine options 9", nineOptionsPart2Levels),
	createSection("Ten options 1", tenOptionsPart1Levels),
	createSection("Ten options 9", tenOptionsPart2Levels),
	createSection("Eleven options 1", elevenOptionsPart1Levels),
	createSection("Eleven options 9", elevenOptionsPart2Levels),
	createSection("Boss Mode", bossModeLevels),
];
