import * as e from "../../enums";
import { createSection } from "./utils";

const majorLevels = [
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MAJOR_1ST_INVERSION],
		levelName: "Major Root & 1st inversion",
	},
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MAJOR_2ND_INVERSION],
		levelName: "Major Root & 2nd inversion",
	},
	{
		options: [e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MAJOR_2ND_INVERSION],
		levelName: "Major 1st inversion & 2nd inversion",
	},
];
const minorLevels = [
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MINOR_1ST_INVERSION],
		levelName: "Minor Root & 1st inversion",
	},
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Minor Root & 2nd inversion",
	},
	{
		options: [e.Inversions.MINOR_1ST_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Minor 1st inversion & 2nd inversion",
	},
];
const twoChordCombos1Levels = [
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MINOR_1ST_INVERSION],
		levelName: "Major Root & Minor 1st inversion",
	},
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major Root & Minor 2nd inversion",
	},
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MAJOR_1ST_INVERSION],
		levelName: "Minor Root & Major 1st inversion",
	},
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MAJOR_2ND_INVERSION],
		levelName: "Minor Root & Major 2nd inversion",
	},
];
const twoChordCombos2Levels = [
	{
		options: [e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MINOR_1ST_INVERSION],
		levelName: "Major 1st inversion & Minor 1st inversion",
	},
	{
		options: [e.Inversions.MAJOR_2ND_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major 2nd inversion & Minor 2nd inversion",
	},
	{
		options: [e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major 1st inversion & Minor 2nd inversion",
	},
	{
		options: [e.Inversions.MAJOR_2ND_INVERSION, e.Inversions.MINOR_1ST_INVERSION],
		levelName: "Major 2nd inversion & Minor 1st inversion",
	},
];
const threeChordCombos1Levels = [
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MINOR_ROOT, e.Inversions.MAJOR_1ST_INVERSION],
		levelName: "Major Root, Minor Root & Major 1st inversion",
	},
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MINOR_ROOT, e.Inversions.MAJOR_2ND_INVERSION],
		levelName: "Major Root, Minor Root & Major 2nd inversion",
	},
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MINOR_ROOT, e.Inversions.MINOR_1ST_INVERSION],
		levelName: "Major Root, Minor Root & Minor 1st inversion",
	},
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MINOR_ROOT, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major Root, Minor Root & Minor 2nd inversion",
	},
];
const threeChordCombos2Levels = [
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MAJOR_2ND_INVERSION],
		levelName: "Major Root, Major 1st inversion & Major 2nd inversion",
	},
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MINOR_1ST_INVERSION],
		levelName: "Major Root, Major 1st inversion & Minor 1st inversion",
	},
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major Root, Major 1st inversion & Minor 2nd inversion",
	},
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MAJOR_2ND_INVERSION, e.Inversions.MINOR_1ST_INVERSION],
		levelName: "Major Root, Major 2nd inversion & Minor 1st inversion",
	},
];
const threeChordCombos3Levels = [
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MAJOR_2ND_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major Root, Major 2nd inversion & Minor 2nd inversion",
	},
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MINOR_1ST_INVERSION, e.Inversions.MAJOR_1ST_INVERSION],
		levelName: "Minor Root, Minor 1st inversion & Major 1st inversion",
	},
	{
		options: [e.Inversions.MAJOR_2ND_INVERSION, e.Inversions.MINOR_1ST_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major 2nd inversion, Minor 1st inversion & Minor 2nd inversion",
	},
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MAJOR_2ND_INVERSION, e.Inversions.MAJOR_2ND_INVERSION],
		levelName: "Minor Root, Major 2nd inversion & Major 2nd inversion",
	},
];
const threeChordCombos4Levels = [
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MAJOR_2ND_INVERSION, e.Inversions.MINOR_1ST_INVERSION],
		levelName: "Minor Root, Major 2nd inversion & Minor 1st inversion",
	},
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MAJOR_2ND_INVERSION],
		levelName: "Minor Root, Major 1st inversion & Major 2nd inversion",
	},
	{
		options: [e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MAJOR_2ND_INVERSION, e.Inversions.MINOR_1ST_INVERSION],
		levelName: "Major 1st inversion, Major 2nd inversion & Minor 1st inversion",
	},
	{
		options: [e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MAJOR_2ND_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major 1st inversion, Major 2nd inversion & Minor 2nd inversion",
	},
];
const threeChordCombos5Levels = [
	{
		options: [e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MINOR_1ST_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major 1st inversion, Minor 1st inversion & Minor 2nd inversion",
	},
	{
		options: [e.Inversions.MAJOR_ROOT, e.Inversions.MINOR_1ST_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Major Root, Minor 1st inversion & Minor 2nd inversion",
	},
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MAJOR_1ST_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Minor Root, Major 1st inversion & Minor 2nd inversion",
	},
	{
		options: [e.Inversions.MINOR_ROOT, e.Inversions.MINOR_1ST_INVERSION, e.Inversions.MINOR_2ND_INVERSION],
		levelName: "Minor Root, Minor 1st inversion & Minor 2nd inversion",
	},
];
const fourChordCombosLevels = [
	{
		options: [
			e.Inversions.MAJOR_ROOT,
			e.Inversions.MINOR_ROOT,
			e.Inversions.MAJOR_1ST_INVERSION,
			e.Inversions.MINOR_1ST_INVERSION,
		],
		levelName: "All root and 1st inversions",
	},
	{
		options: [
			e.Inversions.MAJOR_ROOT,
			e.Inversions.MINOR_ROOT,
			e.Inversions.MAJOR_2ND_INVERSION,
			e.Inversions.MINOR_2ND_INVERSION,
		],
		levelName: "All root and 2nd inversions",
	},
	{
		options: [
			e.Inversions.MAJOR_1ST_INVERSION,
			e.Inversions.MINOR_1ST_INVERSION,
			e.Inversions.MAJOR_2ND_INVERSION,
			e.Inversions.MINOR_2ND_INVERSION,
		],
		levelName: "All 1st and 2nd inversions",
	},
];
const bossLevelLevels = [
	{
		options: [
			e.Inversions.MAJOR_ROOT,
			e.Inversions.MINOR_ROOT,
			e.Inversions.MAJOR_1ST_INVERSION,
			e.Inversions.MINOR_1ST_INVERSION,
			e.Inversions.MAJOR_2ND_INVERSION,
			e.Inversions.MINOR_2ND_INVERSION,
		],
		levelName: "All inversions",
	},
];

export const TRIAD_INVERSIONS = [
	createSection("Major", majorLevels),
	createSection("Minor", minorLevels),
	createSection("Two Chord Combos 1", twoChordCombos1Levels),
	createSection("Two Chord Combos 2", twoChordCombos2Levels),
	createSection("Three Chord Combos 1", threeChordCombos1Levels),
	createSection("Three Chord Combos 2", threeChordCombos2Levels),
	createSection("Three Chord Combos 3", threeChordCombos3Levels),
	createSection("Three Chord Combos 4", threeChordCombos4Levels),
	createSection("Three Chord Combos 5", threeChordCombos5Levels),
	createSection("Four Chord Combos", fourChordCombosLevels),
	createSection("Boss Mode", bossLevelLevels),
];
