import { createSection } from "./utils";
import * as e from "../../enums";

const j = e.DegreesJazz;

const tobVII = [j.bVIImaj7, j.ii7overbVII, j.V7overbVII];
const toVII = [j.VIImaj7, j.ii7overVII, j.V7overVII];
const tobII = [j.bIImaj7, j.ii7overbII, j.V7overbII];
const tobIII = [j.bIIImaj7, j.ii7overbIII, j.V7overbIII];
const tobVI = [j.bVImaj7, j.ii7overbVI, j.V7overbVI];

const tobVIITritone = [j.bVIImaj7, j.ii7overbVII, j.V7overbVII, j.bII7overbVII];
const toVIITritone = [j.VIImaj7, j.ii7overVII, j.V7overVII, j.bII7overVII];
const tobIITritone = [j.bIImaj7, j.ii7overbII, j.V7overbII, j.bII7overbII];
const tobIIITritone = [j.bIIImaj7, j.ii7overbIII, j.V7overbIII, j.bII7overbIII];
const tobVITritone = [j.bVImaj7, j.ii7overbVI, j.V7overbVI, j.bII7overbVI];

const modulations1 = [
	{
		options: [j.Imaj7, ...tobVIITritone],
		levelName: "Modulation down a whole step",
	},
	{
		options: [j.Imaj7, ...toVIITritone],
		levelName: "Modulation down a half step",
	},
	{
		options: [j.Imaj7, ...tobIITritone],
		levelName: "Modulation up a half step",
	},
	{
		options: [j.Imaj7, ...tobIIITritone],
		levelName: "Modulation up a minor 3rd",
	},
	{
		options: [j.Imaj7, ...tobVITritone],
		levelName: "Modulation down a major 3rd",
	},
];

const modulations2 = [
	{
		options: [j.Imaj7, ...tobVII, ...toVII],
		levelName: "Modulation to bVII and VII",
	},
	{
		options: [j.Imaj7, ...tobVII, ...tobII],
		levelName: "Modulation to bVII and II",
	},
	{
		options: [j.Imaj7, ...tobVII, ...tobIII],
		levelName: "Modulation to bVII and III",
	},
	{
		options: [j.Imaj7, ...tobVII, ...tobVI],
		levelName: "Modulation to bVII and bVI",
	},
	{
		options: [j.Imaj7, ...toVII, ...tobII],
		levelName: "Modulation to VII and II",
	},
];

const modulations3 = [
	{
		options: [j.Imaj7, ...toVII, ...tobIII],
		levelName: "Modulation to VII and III",
	},
	{
		options: [j.Imaj7, ...toVII, ...tobVI],
		levelName: "Modulation to VII and bVI",
	},
	{
		options: [j.Imaj7, ...tobII, ...tobIII],
		levelName: "Modulation to II and III",
	},
	{
		options: [j.Imaj7, ...tobII, ...tobVI],
		levelName: "Modulation to II and bVI",
	},
	{
		options: [j.Imaj7, ...tobIII, ...tobVI],
		levelName: "Modulation to III and bVI",
	},
];

const modulations4 = [
	{
		options: [j.Imaj7, ...tobVIITritone, ...toVIITritone],
		levelName: "Modulation to bVII and VII",
	},
	{
		options: [j.Imaj7, ...tobVIITritone, ...tobIITritone],
		levelName: "Modulation to bVII and II",
	},
	{
		options: [j.Imaj7, ...tobVIITritone, ...tobIIITritone],
		levelName: "Modulation to bVII and III",
	},
	{
		options: [j.Imaj7, ...tobVIITritone, ...tobVITritone],
		levelName: "Modulation to bVII and bVI",
	},
	{
		options: [j.Imaj7, ...toVIITritone, ...tobIITritone],
		levelName: "Modulation to VII and II",
	},
];

const modulations5 = [
	{
		options: [j.Imaj7, ...toVIITritone, ...tobIIITritone],
		levelName: "Modulation to VII and III",
	},
	{
		options: [j.Imaj7, ...toVIITritone, ...tobVITritone],
		levelName: "Modulation to VII and bVI",
	},
	{
		options: [j.Imaj7, ...tobIITritone, ...tobIIITritone],
		levelName: "Modulation to II and III",
	},
	{
		options: [j.Imaj7, ...tobIITritone, ...tobVITritone],
		levelName: "Modulation to II and bVI",
	},
	{
		options: [j.Imaj7, ...tobIIITritone, ...tobVITritone],
		levelName: "Modulation to III and bVI",
	},
];

const modulations6 = [
	{
		options: [j.Imaj7, ...tobVIITritone, ...toVIITritone, ...tobIITritone],
		levelName: "Modulation to bVII, VII and II",
	},
	{
		options: [j.Imaj7, ...tobVIITritone, ...toVIITritone, ...tobIIITritone],
		levelName: "Modulation to bVII, VII and III",
	},
	{
		options: [j.Imaj7, ...tobVIITritone, ...toVIITritone, ...tobVITritone],
		levelName: "Modulation to bVII, VII and bVI",
	},
	{
		options: [j.Imaj7, ...tobVIITritone, ...tobIITritone, ...tobIIITritone],
		levelName: "Modulation to bVII, II and III",
	},
];

const modulations7 = [
	{
		options: [j.Imaj7, ...tobVIITritone, ...tobIIITritone, ...tobVITritone],
		levelName: "Modulation to bVII, III and bVI",
	},
	{
		options: [j.Imaj7, ...toVIITritone, ...tobIITritone, ...tobIIITritone],
		levelName: "Modulation to VII, II and III",
	},
	{
		options: [j.Imaj7, ...toVIITritone, ...tobIITritone, ...tobVITritone],
		levelName: "Modulation to VII, II and bVI",
	},
	{
		options: [j.Imaj7, ...tobIITritone, ...tobIIITritone, ...tobVITritone],
		levelName: "Modulation to II, III and bVI",
	},
];

const modulations8 = [
	{
		options: [j.Imaj7, ...tobVIITritone, ...toVIITritone, ...tobIITritone, ...tobIIITritone],
		levelName: "Modulation to bVII, VII, II, III",
	},
	{
		options: [j.Imaj7, ...tobVIITritone, ...toVIITritone, ...tobIITritone, ...tobVITritone],
		levelName: "Modulation to bVII, VII, II, bVI",
	},
	{
		options: [j.Imaj7, ...tobVIITritone, ...toVIITritone, ...tobIIITritone, ...tobVITritone],
		levelName: "Modulation to bVII, VII, III, bVI",
	},
	{
		options: [j.Imaj7, ...tobVIITritone, ...tobIITritone, ...tobIIITritone, ...tobVITritone],
		levelName: "Modulation to bVII, II, III, bVI",
	},
];
const modulations9 = [
	{
		options: [j.Imaj7, ...tobVIITritone, ...toVIITritone, ...tobIITritone, ...tobIIITritone, ...tobVITritone],
		levelName: "All degrees",
	},
];

export const PROGRESSION_JAZZ_MODULATIONS_MAJOR = [
	createSection("Introduction", modulations1),
	createSection("Getting familiar 1", modulations2),
	createSection("Getting familiar 2", modulations3),
	createSection("Introducing tritone substitutions 1", modulations4),
	createSection("Introducing tritone substitutions 2", modulations5),
	createSection("Increasing combinations 1", modulations6),
	createSection("Increasing combinations 2", modulations7),
	createSection("Increasing combinations 3", modulations8),
	createSection("Boss level", modulations9),
];
