const userAgent = navigator.userAgent.toLowerCase();

export function isSafariBrowser() {
	return /safari/.test(userAgent) && !/chrome/.test(userAgent);
}

export function isSafariIOSBrowser() {
	const isIOS = /iphone|ipod|ipad/.test(userAgent);
	return isSafariBrowser() && isIOS;
}

export function isChromeBrowser() {
	const isAndroidChrome = /chrome/.test(userAgent) && /android/.test(userAgent);
	const isIOSChrome = userAgent.includes("crios");
	const isDesktopChrome = /chrome/.test(userAgent) && !/edge/.test(userAgent);

	return isAndroidChrome || isIOSChrome || isDesktopChrome;
}

export function isAppleDevice() {
	return /macintosh|iphone|ipad|ipod/.test(userAgent);
}

export function isAndroidDevice() {
	return /android/.test(userAgent);
}

export function isFirefoxMacBrowser() {
    return /firefox/.test(userAgent) && /macintosh/.test(userAgent);
}
